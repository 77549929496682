import styled from 'styled-components';
import { Opacity, Shadows } from '../GlobalStyle';
import { ModalStyledProps } from './interface';
import { device } from '../Screen';
export const ModalPage = styled.div<ModalStyledProps>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: rgba(0, 0, 0, ${Opacity.medium});
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

interface ModalContainerProps {
  bottom: number
}

export const ModalContainer = styled.div<ModalContainerProps>`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  max-width: 80%;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: ${Shadows.high};
  
  @media ${device.xs} {
    width: 100%;
    max-width: 100%;
    bottom: ${({ bottom }) => (bottom ? bottom : 0) + 'px'};
    border-end-end-radius: 0;
    border-end-start-radius: 0;
  }
`;

import React, { useContext, useEffect, useState } from 'react';
import { GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import Button from '../../../../../common/designSystem/Button/Button';
import { SportCenterHomeContext } from '../../Home';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';
import ShowCourtStatus from '../../../../../common/component/ShowCourtStatus/ShowCourtStatus';
import { ViewSchedules } from '../../../../../common/model/SportCenter';
import { isDefined } from '../../../../../common/util/validations';

const CentersTime = () => {
  const { setFilter, filter, sportCenter, getSportCenter } = useContext(SportCenterHomeContext);
  const [schedules, setSchedules] = useState([] as ViewSchedules[]);

  useEffect(() => {
    if (isDefined(sportCenter?.courts)) {
      let map = [] as ViewSchedules[];
      sportCenter.courts!.forEach((court) => {
        map = [...map, ...court.viewSchedules!];
      });
      setSchedules([...map.sort((a, b) => Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', '')))]);
    }
  }, [sportCenter]);

  return (
    <>
      <GridItem xs={6}>
        <Typography variant={'small'} bold>Horários do Centro</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Carousel height={'46px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
          <Button color={'primary'} onClick={() => setFilter({ ...filter, status: 'reserved' })} selected={filter?.status === 'reserved'} variant={'select-border'} title={'Reservados'}/>
          <Button color={'primary'} onClick={() => setFilter({ ...filter, status: 'available' })} selected={filter?.status === 'available'} variant={'select-border'} title={'Disponíveis'}/>
        </Carousel>
      </GridItem>
      {schedules.filter((schedule) => schedule.status === filter.status).slice(0, 3).map((schedule) =>
        <GridItem xs={6}>
          <ShowCourtStatus {...schedule} get={getSportCenter} date={new Date()}/>
        </GridItem>
      )}
    </>
  );
};

export default CentersTime;

import React from 'react';

const GraphDot = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M0.878662 0.878418V21.1215H21.1218" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M7.14873 5.9917C8.7103 5.9917 9.97618 7.2576 9.97618 8.81915C9.97618 10.3807 8.7103 11.6466 7.14873 11.6466C5.58717 11.6466 4.32129 10.3807 4.32129 8.81915C4.32129 7.2576 5.58717 5.9917 7.14873 5.9917Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.2938 1.26514C19.8552 1.26514 21.1212 2.53103 21.1212 4.09259C21.1212 5.65415 19.8552 6.92004 18.2938 6.92004C16.7323 6.92004 15.4663 5.65415 15.4663 4.09259C15.4663 2.53103 16.7323 1.26514 18.2938 1.26514Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.3631 11.647C15.9247 11.647 17.1906 12.9129 17.1906 14.4744C17.1906 16.036 15.9247 17.3019 14.3631 17.3019C12.8015 17.3019 11.5356 16.036 11.5356 14.4744C11.5356 12.9129 12.8015 11.647 14.3631 11.647Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.878174 14.8595L4.91295 10.5508" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.61475 10.2285L12.202 12.6416" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.6101 11.9206L17.4759 6.7959" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default GraphDot;

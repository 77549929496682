import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import CardCourtData from '../../../common/component/CardCourtData/CardCourtData';
import { Shadows } from '../../../common/designSystem/GlobalStyle';
import { SportCenter } from '../../../common/model/SportCenter';
import UserService from '../../../common/services/user';
import SportCenterService from '../../../common/services/sportCenter';
import { isDefined } from '../../../common/util/validations';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import MenuSportCenter from '../../../common/component/MenuSportCenter/MenuSportCenter';
import ScheduleService from '../../../common/services/schedule';

const MyCourts = () => {
  const navigate = useNavigate();
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const userId = async () => (await UserService.getCurrent())!.id;
  const getSportCenter = async () => {
    let sportCenter = await SportCenterService.getByUserId(await userId());
    sportCenter = { ...sportCenter, courts: await SportCenterService.getCourts(sportCenter.id!) };
    if (isDefined(sportCenter?.courts)) {
      const updatedCourts = [];
      for (const court of sportCenter.courts!) {
        const updatedCourt = {
          ...court,
          viewSchedules: await ScheduleService.getViewSchedules(court.id!)
        };
        updatedCourts.push(updatedCourt);
      }
      sportCenter.courts = updatedCourts;
    }
    if (isDefined(sportCenter.workTime)) {
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);

  return (
    <>
      <Grid alignContent={'start'} isScrollable height={'calc(100% - 128px)'} style={{ paddingTop: '24px', paddingBottom: '16px' }} rowGapXs={16} alignItems={'center'}>
        <GridItem xs={6}>
          <Typography variant={'h2'}>Suas quadras</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'paragraph'}>Selecione a quadra para ver os horários</Typography>
        </GridItem>
        {sportCenter?.courts?.map((court) => {
          let countAvailable = 0;
          let countReserved = 0;
          court.viewSchedules?.forEach((view) => {
            if (view.status === 'reserved') {
              countReserved++;
            } else {
              countAvailable++;
            }
          });
          return <GridItem xs={6}>
            <CardCourtData onClick={() => navigate(RoutePath.SPORT_CENTER_COURT + '?id=' + court.id)} key={court.name}
              court={court.name} available={countAvailable} reserved={countReserved} style={{
                boxShadow: Shadows.med
              }} />
          </GridItem>;
        })}
      </Grid>
      <MenuSportCenter/>
    </>
  );
};

export default MyCourts;

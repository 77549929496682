import React, { useEffect } from 'react';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';
import GameCard from './GameCard';
import { Schedule } from '../../../../../common/model/Schedule';
import { Team } from '../../../../../common/model/Team';
import { isDefined } from '../../../../../common/util/validations';
import { propsFilter } from '../../../../../common/util/web';

const GameCardsCarousel = ({ schedules, teams }: {schedules: Schedule[], teams: Team[] }) => {
  return (<>
    <Carousel height={'214px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
      {schedules.map((schedule) =>
        <GameCard schedule={schedule} key={schedule.id} team={teams.find((time) => isDefined(propsFilter(time.scheduledIds, undefined, schedule.id))) ?? {} as Team}></GameCard>
      )}
    </Carousel>
  </>);
};

export default GameCardsCarousel;

import React, { useEffect, useRef } from 'react';
import { AccordionProps } from './interface';
import { Grid, GridItem } from '../Grid/Grid';
import { getPaddingSize } from '../Grid/styled';
import { getDevice } from '../Screen';
import { Container, Icon } from './styled';
import Next from '../Icons/Next';

const Accordion = ({ open, onClick, header, children, color = 'neutral', colorVariant = 'white', autoScroll = false, ...props }: AccordionProps) => {
  const id = Math.random() + '';

  useEffect(() => {
    if (open && autoScroll) {
      setTimeout(() => document.getElementById(id)?.scrollIntoView(), 200);
    }
  }, [open]);

  return (
    <GridItem {...props} id={id} color={color} colorVariant={colorVariant} onClick={() => open ? () => {} : onClick()} style={{ cursor: open ? 'auto' : 'pointer', borderRadius: '4px', background: 'transparent', padding: getPaddingSize(getDevice(window.innerWidth)) + 'px 0 0 0' }}>
      <Grid noMargin style={{ width: '100%', background: 'transparent' }}>
        <GridItem style={{ cursor: 'pointer', background: 'transparent' }} onClick={() => onClick()} xs={6} marginXs={`0 0 ${getPaddingSize(getDevice(window.innerWidth))}px 0`}>
          <Grid noMargin>
            <GridItem xs={5}>
              {header}
            </GridItem>
            <GridItem justifyContent={'center'} alignItems={'flex-end'} xs={1}>
              <Icon open={open}>
                <Next/>
              </Icon>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem xs={6}>
          <Container open={open}>
            <Grid noMargin rowGapXs={props?.rowGapXs}>
              {children}
            </Grid>
          </Container>
        </GridItem>
      </Grid>
    </GridItem>
  );
};

export default Accordion;

import React, { ReactElement, useState } from 'react';
import { HeaderStyled, UseSpace } from './styled';
import Logo from '../../designSystem/Logo/Logo';
import Menu from '../../designSystem/Icons/Menu';
import Button from '../../designSystem/Button/Button';
import { isDefined } from '../../util/validations';
import { setLocalStorage } from '../../util/localStorage';

const Header = ({ onlyLogo = false, children }: {onlyLogo?: boolean, children?: ReactElement[] | ReactElement}) => {
  const [environmentMode, setEnvironmentMode] = useState(0);

  return (
    <>
      <HeaderStyled onClick={() => {
        if (environmentMode > 10) {
          setLocalStorage(true, 'environmentMode');
        }
      }} onlyLogo={onlyLogo}>
        <div onClick={() => setEnvironmentMode(environmentMode + 1)}><Logo size={'xs'}/></div>
        {!onlyLogo && !isDefined(children) && <Button accessibleTitle={'Menu'} color={'neutral'} onClick={() => null} variant={'transparent'} icon={<Menu color={'neutral'} colorVariant={'a900'}/>}/>}
        {isDefined(children) && <>{children}</>}
      </HeaderStyled>
      <UseSpace/>
    </>
  );
};

export default Header;

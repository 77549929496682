import React from 'react';
import { Grid, GridItem } from '../../designSystem/Grid/Grid';
import Typography from '../../designSystem/Typography/Typography';
import { RoundDiv } from '../../../pages/SportCenter/Home/components/ManagementMyCenter/styled';
import { Colors } from '../../designSystem/Colors';
import styled from 'styled-components';

export interface CourtData extends React.HTMLAttributes<HTMLDivElement>{
  court: string;
  available: number;
  reserved: number;
}

export const StyledGridItem = styled(GridItem)`
  cursor: pointer;
  padding: 15px 16px;
  min-width: calc(275px - 32px);
  border-radius: 8px;
`;

const CardCourtData = ({ court, available, reserved, ...Props }: CourtData) => {
  return (
    <StyledGridItem {...{ ...Props }} xs={6} color={'neutral'} colorVariant={'white'}>
      <Grid noMargin rowGapXs={10} alignContent={'flex-start'}>
        <GridItem xs={6}>
          <Typography style={{ textAlign: 'left' }} variant={'body'}>{court}</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
          <RoundDiv color={Colors.chart6.main}/>
          <Typography variant={'small'} bold>{available}</Typography>
          <Typography variant={'small'}>Disponível</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
          <RoundDiv color={Colors.chart2.main}/>
          <Typography variant={'small'} bold>{reserved}</Typography>
          <Typography variant={'small'}>Reservado</Typography>
        </GridItem>
      </Grid>
    </StyledGridItem>
  );
};

export default CardCourtData;

import React from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Button from '../../../common/designSystem/Button/Button';
import MercadoPagoButtonGroup from './components/ButtonGroup/MercadoPagoButtonGroup';
import GameLogo from './components/GameLogo/GameLogo';
import FinancialManagementGroupButton from './components/ButtonGroup/FinancialManagementGroupButton';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';

const LandingPageFinanceMenager = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid alignContent={'start'} alignItems='center' isScrollable height={'calc(100% - 66px)'} rowGapXs={8}>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h1'} >Maximize seus lucros de uma maneira simples e segura.</Typography>
          <Typography variant={'paragraph'} >Venda os horários do seu centro esportivo com facilidade.</Typography>
        </GridItem>
        <GridItem xs={6}>
          <GameLogo></GameLogo>
        </GridItem>
        <GridItem xs={6}>
          <Button color={'primary'} onClick={() => {}} variant={'filled'} title={'Comece agora'} isFullWidth/>
        </GridItem>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h2'} bold={false}>Gestão financeira do seu centro</Typography>
          <Typography variant={'paragraph'} >Tenha o controle do que você ganhou por mês, por dia e uma média da semana</Typography>
        </GridItem>
        <FinancialManagementGroupButton></FinancialManagementGroupButton>
        <GridItem xs={6}>
          <Button color={'primary'} onClick={() => {}} variant={'filled'} title={'Comece agora'} isFullWidth/>
        </GridItem>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h2'} bold={false}>Conta Mercado Pago para simplificar seu negócio</Typography>
          <Typography variant={'paragraph'} >Utilizamos os meios de pagamento do Mercado Pago para garantir a sua segurança</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Typography variant='small' bold>Benefícios de utilizar o Mercado Pago</Typography>
        </GridItem>
        <GridItem xs={6}>
          <MercadoPagoButtonGroup></MercadoPagoButtonGroup>
        </GridItem>
        <GridItem xs={6}>
          <Button color={'primary'} onClick={() => navigate(RoutePath.SPORT_CENTER_CREATE_ACCOUNT) } variant={'filled'} title={'Comece agora'} isFullWidth/>
        </GridItem>
      </Grid>
    </>
  );
};

export default LandingPageFinanceMenager;

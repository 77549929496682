import { get } from './firestore';
import { collections } from '../constant/collections';
import { BackofficeProps } from '../model/Backoffice';
import { getCache, getTimeCacheInvalid, setCache } from '../util/cache';
import { isDefined } from '../util/validations';

class BackofficeProvider {
  get = async (): Promise<BackofficeProps> => {
    const valueInCache = getCache('backoffice', 'get', undefined);
    const timeCacheInvalid = getTimeCacheInvalid('backoffice', 'get', undefined, 60 * 24);
    if (!isDefined(valueInCache) || timeCacheInvalid) {
      const value = (await get(collections.backoffice))[0] as BackofficeProps;
      setCache('backoffice', 'get', undefined, value);
      return value;
    } else {
      return valueInCache as BackofficeProps;
    }
  };
}

const BackofficeService = new BackofficeProvider();
export default BackofficeService;

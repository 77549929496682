import React from 'react';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import { GridItem } from '../../../../../common/designSystem/Grid/Grid';

const Greeting = () => {
  return (
    <GridItem xs={6}>
      <Typography variant={'h3'}>Olá, Bem vindo!</Typography>
    </GridItem>
  );
};

export default Greeting;

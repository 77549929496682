import React from 'react';

const Games = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M12.5 23.1428C18.6541 23.1428 23.6429 18.154 23.6429 12C23.6429 5.84594 18.6541 0.857117 12.5 0.857117C6.346 0.857117 1.35718 5.84594 1.35718 12C1.35718 18.154 6.346 23.1428 12.5 23.1428Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 0.857117V23.1428" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.1001 19.32C5.35622 18.5553 6.39446 17.4803 7.115 16.1983C7.83554 14.9164 8.21414 13.4706 8.21438 12C8.21414 10.5294 7.83554 9.08363 7.115 7.80167C6.39446 6.51971 5.35622 5.44469 4.1001 4.67999" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.9 4.67999C19.6443 5.44498 18.6066 6.52012 17.8864 7.80206C17.1663 9.084 16.7881 10.5296 16.7881 12C16.7881 13.4703 17.1663 14.916 17.8864 16.1979C18.6066 17.4799 19.6443 18.555 20.9 19.32" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Games;

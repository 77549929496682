import React, { useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Accordion from '../../../../../common/designSystem/Accordion/Accordion';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import UserQuestion from '../../../../../common/designSystem/Icons/UserQuestion';
import Crown from '../../../../../common/designSystem/Icons/Crown';
import { Colors } from '../../../../../common/designSystem/Colors';

const PlayerQuestion = ({ players, open, setOpen }: {players: any[], open: string, setOpen: (val: string) => void}) => {
  return players.length != 0 ? (
    <GridItem xs={6}>
      <Grid style={{ background: Colors.neutral.a200, borderRadius: '8px' }}>
        <GridItem xs={6} alignContent='center'>
          <Accordion
            header={<Typography variant={'body'} bold style={{ display: 'flex', alignItems: 'center' }}><span style={{ marginRight: '8px' }}><UserQuestion/></span>{players.length} Pendentes</Typography>}
            onClick={() => players.length != 0 && setOpen('question')}
            open={open === 'question'}
            xs={6}
            rowGapXs={16}>
            {players.map((player: any) =>
              <GridItem xs={6}>
                <Typography variant='small' style={{ display: 'flex', flexDirection: 'row' }}>{player.name}</Typography>
              </GridItem>
            )}
          </Accordion>
        </GridItem>
      </Grid>
    </GridItem>
  ) : <></>;
};

export default PlayerQuestion;

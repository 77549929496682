import React from 'react';

const GraphBarIncrease = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24" fill="none">
      <path d="M1.8291 11.2673L20.1388 2.94043" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.7158 1.65088L20.1389 2.94051L18.8652 6.36362" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.9508 22.3488H16.9705V11.2038C16.9705 10.9927 17.0544 10.7902 17.2035 10.6409C17.3529 10.4916 17.5554 10.4077 17.7665 10.4077H20.1547C20.3659 10.4077 20.5684 10.4916 20.7177 10.6409C20.8669 10.7902 20.9508 10.9927 20.9508 11.2038V22.3488Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.9901 22.3488H9.00977V13.592C9.00977 13.3808 9.09364 13.1783 9.24294 13.0291C9.39221 12.8798 9.5947 12.7959 9.80584 12.7959H12.1941C12.4052 12.7959 12.6077 12.8798 12.757 13.0291C12.9062 13.1783 12.9901 13.3808 12.9901 13.592V22.3488Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.02943 22.3487H1.04907V15.9802C1.04907 15.769 1.13294 15.5665 1.28224 15.4173C1.43153 15.268 1.63401 15.1841 1.84514 15.1841H4.23336C4.44449 15.1841 4.64698 15.268 4.79626 15.4173C4.94556 15.5665 5.02943 15.769 5.02943 15.9802V22.3487Z" stroke="black" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default GraphBarIncrease;

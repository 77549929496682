import React from 'react';

const BeachTennis = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3071_2823)">
        <path d="M13.4135 23.414C11.8408 14.7048 8.87065 11.9271 0.587014 10.5779M23.4327 13.2512C15.5638 11.0481 12.3994 8.40286 10.7654 0.565452M23.3674 13.7521C22.6189 18.6482 18.7826 22.5285 13.9087 23.3424C13.1157 18.9416 11.9539 15.9381 9.8621 13.8742C7.77475 11.8147 4.83346 10.762 0.658987 10.083C1.47708 5.20643 5.3656 1.36982 10.268 0.629499C11.0978 4.63001 12.3341 7.3765 14.4321 9.39867C16.5125 11.404 19.386 12.6427 23.3674 13.7521ZM0.536537 11.0763C4.62506 11.7451 7.30169 12.7528 9.15976 14.586C11.0216 16.423 12.1334 19.16 12.9145 23.4642C12.6128 23.4879 12.3079 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 11.689 0.512338 11.3809 0.536537 11.0763ZM15.126 8.67869C13.2475 6.86795 12.0762 4.37052 11.2674 0.522948C11.5096 0.507731 11.7538 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12C23.5 12.25 23.492 12.4981 23.4763 12.7441C19.6264 11.6593 16.9983 10.4833 15.126 8.67869Z" stroke="#2C2B3A"/>
      </g>
      <defs>
        <clipPath id="clip0_3071_2823">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BeachTennis;

import React, { useEffect, useState } from 'react';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { Colors } from '../../../common/designSystem/Colors';
import Header from '../../../common/component/Header/Header';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Input from '../../../common/designSystem/Input/Input';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import Checkbox from '../../../common/designSystem/Checkbox/Checkbox';
import Button from '../../../common/designSystem/Button/Button';
import { isDefined, validateEmail, validatePassword, validatePhone } from '../../../common/util/validations';
import { formatPhone } from '../../../common/util/formatText';
import { RoutePath } from '../../../routesPath';
import { useNavigate } from 'react-router-dom';
import Google from '../../../common/designSystem/Icons/Google';
import UserService from '../../../common/services/user';
import AuthenticationService from '../../../common/services/authentication';
import ShowAndHideActive from '../../../common/designSystem/Icons/ShowAndHideActive';
import ShowAndHide from '../../../common/designSystem/Icons/ShowAndHide';
import { enqueueSnackbar } from 'notistack';
import { getAuth } from 'firebase/auth';
import { appFirebase } from '../../../common/services/firebase';
import { delay } from '../../../common/util/web';

const CreateAccountSportCenter = () => {
  const { setBackground } = useGlobalContext();
  const { register, values, validateForm, getValue, setValue } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(true);
  const [validPassword, setValidPassword] = useState(false);
  const [step, setStep] = useState(0);
  const [provider, setProvider] = useState('email');

  useEffect(() => {
    setBackground(Colors.neutral.a100);
  }, []);

  async function hasGoogleLogin() {
    const auth = getAuth(appFirebase);
    if (isDefined(auth.currentUser?.uid)) {
      await getUserInformation();
      setProvider('google');
      setStep(1);
    }
  }

  useEffect(() => {
    hasGoogleLogin();
  }, []);

  async function getUserInformation() {
    const user = await UserService.getCurrent();
    if (isDefined(user) && !isDefined(getValue('name') && !isDefined(getValue('email')))) {
      setValue(user!.name, 'name');
      setValue(user!.email, 'email');
    }
  }

  useEffect(() => {
    getUserInformation();
  }, [step, values]);


  useEffect(() => {
    if (isDefined(values['password']) && isDefined(values['passwordRepeat'])) {
      setValidPassword(values['password'] == values['passwordRepeat']);
    }
  }, [values['password'], values['passwordRepeat'], validPassword]);

  const save = async () => {
    try {
      if (validateForm()) {
        if (provider == 'google') {
          const currentUser = await UserService.getCurrent();
          const { sportCenterName, ...rest } = values;
          const user = {
            role: 'sportCenter',
            ...currentUser,
            ...rest
          };
          await UserService.save(user);
          navigate(RoutePath.SPORT_CENTER_ONBOARDING + '?name=' + sportCenterName);
          enqueueSnackbar('Conta criada com sucesso', { variant: 'success' });
        } else {
          if (validPassword) {
            await AuthenticationService.createAccountWithEmailAndPassword(values.email, values.password, '', 'sportCenter');
            const currentUser = await UserService.getCurrent();
            const { sportCenterName, password, passwordRepeat, ...rest } = values;
            const user = {
              role: 'sportCenter',
              ...currentUser,
              ...rest
            };
            await UserService.save(user);
            navigate(RoutePath.SPORT_CENTER_ONBOARDING + '?name=' + sportCenterName);
            enqueueSnackbar('Conta criada com sucesso', { variant: 'success' });
          } else {
            enqueueSnackbar('As senhas não coincidem', { variant: 'warning' });
          }
        }
      } else {
        enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  return (
    <>
      <Grid alignContent={'start'} isScrollable style={{ paddingBottom: '24px', paddingTop: '24px' }} height={'calc(100% - 48px)'} rowGapXs={16}>
        <GridItem xs={6} marginXs={'16px 0 0 0'}>
          <Typography variant={'paragraph'} component={<h1/>}>Bem vindo ao Joga Já</Typography>
        </GridItem>
        <GridItem xs={6} marginXs={'0 0 16px 0'}>
          <Typography variant={'paragraphSmall'}>Crie sua conta de uma maneira rápida, segura e grátis.</Typography>
        </GridItem>
        {step == 0 && <>
          <GridItem xs={6}>
            <Input register={register({ onValidation: (val) => validateEmail(val) })} required isFullWidth name={'email'} type={'email'} label={'Email:'} placeholder={'Digite'}/>
          </GridItem>
          <GridItem xs={6} marginXs={'0 0 40px 0'}>
            <Button color={'primary'} onClick={() => {
              if (validateForm()) {
                setStep(1);
              }
            }} variant={'filled'} title={'Continuar'} isFullWidth/>
          </GridItem>
          {/* <GridItem xs={2} justifyContent={'center'} marginXs={'0 0 40px 0'}> */}
          {/*   <div style={{ width: '100%', height: '1px', background: Colors.neutral.a500 }}/> */}
          {/* </GridItem> */}
          {/* <GridItem xs={2} marginXs={'0 0 40px 0'}> */}
          {/*   <Typography variant={'small'} color={'neutral'} center colorVariant={'a500'} notIsContrastColor>ou</Typography> */}
          {/* </GridItem> */}
          {/* <GridItem xs={2} justifyContent={'center'} marginXs={'0 0 40px 0'}> */}
          {/*   <div style={{ width: '100%', height: '1px', background: Colors.neutral.a500 }}/> */}
          {/* </GridItem> */}
          {/* <GridItem xs={6} marginXs={'0 0 40px 0'}> */}
          {/*   <Button iconLeft={<Google/>} color={'neutral'} colorVariant={'white'} onClick={async () => { */}
          {/*     await AuthenticationService.loginWithGoogle('sportCenter'); */}
          {/*     setProvider('google'); */}
          {/*     setStep(1); */}
          {/*   }} variant={'filled'} title={'Continuar com Google'} isFullWidth/> */}
          {/* </GridItem> */}
        </>}
        {step == 1 && <>
          <GridItem xs={6}>
            <Input register={register()} required isFullWidth name={'name'} label={'Nome:'} placeholder={'Digite'}/>
          </GridItem>
          <GridItem xs={6}>
            <Input register={register({ onMask: (val) => formatPhone(val), onValidation: (val) => validatePhone(val) })} required isFullWidth maxLength={15} name={'phone'} type={'tel'} label={'Telefone:'} placeholder={'(99) 99999-9999'}/>
          </GridItem>
          <GridItem xs={6}>
            <Input register={register()} required isFullWidth name={'sportCenterName'} label={'Nome do centro esportivo:'} placeholder={'Digite'}/>
          </GridItem>
          {provider == 'email' && <>
            <GridItem xs={6}>
              <Input tooltip={<div style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography variant={'small'} color={!isDefined(getValue('password')) ? 'neutral' : validatePassword(getValue('password')).includes('A senha deve ter entre 6 e 4096 caracteres.') ? 'error' : 'success'} colorVariant={!isDefined(getValue('password')) ? 'white' : 'a200'} notIsContrastColor>Mínimo 6 caracteres</Typography>
                <Typography variant={'small'} color={!isDefined(getValue('password')) ? 'neutral' : validatePassword(getValue('password')).includes('A senha deve conter pelo menos uma letra maiúscula.') ? 'error' : 'success'} colorVariant={!isDefined(getValue('password')) ? 'white' : 'a200'} notIsContrastColor>1 Letra Maiúscula</Typography>
                <Typography variant={'small'} color={!isDefined(getValue('password')) ? 'neutral' : validatePassword(getValue('password')).includes('A senha deve conter pelo menos uma letra minúscula.') ? 'error' : 'success'} colorVariant={!isDefined(getValue('password')) ? 'white' : 'a200'} notIsContrastColor>1 Letra Minúscula</Typography>
                <Typography variant={'small'} color={!isDefined(getValue('password')) ? 'neutral' : validatePassword(getValue('password')).includes('A senha deve conter pelo menos um caractere não alfanumérico.') ? 'error' : 'success'} colorVariant={!isDefined(getValue('password')) ? 'white' : 'a200'} notIsContrastColor>1 Caracter especial</Typography>
                <Typography variant={'small'} color={!isDefined(getValue('password')) ? 'neutral' : validatePassword(getValue('password')).includes('A senha deve conter pelo menos um caractere numérico.') ? 'error' : 'success'} colorVariant={!isDefined(getValue('password')) ? 'white' : 'a200'} notIsContrastColor>1 Número</Typography>
              </div>} register={register({ onValidation: (value) => validatePassword(value).length == 0 })} required isFullWidth
              type={!showPassword ? 'text' : 'password'} name={'password'} label={'Digite sua senha de acesso:'} placeholder={'Digite'} icon={!showPassword ? <ShowAndHideActive onClick={() => setShowPassword(!showPassword)} /> : <ShowAndHide onClick={() => setShowPassword(!showPassword)} />} />
            </GridItem>
            <GridItem xs={6}>
              <Input register={register({ onValidation: (value) => validatePassword(value).length == 0 })} required isFullWidth
                type={!showPassword ? 'text' : 'password'} name={'passwordRepeat'} label={'Repita sua senha de acesso:'} placeholder={'Digite'} icon={!showPassword ? <ShowAndHideActive onClick={() => setShowPassword(!showPassword)} /> : <ShowAndHide onClick={() => setShowPassword(!showPassword)} />} />
            </GridItem>
          </>}
          <GridItem xs={6}>
            <Checkbox
              register={register({ initialValue: false })}
              label={<div>Aceito os <Typography variant={'paragraphSmall'} color={'info'} notIsContrastColor colorVariant={'a500'} component={<span/>}>termos de uso</Typography> e <Typography variant={'paragraphSmall'} color={'info'} notIsContrastColor colorVariant={'a500'} component={<span/>}>política de privacidade</Typography></div>}
              name={'privacity'}
              required
            />
          </GridItem>
          <GridItem xs={6}>
            <Checkbox
              register={register({ initialValue: true })}
              label={<>Desejo receber as comunicações do JogaJá via e-mail</>}
              name={'notification'}
            />
          </GridItem>
          <GridItem xs={6} marginXs={'24px 0 16px 0'}>
            <Button disabled={!(validateForm(true) && getValue('privacity') == true)} color={'primary'} onClick={async () => await save()} variant={'filled'} isFullWidth title={'Criar minha conta'}/>
          </GridItem>
        </>}
      </Grid>
    </>
  );
};

export default CreateAccountSportCenter;

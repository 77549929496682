import styled from 'styled-components';
import React from 'react';
import { isDefined } from '../../util/validations';

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  marginLeft?: number;
}

export const Container = styled.div<ContainerProps>`
  position: absolute;
  width: ${({ marginLeft }) => isDefined(marginLeft) ? `calc(100% - (${marginLeft}px * 2))` : '100%'};
  padding-left: ${({ marginLeft }) => isDefined(marginLeft) ? `calc(${marginLeft}px)` : '0'};
  padding-right: ${({ marginLeft }) => isDefined(marginLeft) ? `calc(${marginLeft}px)` : '0'};
  display: flex;
  flex-direction: row;
  text-align: center;
  overflow: auto;
  scroll-snap-type: x mandatory;
  gap: 10px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

import React from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Button from '../../../../../common/designSystem/Button/Button';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import BackArrow from '../../../../../common/designSystem/Icons/BackArrow';
import { useNavigate } from 'react-router-dom';

const Header = ({ centerName, courtName, lat, lon }: {centerName: string, courtName: string, lat: string, lon: string}) => {
  const navigate = useNavigate();

  function abrirMapa(latitude: string, longitude: string) {
    const coordenadas = latitude + ',' + longitude;
    const url = 'https://www.google.com/maps/search/?api=1&query=' + coordenadas;

    // Tenta abrir o aplicativo de mapa padrão
    window.open(url, '_blank');
  }

  return (<>
    <GridItem xs={6} style={{ height: '157px', background: `linear-gradient(#1D1C20b8, #1D1C20b8), url(${require('../../../../../static/img/center-highlight.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      <Grid style={{ height: '160px', borderRadius: '10px' }}>
        <GridItem xs={6} padding='32px 0 0 0'>
          <Button color={'neutral'} colorVariant={'white'} onClick={() => navigate(-1) } variant={'filled'} icon={<BackArrow></BackArrow>}></Button>
        </GridItem>
        <GridItem xs={3} style={{ backgroundImage: 'url(center-highlight.png)' }} justifyContent='end'>
          <Typography variant={'h3'} color='neutral' colorVariant='white' notIsContrastColor>{courtName}</Typography>
          <Typography variant={'paragraphSmall'} color='neutral' colorVariant='white' notIsContrastColor>{centerName}</Typography>
        </GridItem>
        <GridItem xs={3} style={{ backgroundImage: 'url(center-highlight.png)' }} justifyContent='flex-end' alignItems={'flex-end'}>
          <Button color={'neutral'} colorVariant={'white'} onClick={() => abrirMapa(lat, lon)} variant={'filled'} title={'Ver no Mapa'} iconRight={<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 8.77295C20 14.2007 14.3911 21.0487 12.8341 22.0184C12.7155 22.0935 12.5779 22.1334 12.4375 22.1334C12.2971 22.1334 12.1595 22.0935 12.0409 22.0184C10.4843 21.0487 4.875 14.2007 4.875 8.77295C4.875 4.59635 8.2609 1.21045 12.4375 1.21045C16.6141 1.21045 20 4.59635 20 8.77295Z" stroke="#2C2B3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M15.7984 8.77273C15.7984 9.66415 15.4443 10.5191 14.8139 11.1494C14.1836 11.7797 13.3287 12.1338 12.4373 12.1338C11.5459 12.1338 10.6909 11.7797 10.0606 11.1494C9.43029 10.5191 9.07617 9.66415 9.07617 8.77273C9.07617 7.88131 9.43029 7.0264 10.0606 6.39607C10.6909 5.76574 11.5459 5.41162 12.4373 5.41162C13.3287 5.41162 14.1836 5.76574 14.8139 6.39607C15.4443 7.0264 15.7984 7.88131 15.7984 8.77273Z" stroke="#2C2B3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
          }/>
        </GridItem>
        <GridItem xs={6} isRow padding='0 0 0 24px' style={{ columnGap: '3px' }} >
          {/* <Typography variant={'paragraphSmall'} color='primary' notIsContrastColor>5</Typography> */}
          {/* <FavoriteStar/> */}
        </GridItem>
      </Grid>
    </GridItem>
  </>);
};

export default Header;

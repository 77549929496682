import React from 'react';
import Footer from '../Footer/Footer';
import { Grid, GridItem } from '../../designSystem/Grid/Grid';
import MenuButton from '../../designSystem/MenuButton/MenuButton';
import TimeClockCircle from '../../designSystem/Icons/TimeClockCircle';
import Home from '../../designSystem/Icons/Home';
import Center from '../../designSystem/Icons/Center';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';

const MenuSportCenter = () => {
  const navigate = useNavigate();

  return (<>
    <Footer>
      <Grid noMargin style={{ width: '100%' }}>
        <GridItem xs={2} justifyContent='center' alignItems={'center'}>
          <MenuButton title='Horários' icon={<TimeClockCircle width={24} height={24}/>} onClick={() => navigate(RoutePath.SPORT_CENTER_MY_COURTS)}></MenuButton>
        </GridItem>
        <GridItem xs={2} justifyContent='center' alignItems={'center'}>
          <MenuButton title='Início' icon={<Home/>} onClick={() => navigate(RoutePath.SPORT_CENTER_HOME)}></MenuButton>
        </GridItem>
        <GridItem xs={2} justifyContent='center' alignItems={'center'}>
          <MenuButton title='Centro' icon={<Center/>} onClick={() => navigate(RoutePath.SPORT_CENTER_MY_CENTER)}></MenuButton>
        </GridItem>
      </Grid>
    </Footer></>);
};

export default MenuSportCenter;

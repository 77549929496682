import React from 'react';
import { IconProps } from './interface';
import { getColor, getContrastColor } from '../Colors';

const Next = ({ color = 'neutral', colorVariant, isContrastColor, height = 16, width = 16 }: IconProps) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M5.20101 13.3204C4.96357 13.0893 4.95846 12.7094 5.1896 12.472L9.54308 7.99985L5.1896 3.52775C4.95846 3.29031 4.96357 2.91044 5.20101 2.6793C5.43845 2.44816 5.81832 2.45326 6.04946 2.6907L10.8104 7.58133C11.0371 7.81427 11.0371 8.18543 10.8104 8.41838L6.04946 13.309C5.81832 13.5464 5.43845 13.5516 5.20101 13.3204Z" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
    </svg>
  );
};

export default Next;

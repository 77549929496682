import React, { useEffect } from 'react';
import styled from 'styled-components';
import Logo from '../../../common/designSystem/Logo/Logo';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const SplashScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate(RoutePath.LOGIN);
    }, 1000);
  }, []);

  return (
    <Container>
      <Logo size={'xxl'}/>
    </Container>
  );
};

export default SplashScreen;

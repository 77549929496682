import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Button from '../../../common/designSystem/Button/Button';
import { useNavigate } from 'react-router-dom';
import Input from '../../../common/designSystem/Input/Input';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import Footer from '../../../common/component/Footer/Footer';
import { RoutePath } from '../../../routesPath';
import UserService from '../../../common/services/user';
import SportCenterService from '../../../common/services/sportCenter';
import { isDefined } from '../../../common/util/validations';
import { Court, SportCenter } from '../../../common/model/SportCenter';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import Next from '../../../common/designSystem/Icons/Next';
import { Icon, ModalCourt } from './styled';
import Radio from '../../../common/designSystem/Radio/Radio';
import { formatNumberToReal } from '../../../common/util/formatText';
import Switch from '../../../common/designSystem/Switch/Switch';
import Dialog from '../../../common/designSystem/Dialog/Dialog';
import { enqueueSnackbar } from 'notistack';
import { useGlobalContext } from '../../../contexts/GlobalContext';

const SettingCourt = () => {
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const [openDialog, setOpenDialog] = useState(false);
  const [select, setSelect] = useState<Court | undefined>();
  const { register, getValue, registerValue, setValue, values, validateForm, clearForm } = useForm();
  const { backoffice } = useGlobalContext();
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const userId = async () => (await UserService.getCurrent())!.id;

  const getSportCenter = async (update = false) => {
    let sportCenter = await SportCenterService.getByUserId(await userId(), true);
    if (isDefined(sportCenter)) {
      sportCenter = { ...sportCenter, courts: await SportCenterService.getCourts(sportCenter.id!, true) };
      if (update) {
        const result = new Set<string>();
        sportCenter.courts!.forEach(
          (court) => court.sportServed?.forEach((sport) => {
            !result.has(sport) && result.add(sport);
          })
        );
        await SportCenterService.save({ ...sportCenter, sportServed: [...result] });
      }
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);

  const setSportType =(val: string, name: string) => {
    const oldVal = getValue(name);
    if (oldVal.findIndex((temp: string) => temp == val) !== -1) {
      setValue([...(oldVal.filter((temp: string) => temp !== val))], name);
    } else {
      setValue([...oldVal, val], name);
    }
  };

  const save = async () => {
    try {
      if (validateForm()) {
        const id = values['id'];
        const name = values['name'];
        const type = values['type'];
        const price = parseFloat((values['price'])?.replace(/[^\d]/g, '')) / 100;
        const discountAboveOneHour = values['discountAboveOneHour'];
        const discountPercentage = parseFloat(values['discountPercentage']) / 100;
        const sportServed = values['sportServed'];
        const maxPlayersCount = values['maxPlayersCount'];
        const data = { id, name, type, price, discountAboveOneHour, discountPercentage, sportServed, maxPlayersCount } as Court;
        await SportCenterService.saveCourt(sportCenter.id!, data);
        await getSportCenter(true);
        setSelect(undefined);
        clearForm();
        enqueueSnackbar('Salvo com sucesso', { variant: 'success' });
      } else {
        enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  useEffect(() => {
    if (isDefined(select)) {
      const elementById = document.getElementById('name');
      if (isDefined(elementById)) {
        elementById?.scrollIntoView();
      }
    }
  }, [select]);

  const modal = () => {
    const priceCourt = parseFloat(Number(values['price']?.replace(/[^\d]/g, '')).toFixed(2)) / 100;
    const percentage = (quant = 1) => {
      return formatNumberToReal((((priceCourt * quant) - ((priceCourt * quant) * ((Number(values['discountPercentage']) ?? 0) / 100))).toFixed(2)).toString());
    };
    return (<ModalCourt open={true}>
      <Grid alignContent={'start'} isScrollable height={'calc(100% - 136px)'} rowGapXs={24} style={{ background: '#fff', paddingTop: '24px', paddingBottom: '24px' }}>
        <GridItem xs={6}>
          <Input register={register({ initialValue: select?.name })} isFullWidth name={'name'} id={'name'} label={'Nome da quadra'}/>
          <div style={{ display: 'none' }}><Input register={register({ initialValue: select?.id })} name={'id'} label={'Nome da quadra'}/></div>
        </GridItem>
        <GridItem xs={6} style={{ gap: '8px' }}>
          <Typography variant={'small'}>Tipo de quadra</Typography>
          <Radio
            register={register({ initialValue: select?.type })}
            options={[
              { label: <Typography variant={'small'}>Coberta</Typography>, value: 'covered' },
              { label: <Typography variant={'small'}>Descoberta</Typography>, value: 'uncovered' }
            ]}
            required
            name={'type'}
            xs={3}
            sm={3}
          />
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'small'}>Informe o valor cobrado por hora</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Input register={register({ initialValue: (select?.price ?? 1) * 100, onMask: (value) => formatNumberToReal(value), onValidation: (value) => Number(value?.replace(/[^\d]/g, '')) > 0 })} required isFullWidth name={'price'} label={'1 hora de jogo'}/>
        </GridItem>
        <GridItem xs={6}>
          <Switch
            register={register({ initialValue: select?.discountAboveOneHour })}
            labelActive={'Dar desconto acima de 1 hora'}
            labelInactive={'Dar desconto acima de 1 hora'}
            name={'discountAboveOneHour'}
          />
        </GridItem>
        {values['discountAboveOneHour'] && <>
          <GridItem xs={6}>
            <Input register={register({ initialValue: (select?.discountPercentage ?? 0.05) * 100 })} type={'number'} required isFullWidth name={'discountPercentage'} icon={'%'} label={'Informe a porcentagem de desconto'}/>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'small'}>Veja o desconto na prática</Typography>
            <Typography variant={'xSmall'}>1 hora = {priceCourt}</Typography>
            <Typography variant={'xSmall'}>1 h e 30min = {percentage(1.5)}</Typography>
            <Typography variant={'xSmall'}>2 horas = {percentage(2)}</Typography>
          </GridItem>
        </>}
        <GridItem xs={6}>
          <Typography variant={'small'}>Selecione os esportes essa quadra atende</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} style={{ flexWrap: 'wrap', gap: '16px' }}>
          {backoffice?.sports.map((sportType) => <>
            <Button key={sportType.id} color={'primary'} selected={getValue('sportServed')?.findIndex((temp: string) => temp == sportType.title) !== -1} onClick={() => setSportType( sportType.title, 'sportServed')} variant={'select'} title={sportType.title}/>
          </>)}
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'small'}>Selecione o número máximo de jogadores</Typography>
        </GridItem>
        <GridItem xs={6} flexDirection={'row'} style={{ flexWrap: 'wrap', gap: '16px' }}>
          {['2 vs 2', '5 vs 5', '6 vs 6', '7 vs 7', '10 vs 10', '11 vs 11'].map((playersCount) => <>
            <Button key={playersCount} color={'primary'} selected={getValue('maxPlayersCount') == playersCount} onClick={() => setValue( playersCount, 'maxPlayersCount')} variant={'select-border'} title={playersCount}/>
          </>)}
        </GridItem>
      </Grid>
      <Footer>
        <Grid noMargin style={{ width: '100%' }} alignItems={'center'}>
          <GridItem xs={3}>
            <Button color={'error'} iconLeft={<svg style={{ marginLeft: '-8px' }} width="19" height="21" viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M0.928589 5.5H18.0714" stroke="#F45532" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M3.07141 5.5H15.9286V18.3571C15.9286 18.736 15.778 19.0994 15.5101 19.3673C15.2422 19.6352 14.8789 19.7857 14.5 19.7857H4.49998C4.1211 19.7857 3.75774 19.6352 3.48983 19.3673C3.22192 19.0994 3.07141 18.736 3.07141 18.3571V5.5Z" stroke="#F45532" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M5.92859 5.50007V4.78578C5.92859 3.83858 6.30486 2.93018 6.97464 2.2604C7.64441 1.59063 8.55282 1.21436 9.50002 1.21436C10.4472 1.21436 11.3556 1.59063 12.0254 2.2604C12.6952 2.93018 13.0714 3.83858 13.0714 4.78578V5.50007" stroke="#F45532" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M7.35718 8.35742V16.2146" stroke="#F45532" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
              <path d="M11.6428 8.35742V16.2146" stroke="#F45532" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>} onClick={() => {
              setOpenDialog(true);
            }} isFullWidth variant={'outlined'} iconRight={<Typography style={{ marginLeft: '-8px' }} variant={'small'} color={'error'} notIsContrastColor>Excluir quadra</Typography>}/>
          </GridItem>
          <GridItem xs={3}>
            <Button disabled={!(validateForm(true))} color={'info'} onClick={async () => {
              await save();
            }} isFullWidth variant={'filled'} title={'Salvar'}/>
          </GridItem>
        </Grid>
      </Footer>
    </ModalCourt>);
  };

  const dialog = () => {
    return (
      <Dialog open={openDialog} setOpen={setOpenDialog} title={'Excluir ' + select?.name}
        buttonLabelCancel={'Cancelar'}
        buttonLabelConfirm={'Sim, excluir'}
        onConfirm={async () => {
          try {
            setOpenDialog(false);
            if (isDefined(select?.id)) {
              await SportCenterService.deleteCourt(sportCenter.id!, select!.id!);
            }
            clearForm();
            setSelect(undefined);
            await getSportCenter(true);
            enqueueSnackbar('Excluido com sucesso', { variant: 'success' });
          } catch (e) {
            enqueueSnackbar((e as any)?.message, { variant: 'warning' });
          }
        }}
        onCancel={() => setOpenDialog(false)}>Tem certeza que deseja excluir a {select?.name}?</Dialog>
    );
  };

  useEffect(() => {
    if (isDefined(name) && !isDefined(sportCenter?.courts)) {
      const court: Court = {
        discountAboveOneHour: false,
        discountPercentage: 0,
        name: 'Quadra ' + ((sportCenter.courts?.length ?? 0) + 1),
        price: 0,
        sportServed: [],
        type: 'uncovered'
      };
      registerValue(register({ initialValue: court?.sportServed ?? [] }), 'sportServed', true);
      registerValue(register({ initialValue: court?.maxPlayersCount ?? '' }), 'maxPlayersCount', true);
      setSelect(court);
    }
  }, [name, sportCenter]);

  return (
    <RestrictArea role={'sportCenter'}>
      {isDefined(select) ? modal() : <></>}
      {dialog()}
      <Grid alignContent={'start'} isScrollable height={'calc(100% - 86px)'} rowGapXs={24} marginTop={24}>
        <GridItem xs={6}>
          <Typography variant={'h3'}>{isDefined(name) ? 'Falta pouco, para finalizar vamos configurar as suas quadras' : 'Configurações das suas quadras'}</Typography>
        </GridItem>
        {(isDefined(name) || isDefined(sportCenter)) && <>
          <GridItem xs={6} style={{ gap: '8px' }}>
            {isDefined(sportCenter.courts) && sportCenter.courts!.map((court) => <Grid onClick={() => {
              registerValue(register({ initialValue: court?.sportServed ?? [] }), 'sportServed', true);
              registerValue(register({ initialValue: court?.maxPlayersCount ?? '' }), 'maxPlayersCount', true);
              setSelect(court);
            }} rowGapXs={8} style={{ background: '#fff', padding: '16px', boxShadow: '0px 3px 4px 0px rgba(106, 115, 129, 0.12)' }} noMargin>
              <GridItem xs={5} display={'flex'} flexDirection={'row'} alignItems={'center'}>
                <Typography bold variant={'body'}>{court.name}</Typography>
              </GridItem>
              <GridItem justifyContent={'center'} alignItems={'flex-end'} xs={1}>
                <Icon>
                  <Next/>
                </Icon>
              </GridItem>
            </Grid>
            )}
          </GridItem>
          <GridItem xs={6}>
            <Button color={'primary'} iconLeft={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clipPath="url(#clip0_2053_3687)">
                <path d="M10.0001 19.2858C15.1284 19.2858 19.2858 15.1284 19.2858 10.0001C19.2858 4.87171 15.1284 0.714355 10.0001 0.714355C4.87171 0.714355 0.714355 4.87171 0.714355 10.0001C0.714355 15.1284 4.87171 19.2858 10.0001 19.2858Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M10 5.71436V14.2858" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.71436 10H14.2858" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
              </g>
              <defs>
                <clipPath id="clip0_2053_3687">
                  <rect width="20" height="20" fill="white"/>
                </clipPath>
              </defs>
            </svg>
            } onClick={() => {
              const court: Court = {
                discountAboveOneHour: false,
                discountPercentage: 0,
                name: 'Quadra ' + ((sportCenter.courts?.length ?? 0) + 1),
                price: 0,
                sportServed: [],
                type: 'uncovered'
              };
              registerValue(register({ initialValue: court?.sportServed ?? [] }), 'sportServed', true);
              registerValue(register({ initialValue: court?.maxPlayersCount ?? '' }), 'maxPlayersCount', true);
              setSelect(court);
            }} isFullWidth variant={'filled'} title={'Adicionar quadra'}/>
          </GridItem>
        </>}
      </Grid>
      <Footer>
        <Grid noMargin style={{ width: '100%' }} alignItems={'center'}>
          <GridItem xs={6}>
            <Button color={'info'} onClick={async () => isDefined(name) ? navigate(RoutePath.SPORT_CENTER_HOME) : navigate(RoutePath.SPORT_CENTER_MY_CENTER)} isFullWidth variant={'filled'} title={'Finalizar'}/>
          </GridItem>
        </Grid>
      </Footer>
    </RestrictArea>
  );
};

export default SettingCourt;

import React from 'react';

const DollarCoin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12 22.3467C17.7145 22.3467 22.347 17.7143 22.347 11.9998C22.347 6.28532 17.7145 1.65283 12 1.65283C6.28556 1.65283 1.65308 6.28532 1.65308 11.9998C1.65308 17.7143 6.28556 22.3467 12 22.3467Z" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.709 9.17032C14.6158 8.90671 14.4721 8.66698 14.2893 8.46285C13.9007 8.02863 13.3359 7.75537 12.7073 7.75537H11.0645C10.0181 7.75537 9.16992 8.6036 9.16992 9.64993C9.16992 10.5403 9.78986 11.3105 10.6596 11.5007L13.1609 12.0479C14.1353 12.261 14.8298 13.1245 14.8298 14.1219C14.8298 15.2941 13.8795 16.2452 12.7073 16.2452H11.2924C10.3682 16.2452 9.58206 15.6545 9.29069 14.8302" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 7.75477V5.63232" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12 18.367V16.2446" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default DollarCoin;

export function multiplyArray(arr: any[], multiplier: number) {
  // Verifica se o argumento passado é um array e se o multiplicador é um número positivo
  if (!Array.isArray(arr) || !Number.isInteger(multiplier) || multiplier <= 0) {
    return [];
  }

  // Cria um novo array multiplicando o array original pelo multiplicador
  let multipliedArray: any[] = [];
  for (let i = 0; i < multiplier; i++) {
    multipliedArray = multipliedArray.concat(arr.slice());
  }

  return multipliedArray;
}

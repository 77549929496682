import { PaddingSize } from './Grid/interface';

const size = {
  xs: '719px',
  sm: '959px',
  md: '1279px',
  lg: '1439px',
  xl: '1929px'
};
export const device = {
  xs: `(min-width: 0px) and (max-width: ${size.xs})`,
  sm: `(min-width: ${size.xs}) and (max-width: ${size.sm})`,
  md: `(min-width: ${size.sm}) and (max-width: ${size.md})`,
  lg: `(min-width: ${size.md}) and (max-width: ${size.lg})`,
  xl: `(min-width: ${size.lg}) and (max-width: ${size.xl})`,
  xxl: `(min-width: ${size.xl})`
};

export function getDevice(screen: number): PaddingSize {
  if (screen >= 0 && screen < parseInt(size.xs)) {
    return 'XS';
  } else if (screen >= parseInt(size.xs) && screen < parseInt(size.sm)) {
    return 'SM';
  } else if (screen >= parseInt(size.sm) && screen < parseInt(size.md)) {
    return 'MD';
  } else if (screen >= parseInt(size.md) && screen < parseInt(size.lg)) {
    return 'LG';
  } else if (screen >= parseInt(size.lg) && screen < parseInt(size.xl)) {
    return 'XL';
  } else {
    return 'XXL';
  }
}

import { lazy } from 'react';
import { ReservationFlowSequence } from '../../../../../common/constant/reservationFlow';

const SportChoose = lazy(() => import('./SportChoose/SportChoose'));
const TimeTypeChoose = lazy(() => import('./TimeTypeChoose/TimeTypeChoose'));
const DateChoose = lazy(() => import('./DateChoose/DateChoose'));
const WeekDaysChoose = lazy(() => import('./WeekDaysChoose/WeekDaysChoose'));
const SportCenterChoose = lazy(() => import('./SportCenterChoose/SportCenterChoose'));
const CourtChoose = lazy(() => import('./CourtChoose/CourtChoose'));
const Login = lazy(() => import('./Login/Login'));
const Register = lazy(() => import('./Register/Register'));
const ResetPassword = lazy(() => import('./ResetPassword/ResetPassword'));
const PaymentMethodChoose = lazy(() => import('./PaymentMethodChoose/PaymentMethodChoose'));
const ConfirmPayment = lazy(() => import('./ConfirmPayment/ConfirmPayment'));
const NewCard = lazy(() => import('./NewCard/NewCard'));
const Invitations = lazy(() => import('./Invitations/Invitations'));

export const STEPS = [
  { id: ReservationFlowSequence.SPORT_CHOOSE, component: SportChoose },
  { id: ReservationFlowSequence.TIME_TYPE_CHOOSE, component: TimeTypeChoose },
  { id: ReservationFlowSequence.DATE_CHOOSE, component: DateChoose },
  { id: ReservationFlowSequence.WEEK_DAYS_CHOOSE, component: WeekDaysChoose },
  { id: ReservationFlowSequence.SPORT_CENTER_CHOOSE, component: SportCenterChoose },
  { id: ReservationFlowSequence.COURT_CHOOSE, component: CourtChoose },
  { id: ReservationFlowSequence.LOGIN, component: Login },
  { id: ReservationFlowSequence.REGISTER, component: Register },
  { id: ReservationFlowSequence.RESET_PASSWORD, component: ResetPassword },
  { id: ReservationFlowSequence.PAYMENT_METHOD_CHOOSE, component: PaymentMethodChoose },
  { id: ReservationFlowSequence.CONFIRM_PAYMENT, component: ConfirmPayment },
  { id: ReservationFlowSequence.NEW_CARD, component: NewCard },
  { id: ReservationFlowSequence.INVITATIONS, component: Invitations }
];

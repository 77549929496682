import { getPrefix } from '../util/getEnvironment';

export const collections = {
  payments: getPrefix + 'payments',
  users: getPrefix + 'users',
  sportCenters: getPrefix + 'sportCenters',
  players: getPrefix + 'players',
  team: getPrefix + 'team',
  backoffice: getPrefix + 'backoffice',
  schedules: getPrefix + 'schedules',
  recurrentSchedules: getPrefix + 'recurrentSchedules'
};

import React, { useEffect, useState } from 'react';
import { ButtonProps } from './interface';
import { ButtonStyle } from './styled';
import Loading from '../Icons/Loading';

const Button = (Props : ButtonProps) => {
  const { color, accessibleTitle, colorVariant, iconRight, disabled, isLoading, iconLeft, title, icon, variant } = Props;
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const onClickAction = async () => {
    if (isLoading !== undefined) {
      Props.onClick && await Props.onClick();
    } else {
      if (!isLoadingAction) {
        setIsLoadingAction(true);
        Props.onClick && await Props.onClick();
        setIsLoadingAction(false);
      }
    }
  };

  useEffect(() => {
    if (isLoadingAction) {
      const timer = setTimeout(() => {
        setIsLoadingAction(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [isLoadingAction]);

  useEffect(() => {
    setIsLoadingAction(!!isLoading);
  }, [isLoading]);

  return (
    <ButtonStyle {...Props} aria-label={title || accessibleTitle} onClick={() => onClickAction()} isLoading={isLoadingAction} disabled={isLoadingAction || disabled} hasIconLeft={!!iconLeft} hasIconRight={!!iconRight}>
      <>
        <div className={'children'}>
          <div>
            {icon && <div className={'icon'}>{icon}</div>}
            {iconLeft && <div className={'icon-left'}>{iconLeft}</div>}
            {title}
            {iconRight && <div className={'icon-right'}>{iconRight}</div>}
          </div>
        </div>
        {isLoadingAction && <div className={'is-loading'}><Loading color={color} colorVariant={colorVariant} isContrastColor={variant === 'filled'}/></div>}
      </>
    </ButtonStyle>
  );
};

export default Button;

import React from 'react';

const MoneyCashDollarCoin = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M12.5 7.71401V5.14258" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M9.92871 14.571C9.92871 15.8567 11.0773 16.2853 12.5001 16.2853C13.923 16.2853 15.0716 16.2853 15.0716 14.571C15.0716 11.9996 9.92871 11.9996 9.92871 9.42815C9.92871 7.71387 11.0773 7.71387 12.5001 7.71387C13.923 7.71387 15.0716 8.3653 15.0716 9.42815" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5002 16.2852V18.8566" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5 23.1426C18.6541 23.1426 23.6429 18.1538 23.6429 11.9998C23.6429 5.84576 18.6541 0.856934 12.5 0.856934C6.346 0.856934 1.35718 5.84576 1.35718 11.9998C1.35718 18.1538 6.346 23.1426 12.5 23.1426Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MoneyCashDollarCoin;

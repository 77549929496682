import React from 'react';
import { isProduction } from '../../util/getEnvironment';

const EnvironmentMode = () => {
  return isProduction ? <></> : (
    <div style={{ fontSize: '7pt', transform: 'rotate(45deg)', zIndex: 9999, position: 'fixed', top: 10, right: -30, padding: '5px 30px', color: 'rgb(44 43 58 / 68%)', background: 'rgb(0 47 255 / 17%)', pointerEvents: 'none' }}>
      Dev Mode
    </div>
  );
};

export default EnvironmentMode;

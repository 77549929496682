import React, { useMemo } from 'react';
import { CircleProgressProps } from './interface';
import { percentageToRadians } from './utils';
import { CircleProgressUnit } from './CircleProgressUnit';
import { CircleProgressContainer, CircleProgressWrapper } from './styled';

const CircleProgress = ({ data, size, strokeWidth, children, fill = 'transparent' }: CircleProgressProps) => {
  const getTotalRotate = (currentIndex: number): number => {
    let totalRotate = 0;
    for (let i = 0; i < currentIndex; i++) {
      totalRotate += percentageToRadians(getPercentage(data[i].value));
    }
    return totalRotate;
  };

  const total = useMemo(() => data.reduce((total, current) =>
    current.value + total
  , 0), [data]);

  const getPercentage = (value: number) => {
    return (value * 100) / total;
  };

  return (
    <CircleProgressWrapper fill={fill} size={size}>
      {data.map((circ, index) =>
        <CircleProgressUnit
          key={circ.color}
          percentage={getPercentage(circ.value)}
          rotate={getTotalRotate(index)}
          color={circ.color}
          strokeWidth={strokeWidth}
          size={size}
        />)}
      <CircleProgressContainer size={size} strokeWidth={strokeWidth}>
        {children}
      </CircleProgressContainer>
    </CircleProgressWrapper>
  );
};

export default CircleProgress;

import React from 'react';

const GraphArowIncrease = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
      <path d="M15.9805 1.42773H22.349V7.79631" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.349 1.42773L13.3534 10.4233C13.2046 10.5692 13.0045 10.6509 12.7961 10.6509C12.5877 10.6509 12.3877 10.5692 12.2389 10.4233L8.57694 6.76141C8.42814 6.61556 8.22807 6.53385 8.01969 6.53385C7.81131 6.53385 7.61125 6.61556 7.46244 6.76141L1.65112 12.5727" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default GraphArowIncrease;

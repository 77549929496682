import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import TeamService from '../../../common/services/team';
import { isDefined } from '../../../common/util/validations';
import { Team } from '../../../common/model/Team';
import Typography from '../../../common/designSystem/Typography/Typography';
import { Schedule } from '../../../common/model/Schedule';
import ScheduleService from '../../../common/services/schedule';
import UserService from '../../../common/services/user';
import Button from '../../../common/designSystem/Button/Button';
import { useNavigate } from 'react-router-dom';
import { setLocalStorage } from '../../../common/util/localStorage';
import { RoutePath } from '../../../routesPath';
import Logo from '../../../common/designSystem/Logo/Logo';
import { enqueueSnackbar } from 'notistack';
import { User } from '../../../common/model/User';
import { compareDates } from '../../../common/util/formateDate';

const Invite = () => {
  const queryParams = new URLSearchParams(location.search);
  const query = queryParams.get('team');
  const [scheduleId, setScheduleId] = useState<string | undefined>();
  const [game, setGame] = useState<string | undefined>();
  const [time, setTime] = useState({} as Team);
  const [schedule, setSchedule] = useState({} as Schedule);
  const [user, setUser] = useState<User | undefined>({} as User);
  const navigate = useNavigate();

  const getTime = async () => {
    const timeTemp = await TeamService.get(game!);
    setTime(timeTemp);
    const scheduleTemp = await ScheduleService.get(scheduleId!, true, timeTemp.admin);
    setSchedule(scheduleTemp);
  };

  useEffect(() => {
    if (isDefined(game) && isDefined(scheduleId)) {
      getTime();
    }
  }, [game, scheduleId]);

  useEffect(() => {
    setScheduleId(queryParams.get('team')?.split('game=')[1]);
    setGame(queryParams.get('team')?.split('game=')[0]);
  }, [query]);

  const getUserId = async () => {
    setUser(await UserService.getCurrent());
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    if (isDefined(user)) {
      if (user?.role !== 'player') {
        enqueueSnackbar('Somente conta de jogador pode aceitar convites', { variant: 'warning' });
        UserService.logout();
        setUser(undefined);
      }
    }
  }, [user]);

  const changeMyChoice = async (status: 'confirmed' | 'recused' | 'pending') => {
    try {
      const timeTemp = await TeamService.get(time.id ?? '');
      const dateSplit = (schedule?.date as string).split('-');
      const date = new Date(Number(dateSplit[2]), Number(dateSplit[1]) -1, Number(dateSplit[0]));
      let playersTemp = {};
      for (const datePlayer in timeTemp.players) {
        const dateSplitTemp = (datePlayer as string).split('-');
        const dateTemp = new Date(Number(dateSplitTemp[2]), Number(dateSplitTemp[1]) - 1, Number(dateSplitTemp[0]));
        if (compareDates(date, dateTemp)) {
          playersTemp = {
            ...playersTemp,
            [datePlayer]: [...timeTemp.players[datePlayer], {
              id: user!.id,
              name: user!.name!,
              status: status
            }]
          };
        } else if (dateTemp.getTime() > date.getTime()) {
          const statusTemp = status == 'confirmed' ? 'pending' : 'recused';
          playersTemp = {
            ...playersTemp,
            [datePlayer]: [...timeTemp.players[datePlayer], {
              id: user!.id,
              name: user!.name!,
              status: statusTemp
            }]
          };
        }
      }
      const data :Team = {
        ...timeTemp,
        players: { ...timeTemp.players, ...playersTemp },
        playersIds: [...timeTemp.playersIds, user!.id]
      };
      await TeamService.save(data);
      navigate(RoutePath.PLAYER_HOME);
      if (status == 'confirmed') {
        enqueueSnackbar('Você confirmou sua presença com sucesso!', { variant: 'success' });
      } else {
        enqueueSnackbar('Você recusou sua presença com sucesso!', { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  return isDefined(schedule) ? (
    <Grid isScrollable height={'calc(100% - 40px)'} rowGapXs={16} alignContent={'center'} style={{ paddingTop: '24px', paddingBottom: '16px', backgroundColor: 'white' }}>
      <GridItem xs={6} alignItems={'center'}>
        <Logo size={'xl'}/>
      </GridItem>
      <GridItem xs={6}>
        <Typography variant={'h3'} isFullWidth center>Convite de {time.players[schedule.date as string]?.find((player) => player.id === time.admin)?.name}</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Typography variant={'paragraph'} isFullWidth center>Para jogar {schedule.subSchedules?.find((schedule) => schedule.playerId == time.admin)?.sport} no {schedule.centerName} na quadra {schedule.courtName} no dia {
          (schedule.date as string)?.replaceAll('-', '/')
        } as {
          schedule.subSchedules?.find((schedule) => schedule.playerId == time.admin)?.scheduledHour
        }h</Typography>
      </GridItem>
      {isDefined(user) && time.players[schedule.date as string]?.find((player) => player.id === user!.id!) !== undefined && <>
        <GridItem xs={6}>
          <Typography variant={'paragraph'} isFullWidth center>Esse convite já foi aberto por você!</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Button isFullWidth color={'primary'} onClick={() => navigate(RoutePath.PLAYER_HOME)} variant={'filled'} title={'Ir para Home'}/>
        </GridItem>
      </>}
      {isDefined(user) && time.players[schedule.date as string]?.find((player) => player.id === user!.id!) === undefined && <>
        <GridItem xs={6}>
          <Button isFullWidth color={'primary'} onClick={() => changeMyChoice('confirmed')} variant={'filled'} title={'Aceitar'}/>
        </GridItem>
        <GridItem xs={6}>
          <Button isFullWidth color={'error'} onClick={() => changeMyChoice('recused')} variant={'transparent'} title={'Recusar'}/>
        </GridItem>
      </>}
      {!isDefined(user) && <>
        <GridItem xs={6}>
          <Typography variant={'paragraph'} isFullWidth center>Por favor se conecte para poder aceitar o convite:</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Button isFullWidth color={'info'} onClick={() => {
            setLocalStorage(RoutePath.PLAYER_INVITE+'?team='+query, 'redirectPath');
            navigate(RoutePath.LOGIN);
          }} variant={'filled'} title={'Entrar'}/>
        </GridItem>
        <GridItem xs={6}>
          <Button isFullWidth color={'neutral'} colorVariant={'a700'} onClick={() => {
            setLocalStorage(RoutePath.PLAYER_INVITE+'?team='+query, 'redirectPath');
            navigate(RoutePath.PLAYER_CREATE_ACCOUNT);
          }} variant={'transparent'} title={'Criar uma conta'}/>
        </GridItem>
      </>}
    </Grid>
  ) : <></>;
};

export default Invite;

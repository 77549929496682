import React from 'react';
import { GridItemProps, GridProps } from './interface';
import { GridItemStyle, GridStyle } from './styled';

export const Grid = (Props: GridProps) => {
  return <GridStyle {...Props}>{Props.children}</GridStyle>;
};

export const GridItem = (Props: GridItemProps) => {
  return <GridItemStyle {...Props}>{Props.children}</GridItemStyle>;
};

import React, { useCallback, useEffect, useState } from 'react';
import Header from '../../../common/component/Header/Header';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Carousel from '../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../common/designSystem/Screen';
import Button from '../../../common/designSystem/Button/Button';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import Checkbox from '../../../common/designSystem/Checkbox/Checkbox';
import InputTime from '../../../common/designSystem/InputTime/InputTime';
import Switch from '../../../common/designSystem/Switch/Switch';
import { RoutePath } from '../../../routesPath';
import { useNavigate } from 'react-router-dom';
import UserService from '../../../common/services/user';
import { SportCenter, Structure, WorkTime } from '../../../common/model/SportCenter';
import SportCenterService from '../../../common/services/sportCenter';
import { isDefined } from '../../../common/util/validations';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import Footer from '../../../common/component/Footer/Footer';
import { enqueueSnackbar } from 'notistack';
import { clearAllCache } from '../../../common/util/cache';

const SettingInfra = () => {
  const { register, setValue, getValue, registerValue, values, validateForm } = useForm();
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const userId = async () => (await UserService.getCurrent())!.id;

  const getSportCenter = async () => {
    const sportCenter = await SportCenterService.getByUserId(await userId(), true);
    if (isDefined(sportCenter?.address) && !isDefined(sportCenter?.workTime)) {
      setSportCenter(sportCenter);
      registerValue(register({ initialValue: ['Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'] }), 'weekDaysForWork', true);
    } else if (isDefined(sportCenter?.workTime)) {
      registerValue(register({ initialValue: sportCenter.workTime?.weekDaysForWork }), 'weekDaysForWork', true);
      const structureValue = sportCenter.infrastructure as any;
      if (isDefined(structureValue)) {
        for (const structureKey in structureValue) {
          if (isDefined(structureValue[structureKey] && getValue(structureKey))) {
            registerValue(register({ initialValue: structureValue[structureKey] }), structureKey, false);
          }
        }
      }
      setSportCenter(sportCenter);
    } else {
      registerValue(register({ initialValue: ['Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'] }), 'weekDaysForWork', true);
    }
  };

  useEffect(() => {
    clearAllCache();
    getSportCenter();
  }, []);

  useEffect(() => {
    const workTimeValue = sportCenter.workTime as WorkTime;
    if (isDefined(workTimeValue)) {
      for (const workTimeKey in workTimeValue) {
        if (isDefined(workTimeValue[workTimeKey as keyof WorkTime] && getValue(workTimeKey))) {
          setValue(workTimeValue[workTimeKey as keyof WorkTime], workTimeKey);
        }
      }
    }
    const structureValue = sportCenter.infrastructure as any;
    if (isDefined(structureValue)) {
      for (const structureKey in structureValue) {
        if (isDefined(structureValue[structureKey] && getValue(structureKey))) {
          setValue(structureValue[structureKey], structureKey);
        }
      }
    }
  }, [sportCenter]);

  const setWeekDaysForWork = useCallback((val: string) => {
    const oldVal = getValue('weekDaysForWork');
    if (oldVal.findIndex((temp: string) => temp == val) !== -1) {
      setValue([...(oldVal.filter((temp: string) => temp !== val))], 'weekDaysForWork');
    } else {
      setValue([...oldVal, val], 'weekDaysForWork');
    }
  }, [getValue('weekDaysForWork')]);

  const convertDate = (val: string) => {
    switch (val) {
    case 'S0': return 'Segunda';
    case 'T1': return 'Terça';
    case 'Q2': return 'Quarta';
    case 'Q3': return 'Quinta';
    case 'S4': return 'Sexta';
    case 'S5': return 'Sábado';
    case 'D6': return 'Domingo';
    default:
      return '';
    }
  };

  const save = async () => {
    try {
      if (validateForm()) {
        let sportCenter = await SportCenterService.getByUserId(await userId());
        const { morning, morningOpenTime, morningCloseTime, afternoon, afternoonOpenTime, afternoonCloseTime, night, nightOpenTime, nightCloseTime, weekDaysForWork, ...rest } = values;
        sportCenter = {
          ...sportCenter,
          workTime: { morning, morningOpenTime, morningCloseTime, afternoon, afternoonOpenTime, afternoonCloseTime, night, nightOpenTime, nightCloseTime, weekDaysForWork },
          infrastructure: { ...rest }
        };
        await SportCenterService.save(sportCenter);
        enqueueSnackbar('Salvo com sucesso', { variant: 'success' });
        isDefined(name) ? navigate(RoutePath.SPORT_CENTER_SETTING_COURT + '?name=' + name) : navigate(RoutePath.SPORT_CENTER_MY_CENTER);
      } else {
        enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  return (
    <RestrictArea role={'sportCenter'}>
      <Grid alignContent={'start'} isScrollable style={{ paddingBottom: '40px' }} height={'calc(100% - 106px)'} rowGapXs={8}>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h3'}>{isDefined(name) ? 'Agora vamos configurar o atendimento' : 'Configurações de atendimento'}</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'small'}>Selecione os dias da semana que você atende</Typography>
        </GridItem>
        <GridItem xs={6}>
          <Carousel height={'37px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
            {['S', 'T', 'Q', 'Q', 'S', 'S', 'D'].map((date, index) =>
              <Button color={'primary'}
                onClick={() => setWeekDaysForWork(convertDate(date+index))}
                selected={getValue('weekDaysForWork')?.findIndex((temp: string) => temp == convertDate(date+index)) !== -1}
                noWrapText
                key={convertDate(date+index)}
                variant={'select'} title={date}/>
            )}
          </Carousel>
        </GridItem>
        <GridItem xs={6} marginXs={'16px 0 8px 0'}>
          <Typography variant={'small'}>Turnos e horários que você atende</Typography>
        </GridItem>
        <GridItem xs={2}/>
        <GridItem xs={2}>
          <Typography variant={'xSmall'}>Abre</Typography>
        </GridItem>
        <GridItem xs={2}>
          <Typography variant={'xSmall'}>Fecha</Typography>
        </GridItem>
        {['Manhã', 'Tarde', 'Noite'].map((date) => {
          const name = date == 'Manhã' ? 'morning' : date == 'Tarde' ? 'afternoon' : 'night';
          const getInitHour = (name: string ) => name == 'morning' ? 8 : name == 'afternoon' ? 13 : 18;
          const getCloseHour = (name: string ) => name == 'morning' ? 12 : name == 'afternoon' ? 17 : 23;
          const openInit = ()=> ({ hour: getInitHour(name), minute: 0, value: getInitHour(name) });
          const closeInit = ()=> ({ hour: getCloseHour(name), minute: name == 'morning' ? 0 : 30, value: getCloseHour(name) + (name == 'morning' ? 0 : 0.5) });

          const getHours = (state = 'open') => {
            const all = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
            const numbers = all.filter((hour) => {
              if (isDefined(getValue('afternoonOpenTime'))) {
                if (name == 'morning') {
                  return (hour >= (state == 'open' ? getValue('nightCloseTime').hour : getValue('morningOpenTime').hour)) && (hour <= (state == 'open' ? getValue('morningCloseTime').hour : getValue('afternoonOpenTime').hour));
                } else if (name == 'afternoon') {
                  return (hour >= (state == 'open' ? getValue('morningCloseTime').hour : getValue('afternoonOpenTime').hour)) && (hour <= (state == 'open' ? getValue('afternoonCloseTime').hour : getValue('nightOpenTime').hour));
                } else if (name == 'night') {
                  return (hour >= getValue('afternoonCloseTime').hour && hour < 24) && (hour < 24);
                }
              }
              return true;
            });
            if (name == 'night' && state != 'open' && isDefined(getValue('afternoonOpenTime'))) {
              const night1 = all.filter((hour) => hour > getValue('nightOpenTime').hour);
              const night2 = all.filter((hour) => hour < getValue('morningOpenTime').hour);
              return [...night1, ...night2];
            }
            return numbers;
          };

          return <>
            <GridItem xs={2} justifyContent={'center'}>
              <Checkbox register={register({ initialValue: true })} label={<Typography variant={'xSmall'}>{date}</Typography>} name={name}/>
            </GridItem>
            <GridItem xs={2}>
              <InputTime disabled={!getValue(name)} register={register({ initialValue: openInit() })} hourOptions={getHours()} label={''} name={name+'OpenTime'} isFullWidth/>
            </GridItem>
            <GridItem xs={2}>
              <InputTime disabled={!getValue(name)} register={register({ initialValue: closeInit() })} hourOptions={getHours('close')} label={''} name={name+'CloseTime'} isFullWidth/>
            </GridItem>
          </>;
        })}
        <GridItem xs={2} marginXs={'16px 0 0dcc 0'}>
          <Typography variant={'small'}>Infraestrutura</Typography>
        </GridItem>
        {['Churrasqueira', 'Conveniência', 'Estacionamento', 'Vestiário', 'Banheiros', 'Espaço kids'].map((infra) => <>
          <GridItem xs={6} justifyContent={'center'}>
            <Switch register={register({ initialValue: false })} name={infra} labelActive={infra} labelInactive={infra}/>
          </GridItem>
        </>)}
      </Grid>
      <Footer>
        <Grid noMargin style={{ width: '100%' }} alignItems={'center'}>
          {!isDefined(name) && <GridItem xs={3}>
            <Button color={'error'} onClick={async () => navigate(RoutePath.SPORT_CENTER_MY_CENTER)} isFullWidth variant={'outlined'} title={'Voltar'}/>
          </GridItem>}
          <GridItem xs={isDefined(name) ? 6 : 3}>
            <Button isFullWidth disabled={!validateForm()} color={'info'} onClick={async () => await save()} variant={'filled'} title={isDefined(name) ? 'Próximo' : 'Salvar'}/>
          </GridItem>
        </Grid>
      </Footer>
    </RestrictArea>
  );
};

export default SettingInfra;

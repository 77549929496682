import styled from 'styled-components';

interface CircleProgressContainerProps {
  size: number;
  strokeWidth: number;
}

export const CircleProgressContainer = styled.div<CircleProgressContainerProps>`
  display: flex;
  border-radius: 50%;
  justify-content: center;
  position: relative;
  align-items: center;
  width: ${({ size, strokeWidth }) => `${size - (strokeWidth * 2)}px`};
  height: ${({ size, strokeWidth }) => `${size - (strokeWidth * 2)}px`};
`;

interface CircleProgressWrapperProps {
  fill: string;
  size: number;
}

export const CircleProgressWrapper = styled.div<CircleProgressWrapperProps>`
  display: flex;
  background: ${({ fill }) => fill};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
`;

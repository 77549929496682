import React, { useEffect } from 'react';
import { Grid, GridItem } from '../Grid/Grid';
import { RadioProps } from './interface';
import { active, disabledActive, disabledInactive, inactive } from './svg';
import './styled.css';

const Radio = (Props: RadioProps) => {
  const { options, register, disabled, name, required = false } = Props;
  const PropsScreen = { xs: Props.xs || 6, sm: Props.sm, md: Props.md, lg: Props.lg, xl: Props.xl, xxl: Props.xxl };

  const onClickAction = (value: string) => {
    if (disabled) return;
    register?.setValue(value, name);
  };

  useEffect(() => {
    register?.registerValue(register, name, required);
  }, []);

  return (
    <Grid noMargin rowGapXs={6}>
      {options.map((option, index) => {
        return (
          <GridItem className={disabled ? 'radio disabled' : 'radio'} {...PropsScreen} onClick={() => onClickAction(option.value)} key={index} flexDirection={'row'} alignItems={'center'}>
            {register?.getValue(name) === option.value ? disabled ? disabledActive : active : disabled ? disabledInactive : inactive}
            <label htmlFor={option.value}>{option.label}</label>
          </GridItem>
        );
      })}
    </Grid>
  );
};

export default Radio;

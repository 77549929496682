import React, { useState } from 'react';
import { Grid, GridItem } from '../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../common/designSystem/Typography/Typography';
import Input from '../../../../common/designSystem/Input/Input';
import Button from '../../../../common/designSystem/Button/Button';
import UserService from '../../../../common/services/user';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../routesPath';
import Dialog from '../../../../common/designSystem/Dialog/Dialog';
import MenuPlayer from '../../../../common/component/MenuPlayer/MenuPlayer';
import Footer from '../../../../common/component/Footer/Footer';

const PlayerSettingPassword = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  return (<>
    <Grid alignContent={'flex-start'} isScrollable height={'calc(100% - 66px)'} style={{ paddingTop: '24px', paddingBottom: '16px' }} rowGapXs={24} alignItems={'center'}>
      <GridItem xs={6}>
        <Typography variant="h1" >Alterar minha senha</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Input name={''} type='password' isFullWidth label={'Digite sua senha de Atual'} placeholder='Digite' required></Input>
      </GridItem>
      <GridItem xs={6}>
        <Input name={''} type='password' isFullWidth label={'Digite sua nova senha de acesso'} placeholder='Digite' required></Input>
      </GridItem>
      <GridItem xs={6}>
        <Input name={''} type='password' isFullWidth label={'Repita sua nova senha de acesso'} placeholder='Digite' required></Input>
      </GridItem>
    </Grid>
    <Footer>
      <Grid style={{ width: '100%' }}>
        <GridItem xs={3} justifyContent={'center'}>
          <Button color={'error'} isFullWidth onClick={() => navigate(RoutePath.PLAYER_ACCOUNT)} variant={'outlined'} title={'Voltar'}/>
        </GridItem>
        <GridItem xs={3} justifyContent={'center'}>
          <Button color={'info'} isFullWidth onClick={() => navigate(RoutePath.PLAYER_ACCOUNT)} variant={'filled'} title={'Salvar'}/>
        </GridItem>
      </Grid>
    </Footer>
  </>);
};

export default PlayerSettingPassword;

import styled from 'styled-components';
import { ButtonCePriceStyledProps } from './interface';
import { Colors, getColor } from '../../designSystem/Colors';
import { BorderRadius, Shadows } from '../../designSystem/GlobalStyle';
import React from 'react';

export const ButtonPriceChooseStyled = styled.button<ButtonCePriceStyledProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  outline: none;
  background: ${getColor('neutral', 'white')};
  width: 100%;
  cursor: pointer;
  border: 0.5px solid ${getColor('neutral', 'a100')};
  box-shadow: ${Shadows.med};
  border-radius: ${BorderRadius.medium};
  ${({ margin }) => margin && `margin: ${margin};`}

  :hover {
    filter: brightness(0.95);
  }
`;

export const ImageStyled = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border: 1px solid ${Colors.neutral.a300};
  border-radius: 25px;
  box-shadow: ${Shadows.low};
`;

export const ArrowStyled = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 0;
  width: 16px;
  height: 16px;
`;

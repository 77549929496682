import React from 'react';
import BigButton from '../../../../../common/designSystem/BigButton/BigButton';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';
import PadLockShield from '../../../../../common/designSystem/Icons/PadLockShield';
import GraphBarIncrease from '../../../../../common/designSystem/Icons/GraphBarIncrease';
import Stock from '../../../../../common/designSystem/Icons/Stock';
import ShieldCheck from '../../../../../common/designSystem/Icons/ShieldCheck';
import DollarCoin from '../../../../../common/designSystem/Icons/DollarCoin';

const mercadoPagoButtons = [
  {
    description: 'Sistema de transações 100% seguro',
    iconTop: <PadLockShield></PadLockShield>
  },
  {
    description: 'Seu dinheiro rende todos os dias',
    iconTop: <GraphBarIncrease></GraphBarIncrease>
  },
  {
    description: 'Gerencie suas vendas',
    iconTop: <Stock></Stock>
  },
  {
    description: 'Conta 100% digital e sem custos',
    iconTop: <ShieldCheck></ShieldCheck>
  }
];

const MercadoPagoButtonGroup = () => {
  return (<Grid noMargin>
    <GridItem xs={6}>
      <Carousel height={'108px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
        {mercadoPagoButtons.slice(0, 2).map((obj) => <BigButton onClick={ () => {} } title={''} description={obj.description} iconTop={obj.iconTop} style={{ marginBottom: '8px', minWidth: '124px' }}/>)}
      </Carousel>
    </GridItem>
    <GridItem xs={6}>
      <Carousel height={'108px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
        {mercadoPagoButtons.slice(2, 4).map((obj) => <BigButton onClick={ () => {} } title={''} description={obj.description} iconTop={obj.iconTop} style={{ marginBottom: '8px', minWidth: '124px' }}/>)}
      </Carousel>
    </GridItem>
    <GridItem xs={6} style={{ marginBottom: '8px' }}>
      <BigButton onClick={ () => {} } title={''} description={'Aceite muitos meios de pagamento para facilitar sua venda'} iconTop={<DollarCoin></DollarCoin>}></BigButton>
    </GridItem>
  </Grid>);
};

export default MercadoPagoButtonGroup;

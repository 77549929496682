import React from 'react';
import { BigButtonStyled } from './styled';
import { BigButtonProps } from './interface';
import Typography from '../Typography/Typography';

const BigButton = ({ title, description, margin, iconTop, onClick, ...props }: BigButtonProps) => {
  return (
    <BigButtonStyled { ...props } margin={margin} onClick={() => onClick()}>
      {iconTop && <div>{iconTop}</div>}
      <Typography variant={'body'} style={{ margin: '0 0 10px 0' }} bold center noUserSelect>{title}</Typography>
      <Typography variant={'tiny'} center noUserSelect>{description}</Typography>
    </BigButtonStyled>
  );
};

export default BigButton;

import React from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import BigButton from '../../../common/designSystem/BigButton/BigButton';
import { RoutePath } from '../../../routesPath';
import { useNavigate } from 'react-router-dom';

const CreateAccount = () => {
  const navigate = useNavigate();

  return (
    <Grid alignContent={'start'} rowGapXs={16} style={{ paddingBottom: '24px', paddingTop: '24px' }} isScrollable height={'calc(100% - 48px)'}>
      <GridItem xs={6} marginXs={'16px 0 0 0'}>
        <Typography variant={'paragraph'} component={<h1/>}>Bem vindo ao Joga Já</Typography>
      </GridItem>
      <GridItem xs={6} marginXs={'0 0 16px 0'}>
        <Typography variant={'paragraphSmall'}>Crie sua conta de uma maneira rápida, segura e grátis.</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Typography style={{ marginBottom: '16px' }} variant={'small'}>Selecione uma das opções para criar sua conta</Typography>
        <BigButton style={{ marginBottom: '24px' }} onClick={() => navigate(RoutePath.PLAYER_CREATE_ACCOUNT)} title={'Sou Jogador'} description={'Toque para selecionar'}/>
        <BigButton onClick={() => navigate(RoutePath.SPORT_CENTER_CREATE_ACCOUNT)} title={'Sou Centro Esportivo'} description={'Toque para selecionar'}/>
      </GridItem>
    </Grid>
  );
};

export default CreateAccount;

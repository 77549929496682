import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import { getColor } from '../../../../../common/designSystem/Colors';
import { BorderRadius, Shadows } from '../../../../../common/designSystem/GlobalStyle';
import TimeClockCircle from '../../../../../common/designSystem/Icons/TimeClockCircle';
import Calendar from '../../../../../common/designSystem/Icons/Calendar';
import UserCheck from '../../../../../common/designSystem/Icons/UserCheck';
import UserQuestion from '../../../../../common/designSystem/Icons/UserQuestion';
import UserDelete from '../../../../../common/designSystem/Icons/UserDelete';
import Button from '../../../../../common/designSystem/Button/Button';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import { Schedule } from '../../../../../common/model/Schedule';
import { Team } from '../../../../../common/model/Team';
import { isDefined } from '../../../../../common/util/validations';
import { formatToScheduleDate } from '../../../../../common/util/formateDate';

const GameCard = ({ schedule, team }: {schedule: Schedule, team: Team }) => {
  const navigate = useNavigate();
  const [sport, setSport] = useState<string | undefined>();
  const [scheduledHour, setScheduledHour] = useState<number | undefined>();
  const [date, setDate] = useState<string | undefined>();
  const [dateWeek, setDateWeek] = useState<string | undefined>();
  const [counts, setCounts] = useState({ confirmed: 0, pending: 0, rejected: 0 });

  useEffect(() => {
    if (isDefined(schedule)) {
      setSport(schedule.subSchedules?.find((schedule) => schedule.playerId == team.admin)?.sport);
      setScheduledHour(schedule.subSchedules?.find((schedule) => schedule.playerId == team.admin)?.scheduledHour);
      setDate(formatToScheduleDate(schedule.date as Date)?.replaceAll('-', '/'));
      const dayWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
      setDateWeek(dayWeek[(schedule.date as Date).getDay()]);
    }
  }, [schedule]);

  useEffect(() => {
    if (isDefined(date) && isDefined(team)) {
      let confirmed = 0;
      let pending = 0;
      let rejected = 0;
      team.players[date!.replaceAll('/', '-')].forEach((player) => {
        if (player.status === 'confirmed') {
          confirmed++;
        } else if (player.status === 'recused') {
          rejected++;
        } else {
          pending++;
        }
      });
      setCounts({ confirmed, pending, rejected });
    }
  }, [team, date]);

  return (
    <>
      <GridItem xs={6}
        style={{ height: '185px', cursor: 'pointer', minWidth: '216px', marginBottom: '12px', border: `0.5px solid ${getColor('neutral', 'a100')}`, boxShadow: `${Shadows.med}`, borderRadius: `${BorderRadius.large}`, padding: '16px 16px 0 16px', background: 'white' }}
        onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + team.id + '&scheduleId=' + schedule.id)}>
        <Grid noMargin style={{ width: '100%' }}>
          <GridItem xs={6}>
            <Typography variant={'h3'} isFullWidth style={{ textAlign: 'left' }}>{sport}</Typography>
          </GridItem>
          <GridItem xs={6} style={{ marginTop: '8px' }}>
            <Grid noMargin>
              <GridItem xs={2} lg={5} isRow style={{ justifyItems: 'center', columnGap: '8px' }}>
                <TimeClockCircle width={17} height={16}/>
                <Typography variant={'paragraphSmall'}>{scheduledHour}h</Typography>
              </GridItem>
              <GridItem xs={4} lg={5} isRow style={{ justifyItems: 'center', justifyContent: 'flex-end', columnGap: '8px' }}>
                <div style={{ width: '17px', height: '16px' }}><Calendar width={17} height={16}/></div>
                <Typography variant={'paragraphSmall'}>{date}</Typography>
              </GridItem>
              <GridItem xs={6} lg={5} isRow style={{ justifyItems: 'center', columnGap: '8px' }}>
                <Typography variant={'paragraphSmall'} isFullWidth style={{ textAlign: 'right' }}>{dateWeek}</Typography>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'paragraphSmall'} style={{ textAlign: 'left' }}>Jogadores para a partida</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Grid noMargin>
              <GridItem xs={2} isRow style={{ columnGap: '8px' }} alignItems='center'>
                <UserCheck></UserCheck>
                <Typography variant={'paragraphSmall'} bold>{counts.confirmed}</Typography>
              </GridItem>
              <GridItem xs={2} isRow style={{ columnGap: '8px', justifyContent: 'center' }} alignItems='center'>
                <UserQuestion></UserQuestion>
                <Typography variant={'paragraphSmall'}>{counts.pending}</Typography>
              </GridItem>
              <GridItem xs={2} isRow style={{ columnGap: '8px', justifyContent: 'flex-end' }} alignItems='center'>
                <UserDelete></UserDelete>
                <Typography variant={'paragraphSmall'}>{counts.rejected}</Typography>
              </GridItem>
            </Grid>
          </GridItem>
          <GridItem xs={6}>
            <Button color={'info'} onClick={() => {}} variant={'transparent'} title={'Ver mais'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
          </GridItem>
        </Grid>
      </GridItem>
    </>);
};

export default GameCard;

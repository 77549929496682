import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import { Colors, getColor } from '../../../../../common/designSystem/Colors';
import { BorderRadius, Shadows } from '../../../../../common/designSystem/GlobalStyle';
import LocationPinNavMap from '../../../../../common/designSystem/Icons/LocationPinNavMap';
import TimeClockCircle from '../../../../../common/designSystem/Icons/TimeClockCircle';
import Calendar from '../../../../../common/designSystem/Icons/Calendar';
import UserCheck from '../../../../../common/designSystem/Icons/UserCheck';
import UserQuestion from '../../../../../common/designSystem/Icons/UserQuestion';
import UserDelete from '../../../../../common/designSystem/Icons/UserDelete';
import Button from '../../../../../common/designSystem/Button/Button';
import { Schedule } from '../../../../../common/model/Schedule';
import { dateToString, formatToScheduleDate } from '../../../../../common/util/formateDate';
import { Team } from '../../../../../common/model/Team';
import UserService from '../../../../../common/services/user';
import TeamService from '../../../../../common/services/team';
import { enqueueSnackbar } from 'notistack';
import { isDefined } from '../../../../../common/util/validations';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import { propsFilter } from '../../../../../common/util/web';

const Invite = ({ schedule, time, get }: {schedule: Schedule, time: Team, get: any }) => {
  const [counts, setCounts] = useState({ confirmed: 0, pending: 0, rejected: 0 });
  const [me, setMe] = useState<any>();
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    let confirmed = 0;
    let pending = 0;
    let rejected = 0;
    time.players[formatToScheduleDate(schedule.date as Date)].forEach((player) => {
      if (isDefined(userId) && player.id === userId) {
        setMe(player);
      }
      if (player.status === 'confirmed') {
        confirmed++;
      } else if (player.status === 'recused') {
        rejected++;
      } else {
        pending++;
      }
    });
    setCounts({ confirmed, pending, rejected });
  }, [time, userId]);

  const getUserId = async () => {
    setUserId((await UserService.getCurrent())?.id ?? '');
  };

  useEffect(() => {
    getUserId();
  }, []);

  const changeMyChoice = async (status: 'confirmed' | 'recused' | 'pending') => {
    try {
      const timeTemp = await TeamService.get(time.id ?? '');
      await TeamService.save({
        ...timeTemp, players: { ...timeTemp.players, [formatToScheduleDate(schedule.date as Date)]: timeTemp.players[formatToScheduleDate(schedule.date as Date)].map((player) => {
          if (player.id == userId) {
            return { ...player, status: status };
          }
          return player;
        }) }
      });
      await get();
      if (status == 'confirmed') {
        enqueueSnackbar('Você confirmou sua presença com sucesso!', { variant: 'success' });
      } else {
        enqueueSnackbar('Você recusou sua presença com sucesso!', { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  const getDateWeek = (date: Date) => {
    const dayWeek = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado'];
    return dayWeek[date.getDay()];
  };

  return (<Grid style={{ height: '302px', border: `0.5px solid ${getColor('neutral', 'a100')}`, background: Colors.neutral.white, boxShadow: `${Shadows.med}`, borderRadius: `${BorderRadius.large}` }}>
    <GridItem xs={6} onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer', marginTop: '16px' }}>
      <Typography variant={'h3'}>{schedule.subSchedules![0]?.sport}</Typography>
    </GridItem>
    <GridItem xs={6} onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer' }}>
      <Grid noMargin>
        <GridItem xs={3} isRow style={{ justifyItems: 'center', justifyContent: 'flex-start', columnGap: '8px' }}>
          <LocationPinNavMap width={15} height={20}/>
          <Typography variant={'paragraphSmall'}>{schedule.centerName}</Typography>
        </GridItem>
        <GridItem xs={3} isRow style={{ justifyItems: 'center', columnGap: '8px', justifyContent: 'flex-end' }} alignItems='center'>
          <Calendar/>
          <Typography variant={'paragraphSmall'}>{dateToString(schedule.date as Date, '/')}</Typography>
        </GridItem>
        <GridItem xs={2} alignItems='center' isRow style={{ width: 'calc(100% - 16px)', justifyItems: 'center', columnGap: '8px' }}>
          <TimeClockCircle width={17} height={16}/>
          <Typography variant={'paragraphSmall'}>{schedule.subSchedules![0].scheduledHour}h</Typography>
        </GridItem>
        <GridItem xs={4} lg={5} isRow style={{ width: 'calc(100% - 16px)', justifyItems: 'center', columnGap: '8px', justifyContent: 'flex-end' }}>
          <Typography variant={'paragraphSmall'} isFullWidth style={{ textAlign: 'right' }}>{getDateWeek(schedule.date as Date)}</Typography>
        </GridItem>
      </Grid>
    </GridItem>
    <GridItem xs={6} onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer' }}>
      <Grid noMargin>
        <GridItem xs={6} justifyContent='center'>
          <Typography variant={'paragraphSmall'}>Jogadores para a partida</Typography>
        </GridItem>
        <GridItem xs={1} isRow style={{ columnGap: '8px' }} alignItems='center'>
          <UserCheck></UserCheck>
          <Typography variant={'paragraphSmall'}>{counts.confirmed}</Typography>
        </GridItem>
        <GridItem xs={1} isRow style={{ columnGap: '8px' }} alignItems='center'>
          <UserQuestion></UserQuestion>
          <Typography variant={'paragraphSmall'}>{counts.pending}</Typography>
        </GridItem>
        <GridItem xs={1} isRow style={{ columnGap: '8px' }} alignItems='center'>
          <UserDelete></UserDelete>
          <Typography variant={'paragraphSmall'}>{counts.rejected}</Typography>
        </GridItem>
      </Grid>
    </GridItem>
    <GridItem xs={6} onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer' }}>
      <Typography variant={'paragraphSmall'} bold>{isDefined(me) && me.status == 'pending' ? 'Você vai jogar?' : 'Status para a partida'}</Typography>
    </GridItem>
    {isDefined(me) && me.status == 'pending' && <>
      <GridItem xs={6} padding='0 16px 0 0'>
        <Button color={'info'} onClick={async () => await changeMyChoice('confirmed')} variant={'filled'} title={'Confirmar'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
      </GridItem>
      <GridItem xs={6} padding='8px 16px 0 0'>
        <Button color={'error'} onClick={async () => await changeMyChoice('recused')} variant={'transparent'} title={'Recusar'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
      </GridItem>
    </>
    }
    {isDefined(me) && me.status == 'confirmed' && <>
      <GridItem xs={6} isRow padding='0 16px 0 0' onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer', columnGap: '8px' }}>
        <UserCheck></UserCheck>
        <Typography variant={'paragraphSmall'}>Confirmado para a partida</Typography>
      </GridItem>
      <GridItem xs={6} padding='8px 16px 0 0'>
        <Button color={'error'} onClick={async () => await changeMyChoice('recused')} variant={'transparent'} title={'Cancelar presença na partida'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
      </GridItem>
    </>}
    {isDefined(me) && me.status == 'recused' && <>
      <GridItem xs={6} isRow padding='0 16px 0 0' onClick={() => navigate(RoutePath.PLAYER_GAME + '?game=' + time.id + '&scheduleId=' + schedule.id)} style={{ cursor: 'pointer', columnGap: '8px' }}>
        <UserDelete></UserDelete>
        <Typography variant={'paragraphSmall'}>Recusado, não vou jogar</Typography>
      </GridItem>
      <GridItem xs={6} padding='8px 16px 0 0'>
        <Button color={'success'} onClick={async () => await changeMyChoice('confirmed')} variant={'transparent'} title={'Confirmar minha presença'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
      </GridItem>
    </>}
  </Grid>);
};

export default Invite;

import { isDefined } from './validations';

export const setError = (message: string, fileName: string, local: string, error?: unknown) => {
  console.error(`FileName: ${fileName}/${local} - ${message}: ${(error as any)?.toString()}`, {
    cause: message
  });
};

export const translateError = (errorCode: string) => {
  if (!isDefined(errorCode)) {
    return '';
  }
  if (errorMap[errorCode]) {
    return errorMap[errorCode];
  }
  return errorCode;
};

const errorMap: {[errorCode: string]: string} = {
  // Erros do Firebase Auth
  'auth/invalid-email': 'O e-mail fornecido é inválido.',
  'auth/weak-password': 'A senha fornecida é muito fraca. Ela deve ter pelo menos 6 caracteres.',
  'auth/email-already-in-use': 'Este e-mail já está sendo usado por outra conta.',
  'auth/popup-closed-by-user': 'A operação foi cancelada pelo usuário.',
  'auth/wrong-password': 'A senha fornecida está incorreta.',
  'auth/user-not-found': 'Não foi possível encontrar um usuário com estas credenciais.',
  'auth/invalid-verification-code': 'O código de verificação fornecido é inválido.',
  'auth/missing-verification-code': 'O código de verificação é obrigatório e não foi fornecido.',
  'auth/network-request-failed': 'Não foi possível completar a operação devido a problemas de conexão com a internet.',
  'auth/operation-not-allowed': 'Esta operação não é permitida.',
  'auth/timeout': 'O tempo limite para a operação foi excedido.',
  'auth/invalid-login-credentials': 'As credenciais de login fornecidas são inválidas.',
  'auth/invalid-signup-details': 'Os detalhes fornecidos para o cadastro são inválidos.',
  'auth/too-many-requests': 'Houve muitas tentativas de login. Por favor, tente novamente mais tarde.',
  // Erros do Firestore
  'firestore/aborted': 'Esta operação foi abortada devido a um problema de concorrência. Por favor, tente novamente.',
  'firestore/already-exists': 'O documento que você está tentando criar já existe.',
  'firestore/cancelled': 'A operação foi cancelada.',
  'firestore/data-loss': 'Houve uma perda irreversível de dados ou corrupção.',
  'firestore/deadline-exceeded': 'O prazo para a operação foi excedido. Por favor, tente novamente.',
  'firestore/failed-precondition': 'A operação foi rejeitada devido ao estado atual do sistema. Por favor, tente novamente mais tarde.',
  'firestore/internal': 'Ocorreu um erro interno. Por favor, entre em contato com o suporte para obter assistência.',
  'firestore/invalid-argument': 'O argumento fornecido é inválido. Por favor, verifique e tente novamente.',
  'invalid-argument': 'Cadastro não encontrado.',
  'firestore/not-found': 'O documento solicitado não foi encontrado.',
  'firestore/out-of-range': 'A operação foi tentada além do intervalo permitido.',
  'firestore/permission-denied': 'Você não tem permissão para executar esta operação.',
  'firestore/resource-exhausted': 'Um recurso necessário está esgotado. Por favor, tente novamente mais tarde.',
  'firestore/unauthenticated': 'Você não está autenticado para realizar esta operação.',
  'firestore/unavailable': 'O serviço está temporariamente indisponível. Por favor, tente novamente mais tarde.',
  'firestore/unimplemented': 'Esta operação não está implementada ou não é suportada.',
  'firestore/unknown': 'Ocorreu um erro desconhecido. Por favor, entre em contato com o suporte para obter assistência.',
  // Erros do Storage
  'storage/unauthorized': 'Você não tem autorização para executar esta operação de armazenamento.',
  'storage/canceled': 'A operação de armazenamento foi cancelada.',
  'storage/unknown': 'Ocorreu um erro desconhecido durante a operação de armazenamento. Por favor, entre em contato com o suporte para obter assistência.'
};

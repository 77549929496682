import React from 'react';

const MoneyCashCoinsStack = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M15.9286 12.8571C20.1891 12.8571 23.6429 11.3221 23.6429 9.42857C23.6429 7.53502 20.1891 6 15.9286 6C11.6682 6 8.21436 7.53502 8.21436 9.42857C8.21436 11.3221 11.6682 12.8571 15.9286 12.8571Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.21436 9.42871V19.7144C8.21436 21.6001 11.6429 23.143 15.9286 23.143C20.2144 23.143 23.6429 21.6001 23.6429 19.7144V9.42871" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M23.6429 14.5713C23.6429 16.457 20.2144 17.9999 15.9286 17.9999C11.6429 17.9999 8.21436 16.457 8.21436 14.5713" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.7572 2.57162C13.754 1.3354 11.4223 0.737529 9.07146 0.85733C4.80289 0.85733 1.35718 2.40019 1.35718 4.2859C1.35718 5.29733 2.35146 6.2059 3.92861 6.85733" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.92861 17.1433C2.35146 16.4918 1.35718 15.5833 1.35718 14.5718V4.28613" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M3.92861 12.0001C2.35146 11.3487 1.35718 10.4401 1.35718 9.42871" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MoneyCashCoinsStack;

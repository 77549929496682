import React, { useEffect } from 'react';
import { WrapperCheckboxStyled } from './styled';
import { CheckboxProps } from './interface';
import { active, disabledActive, disabledInactive, inactive } from './svg';
import { isDefined } from '../../util/validations';
const Checkbox = (Props: CheckboxProps) => {
  const { disabled, label, onChange, checked = false, register, name, required = false } = Props;

  const onClickAction = () => {
    if (disabled) return;
    register?.setValue(!register?.getValue(name), name );
  };

  useEffect(() => {
    register?.registerValue(register, name, required);
    isDefined(register?.initialValue) && register?.setValue(register?.initialValue, name );
  }, []);

  return (
    <WrapperCheckboxStyled disabled={disabled} onClick={() => onClickAction()}>
      {register?.getValue(name) ?
        disabled ? disabledActive : active :
        disabled ? disabledInactive : inactive
      }
      {label}
    </WrapperCheckboxStyled>

  );
};

export default Checkbox;

import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Button from '../../../common/designSystem/Button/Button';
import { GlobalStyle } from '../../../common/designSystem/GlobalStyle';
import Typography from '../../../common/designSystem/Typography/Typography';
import { timeout } from 'workbox-core/_private';
import Barbecue from '../../../common/designSystem/Icons/Barbecue';
import Logo from '../../../common/designSystem/Logo/Logo';
import Checkbox from '../../../common/designSystem/Checkbox/Checkbox';
import Radio from '../../../common/designSystem/Radio/Radio';
import Switch from '../../../common/designSystem/Switch/Switch';
import Dialog from '../../../common/designSystem/Dialog/Dialog';
import BigButton from '../../../common/designSystem/BigButton/BigButton';
import Calendar from '../../../common/designSystem/Calendar/Calendar';
import Menu from '../../../common/designSystem/Icons/Menu';
import InputTime from '../../../common/designSystem/InputTime/InputTime';
import InputCard from '../../../common/designSystem/InputCard/InputCard';
import Carousel from '../../../common/designSystem/Carousel/Carousel';
import ButtonSPPrice from '../../../common/component/ButtonSPPrice/ButtonSPPrice';
import ButtonPriceChoose from '../../../common/component/ButtonPriceChoose/ButtonPriceChoose';
import Pix from '../../../common/designSystem/Icons/Pix';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import Input from '../../../common/designSystem/Input/Input';
import CircleProgress from '../../../common/component/Charts/CircleProgress/CircleProgress';
import { Colors } from '../../../common/designSystem/Colors';
import DaysInWeek from '../../../common/designSystem/DaysInWeek/DaysInWeek';
import { getPaddingSize } from '../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../common/designSystem/Screen';
import Select from '../../../common/designSystem/Select/Select';
import Accordion from '../../../common/designSystem/Accordion/Accordion';
import Bars from '../../../common/component/Charts/Bars/Bars';
import { HourMinute } from '../../../common/model/SportCenter';

function Components() {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogObligatory, setOpenDialogObligatory] = useState(false);
  const [data, setData] = useState({} as any);
  const [step, setStep] = useState(0);
  const { register, registerValue, setValue, getValue, values, errors } = useForm();
  const [openAccordion, setOpenAccordion] = useState<string>();

  useEffect(() => {
    console.log('values', values);
    console.log('errors', errors);
  }, [values]);

  useEffect(() => {
    registerValue(register({ initialValue: '1h' }), 'playTime', false);
  }, []);

  function logos() {
    return (
      <>
        <GridItem
          xs={6}
          showOnlyXs
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'xs'} />
        </GridItem>
        <GridItem
          xs={6}
          showOnlySm
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'sm'} />
        </GridItem>
        <GridItem
          xs={6}
          showOnlyMd
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'md'} />
        </GridItem>
        <GridItem
          xs={6}
          showOnlyLg
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'lg'} />
        </GridItem>
        <GridItem
          xs={6}
          showOnlyXl
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'xl'} />
        </GridItem>
        <GridItem
          xs={6}
          showOnlyXxl
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Logo size={'xxl'} />
        </GridItem>
      </>
    );
  }
  function selects() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Select</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'select' ? '' : 'select')}
        open={openAccordion === 'select'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Select
            label={'Select'}
            isFullWidth
            placeholder={'Select'}
            options={[
              { value: 'Select1', title: 'Select 1' },
              { value: 'Select2', title: 'Select 2' }
            ]}
            name={'select'}
            register={register({ initialValue: 'Select1' })}
          />
        </GridItem>
        <GridItem xs={6}>
          <Select
            label={'Select Button'}
            options={[
              { value: 'Select1', title: 'Select Button 1' },
              { value: 'Select2', title: 'Select Button 2' }
            ]}
            name={'selectButton'}
            variant={'button'}
            register={register({ initialValue: 'Select1' })}
          />
        </GridItem>
      </Accordion>
    );
  }
  function daysInWeek() {
    return (
      <Accordion
        header={<Typography variant={'body'}>DaysInWeek</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'DaysInWeek' ? '' : 'DaysInWeek')}
        open={openAccordion === 'DaysInWeek'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <DaysInWeek name={'DaysInWeek'} register={register()} />
        </GridItem>
      </Accordion>
    );
  }
  function charts() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Charts</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Charts' ? '' : 'Charts')}
        open={openAccordion === 'Charts'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <CircleProgress
            data={[
              { value: 50, color: Colors.chart1.main },
              { value: 50, color: Colors.chart2.main },
              { value: 50, color: Colors.chart3.main },
              { value: 50, color: Colors.chart1.main },
              { value: 50, color: Colors.chart2.main }
            ]}
            size={100}
            strokeWidth={16}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <Typography variant={'xSmall'}>total</Typography>
              <Typography variant={'small'} bold>
                70
              </Typography>
            </div>
          </CircleProgress>
        </GridItem>
        <GridItem xs={6}>
          <Bars data={[
            [{ value: 20, color: Colors.chart1.main }, { value: 3, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 7, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 10, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 12, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 16, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 12, color: Colors.chart2.main }],
            [{ value: 20, color: Colors.chart1.main }, { value: 17, color: Colors.chart2.main }]
          ]}
          legend={{
            bottom: ['S', 'T', 'Q', 'Q', 'S', 'S', 'D'],
            leftCount: 6
          }}
          />
        </GridItem>
      </Accordion>
    );
  }
  function buttons() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Buttons</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Buttons' ? '' : 'Buttons')}
        open={openAccordion === 'Buttons'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Button
            color={'primary'}
            isFullWidth
            onClick={async () => await timeout(1500)}
            variant={'filled'}
            title={'Button Filled'}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            isFullWidth
            color={'info'}
            colorVariant={'a200'}
            onClick={async () => await timeout(1500)}
            variant={'outlined'}
            title={'Button Outlined'}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            isFullWidth
            color={'primary'}
            colorVariant={'a900'}
            onClick={async () => await timeout(1500)}
            variant={'transparent'}
            title={'Button Transparent'}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            isFullWidth
            color={'success'}
            onClick={async () => await timeout(500)}
            variant={'filled'}
            disabled
            title={'Button Filled Disabled'}
          />
        </GridItem>
        <GridItem xs={6}>
          <Typography variant={'h2'} center>
            Buttons Icons
          </Typography>
        </GridItem>
        <GridItem xs={6}>
          <Button
            color={'neutral'}
            onClick={() => null}
            variant={'filled'}
            icon={<Menu color={'neutral'} colorVariant={'a900'} />}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            color={'neutral'}
            onClick={() => null}
            variant={'outlined'}
            icon={<Menu color={'neutral'} colorVariant={'a900'} />}
          />
        </GridItem>
        <GridItem xs={6}>
          <Button
            color={'neutral'}
            onClick={() => null}
            variant={'transparent'}
            icon={<Menu color={'neutral'} colorVariant={'a900'} />}
          />
        </GridItem>
      </Accordion>
    );
  }
  function typography() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Typography</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Typography' ? '' : 'Typography')}
        open={openAccordion === 'Typography'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={3}>
          <Typography variant={'h1'}>H1</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'paragraph'}>Paragraph</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'h2'}>H2</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'body'}>Body</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'h3'}>H3</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'paragraphSmall'}>Paragraph Small</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'xSmall'}>X Small</Typography>
        </GridItem>
        <GridItem xs={3}>
          <Typography variant={'tiny'}>Tiny</Typography>
        </GridItem>
      </Accordion>
    );
  }
  function input() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Input</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Input' ? '' : 'Input')}
        open={openAccordion === 'Input'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Input
            register={register({
              onMask: (value) => (value as string)?.replace(/[^\d]/g, ''),
              onValidation: (value) => {
                return (value as number) >= 5;
              }
            })}
            name={'test'}
            label={'Input Label'}
            required
            placeholder={'Placeholder'}
            isFullWidth
            icon={<Barbecue />}
          />
        </GridItem>
        <GridItem xs={6}>
          <Input
            register={register()}
            name={'test2'}
            label={'Input Label'}
            placeholder={'Placeholder'}
            isFullWidth
            disabled
          />
        </GridItem>
      </Accordion>
    );
  }
  function checkbox() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Checkbox</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Checkbox' ? '' : 'Checkbox')}
        open={openAccordion === 'Checkbox'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6} sm={2} justifyContent={'center'}>
          <Checkbox
            register={register({ initialValue: true })}
            label={<>Checkbox Label</>}
            name={'checkboxLabel'}
          />
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Checkbox
            register={register()}
            label={<>Checkbox Inactive Disabled</>}
            name={'checkboxInactiveDisabled'}
            disabled
          />
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Checkbox
            register={register({ initialValue: true })}
            label={<>Checkbox Active Disabled</>}
            name={'checkboxActiveDisabled'}
            checked
            disabled
          />
        </GridItem>
      </Accordion>
    );
  }
  function radio() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Radio</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Radio' ? '' : 'Radio')}
        open={openAccordion === 'Radio'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Radio
            register={register({ initialValue: '1' })}
            options={[
              { label: 'Radio 1', value: '1' },
              { label: 'Radio 2', value: '2' },
              { label: 'Radio 3', value: '3' }
            ]}
            required
            name={'radio'}
            xs={6}
            sm={2}
          />
        </GridItem>
        <GridItem xs={6}>
          <Radio
            register={register({ initialValue: '1' })}
            options={[{ label: 'Disabled Active', value: '1' }]}
            name={'radio2'}
            xs={6}
            sm={2}
            disabled
          />
        </GridItem>
        <GridItem xs={6}>
          <Radio
            register={register()}
            options={[{ label: 'Disabled Inactive', value: '1' }]}
            name={'radio3'}
            xs={6}
            sm={2}
            disabled
          />
        </GridItem>
      </Accordion>
    );
  }
  function switchs() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Switches</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Switches' ? '' : 'Switches')}
        open={openAccordion === 'Switches'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Typography variant={'h2'} center>
            Switches
          </Typography>
        </GridItem>
        <GridItem xs={6} sm={2} justifyContent={'center'}>
          <Switch
            register={register({ initialValue: true })}
            labelActive={'Switch Active'}
            labelInactive={'Switch Inactive'}
            name={'switch'}
          />
        </GridItem>
        <GridItem xs={6} sm={2} justifyContent={'center'}>
          <Switch
            register={register()}
            labelActive={'Switch Disabled Active'}
            labelInactive={'Switch Disabled Inactive'}
            disabled
            name={'switch2'}
          />
        </GridItem>
        <GridItem xs={6} sm={2} justifyContent={'center'}>
          <Switch
            register={register()}
            labelActive={'Switch Disabled Active'}
            labelInactive={'Switch Disabled Inactive'}
            disabled
            name={'switch3'}
          />
        </GridItem>
      </Accordion>
    );
  }
  function dialog() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Dialog</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Dialog' ? '' : 'Dialog')}
        open={openAccordion === 'Dialog'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6} sm={2}>
          <Button
            color={'info'}
            colorVariant={'a300'}
            onClick={() => setOpenDialog(true)}
            variant={'filled'}
            title={'Open Dialog'}
          />
        </GridItem>
        <GridItem xs={6} sm={2}>
          <Button
            color={'info'}
            colorVariant={'a300'}
            onClick={() => setOpenDialogObligatory(true)}
            variant={'filled'}
            title={'Open Dialog Obligatory'}
          />
        </GridItem>
        <Dialog
          open={openDialog}
          setOpen={setOpenDialog}
          title={'Desktop Example'}
          buttonLabelConfirm={'Confirm'}
          buttonLabelCancel={'Cancel'}
          onCancel={() => console.log('Cancel')}
          onConfirm={() => console.log('Confirm')}
        >
          <Typography variant={'paragraph'}>
            Thi is the modal body, You'll probably end up detatching me to build
            out your modal body more. I've got a hidden input in here if you
            want to use it!
          </Typography>
        </Dialog>
        <Dialog
          open={openDialogObligatory}
          isObligatory
          setOpen={setOpenDialogObligatory}
          title={'Desktop Example'}
          buttonLabelConfirm={'Confirm'}
          buttonLabelCancel={'Cancel'}
          onCancel={() => console.log('Cancel')}
          onConfirm={() => console.log('Confirm')}
        >
          <Typography variant={'paragraph'}>
            Thi is the modal body, You'll probably end up detatching me to build
            out your modal body more. I've got a hidden input in here if you
            want to use it!
          </Typography>
        </Dialog>
      </Accordion>
    );
  }
  function bigButtons() {
    return (
      <Accordion
        header={<Typography variant={'body'}>BigButton</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'BigButton' ? '' : 'BigButton')}
        open={openAccordion === 'BigButton'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <BigButton
            onClick={() => null}
            title={'BigButton'}
            description={'Description'}
          />
        </GridItem>
      </Accordion>
    );
  }
  function calendar() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Calendar</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Calendar' ? '' : 'Calendar')}
        open={openAccordion === 'Calendar'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6} alignItems={'center'}>
          <Calendar
            register={register({ initialValue: new Date() })}
            onlyFutureDates
            name={'calendar'}
          />
        </GridItem>
      </Accordion>
    );
  }
  function inputTime() {
    return (
      <Accordion
        header={<Typography variant={'body'}>InputTime</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'InputTime' ? '' : 'InputTime')}
        open={openAccordion === 'InputTime'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <InputTime
            register={register({
              initialValue: { hour: 19, minute: 0 } as HourMinute
            })}
            isFullWidth
            name={'InputTime'}
            label={'Selecione o horário'}
            required
          />
        </GridItem>
      </Accordion>
    );
  }
  function inputCard() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Input Card</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'InputCard' ? '' : 'InputCard')}
        open={openAccordion === 'InputCard'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Typography variant={'h2'} center>
            Input Card
          </Typography>
        </GridItem>
        <GridItem xs={6}>
          <InputCard setData={setData} step={step} />
        </GridItem>
        {step <= 3 && (
          <GridItem xs={6} alignItems={'flex-end'}>
            <Button
              color={'primary'}
              variant={'filled'}
              onClick={() => setStep((valTemp) => valTemp + 1)}
              title={'Avançar'}
            />
          </GridItem>
        )}
      </Accordion>
    );
  }
  function buttonPrice() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Button Price</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Button Price' ? '' : 'Button Price')}
        open={openAccordion === 'Button Price'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6} alignItems={'flex-end'}>
          <ButtonSPPrice
            onClick={() => console.log('Centro Joga 10')}
            name={'Centro Joga 10'}
            type={'Areia e Sintético'}
            sportType={[]}
            price={'R$ 380,00 à R$ 450,00'}
          />
        </GridItem>
        <GridItem xs={6} alignItems={'flex-end'}>
          <ButtonPriceChoose
            onClick={() => console.log('Centro Joga 10')}
            title={'Novo cartão'}
            description={'Toque para adicionar'}
            icon={<Pix />}
          />
        </GridItem>
      </Accordion>
    );
  }
  function carousel() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Carousel</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Carousel' ? '' : 'Carousel')}
        open={openAccordion === 'Carousel'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6}>
          <Carousel
            height={'46px'}
            marginLeft={getPaddingSize(getDevice(window.innerWidth))*2}
          >
            {[
              '1h',
              '1h 30min',
              '2h',
              '2h 30min',
              '3h',
              '3h 30min',
              '4h',
              '4h 30min'
            ].map((time) => (
              <Button
                color={'primary'}
                onClick={() => setValue(time, 'playTime')}
                variant={'select'}
                title={time}
                selected={time === getValue('playTime')}
                noWrapText
                key={time}
              />
            ))}
          </Carousel>
        </GridItem>
      </Accordion>
    );
  }
  function mercadoPago() {
    return (
      <Accordion
        header={<Typography variant={'body'}>Mercado Pago</Typography>}
        onClick={() => setOpenAccordion(openAccordion == 'Mercado Pago' ? '' : 'Mercado Pago')}
        open={openAccordion === 'Mercado Pago'}
        xs={6}
        rowGapXs={16}
      >
        <GridItem xs={6} alignItems={'flex-end'}>
          <Button
            color={'primary'}
            isFullWidth
            onClick={async () => {
              window.location.href =
                'https://auth.mercadopago.com.br/authorization?' +
                `client_id=${8067033164530686}` +
                '&response_type=code' +
                `&state=${2}` +
                '&platform_id=mp' +
                `&redirect_uri=${'https://jogaja.com/mp-receive'}`;
            }}
            variant={'filled'}
            title={'Vincular ao Mercado Pago'}
          />
        </GridItem>
      </Accordion>
    );
  }

  return (
    <>
      <GlobalStyle />
      <Grid rowGapMd={16} isScrollable height={'calc(100% - 32px)'} paddingTop={'16px !important'}
        paddingBottom={'16px !important'} color={'neutral'} colorVariant={'a200'}>
        {logos()}
        <GridItem xs={6}>
          <Typography variant={'h1'} center>Componentes</Typography>
        </GridItem>
        {selects()}
        {daysInWeek()}
        {charts()}
        {buttons()}
        {typography()}
        {input()}
        {checkbox()}
        {radio()}
        {switchs()}
        {dialog()}
        {bigButtons()}
        {calendar()}
        {inputTime()}
        {inputCard()}
        {buttonPrice()}
        {carousel()}
        {mercadoPago()}
      </Grid>
    </>
  );
}

export default Components;

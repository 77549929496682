import React from 'react';
import styled from 'styled-components';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import Loading from '../../../../../common/designSystem/Icons/Loading';
import { Colors } from '../../../../../common/designSystem/Colors';

const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: ${Colors.neutral.white};
  top: 0;
  left: 0;
`;

const LoadingPage: React.FC<{ text: string }> = ({ text }) => {
  return (
    <Container>
      <Typography variant='body' colorVariant='a400' center style={{ margin: '0 24px 16px' }}>{text}</Typography>
      <Loading color='neutral' colorVariant='a400' />
    </Container>
  );
};

export default LoadingPage;

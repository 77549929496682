import React from 'react';

const SettingSliderHorizontal = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M3.42885 6.00028C4.84901 6.00028 6.00028 4.84901 6.00028 3.42885C6.00028 2.00869 4.84901 0.857422 3.42885 0.857422C2.00869 0.857422 0.857422 2.00869 0.857422 3.42885C0.857422 4.84901 2.00869 6.00028 3.42885 6.00028Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.00027 3.42871H23.1431" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.0003 14.5716C13.4205 14.5716 14.5717 13.4203 14.5717 12.0001C14.5717 10.58 13.4205 9.42871 12.0003 9.42871C10.5801 9.42871 9.42886 10.58 9.42886 12.0001C9.42886 13.4203 10.5801 14.5716 12.0003 14.5716Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M0.857422 12H9.42885" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5718 12H23.1432" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.5714 23.1429C21.9916 23.1429 23.1429 21.9916 23.1429 20.5714C23.1429 19.1513 21.9916 18 20.5714 18C19.1513 18 18 19.1513 18 20.5714C18 21.9916 19.1513 23.1429 20.5714 23.1429Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.0003 20.5713H0.857422" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default SettingSliderHorizontal;

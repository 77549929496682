import React from 'react';

const UserCheck = () => {
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M20.2862 12.429L14.4005 20.2862L10.4719 17.3433" stroke="#078364" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.14324 8.1431C9.91844 8.1431 11.3575 6.70401 11.3575 4.92881C11.3575 3.15361 9.91844 1.71452 8.14324 1.71452C6.36804 1.71452 4.92896 3.15361 4.92896 4.92881C4.92896 6.70401 6.36804 8.1431 8.14324 8.1431Z" stroke="#078364" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.28603 18.8578H1.7146V16.7149C1.71572 15.4443 2.09335 14.2025 2.79981 13.1463C3.50626 12.0902 4.50986 11.2671 5.68383 10.781C6.8578 10.2949 8.14949 10.1676 9.39577 10.4152C10.642 10.6628 11.787 11.2741 12.686 12.1721" stroke="#078364" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>);
};

export default UserCheck;

import styled from 'styled-components';
import { AccordionStyledProps } from './interface';
import { getPaddingSize } from '../Grid/styled';
import { getDevice } from '../Screen';

export const Container = styled.div<AccordionStyledProps>`
  display: block;
  max-height: ${({ open }) => (open ? '800px' : '0')};
  overflow: hidden;
  margin-bottom: ${({ open }) => (open ? getPaddingSize(getDevice(window.innerWidth)) + 'px' : '0')};
  transition: max-height 0.5s, margin-bottom 1s;
`;

export const Icon = styled.div<AccordionStyledProps>`
  display: block;
  width: 16px;
  height: 16px;
  transform: rotate(${({ open }) => (open ? '270deg' : '90deg')});
`;

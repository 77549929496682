import React, { useEffect, useRef, useState } from 'react';
import { CarouselStyledProps } from './interface';
import { Container } from './styled';
import { getPaddingSize } from '../Grid/styled';
import { getDevice } from '../Screen';

const Carousel = ({ children, marginLeft = getPaddingSize(getDevice(window.innerWidth)), height }: CarouselStyledProps) => {
  const [isMouseOver, setIsMouseOver] = useState(false);
  const container = useRef<HTMLDivElement>(null);
  // const [hasScroll, setHasScroll] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOver(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOver(false);
  };

  useEffect(() => {
    let handleMouseWheel: (event: WheelEvent) => void = () => {};
    if (isMouseOver) {
      handleMouseWheel = (event: WheelEvent) => {
        event.preventDefault();
        event.stopPropagation();
        const { deltaY } = event;
        if (container.current !== null) {
          const scrollLeft = container.current.scrollLeft - deltaY * 30;

          container.current.scrollTo({
            left: scrollLeft,
            behavior: 'smooth'
          });
        }
      };
    }

    window.addEventListener('wheel', handleMouseWheel, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleMouseWheel);
    };
  }, [isMouseOver]);

  // useEffect(() => {
  //   if (container.current !== undefined) {
  //     let root = container.current!.parentElement! as HTMLElement;
  //     let size = container.current!.parentElement!.scrollWidth;
  //
  //     while (root?.scrollWidth == size) {
  //       size = root?.scrollWidth;
  //       root = root!.parentElement!;
  //     }
  //
  //     const windowWidth = window.innerWidth;
  //     setHasScroll(root!.scrollWidth > windowWidth && windowWidth > 820);
  //   }
  // }, [container]);

  // Margin de acordo com o device <Carousel marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
  return (
    <div style={{ height: height, width: '100vw', marginLeft: `-${marginLeft}px`, position: 'relative' }}>
      <Container
        ref={container}
        marginLeft={marginLeft}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children == undefined ? <></> : Array.isArray(children)
          ? children.map((component, index) =>
            React.cloneElement(
              component,
              {
                style: {
                  ...component?.props?.style
                }
              },
              component?.props?.children
            )
          )
          : React.cloneElement(
            children!,
            {
              style: {
                ...children?.props?.style
              }
            },
            children?.props?.children
          )}
      </Container>
    </div>
  );
};

export default Carousel;

import React, { useEffect, useRef } from 'react';
import { ModalProps } from './interface';
import { ButtonContainer, ModalContainer, ModalPage } from './styled';
import { Grid, GridItem } from '../Grid/Grid';
import Typography from '../Typography/Typography';
import Close from '../Icons/Close';
import useScreen from '../../hook/useScreen';
import { isDefined } from '../../util/validations';

const Modal = (Props: ModalProps) => {
  const { open, title, children, setOpen, onCancel } = Props;
  const { height } = useScreen();
  const modalRef = useRef<HTMLDivElement>(null);

  const onCancelClick = () => {
    onCancel && onCancel();
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }
    onCancelClick();
  };

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <ModalPage style={{ height: height+'px' }} open={open} onClick={(event) => handleClose(event)}>
      <ModalContainer bottom={0}>
        <Grid noMargin height={'auto'}>
          <GridItem xs={5}><Typography variant={'h3'}>{title}</Typography></GridItem>
          <GridItem xs={1} alignItems={'flex-end'}><ButtonContainer onClick={() => onCancelClick()}><Close/></ButtonContainer></GridItem>
          <GridItem ref={modalRef} xs={6} marginXs={'16px 0 0 0'}>{children}</GridItem>
        </Grid>
      </ModalContainer>
    </ModalPage>
  );
};

export default Modal;

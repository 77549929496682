import React, { useEffect, useState } from 'react';
import Header from './Components/Header/Header';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import CenterHighlights from './Components/CenterHighLights/CenterHighlights';
import GameCardsCarousel from './Components/GameCard/GameCardsCarousel';
import MenuPlayer from '../../../common/component/MenuPlayer/MenuPlayer';
import { getPaddingSize } from '../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../common/designSystem/Screen';
import SportCenterService from '../../../common/services/sportCenter';
import { SportCenter } from '../../../common/model/SportCenter';
import ScheduleService from '../../../common/services/schedule';
import TeamService from '../../../common/services/team';
import { Schedule } from '../../../common/model/Schedule';
import { isDefined } from '../../../common/util/validations';
import { Team } from '../../../common/model/Team';
import { compareDates } from '../../../common/util/formateDate';
import Invite from './Components/Invite/Invite';
import UserService from '../../../common/services/user';
import { propsFilter, propsFilterKey } from '../../../common/util/web';
import ReserveTimeAlert from './Components/ReserveTimeAlert/ReserveTimeAlert';

const Home = () => {
  const [centerHighlights, setCenterHighlights] = useState([] as SportCenter[]);
  const [schedules, setSchedules] = useState([] as Schedule[]);
  const [Team, setTeam] = useState([] as Team[]);
  const [userId, setUserId] = useState('');

  const getTimeAndSchedules = async () => {
    let teamTemp = await TeamService.getAllMy();
    teamTemp = teamTemp.map((tTemp) => {
      const players = propsFilter(tTemp.players, 'id', userId);
      let keys: string[] = [];
      for (const date in players) {
        keys = [...keys, date];
      }
      const schedules = propsFilterKey(tTemp.scheduledIds, keys);
      return { ...tTemp, players: players, scheduledIds: schedules };
    });
    let schedulesTemp: Schedule[] = [];
    for (const timeTemp of teamTemp) {
      for (const date in timeTemp.scheduledIds) {
        schedulesTemp = [...schedulesTemp, (await ScheduleService.get(timeTemp.scheduledIds[date], true, timeTemp.admin))];
      }
    }
    schedulesTemp = schedulesTemp.sort((a, b) => {
      const dateSplitA = (a?.date as string).split('-');
      const dateA = new Date(Number(dateSplitA[2]), Number(dateSplitA[1]), Number(dateSplitA[0]));
      const dateSplitB = (b?.date as string).split('-');
      const dateB = new Date(Number(dateSplitB[2]), Number(dateSplitB[1]), Number(dateSplitB[0]));
      return dateA.getTime() - dateB.getTime();
    });
    schedulesTemp = schedulesTemp.map((scheduleTemp) => {
      const dateSplit = (scheduleTemp?.date as string).split('-');
      return { ...scheduleTemp, date: new Date(Number(dateSplit[2]), Number(dateSplit[1]) -1, Number(dateSplit[0])) };
    });
    const filter = schedulesTemp.filter((scheduleTemp) => (scheduleTemp.date as Date).getTime() >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDay()).getTime());
    setSchedules(filter);
    setTeam(teamTemp);
  };

  const getCenterHighlights = async () => {
    const centerHighlightsTemp = await SportCenterService.getHighlight();
    setCenterHighlights(centerHighlightsTemp);
  };

  const getUserId = async () => {
    setUserId((await UserService.getCurrent())!.id);
  };

  useEffect(() => {
    getTimeAndSchedules();
  }, [userId]);

  useEffect(() => {
    getCenterHighlights();
  }, []);

  useEffect(() => {
    getUserId();
  }, []);

  return (<RestrictArea role={'player'}>
    <Header/>
    <Grid alignContent={'start'} isScrollable height={'calc(100% - 262px)'} style={{ paddingTop: '24px', paddingBottom: '16px', backgroundColor: 'white' }} rowGapXs={16} >
      <ReserveTimeAlert schedules={schedules}/>
      {isDefined(schedules.filter((schedule) => compareDates(schedule.date as Date, new Date()))) && <><div style={{ marginLeft: '-' + getPaddingSize(getDevice(window.innerWidth)) + 'px', marginTop: '-24px', position: 'relative', width: '100vw', height: '181px', background: `url(${require('../../../static/img/field.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
      </div>
      <GridItem xs={6} style={{ zIndex: 4, marginTop: '-173px' }}>
        <Typography variant={'paragraph'} color='neutral' colorVariant='white' notIsContrastColor bold style={{ marginBottom: '24px' }}>
          Dia de jogo
        </Typography>
        <Invite get={async () => await getTimeAndSchedules()} schedule={schedules[0]} time={Team.find((time) => isDefined(propsFilter(time.scheduledIds, undefined, schedules[0].id))) ?? {} as Team}/>
      </GridItem></>}
      {isDefined(schedules.filter((schedule) => !compareDates(schedule.date as Date, new Date()))) && <GridItem xs={6} style={{ rowGap: '16px' }}>
        <Typography variant={'paragraph'}>
          Próximos jogos
        </Typography>
        <GameCardsCarousel teams={Team} schedules={schedules.filter((schedule) => !compareDates(schedule.date as Date, new Date()))}/>
      </GridItem>}
      {isDefined(centerHighlights) && <GridItem xs={6} style={{ rowGap: '16px', zIndex: 4 }}>
        <Typography variant={'paragraph'}>
          Centros em destaque
        </Typography>
        {centerHighlights.map((center) => <CenterHighlights key={center.id} center={center}/>)}
      </GridItem>}
    </Grid>
    <MenuPlayer/>
  </RestrictArea>);
};
export default Home;

import styled from 'styled-components';
import { Shadows } from '../../designSystem/GlobalStyle';
import { getColor } from '../../designSystem/Colors';
import { isDefined } from '../../util/validations';

export interface HeaderStyledProps {
  onlyLogo?: boolean;
}
export const HeaderStyled = styled.div<HeaderStyledProps>`
  position: absolute;
  display: flex;
  justify-content: ${({ onlyLogo }) => onlyLogo ? 'center' : 'space-between'};
  align-items: center;
  padding: 0 24px;
  height: 64px;
  width: calc(100% - 48px);
  background: ${getColor('neutral', 'white')};
  box-shadow: ${Shadows.low};
  top: 0;
  z-index: 2;
`;

export const UseSpace = styled.div`
  height: 64px;
  width: 100%;
  background: ${getColor('neutral', 'white')};
`;


import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import Button from '../../../../../common/designSystem/Button/Button';
import { Colors, getColor } from '../../../../../common/designSystem/Colors';
import { BorderRadius, Shadows } from '../../../../../common/designSystem/GlobalStyle';
import { Schedule } from '../../../../../common/model/Schedule';

const ReserveTimeAlert = ({ schedules } : {schedules: Schedule[]}) => {
  const navigate = useNavigate();
  return schedules.length === 0 ? <GridItem xs={6}>
    <Grid alignContent={'center'} justifyContent='center' alignItems='center' rowGapXs={24} style={{ height: '157px', border: `0.5px solid ${getColor('neutral', 'a100')}`, background: Colors.neutral.white, boxShadow: `${Shadows.med}`, borderRadius: `${BorderRadius.large}` }}>
      <GridItem xs={6} alignItems='center'>
        <Typography variant={'paragraph'}>
          Você ainda não possui um jogo
        </Typography>
        <Typography variant={'paragraphSmall'} style={{ textAlign: 'center' }}>
          Reserve um horário e convide seus amigos!
        </Typography>
      </GridItem>
      <GridItem xs={6} alignItems='center' justifyContent='center' >
        <Button color={'primary'} onClick={() => navigate(RoutePath.PLAYER_RESERVATION)} variant={'filled'} title={'Reservar um horário'} isFullWidth/>
      </GridItem>
    </Grid>
  </GridItem> : (
    <></>
  );
};

export default ReserveTimeAlert;

import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  or,
  orderBy as firestoreOrderBy,
  query as firestoreQuery,
  setDoc,
  startAfter,
  where
} from 'firebase/firestore';
import { database } from './firebase';
import { isDefined } from '../util/validations';
import { translateError } from '../util/error';

/**
* Salva um documento no Firestore.
* @param {string} path - O caminho para a coleção ou subcoleção onde o documento será salvo.
* @param {any} data - Os dados do documento a ser salvo.
* @param {string} id - O ID do documento a ser salvo. Se não for fornecido, um ID será gerado automaticamente.
* @return {Promise<void>} - Uma Promise que é resolvida quando o documento é salvo com sucesso.
*/
export const save = async (path: string, data: any): Promise<any> => {
  try {
    if (!isDefined(data?.id)) {
      delete data.id;
      const collectionRef = collection(database, path);
      const obj = await addDoc(collectionRef, data);
      const saved = { ...data, id: obj.id };
      await setDoc(obj, saved, { merge: true });
      return saved;
    }
    const docRef = doc(database, path, data?.id);
    await setDoc(docRef, data, { merge: true });
    return data;
  } catch (e) {
    console.log(e);
    throw new Error((e as any).message, (e as any).code);
  }
};

/**
* Deleta um documento no Firestore.
* @param {string} path - O caminho para a coleção ou subcoleção onde o documento será deletado.
* @param {string} id - O ID do documento a ser deletado.
* @return {Promise<void>} - Uma Promise que é resolvida quando o documento é deletado com sucesso.
*/
export const remove = async (path: string, id: string): Promise<void> => {
  const docRef = doc(database, path, id);
  await deleteDoc(docRef);
};

/**
* Obtém um documento ou uma coleção no Firestore.
* @param {string} path - O caminho para o documento ou coleção.
* @return {Promise<any>} - Uma Promise que é resolvida com o documento ou coleção obtidos.
*/
export const get = async (path: string): Promise<any> => {
  const pathParts = path.split('/');

  if (pathParts.length % 2 === 0) {
    const collectionPath = pathParts.slice(0, -1).join('/');
    const documentId = pathParts[pathParts.length - 1];
    const docSnapshot = await getDoc(doc(database, collectionPath, documentId));
    if (docSnapshot.exists()) {
      return docSnapshot.data();
    } else {
      return null;
    }
  } else {
    const collectionPath = pathParts.join('/');
    const collectionRef = collection(database, collectionPath);
    const collectionSnapshot = await getDocs(collectionRef);
    const documents: any[] = [];
    collectionSnapshot.forEach((doc) => {
      documents.push(doc.data());
    });
    return documents;
  }
};

type WhereFilterOp = '<' | '<=' | '==' | '!=' | '>=' | '>' | 'array-contains' | 'in'| 'array-contains-any' | 'not-in';
type OrderByDirection = 'desc' | 'asc';

/**
 * Realiza uma consulta no Firestore.
 * @return {Promise<any[]>} - Uma Promise que é resolvida com os documentos que correspondem à consulta.
 */
export const query = async ({ collectionPath, query, orderBy, isOr = false, page, size }: {
  collectionPath: string,
  query: Array<{field: string, operator: WhereFilterOp, value: any | any[]}>,
  orderBy?: Array<{field: string, direction: OrderByDirection}>,
  isOr?: boolean,
  isSubCollection?: boolean,
  page: number,
  size: number
}): Promise<any[]> => {
  const collectionRef = collection(database, collectionPath);
  const queryFilters = query.map((filter) => where(filter.field, filter.operator, filter.value));
  const queryOrders = orderBy?.map((order) => firestoreOrderBy(order.field, order.direction));
  const startAfterNum = (page * size) + 1;
  let q = firestoreQuery(collectionRef, ...queryFilters, firestoreOrderBy('id', 'asc'), startAfter(startAfterNum), limit(size!));
  if (isDefined(orderBy)) {
    q = firestoreQuery(collectionRef, ...queryFilters, ...queryOrders!, startAfter(startAfterNum), limit(size!));
  }
  if (isOr) {
    q = firestoreQuery(collectionRef, or(...queryFilters), startAfter(startAfterNum), limit(size!));
  } if (isOr && isDefined(orderBy)) {
    q = firestoreQuery(collectionRef, or(...queryFilters), ...queryOrders!, startAfter(startAfterNum), limit(size!));
  }
  const querySnapshot = await getDocs(q);
  const documents: any[] = [];
  querySnapshot.forEach((doc) => {
    documents.push(doc.data());
  });
  return documents;
};

import React from 'react';
import { FooterStyled, UseSpace } from './styled';
import { FooterProps } from './interface';

const Footer = ({ children, hidden = false }: FooterProps) => {
  return (
    <>
      <FooterStyled hidden={hidden}>
        {children}
      </FooterStyled>
      <UseSpace hidden={hidden}/>
    </>
  );
};

export default Footer;

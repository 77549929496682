import React, { useEffect, useState } from 'react';
import { Colors } from '../../../designSystem/Colors';
import { isDefined } from '../../../util/validations';
import Typography from '../../../designSystem/Typography/Typography';
import { ChartInterface } from './interface';

const getMaxValue = (data: { value: number, color: string }[][]): number => {
  let maxValue = 0;

  data.forEach((bar) => {
    bar.forEach((item) => {
      if (item.value > maxValue) {
        maxValue = item.value;
      }
    });
  });

  return maxValue;
};

const generateNumbers = (max: number, min: number, pont: number | undefined): string[] => {
  const numbers: string[] = [];
  if (pont === undefined) return numbers;

  const step = (max - min) / (pont - 1);

  for (let i = 0; i < pont; i++) {
    const number = max - (step * i);
    numbers.push(number.toFixed(0));
  }

  return numbers;
};

const Bars = ({
  data,
  legend
}: ChartInterface) => {
  const maxValue = getMaxValue(data);
  const effectTime = '1s';
  const getPercentage = (val: number) => {
    return (val * 100) / maxValue;
  };

  const numbers = isDefined(legend?.leftCount) ? generateNumbers(maxValue, 0, legend?.leftCount) : [];
  return (
    <div>
      <div style={{ display: 'flex', position: 'relative' }}>
        {isDefined(legend?.leftCount) && <>
          <div style={{ marginRight: '6px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            {numbers.map((val, index) =>
              <Typography key={index} variant={'xSmall'} style={{ textAlign: 'right', marginTop: index == 0 ? '-6px' : '0', marginBottom: index + 1 === numbers.length ? '-6px' : '' }}>
                {val}
              </Typography>)}
          </div>
          <div>
            {numbers.map((val, index) =>
              <div key={index} style={{ height: '1px', width: 'calc(100% - 16px)', background: Colors.neutral.a200, zIndex: '2', top: getPercentage(Number(val)) + '%', left: '16px', position: 'absolute' }}/>)}
          </div>
        </>}
        <div style={{ height: '84px', width: '100%', display: 'flex', gap: '8px', transition: 'all '+ effectTime }} key={'a'}>
          {data.map((bar, index) => <div key={index} style={{ position: 'relative', width: 100 / data.length+'%', height: '100%', transition: 'all '+ effectTime }}>
            {bar.map((item) => <div key={item.color} style={{
              position: 'absolute',
              background: item.color,
              height: getPercentage(item.value) + '%',
              width: '100%',
              bottom: 0,
              borderRadius: '4px',
              transition: 'all '+ effectTime
            }}/>)}
          </div>)}
        </div>
      </div>
      <div style={{ height: 'auto', width: isDefined(legend?.leftCount) ? 'calc(100% - 16px)' : '100%', marginLeft: isDefined(legend?.leftCount) ? '16px' : '0', display: 'flex', gap: '8px' }}>
        {legend?.bottom?.map((leg) => <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', width: 100 / (legend?.bottom ?? []).length+'%', height: '100%', transition: 'all '+ effectTime }}>
          <Typography variant={'xSmall'}>{leg}</Typography>
        </div>)}
      </div>
    </div>
  );
};

export default Bars;

import React from 'react';
import { Grid, GridItem } from '../../designSystem/Grid/Grid';
import MenuButton from '../../designSystem/MenuButton/MenuButton';
import Games from '../../designSystem/Icons/Games';
import MailSend from '../../designSystem/Icons/MailSend';
import Perfil from '../../designSystem/Icons/Perfil';
import Footer from '../Footer/Footer';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import Reservation from '../../../pages/Player/Reservation/Reservation';
import Center from '../../designSystem/Icons/Center';
import Home from '../../designSystem/Icons/Home';

const MenuPlayer = () => {
  const navigate = useNavigate();
  return (<>
    <Footer>
      <Grid noMargin style={{ width: '100%' }}>
        <GridItem xs={3} justifyContent='center' alignItems={'center'}>
          <Grid noMargin style={{ width: '100%' }}>
            <GridItem xs={3} justifyContent='center' alignItems={'center'}>
              <MenuButton title='Início' icon={<Home/>} onClick={() => navigate(RoutePath.PLAYER_HOME)}></MenuButton>
            </GridItem>
            <GridItem xs={3} justifyContent='center' alignItems={'center'}>
              <MenuButton title='Reservar' icon={<Center/>} onClick={() => navigate(RoutePath.PLAYER_RESERVATION)}></MenuButton>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem xs={3} justifyContent='center' alignItems={'center'}>
          <Grid noMargin style={{ width: '100%' }}>
            <GridItem xs={3} justifyContent='center' alignItems={'center'}>
              <MenuButton title='Jogos' icon={<Games/>} onClick={() => navigate(RoutePath.MY_GAMES)}></MenuButton>
            </GridItem>
            <GridItem xs={3} justifyContent='center' alignItems={'center'}>
              <MenuButton title='Perfil' icon={<Perfil/>} onClick={() => navigate(RoutePath.PLAYER_ACCOUNT)}></MenuButton>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Footer></>);
};

export default MenuPlayer;

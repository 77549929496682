import React, { useEffect, useState } from 'react';
import Input from '../Input/Input';
import { InputTimeProps } from './interface';
import Dialog from '../Dialog/Dialog';
import { ContainerTime, OptionTime, Time } from './styled';
import { isDefined } from '../../util/validations';
import { HourMinute } from '../../model/SportCenter';

const InputTime = ({ name, label, required = false, hourOptions, isFullWidth, register, disabled, minuteOptions }: InputTimeProps) => {
  const [open, setOpen] = useState(false);
  const hour = hourOptions || [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];
  const minute = minuteOptions ?? ['00', '30'];
  const [selectHour, setSelectHour] = useState(0);
  const [selectMinute, setSelectMinute] = useState(0);
  const [selectTime, setSelectTime] = useState<HourMinute>(register?.initialValue ?? { hour: selectHour, minute: selectMinute } as HourMinute);
  const [canChange, setCanChange] = useState(false);

  const handleScroll = (event: Event, hourTemp: number[]) => {
    const target = event.target as HTMLElement;
    if (!target) return;
    const scroll = target.scrollTop;
    const height = target.scrollHeight;
    const countOfElements = target.childElementCount;
    const heightOfElement = height / countOfElements;
    const option = Math.round(scroll / heightOfElement);
    if (target.id === 'time-hour'+name) {
      setSelectHour(hourTemp[option]);
    } else if ('time-minute'+name) {
      setSelectMinute(option);
    }
  };

  const setScroll = (element: HTMLElement, option: number) => {
    const height = element.scrollHeight;
    const countOfElements = element.childElementCount;
    const heightOfElement = height / countOfElements;
    element.scroll({ left: 0, top: heightOfElement * option, behavior: 'smooth' });
  };

  const clickHour = (option: number) => {
    setSelectHour(option);
    const element = document.getElementById('time-hour'+name);
    setScroll(element as HTMLElement, hour.findIndex((item) => item === option));
  };

  const clickMinute = (option: number) => {
    setSelectMinute(option);
    const element = document.getElementById('time-minute'+name);
    setScroll(element as HTMLElement, option);
  };

  useEffect(() => {
    clickHour(selectTime.hour);
    clickMinute(selectTime.minute);
  }, [open]);

  useEffect(() => {
    register?.registerValue(register, name, required);
  }, []);

  useEffect(() => {
    const element = document.getElementById('time-hour'+name);
    element?.addEventListener('scroll', (event) => {
      handleScroll(event, hour);
    });
    return () => {
      element?.removeEventListener('scroll', (event) => {
        handleScroll(event, hour);
      });
    };
  }, [hour, hourOptions]);

  useEffect(() => {
    const element = document.getElementById('time-minute'+name);
    element?.addEventListener('scroll', (event) => {
      handleScroll(event, []);
    });
    return () => {
      element?.removeEventListener('scroll', (event) => {
        handleScroll(event, []);
      });
    };
  }, []);

  useEffect(() => {
    if (canChange) {
      const inputValue = { hour: selectHour, minute: selectMinute } as HourMinute;
      clickHour(selectHour);
      clickMinute(selectMinute);
      setSelectTime({ ...inputValue, value: inputValue.hour + (inputValue.minute == 0 ? 0 : 0.5) });
      const formValue = { hour: inputValue.hour, minute: inputValue.minute == 0 ? 0 : 30, value: selectHour + (selectMinute == 0 ? 0 : 0.5 ) } as HourMinute;
      register?.setValue(formValue, name);
      setCanChange(false);
    }
  }, [canChange]);

  useEffect(() => {
    const value = register?.getValue(name) as HourMinute;
    if (isDefined(value)) {
      clickHour(value?.hour);
      clickMinute(value?.minute);
      setSelectTime({ ...value, value: value.hour + (value.minute == 0 ? 0 : 0.5) });
    }
  }, [register?.getValue(name)]);

  function getValue() {
    return `${selectTime.hour}:${selectTime.minute == 0 ? '00' : '30' }h`;
  }

  return (
    <>
      <Input disabled={disabled} isFullWidth={isFullWidth} name={name} inputMode={'none'} label={label} required={required} value={getValue()} placeholder={'00:00'} onClick={() => setOpen(true)}/>
      <Dialog open={open} setOpen={setOpen} title={label} buttonLabelCancel={'Cancelar'} buttonLabelConfirm={'Salvar'} onConfirm={() => setCanChange(true)} onCancel={() => null}>
        <ContainerTime>
          <Time id={'time-hour'+name}>
            <OptionTime selected={false}></OptionTime>
            {hour.map((item, index) => (
              <OptionTime onClick={() => clickHour(item)} key={item} selected={selectHour === item}>{item}</OptionTime>
            ))}
            <OptionTime selected={false}></OptionTime>
          </Time>
          :
          <Time id={'time-minute'+name}>
            <OptionTime selected={false}></OptionTime>
            {minute.map((item, index) => (
              <OptionTime onClick={() => clickMinute(index)} key={index} selected={selectMinute === index}>{item}</OptionTime>
            ))}
            <OptionTime selected={false}></OptionTime>
          </Time>
        </ContainerTime>
      </Dialog>
    </>
  );
};

export default InputTime;

import React from 'react';

const Basketball = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3071_2825)">
        <path d="M11.8283 5.01505C11.4034 6.29331 10.7263 7.51116 9.64883 8.97119L4.21411 3.53646C6.26277 1.65077 8.99658 0.5 12 0.5C12.1643 0.5 12.3278 0.503444 12.4903 0.510258C12.4414 2.32502 12.2603 3.71551 11.8283 5.01505ZM12.7773 5.33052C13.2472 3.91698 13.4358 2.43388 13.4884 0.595378C15.8874 0.905281 18.0549 1.95457 19.7552 3.50828L11.9705 11.2929L10.3637 9.68601C11.5309 8.12128 12.2964 6.7769 12.7773 5.33052ZM12.6776 12L20.4635 4.21411C22.0263 5.91198 23.0842 8.08033 23.4008 10.4821C21.5499 10.4402 20.0539 10.5775 18.6094 11.0144C17.1209 11.4645 15.7182 12.2231 14.0562 13.3785L12.6776 12ZM18.8988 11.9716C20.2346 11.5676 21.6456 11.436 23.4887 11.4845C23.4962 11.6554 23.5 11.8272 23.5 12C23.5 15.0034 22.3492 17.7372 20.4635 19.7859L14.775 14.0973C16.3227 13.0341 17.5893 12.3676 18.8988 11.9716ZM14.0807 14.8172L19.7552 20.4917C17.7099 22.3607 14.9884 23.5 12 23.5C11.8352 23.5 11.6712 23.4965 11.5082 23.4897C11.5503 21.6609 11.7106 20.2473 12.1041 18.9076C12.4884 17.5997 13.1023 16.3382 14.0807 14.8172ZM11.1447 18.6257C10.7216 20.0659 10.5552 21.5597 10.5099 23.4044C8.09709 23.0923 5.91854 22.0324 4.21411 20.4635L11.9705 12.7071L13.3576 14.0942C12.2787 15.7463 11.5798 17.1447 11.1447 18.6257ZM11.2634 12L3.50828 19.7552C1.95391 18.0542 0.904398 15.8856 0.594988 13.4853C2.54093 13.4205 4.07813 13.2238 5.49488 12.7535C6.93126 12.2766 8.2149 11.5284 9.66352 10.4001L11.2634 12ZM0.510142 12.4875C0.503405 12.3259 0.5 12.1634 0.5 12C0.5 9.01156 1.63931 6.29008 3.50828 4.24484L8.95074 9.68731C7.60338 10.7255 6.44562 11.3842 5.17983 11.8044C3.87929 12.2361 2.43586 12.4259 0.510142 12.4875Z" stroke="#2C2B3A"/>
      </g>
      <defs>
        <clipPath id="clip0_3071_2825">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Basketball;

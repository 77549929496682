import React from 'react';

const Crown = () => {
  return (<>
    <svg width="19" height="12" viewBox="0 0 19 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.1247 10.8158H15.8747C16.1 10.816 16.3227 10.8643 16.5278 10.9574L17.4628 3.90879C17.4908 3.71314 17.4079 3.51812 17.2475 3.40256C17.0872 3.28699 16.876 3.26999 16.6992 3.35842L13.6668 5.66758C13.5517 5.76183 13.4022 5.80344 13.2549 5.7823C13.1076 5.76116 12.9759 5.67921 12.8919 5.55638L9.8992 1.17888C9.79858 1.06337 9.65289 0.99707 9.4997 0.99707C9.34652 0.99707 9.20083 1.06337 9.1002 1.17888L6.1075 5.55638C6.02348 5.67921 5.89179 5.76116 5.74448 5.7823C5.59717 5.80344 5.44775 5.76183 5.33258 5.66758L2.3002 3.35913C2.12345 3.2707 1.91221 3.2877 1.75188 3.40327C1.59154 3.51883 1.50862 3.71385 1.53662 3.9095L2.47162 10.9581C2.6767 10.8647 2.89936 10.8162 3.1247 10.8158Z" stroke="#F7C46B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15.875 0.815674H3.125C2.2448 0.815674 1.53125 1.52922 1.53125 2.40942V2.40942C1.53125 3.28963 2.2448 4.00317 3.125 4.00317H15.875C16.7552 4.00317 17.4687 3.28963 17.4687 2.40942V2.40942C17.4687 1.52922 16.7552 0.815674 15.875 0.815674Z" stroke="#F7C46B" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>);
};

export default Crown;

import React, { Suspense } from 'react';
import { Grid } from '../../../../../common/designSystem/Grid/Grid';
import { STEPS } from '../Steps/steps';
import { useContextReservation } from '../../Reservation';

const StepContainer = () => {
  const { currentStep } = useContextReservation();

  const currentIndex = STEPS.findIndex((step) => step.id === currentStep);
  const CurrentStepComponent = STEPS[currentIndex].component;

  return (
    <Grid alignContent={'start'} isScrollable height={'calc(100% - 134px)'} style={{ paddingTop: '24px', paddingBottom: '24px' }} colorVariant={'a100'}>
      <Suspense fallback={<></>}>
        <CurrentStepComponent/>
      </Suspense>
    </Grid>
  );
};

export default StepContainer;

import styled from 'styled-components';
import { Colors, getColor, getContrastColor } from '../Colors';
import { CardImageInputProps, CardInputStyledProps, CardTextStyledProps } from './interface';

export const CardInput = styled.div<CardInputStyledProps>`
  width: calc(100% - ${(props) => props.isCardNumber ? '34px' : '36px'});
  height: 48px;
  border: 1px solid ${getColor('neutral', 'a600')};
  border-radius: 4px;
  padding: 0 16px;
  font-size: 16px;
  color: ${getContrastColor('neutral', 'white')};
  background: ${Colors.neutral.white};
  margin: 1px 0 1px 0px;
  
  :hover {
    margin: 0;
    border: 2px solid ${getColor('neutral', 'a600')};
  }

  &.focused {
    margin: 0;
    border: 2px solid ${getColor('primary', 'a500')};
  }

  ${(props) => props.isCardNumber && props.hasError && `
    margin: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  `}
  
  ${(props) => props.hasError && `
    margin: 0;
    border: 2px solid ${getColor('error', 'a300')};
  `}
  
  ${(props) => props.isCardNumber && `
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
  `}


  ${(props) => props.isCardNumber && `
    :hover {
      margin: 0;
      border-right: 0;
    }
  `}
  
  ${(props) => props.isCardNumber && `
    &.focused {
      margin: 0;
      border-right: 0;
    }
  `}
`;

export const ImageCardStyled = styled.img<CardImageInputProps>`
  height: 28px;
  width: 28px;
  padding: 10px;
  border: 1px solid ${getColor('neutral', 'a600')};
  border-radius: 4px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: 0 !important;
  object-fit: contain;
  margin: 1px 1px 1px 0;
  background: ${Colors.neutral.white};

  &.hover {
    margin: 0;
    border: 2px solid ${getColor('neutral', 'a600')};
  }
  
  &.focused {
    margin: 0;
    border: 2px solid ${getColor('primary', 'a500')};
  }
  
  ${(props) => props.hasError && `
    border: 2px solid ${getColor('error', 'a300')};
  `}
`;

export const CardNumberInputContainer = styled.div`
  display: flex;
`;

export const CardContainer = styled.div`
  display: block;
  width: 272px;
  height: 167px;
`;

export const CardText = styled.div<CardTextStyledProps>`
  display: block;
  position: relative;
  width: fit-content;
  padding: 4px 6px;
  top: ${({ top }) => top ? top + 'px' : 'auto'};
  margin-left: ${({ left }) => left ? left + 'px' : 'auto'};
  margin-right: ${({ right }) => right ? right + 'px' : 'auto'};
  bottom: ${({ bottom }) => bottom ? bottom + 'px' : 'auto'};
  ${({ textAlign }) => textAlign ? `text-align: ${textAlign};` : ''}
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 12px;
  border-radius: 16px;
  ${({ selected }) => selected && `border: 1px solid ${Colors.error.main};`}
`;

import styled from 'styled-components';

interface RoundDivProps {
  color: string;
}

export const RoundDiv = styled.div<RoundDivProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${(props) => props.color};
`;

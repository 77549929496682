import { useCallback, useMemo, useState } from 'react';
import { CleanFunction, FormComponent, Register, RegisterParams, Value } from './interface';
import { isDefined } from '../../../util/validations';

function validation(obj: FormComponent, value: Value, required: boolean) {
  const valid = obj?.onValidation ? !obj.onValidation(value) : false;
  const checkRequired = required ? !isDefined(value) : false;
  return (value !== undefined && value !== '' && valid) || checkRequired;
}

const UseForm = () => {
  const [components, setComponents] = useState([] as FormComponent[]);
  const setComponent = (comp: FormComponent) => {
    setComponents((val) => {
      const index = val.findIndex((temp) => temp.name === comp.name);
      if (index < 0) {
        return [...val, comp];
      }
      val[index] = comp;
      return [...val];
    });
  };

  const cleanValue: CleanFunction = useCallback(() => {
    return components.reduce((cleaned, value) => {
      return { ...cleaned, [value.name]: value.value };
    }, {});
  }, [components]);

  const getValue = useCallback((name: string) => {
    return cleanValue()[name];
  }, [cleanValue]);

  const cleanError: CleanFunction = useCallback(() => {
    return components.reduce((cleaned, value) => {
      return { ...cleaned, [value.name]: value.error ?? false };
    }, {});
  }, [components]);

  const getError = useCallback((name: string) => {
    return cleanError()[name];
  }, [cleanError]);

  const validateForm = useCallback((notUpdateComponent?: boolean) => {
    const validate: { [p: string]: boolean } = components.reduce((cleaned, value) => {
      const valid = validation(value, value.value, value.required || false);
      if (value.error !== valid && notUpdateComponent === undefined || notUpdateComponent === false) {
        setComponent({
          ...value,
          error: valid
        });
      }
      return { ...cleaned, [value.name]: valid };
    }, {});
    return !Object.keys(validate).some((name) => validate[name]);
  }, [components]);

  const setValue = useCallback((value: Value, name: string) => {
    const stateElement = components.find((temp) => temp.name === name);
    if (stateElement !== undefined) {
      const valueMasked = stateElement.onMask ? stateElement.onMask(value ?? '') : value;
      setComponent({
        ...stateElement,
        value: valueMasked,
        name: name,
        error: validation(stateElement, value, stateElement.required || false)
      });
    }
  }, [components]);

  const registerValue = useCallback((register: Register | undefined, name: string, required: boolean) => {
    if (register !== undefined) {
      const value = register.onMask ? register.onMask(register.initialValue ?? '') : register.initialValue;
      setComponent({
        name,
        value: value,
        error: false,
        onMask: register.onMask,
        onValidation: register.onValidation,
        required: required || false
      });
    }
  }, [components]);

  const generalVariables = useMemo(() => ({
    values: cleanValue(),
    errors: cleanError()
  }), [cleanValue, cleanError]);

  const clearForm = () => {
    setComponents([]);
  };

  return {
    register: (params?: RegisterParams): Register => ({
      registerValue,
      setValue,
      initialValue: params?.initialValue,
      onValidation: params?.onValidation,
      onMask: params?.onMask,
      getValue,
      getError
    }),
    registerValue,
    validateForm,
    ...generalVariables,
    getValue,
    getError,
    setValue,
    clearForm
  };
};

export default UseForm;

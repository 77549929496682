import React from 'react';
import { GridItem } from '../../../../../common/designSystem/Grid/Grid';
import BigButton from '../../../../../common/designSystem/BigButton/BigButton';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';
import MoneyCashCoinsStack from '../../../../../common/designSystem/Icons/MoneyCashCoinsStack';
import MoneyCashDollarCoin from '../../../../../common/designSystem/Icons/MoneyCashDollarCoin';
import MoneyGraph from '../../../../../common/designSystem/Icons/MoneyGraph';

const FinancialManagementGroupButton = () => {
  const buttons = [
    {
      title: 'Total por mês',
      icon: <MoneyCashCoinsStack></MoneyCashCoinsStack>
    },
    {
      title: 'Total por dia',
      icon: <MoneyCashDollarCoin></MoneyCashDollarCoin>
    },
    {
      title: 'Média por semana',
      icon: <MoneyGraph></MoneyGraph>
    }
  ];

  return (<>
    <GridItem xs={6}>
      <Carousel height={'146px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
        {buttons.map((obj) => <BigButton onClick={ () => {} } title={''} description={obj.title} style={{ minWidth: '123px', padding: '16px 10px', height: '138px', margin: '0 0 8px 0' }} iconTop={obj.icon}/>)}
      </Carousel>
    </GridItem>
  </>);
};

export default FinancialManagementGroupButton;

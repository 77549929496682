import { get, remove, save, query } from './firestore';
import { collections } from '../constant/collections';
import { Schedule, SubSchedule } from '../model/Schedule';
import { SportCenter, ViewSchedules } from '../model/SportCenter';
import { isDefined } from '../util/validations';
import { formatToScheduleDate } from '../util/formateDate';
import SportCenterService from './sportCenter';
import UserService from './user';
import { workerData } from 'worker_threads';
import { WeekDay } from '../model/enums/WeekDay';
import { RecurrentSchedule } from '../model/RecurrentSchedule';
import { multiplyArray } from '../util/array';
import { Team } from '../model/Team';
import { getCache, getTimeCacheInvalid, setCache } from '../util/cache';

class ScheduleServiceProvider {
  save = async (data: Schedule) => {
    const { subSchedules, ...schedule } = data;
    const savedSchedule = (await this.findByDateAndSportCenterIdAndCourtId(schedule.date as string, schedule.sportCenterId, schedule.courtId));
    let saved = (await save(collections.schedules, {
      ...schedule,
      id: savedSchedule?.id,
      reservedHours: [...schedule.reservedHours, ...(isDefined(savedSchedule?.reservedHours) ? savedSchedule.reservedHours : [])]
    }));
    if (isDefined(subSchedules)) {
      const playersId = [];
      for (let i = 0; i < subSchedules!.length; i++) {
        const subSchedule = subSchedules![i];
        playersId.push(subSchedule.playerId);
        this.saveSubSchedule(saved.id, subSchedule);
      }
      saved = (await save(collections.schedules, {
        ...saved,
        playersId: [...playersId]
      }));
    }
    return saved as Schedule;
  };

  saveSubSchedule = async (scheduleId: string, data: SubSchedule) => {
    return (await save(`${collections.schedules}/${scheduleId}/subSchedules`, data));
  };

  getSubSchedule = async (scheduleId: string, subScheduleId: string): Promise<SubSchedule> => {
    return (await get(`${collections.schedules}/${scheduleId}/subSchedules/${subScheduleId}`)) as SubSchedule;
  };

  getAllSubSchedules = async (scheduleId: string): Promise<SubSchedule[]> => {
    return (await get(`${collections.schedules}/${scheduleId}/subSchedules`))as SubSchedule[];
  };

  get = async (scheduleId: string, allInformation = false, playerId: string | undefined = undefined): Promise<Schedule> => {
    const valueInCache = getCache('schedule', 'get', scheduleId + allInformation + playerId) as Schedule;
    const timeCacheInvalid = getTimeCacheInvalid('schedule', 'get', scheduleId + allInformation + playerId);
    if (!isDefined(valueInCache) || timeCacheInvalid) {
      const schedule = (await get(`${collections.schedules}/${scheduleId}`)) as Schedule;
      if (allInformation) {
        schedule.subSchedules = (await query({
          collectionPath: `${collections.schedules}/${scheduleId}/subSchedules`,
          query: [
            {
              field: 'playerId',
              operator: '==',
              value: playerId ?? (await UserService.getCurrent())?.id ?? ''
            }
          ],
          page: 0, size: 999
        })) as SubSchedule[];
      }
      setCache('schedule', 'get', scheduleId + allInformation + playerId, schedule);
      return schedule;
    }
    return valueInCache;
  };

  getAll = async (): Promise<Schedule[]> => {
    return (await get(collections.schedules))as Schedule[];
  };

  getAllByPlayer = async (id: string, allInformation = false): Promise<Schedule[]> => {
    const schedules = (await query({
      collectionPath: collections.schedules,
      query: [
        {
          field: 'playersId',
          operator: 'array-contains',
          value: id
        }
      ],
      orderBy: [
        {
          field: 'date',
          direction: 'asc'
        }
      ],
      page: 0, size: 999
    })) as Schedule[];
    if (allInformation) {
      for (let i = 0; i < schedules.length; i++) {
        const subSchedule = schedules[i];
        subSchedule.subSchedules = (await query({
          collectionPath: `${collections.schedules}/${subSchedule.id}/subSchedules`,
          query: [
            {
              field: 'playerId',
              operator: '==',
              value: id
            }
          ],
          page: 0, size: 999
        })) as SubSchedule[];
      }
    }
    return schedules;
  };

  getAllMyPlayer = async (allInformation = false) => {
    const user = await UserService.getCurrent();
    if (isDefined(user?.id)) {
      return await this.getAllByPlayer(user!.id!, allInformation);
    }
    return [];
  };

  findByDateAndSportCenterIdAndCourtId = async (date: string, sportCenterId: string, courtId: string | undefined): Promise<Schedule> => {
    return (await query({
      collectionPath: collections.schedules,
      query: isDefined(courtId) ? [
        {
          field: 'date',
          operator: '==',
          value: date
        },
        {
          field: 'sportCenterId',
          operator: '==',
          value: sportCenterId
        },
        {
          field: 'courtId',
          operator: '==',
          value: courtId
        }
      ] : [
        {
          field: 'date',
          operator: '==',
          value: date
        },
        {
          field: 'sportCenterId',
          operator: '==',
          value: sportCenterId
        }
      ],
      page: 0,
      size: 1
    }))[0] as Schedule;
  };

  findByDatesAndSportCenterIdAndCourtId = async (date: string[] | string, sportCenterId?: string, courtId?: string): Promise<Schedule[]> => {
    return (await query({
      collectionPath: collections.schedules,
      query: isDefined(courtId) ? isDefined(sportCenterId) ? [
        {
          field: 'date',
          operator: Array.isArray(date) ? 'in' : '==',
          value: date
        },
        {
          field: 'sportCenterId',
          operator: '==',
          value: sportCenterId
        },
        {
          field: 'courtId',
          operator: '==',
          value: courtId
        }
      ] : [
        {
          field: 'date',
          operator: Array.isArray(date) ? 'in' : '==',
          value: date
        },
        {
          field: 'courtId',
          operator: '==',
          value: courtId
        }
      ] : [
        {
          field: 'date',
          operator: Array.isArray(date) ? 'in' : '==',
          value: date
        },
        {
          field: 'sportCenterId',
          operator: '==',
          value: sportCenterId
        }
      ],
      page: 0,
      size: 10000
    })) as Schedule[];
  };

  filterCenterCourtsByAvailableDate = async (centers: SportCenter[], date: string | string[], hours: number[]) : Promise<SportCenter[]> => {
    const schedules = (await query({
      collectionPath: collections.schedules,
      query: Array.isArray(date) ? [
        {
          field: 'date',
          operator: 'in',
          value: date
        }
      ] : [
        {
          field: 'date',
          operator: '==',
          value: date
        }
      ],
      page: 0,
      size: 30
    })) as Schedule[];
    const centersWithSchedules = schedules.map((schedule) => schedule.sportCenterId);
    const courtsWithSchedule = schedules.map((schedule) => schedule.courtId);

    return Promise.all(
      centers.filter((center) => {
        if (centersWithSchedules.includes(center.id!)) {
          center.courts = center.courts?.filter((court) => {
            if (courtsWithSchedule.includes(court.id!)) {
              const schedule = schedules.find((schedule) => schedule.sportCenterId === center.id! && schedule.courtId === court.id!);
              return hours.every((hour) => !schedule?.reservedHours?.includes(hour));
            }
            return true;
          });
          return isDefined(center?.courts) && center.courts!.length > 0;
        }
        return true;
      })
    );
  };

  filterCenterCourtsByAvailableWeekday = async (centers: SportCenter[], weekdays: WeekDay[], hours: number[]) : Promise<SportCenter[]> => {
    const schedules = [] as RecurrentSchedule[];
    weekdays.forEach(async (weekday) => {
      const schedulesPerWeekday = (await query({
        collectionPath: collections.schedules,
        query: [
          {
            field: 'date',
            operator: '==',
            value: weekday
          }
        ],
        page: 0,
        size: 30
      })) as RecurrentSchedule[];
      schedules.push(...schedulesPerWeekday);
    });
    const centersWithSchedules = schedules.map((schedule) => schedule.sportCenterId);
    const courtsWithSchedule = schedules.map((schedule) => schedule.courtId);
    return Promise.all(
      centers.filter((center) => {
        if (centersWithSchedules.includes(center.id!)) {
          center.courts = center.courts?.filter((court) => {
            if (courtsWithSchedule.includes(court.id!)) {
              const courtSchedules = schedules.filter((schedule) => schedule.sportCenterId === center.id! && schedule.courtId === court.id!);
              return courtSchedules.every((schedule) => hours.every((hour) => !schedule?.reservedHours?.includes(hour)));
            }
            return true;
          });
          return isDefined(center?.courts) && center.courts!.length > 0;
        }
        return true;
      })
    );
  };

  getAvailable = (schedule: Schedule, sportCenter: SportCenter, startTimeFilter = 0) => {
    let available: number[] = [];
    for (let i = 0; i < 24; i++) {
      available = [...available, i];
    }
    const dayWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    let dayWeekIndex: number | undefined = undefined;
    if (isDefined(schedule)) {
      const date = (schedule?.date as string).split('-');
      dayWeekIndex = (new Date(Number(date[2]), Number(date[1]), Number(date[0]))).getDay();
    }
    return available.filter((num) => {
      const workTime = sportCenter.workTime!;
      const closeNight = workTime.nightCloseTime.value;
      return ((workTime.morning && workTime.morningOpenTime.value <= num &&
          workTime.morningCloseTime.value >= num) ||
        (workTime.afternoon && workTime.afternoonOpenTime.value <= num &&
          workTime.afternoonCloseTime.value >= num) ||
        (workTime.night && workTime.nightOpenTime.value <= num &&
          (closeNight == 0 ? 24 : closeNight) >= num));
    }).filter((num) => !schedule?.reservedHours.includes(num) && startTimeFilter <= num).filter((num) => isDefined(dayWeekIndex) ? sportCenter.workTime!.weekDaysForWork.includes(dayWeek[dayWeekIndex!]) : false);
  };

  getAvailableNoSchedule = (date: Date, sportCenter: SportCenter, startTimeFilter = 0) => {
    let available: number[] = [];
    for (let i = 0; i < 24; i++) {
      available = [...available, i];
    }
    const dayWeek = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    const dayWeekIndex = date.getDay();
    return available.filter((num) => {
      const workTime = sportCenter.workTime!;
      const closeNight = workTime.nightCloseTime.value;
      return ((workTime.morning && workTime.morningOpenTime.value <= num &&
        workTime.morningCloseTime.value >= num) ||
        (workTime.afternoon && workTime.afternoonOpenTime.value <= num &&
        workTime.afternoonCloseTime.value >= num) ||
        (workTime.night && workTime.nightOpenTime.value <= num &&
        (closeNight == 0 ? 24 : closeNight) >= num));
    }).filter((num) => startTimeFilter <= num).filter((num) => sportCenter.workTime!.weekDaysForWork.includes(dayWeek[dayWeekIndex]));
  };

  toTime = (val: number) =>
    val < 1000
      ? '0' +
        val.toString().slice(0, 1) +
        ':' +
        val.toString().replaceAll('5', '3').slice(1, 3)
      : val.toString().replace('24', '00').slice(0, 2) +
        ':' +
        val.toString().replaceAll('5', '3').slice(2, 4);

  getViewSchedules = async (
    courtId: string | undefined,
    date: Date | Date[] = new Date(),
    startTimeFilter = 0
  ) => {
    const userId = (await UserService.getCurrent())!.id;
    const sportCenter = await SportCenterService.getByUserId(userId);
    sportCenter.courts = await SportCenterService.getCourts(sportCenter.id!);
    const court = sportCenter.courts?.find((court) => court.id === courtId);
    const scheduleTemp =
      Array.isArray(date) ? (await ScheduleService.findByDatesAndSportCenterIdAndCourtId(
        date.map((dat) => formatToScheduleDate(dat)),
        sportCenter.id!,
        courtId
      )) : (await ScheduleService.findByDateAndSportCenterIdAndCourtId(
        formatToScheduleDate(date),
        sportCenter.id!,
        courtId
      ));
    if (isDefined(scheduleTemp)) {
      if (Array.isArray(scheduleTemp)) {
        for (const temp of scheduleTemp) {
          if (isDefined(temp.id)) {
            temp.subSchedules = await ScheduleService.getAllSubSchedules(temp.id!);
          }
        }
      } else {
        scheduleTemp.subSchedules = await ScheduleService.getAllSubSchedules(scheduleTemp.id!);
      }
    }
    let schedulesTemp: ViewSchedules[] = [];
    let subSchedulesTemp = [] as ViewSchedules[];
    if (Array.isArray(scheduleTemp)) {
      const subSchedulesArray = scheduleTemp.map((temp) => {
        if (isDefined(temp?.subSchedules)) {
          return temp.subSchedules!.map((subTemp) => ({
            startTime: this.toTime(subTemp.scheduledHour * 100),
            endTime: this.toTime((subTemp.scheduledHour + subTemp.duration) * 100),
            courtName: court?.name,
            playerName: subTemp?.playerName,
            type: subTemp.reservationType,
            status: subTemp.reservationType != 'Bloqueado' ? 'reserved' : 'blocked',
            sport: subTemp.sport,
            id: temp.id+'/'+subTemp.id
          } as ViewSchedules));
        }
        return [];
      });
      subSchedulesTemp = subSchedulesArray.flat();
    } else {
      subSchedulesTemp = isDefined(scheduleTemp?.subSchedules) ? scheduleTemp.subSchedules!.map((temp) => ({
        startTime: this.toTime(temp.scheduledHour * 100),
        endTime: this.toTime((temp.scheduledHour + temp.duration) * 100),
        courtName: court?.name,
        courtId: court?.id,
        playerName: temp?.playerName,
        type: temp.reservationType,
        status: temp.reservationType != 'Bloqueado' ? 'reserved' : 'blocked',
        sport: temp.sport,
        id: scheduleTemp.id+'/'+temp.id
      } as ViewSchedules)
      ) : [];
    }
    schedulesTemp = [
      ...schedulesTemp,
      ...subSchedulesTemp
    ];
    if (Array.isArray(scheduleTemp)) {
      const arrayResult: ViewSchedules[] = [];
      arrayResult.push(...schedulesTemp);

      for (const temp of scheduleTemp) {
        if (isDefined(temp?.subSchedules)) {
          const available = ScheduleService.getAvailable(temp, sportCenter, startTimeFilter);
          arrayResult.push(
            ...(isDefined(courtId) ? available : multiplyArray(available, sportCenter.courts.length)).map((subTemp) => ({
              startTime: this.toTime(subTemp * 100),
              endTime: this.toTime((subTemp + 1) * 100),
              courtName: court?.name,
              courtId: court?.id,
              playerName: '',
              status: 'available',
              sport: court?.sportServed?.join(' | ')
            } as ViewSchedules))
          );
        }
      }

      const dates = (date as Date[]).filter((dat) => scheduleTemp.find((temp: Schedule) => temp.date === formatToScheduleDate(dat)) === undefined);

      for (const temp of dates) {
        if (isDefined(temp)) {
          const availableNoSchedule = ScheduleService.getAvailableNoSchedule(temp, sportCenter, startTimeFilter);
          arrayResult.push(
            ...(isDefined(courtId) ? availableNoSchedule : multiplyArray(availableNoSchedule, sportCenter.courts.length)).map((subTemp) => ({
              startTime: this.toTime(subTemp * 100),
              endTime: this.toTime((subTemp + 1) * 100),
              courtName: court?.name,
              courtId: court?.id,
              playerName: '',
              status: 'available',
              sport: court?.sportServed?.join(' | ')
            } as ViewSchedules))
          );
        }
      }

      return arrayResult;
    } else {
      const available = ScheduleService.getAvailable(scheduleTemp, sportCenter, startTimeFilter);
      const viewSchedules = [
        ...schedulesTemp,
        ...(isDefined(courtId) ? available : multiplyArray(available, sportCenter.courts.length)).map((temp) => ({
          startTime: this.toTime(temp * 100),
          endTime: this.toTime((temp + 1) * 100),
          courtName: court?.name,
          courtId: court?.id,
          playerName: '',
          status: 'available',
          sport: court?.sportServed?.join(' | ')
        } as ViewSchedules))
      ];

      if (scheduleTemp?.date !== formatToScheduleDate((date as Date))) {
        const availableNoSchedule = ScheduleService.getAvailableNoSchedule((date as Date), sportCenter, startTimeFilter);
        viewSchedules.push(...[
          ...(isDefined(courtId) ? availableNoSchedule : multiplyArray(availableNoSchedule, sportCenter.courts.length)).map((temp) => ({
            startTime: this.toTime(temp * 100),
            endTime: this.toTime((temp + 1) * 100),
            courtName: court?.name,
            courtId: court?.id,
            playerName: '',
            status: 'available',
            sport: court?.sportServed?.join(' | ')
          } as ViewSchedules))
        ]);
      }
      return viewSchedules.sort((a, b) => Number(a.startTime.replace(':', '')) - Number(b.startTime.replace(':', '')));
    }
  };

  delete = async (id: string) => {
    return await remove(collections.schedules, id);
  };
}

const ScheduleService = new ScheduleServiceProvider();
export default ScheduleService;

import React, { useEffect, useState } from 'react';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import Typography from '../../../common/designSystem/Typography/Typography';
import GameCardsCarousel from '../Home/Components/GameCard/GameCardsCarousel';
import { Schedule } from '../../../common/model/Schedule';
import { Team } from '../../../common/model/Team';
import TeamService from '../../../common/services/team';
import { propsFilter, propsFilterKey } from '../../../common/util/web';
import ScheduleService from '../../../common/services/schedule';
import UserService from '../../../common/services/user';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import GameCard from '../Home/Components/GameCard/GameCard';
import { isDefined } from '../../../common/util/validations';
import MenuPlayer from '../../../common/component/MenuPlayer/MenuPlayer';

const MyGames = () => {
  const [schedules, setSchedules] = useState([] as Schedule[]);
  const [Team, setTeam] = useState([] as Team[]);
  const [userId, setUserId] = useState('');

  const getTimeAndSchedules = async () => {
    let teamTemp = await TeamService.getAllMy();
    teamTemp = teamTemp.map((tTemp) => {
      const players = propsFilter(tTemp.players, 'id', userId);
      let keys: string[] = [];
      for (const date in players) {
        keys = [...keys, date];
      }
      const schedules = propsFilterKey(tTemp.scheduledIds, keys);
      return { ...tTemp, players: players, scheduledIds: schedules };
    });
    let schedulesTemp: Schedule[] = [];
    for (const timeTemp of teamTemp) {
      for (const date in timeTemp.scheduledIds) {
        schedulesTemp = [...schedulesTemp, (await ScheduleService.get(timeTemp.scheduledIds[date], true, timeTemp.admin))];
      }
    }
    schedulesTemp = schedulesTemp.sort((a, b) => {
      const dateSplitA = (a?.date as string).split('-');
      const dateA = new Date(Number(dateSplitA[2]), Number(dateSplitA[1]), Number(dateSplitA[0]));
      const dateSplitB = (b?.date as string).split('-');
      const dateB = new Date(Number(dateSplitB[2]), Number(dateSplitB[1]), Number(dateSplitB[0]));
      return dateA.getTime() - dateB.getTime();
    });
    schedulesTemp = schedulesTemp.map((scheduleTemp) => {
      const dateSplit = (scheduleTemp?.date as string).split('-');
      return { ...scheduleTemp, date: new Date(Number(dateSplit[2]), Number(dateSplit[1]) -1, Number(dateSplit[0])) };
    });
    setSchedules(schedulesTemp);
    setTeam(teamTemp);
  };

  const getUserId = async () => {
    setUserId((await UserService.getCurrent())!.id);
  };

  useEffect(() => {
    getTimeAndSchedules();
  }, [userId]);

  useEffect(() => {
    getUserId();
  }, []);

  const nextGames = schedules.filter((scheduleTemp) => (scheduleTemp.date as Date).getTime() > new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getHours()).getTime());
  const historyGames = schedules
    .filter((scheduleTemp) => (scheduleTemp.date as Date).getTime() <= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getHours()).getTime())
    .sort((a, b) => (b.date as Date).getTime() - (a.date as Date).getTime());
  return (
    <RestrictArea role={'player'}>
      <Grid isScrollable height={'calc(100% - 126px)'} style={{ paddingTop: '24px', paddingBottom: '16px' }}>
        {isDefined(nextGames) && <GridItem xs={6}>
          <Typography variant={'paragraph'} style={{ paddingBottom: '16px' }}>
          Próximos jogos
          </Typography>
          <Grid noMargin>
            {nextGames.map((schedule) =>
              <GridItem xs={6} sm={3} lg={4}>
                <GameCard schedule={schedule} key={schedule.id} team={Team.find((time) => isDefined(propsFilter(time.scheduledIds, undefined, schedule.id))) ?? {} as Team}></GameCard>
              </GridItem>
            )}
          </Grid>
        </GridItem>}
        {isDefined(historyGames) && <GridItem xs={6}>
          <Typography variant={'paragraph'} style={{ paddingBottom: '16px' }}>
          Histórico dos Jogos
          </Typography>
          <Grid noMargin>
            {historyGames.map((schedule) =>
              <GridItem xs={6} sm={3} lg={4}>
                <GameCard schedule={schedule} key={schedule.id} team={Team.find((time) => isDefined(propsFilter(time.scheduledIds, undefined, schedule.id))) ?? {} as Team}></GameCard>
              </GridItem>
            )}
          </Grid>
        </GridItem>}
      </Grid>
      <MenuPlayer/>
    </RestrictArea>
  );
};

export default MyGames;

import { CSSProperties, useEffect, useState } from 'react';
import { MercadoPagoProps, payment } from './type';
export const useMercadoPago = ({
  publicKey,
  placeHolders,
  style,
  locale = 'pt-BR'
}: {
  publicKey: string;
  locale: string;
  style?: {
    cardNumber: CSSProperties;
    expirationDate: CSSProperties;
    securityCode: CSSProperties;
  };
  placeHolders: {
    cardNumber: string;
    expirationDate: string;
    securityCode: string;
  };
}): MercadoPagoProps => {
  const [mercadoPago, setMercadoPago] = useState({} as any);
  const [issuers, setIssuers] = useState({} as any);
  const [installments, setInstallments] = useState({} as any);
  const [paymentMethods, setPaymentMethods] = useState({} as any);
  const [payment, setPayment] = useState(null as payment | null);
  const url = 'https://sdk.mercadopago.com/js/v2';

  const ids = {
    cardNumber: 'cardNumber',
    cardNumberImage: 'cardNumberImage',
    expirationDate: 'expirationDate',
    securityCode: 'securityCode'
  };

  const componentCardNumber = document.getElementById(ids.cardNumber);
  const componentExpirationDate = document.getElementById(ids.expirationDate);
  const componentSecurityCode = document.getElementById(ids.securityCode);

  const cardNumberImage = document.getElementById(ids.cardNumberImage);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    script.onload = () =>
      setMercadoPago(
        // @ts-ignore
        new MercadoPago(publicKey, {
          locale: locale,
          advancedFraudPrevention: true
        })
      );

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [locale, publicKey]);

  const setHoverImage = () => {
    if (cardNumberImage) {
      cardNumberImage.classList.add('hover');
    }
  };

  const removeHoverImage = () => {
    if (cardNumberImage) {
      cardNumberImage.classList.remove('hover');
    }
  };

  useEffect(() => {
    if (componentCardNumber) {
      componentCardNumber.addEventListener('mouseenter', () => setHoverImage());
      componentCardNumber.addEventListener('mouseleave', () =>
        removeHoverImage()
      );
    }
    return () => {
      if (componentCardNumber) {
        componentCardNumber.removeEventListener('mouseenter', () =>
          setHoverImage()
        );
        componentCardNumber.removeEventListener('mouseleave', () =>
          removeHoverImage()
        );
      }
    };
  }, [componentCardNumber]);

  const getIssuers = (paymentMethodId: any, bin: any) => {
    if (mercadoPago === undefined && payment === null) return;
    mercadoPago
      ?.getIssuers({ paymentMethodId, bin })
      .then((results: any) => {
        setIssuers(results[0]);
      })
      .catch((error: any) => {
        setIssuers(error);
      });
  };

  const getInstallments = (paymentTypeId: any, bin: any, amount: any) => {
    if (mercadoPago === undefined) return;
    mercadoPago
      ?.getInstallments({
        amount: amount,
        bin,
        paymentTypeId: paymentTypeId
      })
      .then((results: any) => {
        setInstallments(results[0]);
      })
      .catch((error: any) => {
        setInstallments(error);
      });
  };

  const getComponentsMercadoPago = () => {
    if (mercadoPago === undefined && payment === null) return;
    if (
      componentCardNumber &&
      componentCardNumber.children.length === 0 &&
      componentExpirationDate &&
      componentExpirationDate.children.length === 0 &&
      componentSecurityCode &&
      componentSecurityCode.children.length === 0
    ) {
      const cardNumber = mercadoPago?.fields
        ?.create(ids.cardNumber, {
          placeholder: placeHolders.cardNumber
        })
        .mount(ids.cardNumber);

      cardNumber?.on('binChange', (data: any) => {
        mercadoPago
          .getPaymentMethods(data)
          .then(({ results }: any) => {
            if (results.length === 0) {
              throw new Error('No payment methods found');
            }
            setPaymentMethods(results[0]);
            getIssuers(results[0].id, data.bin);
            if (payment?.umount) {
              getInstallments(
                results[0].payment_type_id,
                data.bin,
                payment?.umount
              );
            }
          })
          .catch((error: any) => {
            setPaymentMethods(error);
          });
      });

      cardNumber?.on('focus', () => {
        componentCardNumber.classList.add('focused');
        if (cardNumberImage) {
          cardNumberImage.classList.add('focused');
        }
      });

      cardNumber?.on('blur', () => {
        componentCardNumber.classList.remove('focused');
        if (cardNumberImage) {
          cardNumberImage.classList.remove('focused');
        }
      });

      const expirationDate = mercadoPago?.fields
        ?.create(ids.expirationDate, {
          placeholder: placeHolders.expirationDate,
          style: style && style.cardNumber
        })
        .mount(ids.expirationDate);

      expirationDate?.on('focus', () => {
        componentExpirationDate.classList.add('focused');
      });

      expirationDate?.on('blur', () => {
        componentExpirationDate.classList.remove('focused');
      });

      const securityCode = mercadoPago?.fields
        ?.create(ids.securityCode, {
          placeholder: placeHolders.securityCode,
          style: style && style.cardNumber
        })
        .mount(ids.securityCode);

      securityCode?.on('focus', () => {
        componentSecurityCode.classList.add('focused');
      });

      securityCode?.on('blur', () => {
        componentSecurityCode.classList.remove('focused');
      });
    }
  };

  const generateToken = async () => {
    if (mercadoPago === undefined && payment === null) return;
    try {
      return await mercadoPago.fields.createCardToken({
        cardholderName: payment?.cardHolderName,
        identificationType: payment?.identificationType,
        identificationNumber: payment?.identificationNumber?.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')
      });
    } catch (e) {
      return e;
    }
  };

  useEffect(() => {
    getComponentsMercadoPago();
  }, [mercadoPago, payment]);

  return {
    ids,
    issuers,
    installments,
    paymentMethods,
    setPayment,
    generateToken
  };
};

import React, { useEffect, useState } from 'react';
import {
  CalendarBody,
  CalendarHeader,
  CalendarStyled,
  Column,
  Days,
  EmptyColumn,
  Title,
  WeekDays,
  WeekDaysColumn
} from './styled';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { CalendarProps } from './interface';
import Back from '../Icons/Back';
import Next from '../Icons/Next';
import { isDefined } from '../../util/validations';

const getNumberOfDaysInMonth = (month: number, year: number) => {
  return new Date(year, month + 1, 0).getDate();
};
const getWeekDayOfFirstDayOfMonth = (month: number, year: number) => {
  return new Date(year, month, 1).getDay();
};
const getMonthName = (month: number) => {
  const monthNames = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
  ];
  return monthNames[month];
};
const getWeekDayNameShort = (day: number) => {
  const weekDayNames = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  return weekDayNames[day];
};

const startDate = new Date(new Date().setDate(new Date().getDate() + 0));

const getMonthNow = () => {
  return startDate.getMonth();
};

const getYearNow = () => {
  return startDate.getFullYear();
};

const getDayNow = () => {
  return startDate.getDate() - 1;
};

const Calendar = ({ onlyFutureDates, register, name, required = false, disabledAfterDays }: CalendarProps) => {
  const [selectDay, setSelectDay] = useState(0);
  const [selectMonth, setSelectMonth] = useState(getMonthNow());
  const [selectYear, setSelectYear] = useState(getYearNow());
  const [actualMonth, setActualMonth] = useState(getMonthNow());
  const [actualYear, setActualYear] = useState(getYearNow());

  const backMonth = () => {
    if (
      onlyFutureDates &&
      actualMonth === getMonthNow() &&
      actualYear === getYearNow()
    ) {
      return;
    }
    if (actualMonth === 0) {
      setActualMonth(11);
      setActualYear(actualYear - 1);
    } else {
      setActualMonth(actualMonth - 1);
    }
  };

  const nextMonth = () => {
    if (actualMonth === 11) {
      setActualMonth(0);
      setActualYear(actualYear + 1);
    } else {
      setActualMonth(actualMonth + 1);
    }
  };

  const handleOnClicked = async (index: number) => {
    await setSelectDay(index);
    await setSelectMonth(actualMonth);
    await setSelectYear(actualYear);
  };

  const getDisabledAfterDays = (): Date => new Date(new Date().setDate(getDayNow() + disabledAfterDays!));

  useEffect(() => {
    register?.registerValue(register, name, required);
    const initialValue = register?.initialValue as Date;
    if (isDefined(initialValue)) {
      setSelectDay(initialValue.getDate() - 1);
      setActualMonth(initialValue.getMonth());
      setSelectYear(initialValue.getFullYear());
    }
  }, []);

  useEffect(() => {
    const date = new Date(selectYear, selectMonth, selectDay + 1);
    register?.setValue(date, name);
  }, [selectDay, selectMonth, selectYear]);

  return (
    <CalendarStyled>
      <CalendarHeader>
        <Button
          color={'neutral'}
          disabled={
            onlyFutureDates &&
            actualMonth === getMonthNow() &&
            actualYear === getYearNow()
          }
          onClick={() => backMonth()}
          variant={'transparent'}
          icon={<Back color={'neutral'} />}
        />
        <Title>
          <Typography variant={'body'}>{getMonthName(actualMonth)}</Typography>
          <Typography variant={'tiny'}>{actualYear}</Typography>
        </Title>
        <Button
          color={'neutral'}
          onClick={() => nextMonth()}
          variant={'transparent'}
          icon={<Next color={'neutral'} />}
        />
      </CalendarHeader>
      <CalendarBody>
        <WeekDays>
          <WeekDaysColumn>{getWeekDayNameShort(0)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(1)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(2)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(3)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(4)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(5)}</WeekDaysColumn>
          <WeekDaysColumn>{getWeekDayNameShort(6)}</WeekDaysColumn>
        </WeekDays>
        <Days>
          {Array.from(
            Array(getWeekDayOfFirstDayOfMonth(actualMonth, actualYear))
          ).map((_, index) => {
            return <EmptyColumn key={index}></EmptyColumn>;
          })}
          {Array.from(
            Array(getNumberOfDaysInMonth(actualMonth, actualYear))
          ).map((_, index) => {
            return (
              <Column
                key={index}
                disabled={
                  isDefined(disabledAfterDays) ? (
                    onlyFutureDates &&
                    actualYear === getYearNow() &&
                    actualMonth === getMonthNow() &&
                    index < getDayNow()
                  ) || (
                    actualMonth == getDisabledAfterDays().getMonth() ? index >= getDisabledAfterDays().getDate() : actualMonth < getDisabledAfterDays().getMonth() ? false : true
                  ) : (
                    onlyFutureDates &&
                    actualYear === getYearNow() &&
                    actualMonth === getMonthNow() &&
                    index < getDayNow()
                  )
                }
                onClick={() =>
                  !(
                    isDefined(disabledAfterDays) ? (
                      onlyFutureDates &&
                    actualYear === getYearNow() &&
                    actualMonth === getMonthNow() &&
                    index < getDayNow()
                    ) || (
                      actualMonth == getDisabledAfterDays().getMonth() ? index >= getDisabledAfterDays().getDate() : actualMonth < getDisabledAfterDays().getMonth() ? false : true
                    ) : (
                      onlyFutureDates &&
                    actualYear === getYearNow() &&
                    actualMonth === getMonthNow() &&
                    index < getDayNow()
                    )
                  ) && handleOnClicked(index)
                }
                selectDay={
                  index === selectDay &&
                  actualMonth === selectMonth &&
                  actualYear === selectYear
                }
              >
                {index + 1}
              </Column>
            );
          })}
        </Days>
      </CalendarBody>
    </CalendarStyled>
  );
};

export default Calendar;

import React, { useContext, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import Button from '../../../../../common/designSystem/Button/Button';
import SportCenterService from '../../../../../common/services/sportCenter';
import { SportCenterHomeContext } from '../../Home';
import { isDefined } from '../../../../../common/util/validations';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import { SportCenter } from '../../../../../common/model/SportCenter';
import UserService from '../../../../../common/services/user';
import { createPayment } from '../../../../../common/services/payment';
import { getPriceTax } from '../../../../../common/util/number';

const Dependencies = () => {
  const navigate = useNavigate();
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const [pixChecked, setPixChecked] = useState(false);
  const userId = async () => (await UserService.getCurrent())?.id ?? '';
  const getSportCenter = async (noCache = false) => {
    let sportCenter = await SportCenterService.getByUserId(await userId(), noCache);
    if (isDefined(sportCenter)) {
      sportCenter = { ...sportCenter, courts: await SportCenterService.getCourts(sportCenter.id ?? '') };
    }
    if (isDefined(sportCenter?.workTime)) {
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);


  useEffect(() => {
    if (isDefined(sportCenter) && isDefined(sportCenter?.paymentProvider) && sportCenter?.paymentProvider?.includes('Mercado Pago') && !sportCenter?.paymentProvider?.includes('Mercado Pago Pix')) {
      payWithPix();
    }
  }, [sportCenter]);

  function getSettingCourts() {
    return isDefined(sportCenter) && !isDefined(sportCenter?.courts) && (
      <GridItem xs={6}>
        <Grid noMargin rowGapXs={16}>
          <GridItem xs={6}>
            <Typography variant={'small'} bold>
              Configure suas quadras
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'small'}>
              Para vender horários, configure suas quadras. Comece agora!
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Button
              color={'info'}
              isFullWidth
              onClick={() => navigate(RoutePath.SPORT_CENTER_SETTING_COURT)}
              variant={'filled'}
              title={'Configurar quadras'}
            />
          </GridItem>
        </Grid>
      </GridItem>
    );
  }

  async function payWithPix() {
    const user = await UserService.getCurrent();
    const paymentCreated = await createPayment({
      clientEmail: user?.email ?? '',
      clientIdentification: '10456215956',
      clientIdentificationType: 'CPF',
      installments: 1,
      itemId: 'Teste PIX',
      itemTitle: 'Teste PIX',
      paymentMethodId: 'pix',
      itemPrice: 1,
      userId: sportCenter.id!
    });
    if (isDefined(paymentCreated) && Number(paymentCreated!.status) !== 500) {
      await SportCenterService.save({ ...sportCenter, paymentProvider: [...sportCenter.paymentProvider!, 'Mercado Pago Pix'] });
      await getSportCenter(true);
    }
    setPixChecked(true);
  }

  function getMercadoPago() {
    return isDefined(sportCenter) && !isDefined(sportCenter?.paymentProvider) && !sportCenter?.paymentProvider?.includes('Mercado Pago') && (
      <GridItem xs={6}>
        <Grid noMargin rowGapXs={16} alignContent={'flex-start'}>
          <GridItem xs={6}>
            <Typography variant={'small'} bold>
              Horários do centro
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'small'}>
              Para vender horários, vincule sua conta ao Mercado Pago. É um processo seguro e simples. Comece agora!
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Button
              color={'info'}
              isFullWidth
              onClick={async () => {
                window.location.href =
                'https://auth.mercadopago.com.br/authorization?' +
                `client_id=${8067033164530686}` +
                '&response_type=code' +
                `&state=${sportCenter.id}` +
                '&platform_id=mp' +
                `&redirect_uri=${'https://jogaja.com/mp-receive'}`;
              }}
              variant={'filled'}
              title={'Ir para o Mercado Pago'}
            />
          </GridItem>
        </Grid>
      </GridItem>
    );
  }

  function getMercadoPagoPix() {
    return isDefined(sportCenter) && pixChecked && isDefined(sportCenter?.paymentProvider) && sportCenter?.paymentProvider?.includes('Mercado Pago') && !sportCenter?.paymentProvider?.includes('Mercado Pago Pix') && (
      <GridItem xs={6}>
        <Grid noMargin rowGapXs={16} alignContent={'flex-start'}>
          <GridItem xs={6}>
            <Typography variant={'small'} bold>
              Método de pagamento via PIX
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'small'}>
             Para ativar o método de pagamento via PIX, você precisa cadastrar uma chave PIX na sua conta do Mercado Pago. Se ainda não tiver uma chave, pode criar uma aleatória para facilitar o processo de cadastro.
            </Typography>
          </GridItem>
          <GridItem xs={6}>
            <Button
              color={'info'}
              isFullWidth
              onClick={async () => {
                window.open(
                  'https://mercadopago.com.br',
                  '_blank');
              }}
              variant={'filled'}
              title={'Ir para o Mercado Pago'}
            />
          </GridItem>
        </Grid>
      </GridItem>
    );
  }

  return (
    <>
      {getSettingCourts()}
      {getMercadoPago()}
      {getMercadoPagoPix()}
    </>
  );
};

export default Dependencies;

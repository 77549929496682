import React from 'react';
import { IconProps } from './interface';
import { getColor, getContrastColor } from '../Colors';

const Back = ({ color = 'neutral', colorVariant, isContrastColor, height = 16, width = 16 }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.799 2.67947C11.0364 2.91062 11.0415 3.29048 10.8104 3.52792L6.45692 8.00003L10.8104 12.4721C11.0415 12.7096 11.0364 13.0894 10.799 13.3206C10.5616 13.5517 10.1817 13.5466 9.95054 13.3092L5.18964 8.41855C4.96288 8.18561 4.96288 7.81444 5.18964 7.5815L9.95054 2.69088C10.1817 2.45343 10.5615 2.44833 10.799 2.67947Z" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
    </svg>
  );
};

export default Back;

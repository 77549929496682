import styled from 'styled-components';
import { Opacity, Shadows } from '../GlobalStyle';
import { DialogStyledProps } from './interface';
import { device } from '../Screen';
export const DialogPage = styled.div<DialogStyledProps>`
  display: ${({ open }) => (open ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: rgba(0, 0, 0, ${Opacity.medium});
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  
  .vibrate {
    @keyframes vibrate {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(1deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-1deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
    transition: transform 30ms linear;
    animation: vibrate 150ms linear infinite;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
`;

interface DialogContainerProps {
  bottom: number
}

export const DialogContainer = styled.div<DialogContainerProps>`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  background: #ffffff;
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: ${Shadows.high};
  
  @media ${device.xs} {
    width: 100%;
    max-width: 100%;
    bottom: ${({ bottom }) => (bottom ? bottom : 0) + 'px'}; // TODO - Quando for iphone ser 136 o bottom
    border-end-end-radius: 0;
    border-end-start-radius: 0;
  }
`;

import React from 'react';

const BackArrow = () => {
  return (
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.42829 1.71436L1.14258 6.00007L5.42829 10.2858" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default BackArrow;

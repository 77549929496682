import styled from 'styled-components';
import { OptionProps } from './interface';
import { getColor } from '../Colors';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 120px;
  margin: 35px 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
`;

export const WrapperOption = styled.div<React.HTMLAttributes<HTMLDivElement>>`
  height: 132px;
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  overflow: auto;
  scroll-snap-type: y mandatory;
  gap: 6px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Option = styled.div<OptionProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  scroll-snap-align: center;
  height: 40px;
  min-height: 40px;
  background: ${({ selected }) =>
    selected ? getColor('neutral', 'a100') : getColor('neutral', 'white')};
`;

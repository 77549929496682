import React from 'react';
import { LogoProps } from './interface';

const Logo = ({ size } : LogoProps) => {
  switch (size) {
  case 'xs':
    return (
      <svg width="68" height="32" viewBox="0 0 68 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.6639 10.7854V17.3279C17.6639 18.2358 17.4013 18.9435 16.8762 19.4508C16.351 19.9493 15.6478 20.1986 14.7666 20.1986C13.8764 20.1986 13.1554 19.9404 12.6035 19.4241C12.0606 18.9079 11.7891 18.1869 11.7891 17.2611H13.6583C13.6672 17.6617 13.7607 17.9732 13.9387 18.1958C14.1257 18.4183 14.3927 18.5296 14.7399 18.5296C15.0781 18.5296 15.3362 18.4227 15.5143 18.2091C15.6923 17.9955 15.7813 17.7017 15.7813 17.3279V10.7854H17.6639Z" fill="#2C2B3A"/>
        <path d="M21.5268 20.2253C20.8147 20.2253 20.1738 20.0695 19.6041 19.7579C19.0345 19.4375 18.5849 18.988 18.2556 18.4094C17.9351 17.8308 17.7749 17.1632 17.7749 16.4066C17.7749 15.65 17.9396 14.9824 18.2689 14.4038C18.6072 13.8252 19.0656 13.3801 19.6442 13.0686C20.2228 12.7481 20.8681 12.5879 21.5802 12.5879C22.2923 12.5879 22.9377 12.7481 23.5163 13.0686C24.0949 13.3801 24.5488 13.8252 24.8782 14.4038C25.2164 14.9824 25.3856 15.65 25.3856 16.4066C25.3856 17.1632 25.212 17.8308 24.8648 18.4094C24.5266 18.988 24.0637 19.4375 23.4762 19.7579C22.8976 20.0695 22.2478 20.2253 21.5268 20.2253ZM21.5268 18.5963C21.8651 18.5963 22.1811 18.5162 22.4748 18.356C22.7775 18.1869 23.0178 17.9376 23.1958 17.6083C23.3739 17.2789 23.4629 16.8784 23.4629 16.4066C23.4629 15.7034 23.2759 15.1649 22.9021 14.791C22.5371 14.4082 22.0876 14.2169 21.5535 14.2169C21.0195 14.2169 20.5699 14.4082 20.205 14.791C19.8489 15.1649 19.6709 15.7034 19.6709 16.4066C19.6709 17.1098 19.8445 17.6528 20.1916 18.0355C20.5477 18.4094 20.9928 18.5963 21.5268 18.5963Z" fill="#2C2B3A"/>
        <path d="M28.0519 12.5879C28.6038 12.5879 29.0889 12.6992 29.5073 12.9217C29.9256 13.1354 30.255 13.4157 30.4953 13.7629V12.7081H32.3779V20.1585C32.3779 20.8439 32.24 21.4536 31.964 21.9877C31.6881 22.5307 31.2742 22.958 30.7223 23.2695C30.1704 23.59 29.5028 23.7502 28.7195 23.7502C27.6691 23.7502 26.8057 23.5054 26.1292 23.0158C25.4616 22.5262 25.0833 21.8586 24.9943 21.013H26.8502C26.9481 21.3513 27.1573 21.6183 27.4778 21.8141C27.8071 22.0189 28.2032 22.1212 28.6661 22.1212C29.2091 22.1212 29.6497 21.9566 29.9879 21.6272C30.3262 21.3068 30.4953 20.8172 30.4953 20.1585V19.0102C30.255 19.3574 29.9212 19.6467 29.4939 19.8781C29.0755 20.1095 28.5949 20.2253 28.0519 20.2253C27.4288 20.2253 26.8591 20.065 26.3428 19.7446C25.8266 19.4241 25.4171 18.9746 25.1145 18.396C24.8207 17.8085 24.6738 17.1365 24.6738 16.3799C24.6738 15.6322 24.8207 14.969 25.1145 14.3904C25.4171 13.8119 25.8221 13.3668 26.3295 13.0552C26.8458 12.7437 27.4199 12.5879 28.0519 12.5879ZM30.4953 16.4066C30.4953 15.9526 30.4063 15.5654 30.2283 15.245C30.0502 14.9156 29.8099 14.6664 29.5073 14.4973C29.2046 14.3192 28.8797 14.2302 28.5326 14.2302C28.1854 14.2302 27.865 14.3148 27.5712 14.4839C27.2775 14.653 27.0371 14.9023 26.8502 15.2316C26.6722 15.5521 26.5832 15.9348 26.5832 16.3799C26.5832 16.8249 26.6722 17.2166 26.8502 17.5549C27.0371 17.8842 27.2775 18.1379 27.5712 18.3159C27.8739 18.4939 28.1943 18.583 28.5326 18.583C28.8797 18.583 29.2046 18.4984 29.5073 18.3293C29.8099 18.1512 30.0502 17.902 30.2283 17.5816C30.4063 17.2522 30.4953 16.8606 30.4953 16.4066Z" fill="#2C2B3A"/>
        <path d="M32.1207 16.3799C32.1207 15.6322 32.2676 14.969 32.5613 14.3904C32.864 13.8119 33.269 13.3668 33.7763 13.0552C34.2926 12.7437 34.8668 12.5879 35.4988 12.5879C36.0506 12.5879 36.5313 12.6992 36.9408 12.9217C37.3591 13.1443 37.6929 13.4246 37.9422 13.7629V12.7081H39.8248V20.1051H37.9422V19.0236C37.7018 19.3707 37.368 19.66 36.9408 19.8915C36.5224 20.114 36.0373 20.2253 35.4854 20.2253C34.8623 20.2253 34.2926 20.065 33.7763 19.7446C33.269 19.4241 32.864 18.9746 32.5613 18.396C32.2676 17.8085 32.1207 17.1365 32.1207 16.3799ZM37.9422 16.4066C37.9422 15.9526 37.8532 15.5654 37.6751 15.245C37.4971 14.9156 37.2568 14.6664 36.9541 14.4973C36.6515 14.3192 36.3266 14.2302 35.9794 14.2302C35.6323 14.2302 35.3118 14.3148 35.0181 14.4839C34.7243 14.653 34.484 14.9023 34.2971 15.2316C34.119 15.5521 34.03 15.9348 34.03 16.3799C34.03 16.8249 34.119 17.2166 34.2971 17.5549C34.484 17.8842 34.7243 18.1379 35.0181 18.3159C35.3207 18.4939 35.6412 18.583 35.9794 18.583C36.3266 18.583 36.6515 18.4984 36.9541 18.3293C37.2568 18.1512 37.4971 17.902 37.6751 17.5816C37.8532 17.2522 37.9422 16.8606 37.9422 16.4066Z" fill="#2C2B3A"/>
        <path d="M47.5477 10.7853V17.3278C47.5477 18.2357 47.2851 18.9434 46.76 19.4508C46.2348 19.9492 45.5316 20.1985 44.6503 20.1985C43.7602 20.1985 43.0392 19.9403 42.4873 19.424C41.9443 18.9078 41.6729 18.1868 41.6729 17.261H43.5421C43.551 17.6616 43.6445 17.9731 43.8225 18.1957C44.0094 18.4182 44.2765 18.5295 44.6236 18.5295C44.9619 18.5295 45.22 18.4226 45.3981 18.209C45.5761 17.9954 45.6651 17.7016 45.6651 17.3278V10.7853H47.5477Z" fill="#FFBD4A"/>
        <path d="M47.6454 16.3798C47.6454 15.6321 47.7922 14.9689 48.086 14.3903C48.3886 13.8118 48.7936 13.3667 49.301 13.0551C49.8173 12.7436 50.3914 12.5878 51.0234 12.5878C51.5753 12.5878 52.056 12.6991 52.4654 12.9216C52.8838 13.1442 53.2176 13.4246 53.4668 13.7628V12.708H55.3495V20.105H53.4668V19.0235C53.2265 19.3706 52.8927 19.6599 52.4654 19.8914C52.0471 20.1139 51.5619 20.2252 51.0101 20.2252C50.387 20.2252 49.8173 20.0649 49.301 19.7445C48.7936 19.424 48.3886 18.9745 48.086 18.3959C47.7922 17.8085 47.6454 17.1364 47.6454 16.3798ZM53.4668 16.4065C53.4668 15.9525 53.3778 15.5653 53.1998 15.2449C53.0218 14.9155 52.7814 14.6663 52.4788 14.4972C52.1761 14.3191 51.8512 14.2301 51.5041 14.2301C51.1569 14.2301 50.8365 14.3147 50.5427 14.4838C50.249 14.6529 50.0087 14.9022 49.8217 15.2315C49.6437 15.552 49.5547 15.9347 49.5547 16.3798C49.5547 16.8249 49.6437 17.2165 49.8217 17.5548C50.0087 17.8841 50.249 18.1378 50.5427 18.3158C50.8454 18.4939 51.1658 18.5829 51.5041 18.5829C51.8512 18.5829 52.1761 18.4983 52.4788 18.3292C52.7814 18.1512 53.0218 17.9019 53.1998 17.5815C53.3778 17.2521 53.4668 16.8605 53.4668 16.4065ZM53.2131 10.7853L50.1422 12.1873V10.8521L53.2131 9.26318V10.7853Z" fill="#FFBD4A"/>
      </svg>
    );
  case 'sm':
    return (
      <svg width="85" height="40" viewBox="0 0 85 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.0809 13.4814V21.6595C22.0809 22.7945 21.7527 23.679 21.0962 24.3132C20.4397 24.9363 19.5607 25.2479 18.4592 25.2479C17.3465 25.2479 16.4452 24.9252 15.7554 24.2799C15.0767 23.6345 14.7373 22.7333 14.7373 21.5761H17.0739C17.085 22.0768 17.2019 22.4662 17.4244 22.7444C17.6581 23.0226 17.9919 23.1616 18.4258 23.1616C18.8486 23.1616 19.1713 23.0281 19.3938 22.7611C19.6163 22.494 19.7276 22.1269 19.7276 21.6595V13.4814H22.0809Z" fill="#2C2B3A"/>
        <path d="M26.9095 25.2813C26.0194 25.2813 25.2183 25.0865 24.5062 24.6971C23.7941 24.2966 23.2322 23.7347 22.8205 23.0114C22.4199 22.2882 22.2196 21.4537 22.2196 20.5079C22.2196 19.5622 22.4255 18.7277 22.8372 18.0044C23.26 17.2812 23.833 16.7249 24.5562 16.3354C25.2795 15.9349 26.0861 15.7346 26.9763 15.7346C27.8664 15.7346 28.6731 15.9349 29.3963 16.3354C30.1196 16.7249 30.687 17.2812 31.0987 18.0044C31.5215 18.7277 31.7329 19.5622 31.7329 20.5079C31.7329 21.4537 31.516 22.2882 31.082 23.0114C30.6592 23.7347 30.0806 24.2966 29.3463 24.6971C28.623 25.0865 27.8108 25.2813 26.9095 25.2813ZM26.9095 23.2451C27.3323 23.2451 27.7273 23.1449 28.0945 22.9447C28.4728 22.7333 28.7732 22.4217 28.9958 22.01C29.2183 21.5983 29.3296 21.0976 29.3296 20.5079C29.3296 19.6289 29.0959 18.9558 28.6286 18.4884C28.1724 18.01 27.6105 17.7708 26.9429 17.7708C26.2753 17.7708 25.7134 18.01 25.2572 18.4884C24.8121 18.9558 24.5896 19.6289 24.5896 20.5079C24.5896 21.3869 24.8066 22.0657 25.2405 22.5441C25.6856 23.0114 26.2419 23.2451 26.9095 23.2451Z" fill="#2C2B3A"/>
        <path d="M35.0658 15.7346C35.7557 15.7346 36.3621 15.8737 36.885 16.1518C37.408 16.4189 37.8197 16.7694 38.1201 17.2033V15.8848H40.4734V25.1978C40.4734 26.0546 40.3009 26.8167 39.956 27.4843C39.6111 28.1631 39.0937 28.6971 38.4038 29.0866C37.714 29.4871 36.8795 29.6874 35.9003 29.6874C34.5874 29.6874 33.5081 29.3814 32.6625 28.7695C31.828 28.1575 31.3551 27.323 31.2438 26.266H33.5637C33.6861 26.6888 33.9476 27.0226 34.3482 27.2674C34.7599 27.5233 35.255 27.6512 35.8336 27.6512C36.5123 27.6512 37.0631 27.4454 37.4859 27.0337C37.9087 26.6332 38.1201 26.0212 38.1201 25.1978V23.7625C37.8197 24.1964 37.4024 24.558 36.8684 24.8473C36.3454 25.1366 35.7446 25.2813 35.0658 25.2813C34.287 25.2813 33.5749 25.081 32.9295 24.6804C32.2842 24.2799 31.7723 23.718 31.394 22.9947C31.0269 22.2604 30.8433 21.4203 30.8433 20.4745C30.8433 19.5399 31.0269 18.711 31.394 17.9877C31.7723 17.2645 32.2786 16.7082 32.9128 16.3187C33.5582 15.9293 34.2758 15.7346 35.0658 15.7346ZM38.1201 20.5079C38.1201 19.9405 38.0088 19.4565 37.7863 19.0559C37.5638 18.6442 37.2633 18.3327 36.885 18.1213C36.5067 17.8987 36.1006 17.7875 35.6667 17.7875C35.2327 17.7875 34.8322 17.8932 34.465 18.1046C34.0978 18.316 33.7974 18.6275 33.5637 19.0392C33.3412 19.4398 33.2299 19.9182 33.2299 20.4745C33.2299 21.0309 33.3412 21.5205 33.5637 21.9433C33.7974 22.355 34.0978 22.6721 34.465 22.8946C34.8433 23.1171 35.2439 23.2284 35.6667 23.2284C36.1006 23.2284 36.5067 23.1227 36.885 22.9113C37.2633 22.6888 37.5638 22.3772 37.7863 21.9766C38.0088 21.565 38.1201 21.0754 38.1201 20.5079Z" fill="#2C2B3A"/>
        <path d="M40.1518 20.4745C40.1518 19.5399 40.3354 18.711 40.7026 17.9877C41.0809 17.2645 41.5872 16.7082 42.2214 16.3187C42.8668 15.9293 43.5844 15.7346 44.3744 15.7346C45.0643 15.7346 45.6651 15.8737 46.1769 16.1518C46.6999 16.43 47.1171 16.7805 47.4287 17.2033V15.8848H49.782V25.1311H47.4287V23.7792C47.1283 24.2131 46.711 24.5747 46.1769 24.864C45.654 25.1422 45.0476 25.2813 44.3577 25.2813C43.5789 25.2813 42.8668 25.081 42.2214 24.6804C41.5872 24.2799 41.0809 23.718 40.7026 22.9947C40.3354 22.2604 40.1518 21.4203 40.1518 20.4745ZM47.4287 20.5079C47.4287 19.9405 47.3174 19.4565 47.0949 19.0559C46.8723 18.6442 46.5719 18.3327 46.1936 18.1213C45.8153 17.8987 45.4092 17.7875 44.9753 17.7875C44.5413 17.7875 44.1408 17.8932 43.7736 18.1046C43.4064 18.316 43.106 18.6275 42.8723 19.0392C42.6498 19.4398 42.5385 19.9182 42.5385 20.4745C42.5385 21.0309 42.6498 21.5205 42.8723 21.9433C43.106 22.355 43.4064 22.6721 43.7736 22.8946C44.1519 23.1171 44.5524 23.2284 44.9753 23.2284C45.4092 23.2284 45.8153 23.1227 46.1936 22.9113C46.5719 22.6888 46.8723 22.3772 47.0949 21.9766C47.3174 21.565 47.4287 21.0754 47.4287 20.5079Z" fill="#2C2B3A"/>
        <path d="M59.4364 13.4815V21.6596C59.4364 22.7945 59.1081 23.6791 58.4517 24.3133C57.7952 24.9364 56.9162 25.248 55.8146 25.248C54.702 25.248 53.8007 24.9253 53.1109 24.2799C52.4321 23.6346 52.0928 22.7333 52.0928 21.5762H54.4294C54.4405 22.0769 54.5573 22.4663 54.7799 22.7445C55.0135 23.0226 55.3473 23.1617 55.7813 23.1617C56.2041 23.1617 56.5267 23.0282 56.7493 22.7611C56.9718 22.4941 57.0831 22.1269 57.0831 21.6596V13.4815H59.4364Z" fill="#FFBD4A"/>
        <path d="M59.5584 20.4746C59.5584 19.54 59.742 18.711 60.1092 17.9878C60.4875 17.2646 60.9938 16.7082 61.628 16.3188C62.2733 15.9294 62.991 15.7347 63.781 15.7347C64.4708 15.7347 65.0717 15.8737 65.5835 16.1519C66.1065 16.4301 66.5237 16.7806 66.8352 17.2034V15.8849H69.1885V25.1311H66.8352V23.7792C66.5348 24.2132 66.1176 24.5748 65.5835 24.8641C65.0605 25.1423 64.4541 25.2813 63.7643 25.2813C62.9854 25.2813 62.2733 25.0811 61.628 24.6805C60.9938 24.2799 60.4875 23.718 60.1092 22.9948C59.742 22.2604 59.5584 21.4204 59.5584 20.4746ZM66.8352 20.508C66.8352 19.9405 66.724 19.4565 66.5014 19.056C66.2789 18.6443 65.9785 18.3327 65.6002 18.1213C65.2219 17.8988 64.8158 17.7875 64.3818 17.7875C63.9479 17.7875 63.5473 17.8932 63.1801 18.1046C62.813 18.316 62.5125 18.6276 62.2789 19.0393C62.0563 19.4398 61.9451 19.9183 61.9451 20.4746C61.9451 21.031 62.0563 21.5205 62.2789 21.9433C62.5125 22.355 62.813 22.6721 63.1801 22.8947C63.5584 23.1172 63.959 23.2285 64.3818 23.2285C64.8158 23.2285 65.2219 23.1228 65.6002 22.9114C65.9785 22.6888 66.2789 22.3773 66.5014 21.9767C66.724 21.565 66.8352 21.0755 66.8352 20.508ZM66.5181 13.4815L62.6794 15.234V13.565L66.5181 11.5789V13.4815Z" fill="#FFBD4A"/>
      </svg>
    );
  case 'md':
    return (
      <svg width="118" height="56" viewBox="0 0 118 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.9129 18.8743V30.3236C30.9129 31.9125 30.4533 33.1509 29.5343 34.0388C28.6152 34.9111 27.3846 35.3473 25.8424 35.3473C24.2847 35.3473 23.023 34.8955 22.0572 33.9921C21.1069 33.0886 20.6318 31.8268 20.6318 30.2068H23.9031C23.9187 30.9077 24.0822 31.453 24.3938 31.8424C24.7209 32.2318 25.1882 32.4265 25.7957 32.4265C26.3877 32.4265 26.8394 32.2396 27.1509 31.8658C27.4625 31.4919 27.6183 30.9778 27.6183 30.3236V18.8743H30.9129Z" fill="#2C2B3A"/>
        <path d="M37.6729 35.394C36.4268 35.394 35.3052 35.1214 34.3082 34.5762C33.3113 34.0154 32.5246 33.2288 31.9483 32.2162C31.3875 31.2037 31.1071 30.0354 31.1071 28.7113C31.1071 27.3873 31.3953 26.219 31.9716 25.2064C32.5636 24.1939 33.3658 23.4151 34.3783 22.8699C35.3909 22.3091 36.5202 22.0287 37.7664 22.0287C39.0126 22.0287 40.1419 22.3091 41.1545 22.8699C42.167 23.4151 42.9614 24.1939 43.5378 25.2064C44.1297 26.219 44.4257 27.3873 44.4257 28.7113C44.4257 30.0354 44.1219 31.2037 43.5144 32.2162C42.9225 33.2288 42.1125 34.0154 41.0844 34.5762C40.0718 35.1214 38.9347 35.394 37.6729 35.394ZM37.6729 32.5434C38.2649 32.5434 38.8179 32.4032 39.3319 32.1228C39.8615 31.8268 40.2821 31.3906 40.5937 30.8143C40.9052 30.2379 41.061 29.5369 41.061 28.7113C41.061 27.4807 40.7339 26.5383 40.0796 25.8841C39.441 25.2142 38.6543 24.8793 37.7197 24.8793C36.785 24.8793 35.9984 25.2142 35.3597 25.8841C34.7366 26.5383 34.4251 27.4807 34.4251 28.7113C34.4251 29.942 34.7288 30.8922 35.3363 31.562C35.9594 32.2162 36.7383 32.5434 37.6729 32.5434Z" fill="#2C2B3A"/>
        <path d="M49.0918 22.0287C50.0576 22.0287 50.9065 22.2234 51.6387 22.6128C52.3708 22.9867 52.9472 23.4774 53.3678 24.0849V22.239H56.6624V35.2772C56.6624 36.4766 56.4209 37.5437 55.938 38.4783C55.4551 39.4285 54.7308 40.1763 53.765 40.7215C52.7992 41.2822 51.6309 41.5626 50.2601 41.5626C48.422 41.5626 46.911 41.1343 45.7271 40.2775C44.5588 39.4208 43.8967 38.2525 43.741 36.7726H46.9888C47.1602 37.3645 47.5263 37.8319 48.087 38.1746C48.6634 38.5328 49.3566 38.712 50.1666 38.712C51.1168 38.712 51.8879 38.4238 52.4798 37.8474C53.0718 37.2867 53.3678 36.4299 53.3678 35.2772V33.2677C52.9472 33.8752 52.363 34.3815 51.6153 34.7865C50.8832 35.1915 50.042 35.394 49.0918 35.394C48.0014 35.394 47.0044 35.1136 46.1009 34.5528C45.1974 33.9921 44.4809 33.2054 43.9513 32.1929C43.4372 31.1648 43.1802 29.9887 43.1802 28.6646C43.1802 27.3561 43.4372 26.1956 43.9513 25.1831C44.4809 24.1706 45.1897 23.3917 46.0776 22.8465C46.9811 22.3013 47.9858 22.0287 49.0918 22.0287ZM53.3678 28.7113C53.3678 27.9169 53.212 27.2393 52.9004 26.6785C52.5889 26.1021 52.1683 25.666 51.6387 25.37C51.109 25.0585 50.5405 24.9027 49.933 24.9027C49.3254 24.9027 48.7647 25.0507 48.2506 25.3466C47.7366 25.6426 47.316 26.0788 46.9888 26.6551C46.6773 27.2159 46.5215 27.8857 46.5215 28.6646C46.5215 29.4435 46.6773 30.1289 46.9888 30.7208C47.316 31.2972 47.7366 31.7411 48.2506 32.0527C48.7802 32.3642 49.341 32.52 49.933 32.52C50.5405 32.52 51.109 32.372 51.6387 32.076C52.1683 31.7645 52.5889 31.3283 52.9004 30.7676C53.212 30.1912 53.3678 29.5058 53.3678 28.7113Z" fill="#2C2B3A"/>
        <path d="M56.2122 28.6646C56.2122 27.3561 56.4692 26.1956 56.9833 25.1831C57.5129 24.1706 58.2217 23.3917 59.1096 22.8465C60.0131 22.3013 61.0178 22.0287 62.1238 22.0287C63.0896 22.0287 63.9308 22.2234 64.6473 22.6128C65.3795 23.0023 65.9636 23.4929 66.3998 24.0849V22.239H69.6944V35.1837H66.3998V33.2911C65.9792 33.8986 65.395 34.4049 64.6473 34.8099C63.9152 35.1993 63.0662 35.394 62.1004 35.394C61.01 35.394 60.0131 35.1136 59.1096 34.5528C58.2217 33.9921 57.5129 33.2054 56.9833 32.1929C56.4692 31.1648 56.2122 29.9887 56.2122 28.6646ZM66.3998 28.7113C66.3998 27.9169 66.244 27.2393 65.9324 26.6785C65.6209 26.1021 65.2003 25.666 64.6707 25.37C64.1411 25.0585 63.5725 24.9027 62.965 24.9027C62.3575 24.9027 61.7967 25.0507 61.2826 25.3466C60.7686 25.6426 60.348 26.0788 60.0209 26.6551C59.7093 27.2159 59.5535 27.8857 59.5535 28.6646C59.5535 29.4435 59.7093 30.1289 60.0209 30.7208C60.348 31.2972 60.7686 31.7411 61.2826 32.0527C61.8122 32.3642 62.373 32.52 62.965 32.52C63.5725 32.52 64.1411 32.372 64.6707 32.076C65.2003 31.7645 65.6209 31.3283 65.9324 30.7676C66.244 30.1912 66.3998 29.5058 66.3998 28.7113Z" fill="#2C2B3A"/>
        <path d="M83.2097 18.8742V30.3235C83.2097 31.9124 82.7502 33.1508 81.8312 34.0387C80.9121 34.911 79.6815 35.3472 78.1393 35.3472C76.5816 35.3472 75.3198 34.8954 74.354 33.992C73.4038 33.0885 72.9287 31.8267 72.9287 30.2067H76.1999C76.2155 30.9077 76.3791 31.4529 76.6906 31.8423C77.0178 32.2317 77.4851 32.4264 78.0926 32.4264C78.6845 32.4264 79.1363 32.2395 79.4478 31.8657C79.7594 31.4918 79.9151 30.9777 79.9151 30.3235V18.8742H83.2097Z" fill="#FFBD4A"/>
        <path d="M83.3806 28.6645C83.3806 27.356 83.6376 26.1955 84.1517 25.183C84.6813 24.1705 85.3901 23.3916 86.278 22.8464C87.1815 22.3012 88.1862 22.0286 89.2922 22.0286C90.258 22.0286 91.0992 22.2233 91.8157 22.6127C92.5479 23.0022 93.132 23.4928 93.5682 24.0848V22.2389H96.8628V35.1836H93.5682V33.291C93.1476 33.8985 92.5634 34.4048 91.8157 34.8098C91.0836 35.1992 90.2346 35.3939 89.2688 35.3939C88.1784 35.3939 87.1815 35.1135 86.278 34.5527C85.3901 33.992 84.6813 33.2053 84.1517 32.1928C83.6376 31.1647 83.3806 29.9886 83.3806 28.6645ZM93.5682 28.7112C93.5682 27.9168 93.4124 27.2392 93.1009 26.6784C92.7893 26.102 92.3687 25.6659 91.8391 25.3699C91.3095 25.0584 90.7409 24.9026 90.1334 24.9026C89.5259 24.9026 88.9651 25.0506 88.451 25.3465C87.937 25.6425 87.5164 26.0787 87.1893 26.655C86.8777 27.2158 86.7219 27.8856 86.7219 28.6645C86.7219 29.4434 86.8777 30.1288 87.1893 30.7207C87.5164 31.2971 87.937 31.741 88.451 32.0526C88.9806 32.3641 89.5414 32.5199 90.1334 32.5199C90.7409 32.5199 91.3095 32.3719 91.8391 32.0759C92.3687 31.7644 92.7893 31.3282 93.1009 30.7675C93.4124 30.1911 93.5682 29.5057 93.5682 28.7112ZM93.1242 18.8742L87.75 21.3276V18.991L93.1242 16.2104V18.8742Z" fill="#FFBD4A"/>
      </svg>
    );
  case 'lg':
    return (
      <svg width="169" height="80" viewBox="0 0 169 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M44.1608 26.9634V43.3196C44.1608 45.5894 43.5043 47.3585 42.1914 48.627C40.8785 49.8732 39.1204 50.4963 36.9174 50.4963C34.692 50.4963 32.8895 49.8509 31.5098 48.5602C30.1524 47.2695 29.4736 45.467 29.4736 43.1527H34.1468C34.1691 44.1541 34.4027 44.9329 34.8478 45.4893C35.3151 46.0456 35.9827 46.3238 36.8506 46.3238C37.6962 46.3238 38.3416 46.0567 38.7866 45.5226C39.2317 44.9886 39.4542 44.2542 39.4542 43.3196V26.9634H44.1608Z" fill="#2C2B3A"/>
        <path d="M53.8181 50.563C52.0378 50.563 50.4356 50.1736 49.0113 49.3947C47.5871 48.5936 46.4633 47.4698 45.64 46.0233C44.8388 44.5769 44.4383 42.9079 44.4383 41.0163C44.4383 39.1248 44.85 37.4558 45.6733 36.0094C46.519 34.5629 47.665 33.4502 49.1115 32.6714C50.5579 31.8702 52.1713 31.4697 53.9516 31.4697C55.7318 31.4697 57.3452 31.8702 58.7917 32.6714C60.2381 33.4502 61.3731 34.5629 62.1964 36.0094C63.0421 37.4558 63.4649 39.1248 63.4649 41.0163C63.4649 42.9079 63.0309 44.5769 62.1631 46.0233C61.3174 47.4698 60.1603 48.5936 58.6915 49.3947C57.2451 50.1736 55.6206 50.563 53.8181 50.563ZM53.8181 46.4907C54.6637 46.4907 55.4537 46.2904 56.188 45.8898C56.9446 45.467 57.5455 44.8439 57.9906 44.0205C58.4356 43.1972 58.6582 42.1958 58.6582 41.0163C58.6582 39.2583 58.1908 37.912 57.2562 36.9774C56.3438 36.0205 55.22 35.542 53.8848 35.542C52.5496 35.542 51.4258 36.0205 50.5134 36.9774C49.6233 37.912 49.1782 39.2583 49.1782 41.0163C49.1782 42.7744 49.6122 44.1318 50.4801 45.0887C51.3702 46.0233 52.4829 46.4907 53.8181 46.4907Z" fill="#2C2B3A"/>
        <path d="M70.1307 31.4697C71.5104 31.4697 72.7232 31.7478 73.7691 32.3042C74.815 32.8383 75.6384 33.5392 76.2392 34.4071V31.7701H80.9458V50.3961C80.9458 52.1096 80.6009 53.634 79.911 54.9692C79.2212 56.3266 78.1864 57.3948 76.8067 58.1737C75.427 58.9748 73.758 59.3753 71.7997 59.3753C69.1738 59.3753 67.0152 58.7634 65.324 57.5394C63.655 56.3155 62.7092 54.6465 62.4867 52.5324H67.1265C67.3713 53.3781 67.8942 54.0457 68.6954 54.5352C69.5187 55.0471 70.509 55.303 71.6662 55.303C73.0236 55.303 74.1252 54.8913 74.9708 54.0679C75.8164 53.2668 76.2392 52.0429 76.2392 50.3961V47.5254C75.6384 48.3933 74.8039 49.1166 73.7357 49.6951C72.6898 50.2737 71.4881 50.563 70.1307 50.563C68.573 50.563 67.1487 50.1625 65.8581 49.3613C64.5674 48.5602 63.5437 47.4364 62.7871 45.99C62.0527 44.5212 61.6856 42.8411 61.6856 40.9496C61.6856 39.0803 62.0527 37.4224 62.7871 35.976C63.5437 34.5295 64.5562 33.4168 65.8247 32.638C67.1154 31.8591 68.5507 31.4697 70.1307 31.4697ZM76.2392 41.0163C76.2392 39.8814 76.0167 38.9134 75.5716 38.1123C75.1266 37.2889 74.5257 36.6658 73.7691 36.243C73.0125 35.7979 72.2003 35.5754 71.3324 35.5754C70.4645 35.5754 69.6634 35.7868 68.929 36.2096C68.1947 36.6324 67.5938 37.2555 67.1265 38.0789C66.6814 38.88 66.4589 39.8369 66.4589 40.9496C66.4589 42.0623 66.6814 43.0414 67.1265 43.887C67.5938 44.7104 68.1947 45.3446 68.929 45.7897C69.6856 46.2348 70.4867 46.4573 71.3324 46.4573C72.2003 46.4573 73.0125 46.2459 73.7691 45.8231C74.5257 45.378 75.1266 44.7549 75.5716 43.9538C76.0167 43.1304 76.2392 42.1513 76.2392 41.0163Z" fill="#2C2B3A"/>
        <path d="M80.3027 40.9496C80.3027 39.0803 80.6699 37.4224 81.4043 35.976C82.1609 34.5295 83.1734 33.4168 84.4418 32.638C85.7325 31.8591 87.1679 31.4697 88.7479 31.4697C90.1276 31.4697 91.3292 31.7478 92.3529 32.3042C93.3988 32.8605 94.2333 33.5615 94.8564 34.4071V31.7701H99.563V50.2626H94.8564V47.5588C94.2555 48.4267 93.421 49.1499 92.3529 49.7285C91.307 50.2849 90.0942 50.563 88.7145 50.563C87.1567 50.563 85.7325 50.1625 84.4418 49.3613C83.1734 48.5602 82.1609 47.4364 81.4043 45.99C80.6699 44.5212 80.3027 42.8411 80.3027 40.9496ZM94.8564 41.0163C94.8564 39.8814 94.6339 38.9134 94.1888 38.1123C93.7437 37.2889 93.1429 36.6658 92.3863 36.243C91.6297 35.7979 90.8174 35.5754 89.9495 35.5754C89.0816 35.5754 88.2805 35.7868 87.5462 36.2096C86.8118 36.6324 86.211 37.2555 85.7437 38.0789C85.2986 38.88 85.0761 39.8369 85.0761 40.9496C85.0761 42.0623 85.2986 43.0414 85.7437 43.887C86.211 44.7104 86.8118 45.3446 87.5462 45.7897C88.3028 46.2348 89.1039 46.4573 89.9495 46.4573C90.8174 46.4573 91.6297 46.2459 92.3863 45.8231C93.1429 45.378 93.7437 44.7549 94.1888 43.9538C94.6339 43.1304 94.8564 42.1513 94.8564 41.0163Z" fill="#2C2B3A"/>
        <path d="M118.871 26.9633V43.3195C118.871 45.5893 118.214 47.3584 116.901 48.6269C115.588 49.8731 113.83 50.4962 111.627 50.4962C109.402 50.4962 107.599 49.8508 106.22 48.5601C104.862 47.2694 104.184 45.4669 104.184 43.1526H108.857C108.879 44.154 109.113 44.9328 109.558 45.4892C110.025 46.0455 110.693 46.3237 111.561 46.3237C112.406 46.3237 113.052 46.0566 113.497 45.5225C113.942 44.9885 114.164 44.2541 114.164 43.3195V26.9633H118.871Z" fill="#FFBD4A"/>
        <path d="M119.115 40.9495C119.115 39.0802 119.482 37.4223 120.216 35.9759C120.973 34.5294 121.986 33.4167 123.254 32.6379C124.545 31.859 125.98 31.4696 127.56 31.4696C128.94 31.4696 130.141 31.7477 131.165 32.3041C132.211 32.8604 133.045 33.5614 133.669 34.407V31.77H138.375V50.2625H133.669V47.5587C133.068 48.4266 132.233 49.1498 131.165 49.7284C130.119 50.2848 128.906 50.5629 127.527 50.5629C125.969 50.5629 124.545 50.1624 123.254 49.3612C121.986 48.5601 120.973 47.4363 120.216 45.9899C119.482 44.5211 119.115 42.841 119.115 40.9495ZM133.669 41.0162C133.669 39.8813 133.446 38.9133 133.001 38.1122C132.556 37.2888 131.955 36.6657 131.198 36.2429C130.442 35.7978 129.63 35.5753 128.762 35.5753C127.894 35.5753 127.093 35.7867 126.358 36.2095C125.624 36.6323 125.023 37.2554 124.556 38.0788C124.111 38.8799 123.888 39.8368 123.888 40.9495C123.888 42.0621 124.111 43.0413 124.556 43.8869C125.023 44.7103 125.624 45.3445 126.358 45.7896C127.115 46.2346 127.916 46.4572 128.762 46.4572C129.63 46.4572 130.442 46.2458 131.198 45.823C131.955 45.3779 132.556 44.7548 133.001 43.9537C133.446 43.1303 133.669 42.1512 133.669 41.0162ZM133.034 26.9633L125.357 30.4682V27.1302L133.034 23.158V26.9633Z" fill="#FFBD4A"/>
      </svg>
    );
  case 'xl':
    return (
      <svg width="270" height="128" viewBox="0 0 270 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M70.6577 43.1411V69.311C70.6577 72.9428 69.6073 75.7734 67.5066 77.8029C65.4059 79.7968 62.5931 80.7937 59.0682 80.7937C55.5076 80.7937 52.6236 79.7612 50.4161 77.6961C48.2442 75.631 47.1582 72.7469 47.1582 69.044H54.6353C54.6709 70.6462 55.0448 71.8924 55.7569 72.7825C56.5046 73.6727 57.5728 74.1177 58.9614 74.1177C60.3144 74.1177 61.3469 73.6905 62.059 72.8359C62.7711 71.9814 63.1272 70.8064 63.1272 69.311V43.1411H70.6577Z" fill="#2C2B3A"/>
        <path d="M86.1093 80.9005C83.2609 80.9005 80.6973 80.2774 78.4185 79.0313C76.1398 77.7495 74.3417 75.9514 73.0243 73.6371C71.7426 71.3227 71.1017 68.6523 71.1017 65.6259C71.1017 62.5994 71.7604 59.929 73.0777 57.6147C74.4307 55.3003 76.2644 53.5201 78.5788 52.2739C80.8931 50.9921 83.4745 50.3512 86.3229 50.3512C89.1713 50.3512 91.7527 50.9921 94.0671 52.2739C96.3814 53.5201 98.1973 55.3003 99.5147 57.6147C100.868 59.929 101.544 62.5994 101.544 65.6259C101.544 68.6523 100.85 71.3227 99.4613 73.6371C98.1083 75.9514 96.2568 77.7495 93.9069 79.0313C91.5925 80.2774 88.9933 80.9005 86.1093 80.9005ZM86.1093 74.3848C87.4623 74.3848 88.7263 74.0643 89.9013 73.4234C91.1118 72.7469 92.0732 71.75 92.7853 70.4326C93.4974 69.1152 93.8534 67.5129 93.8534 65.6259C93.8534 62.813 93.1057 60.6589 91.6103 59.1635C90.1505 57.6325 88.3524 56.867 86.2161 56.867C84.0798 56.867 82.2817 57.6325 80.8219 59.1635C79.3977 60.6589 78.6856 62.813 78.6856 65.6259C78.6856 68.4387 79.3799 70.6106 80.7685 72.1416C82.1927 73.6371 83.973 74.3848 86.1093 74.3848Z" fill="#2C2B3A"/>
        <path d="M112.21 50.3512C114.417 50.3512 116.358 50.7963 118.031 51.6864C119.704 52.5409 121.022 53.6625 121.983 55.0511V50.8319H129.514V80.6335C129.514 83.3751 128.962 85.8141 127.858 87.9504C126.754 90.1223 125.099 91.8314 122.891 93.0775C120.684 94.3593 118.013 95.0002 114.88 95.0002C110.678 95.0002 107.225 94.0211 104.519 92.0628C101.848 90.1045 100.335 87.4341 99.9791 84.0516H107.403C107.794 85.4046 108.631 86.4728 109.913 87.2561C111.23 88.075 112.815 88.4845 114.666 88.4845C116.838 88.4845 118.601 87.8258 119.954 86.5084C121.307 85.2266 121.983 83.2683 121.983 80.6335V76.0404C121.022 77.429 119.687 78.5862 117.978 79.5119C116.304 80.4377 114.381 80.9005 112.21 80.9005C109.717 80.9005 107.438 80.2596 105.373 78.9778C103.308 77.6961 101.67 75.898 100.46 73.5837C99.2848 71.2337 98.6973 68.5455 98.6973 65.519C98.6973 62.5282 99.2848 59.8756 100.46 57.5613C101.67 55.2469 103.29 53.4667 105.32 52.2205C107.385 50.9743 109.682 50.3512 112.21 50.3512ZM121.983 65.6259C121.983 63.81 121.627 62.2612 120.915 60.9794C120.203 59.662 119.242 58.665 118.031 57.9885C116.82 57.2764 115.521 56.9204 114.132 56.9204C112.744 56.9204 111.462 57.2586 110.287 57.9351C109.112 58.6116 108.15 59.6086 107.403 60.926C106.691 62.2078 106.335 63.7388 106.335 65.519C106.335 67.2993 106.691 68.8659 107.403 70.2189C108.15 71.5363 109.112 72.5511 110.287 73.2632C111.497 73.9753 112.779 74.3314 114.132 74.3314C115.521 74.3314 116.82 73.9931 118.031 73.3166C119.242 72.6045 120.203 71.6076 120.915 70.3258C121.627 69.0084 121.983 67.4417 121.983 65.6259Z" fill="#2C2B3A"/>
        <path d="M128.485 65.519C128.485 62.5282 129.072 59.8756 130.247 57.5613C131.458 55.2469 133.078 53.4667 135.107 52.2205C137.172 50.9743 139.469 50.3512 141.997 50.3512C144.204 50.3512 146.127 50.7963 147.765 51.6864C149.438 52.5765 150.774 53.6981 151.771 55.0511V50.8319H159.301V80.4199H151.771V76.0938C150.809 77.4824 149.474 78.6396 147.765 79.5653C146.092 80.4555 144.151 80.9005 141.944 80.9005C139.451 80.9005 137.172 80.2596 135.107 78.9778C133.078 77.6961 131.458 75.898 130.247 73.5837C129.072 71.2337 128.485 68.5455 128.485 65.519ZM151.771 65.6259C151.771 63.81 151.415 62.2612 150.702 60.9794C149.99 59.662 149.029 58.665 147.818 57.9885C146.608 57.2764 145.308 56.9204 143.92 56.9204C142.531 56.9204 141.249 57.2586 140.074 57.9351C138.899 58.6116 137.938 59.6086 137.19 60.926C136.478 62.2078 136.122 63.7388 136.122 65.519C136.122 67.2993 136.478 68.8659 137.19 70.2189C137.938 71.5363 138.899 72.5511 140.074 73.2632C141.285 73.9753 142.567 74.3314 143.92 74.3314C145.308 74.3314 146.608 73.9931 147.818 73.3166C149.029 72.6045 149.99 71.6076 150.702 70.3258C151.415 69.0084 151.771 67.4417 151.771 65.6259Z" fill="#2C2B3A"/>
        <path d="M190.194 43.1411V69.311C190.194 72.9428 189.143 75.7734 187.043 77.8029C184.942 79.7968 182.129 80.7937 178.604 80.7937C175.044 80.7937 172.16 79.7612 169.952 77.6961C167.78 75.631 166.694 72.7469 166.694 69.044H174.171C174.207 70.6462 174.581 71.8924 175.293 72.7825C176.041 73.6727 177.109 74.1177 178.497 74.1177C179.85 74.1177 180.883 73.6905 181.595 72.8359C182.307 71.9814 182.663 70.8064 182.663 69.311V43.1411H190.194Z" fill="#FFBD4A"/>
        <path d="M190.584 65.5191C190.584 62.5282 191.172 59.8756 192.347 57.5613C193.557 55.2469 195.177 53.4667 197.207 52.2205C199.272 50.9743 201.569 50.3512 204.097 50.3512C206.304 50.3512 208.227 50.7963 209.865 51.6864C211.538 52.5765 212.873 53.6981 213.87 55.0511V50.8319H221.401V80.4199H213.87V76.0938C212.909 77.4824 211.574 78.6396 209.865 79.5653C208.191 80.4555 206.251 80.9005 204.043 80.9005C201.551 80.9005 199.272 80.2596 197.207 78.9779C195.177 77.6961 193.557 75.898 192.347 73.5837C191.172 71.2337 190.584 68.5455 190.584 65.5191ZM213.87 65.6259C213.87 63.81 213.514 62.2612 212.802 60.9794C212.09 59.662 211.129 58.665 209.918 57.9885C208.707 57.2764 207.408 56.9204 206.019 56.9204C204.631 56.9204 203.349 57.2586 202.174 57.9351C200.999 58.6116 200.038 59.6086 199.29 60.926C198.578 62.2078 198.222 63.7388 198.222 65.5191C198.222 67.2993 198.578 68.8659 199.29 70.219C200.038 71.5363 200.999 72.5511 202.174 73.2632C203.384 73.9753 204.666 74.3314 206.019 74.3314C207.408 74.3314 208.707 73.9931 209.918 73.3166C211.129 72.6045 212.09 71.6076 212.802 70.3258C213.514 69.0084 213.87 67.4417 213.87 65.6259ZM212.855 43.1411L200.572 48.749V43.4082L212.855 37.0526V43.1411Z" fill="#FFBD4A"/>
      </svg>
    );
  case 'xxl':
    return (
      <svg width="539" height="256" viewBox="0 0 539 256" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M141.314 86.2822V138.622C141.314 145.886 139.214 151.547 135.012 155.606C130.811 159.594 125.185 161.587 118.135 161.587C111.014 161.587 105.246 159.522 100.831 155.392C96.4874 151.262 94.3154 145.494 94.3154 138.088H109.27C109.341 141.292 110.089 143.785 111.513 145.565C113.008 147.345 115.145 148.235 117.922 148.235C120.628 148.235 122.693 147.381 124.117 145.672C125.541 143.963 126.253 141.613 126.253 138.622V86.2822H141.314Z" fill="#2C2B3A"/>
        <path d="M172.218 161.801C166.521 161.801 161.394 160.555 156.836 158.063C152.279 155.499 148.682 151.903 146.048 147.274C143.484 142.645 142.202 137.305 142.202 131.252C142.202 125.199 143.52 119.858 146.155 115.229C148.861 110.601 152.528 107.04 157.157 104.548C161.785 101.984 166.948 100.702 172.645 100.702C178.342 100.702 183.504 101.984 188.133 104.548C192.762 107.04 196.394 110.601 199.028 115.229C201.734 119.858 203.087 125.199 203.087 131.252C203.087 137.305 201.699 142.645 198.922 147.274C196.216 151.903 192.513 155.499 187.813 158.063C183.184 160.555 177.986 161.801 172.218 161.801ZM172.218 148.77C174.924 148.77 177.452 148.129 179.802 146.847C182.223 145.494 184.145 143.5 185.57 140.865C186.994 138.23 187.706 135.026 187.706 131.252C187.706 125.626 186.21 121.318 183.22 118.327C180.3 115.265 176.704 113.734 172.431 113.734C168.159 113.734 164.562 115.265 161.643 118.327C158.794 121.318 157.37 125.626 157.37 131.252C157.37 136.877 158.759 141.221 161.536 144.283C164.384 147.274 167.945 148.77 172.218 148.77Z" fill="#2C2B3A"/>
        <path d="M224.418 100.702C228.833 100.702 232.714 101.593 236.061 103.373C239.408 105.082 242.043 107.325 243.965 110.102V101.664H259.026V161.267C259.026 166.75 257.923 171.628 255.715 175.901C253.508 180.245 250.196 183.663 245.781 186.155C241.366 188.719 236.025 190 229.759 190C221.356 190 214.449 188.042 209.037 184.126C203.696 180.209 200.669 174.868 199.957 168.103H214.805C215.588 170.809 217.261 172.946 219.825 174.512C222.46 176.15 225.629 176.969 229.332 176.969C233.675 176.969 237.2 175.652 239.906 173.017C242.612 170.453 243.965 166.537 243.965 161.267V152.081C242.043 154.858 239.372 157.172 235.954 159.024C232.607 160.875 228.762 161.801 224.418 161.801C219.433 161.801 214.876 160.519 210.746 157.956C206.615 155.392 203.34 151.796 200.919 147.167C198.569 142.467 197.394 137.091 197.394 131.038C197.394 125.056 198.569 119.751 200.919 115.123C203.34 110.494 206.58 106.933 210.639 104.441C214.769 101.949 219.362 100.702 224.418 100.702ZM243.965 131.252C243.965 127.62 243.253 124.522 241.829 121.959C240.405 119.324 238.482 117.33 236.061 115.977C233.64 114.553 231.041 113.841 228.263 113.841C225.486 113.841 222.923 114.517 220.573 115.87C218.223 117.223 216.3 119.217 214.805 121.852C213.38 124.416 212.668 127.478 212.668 131.038C212.668 134.599 213.38 137.732 214.805 140.438C216.3 143.073 218.223 145.102 220.573 146.526C222.994 147.951 225.557 148.663 228.263 148.663C231.041 148.663 233.64 147.986 236.061 146.633C238.482 145.209 240.405 143.215 241.829 140.652C243.253 138.017 243.965 134.883 243.965 131.252Z" fill="#2C2B3A"/>
        <path d="M256.969 131.038C256.969 125.056 258.143 119.751 260.493 115.123C262.915 110.494 266.155 106.933 270.214 104.441C274.344 101.949 278.937 100.702 283.993 100.702C288.408 100.702 292.253 101.593 295.529 103.373C298.876 105.153 301.546 107.396 303.54 110.102V101.664H318.601V160.84H303.54V152.188C301.618 154.965 298.947 157.279 295.529 159.131C292.182 160.911 288.301 161.801 283.886 161.801C278.901 161.801 274.344 160.519 270.214 157.956C266.155 155.392 262.915 151.796 260.493 147.167C258.143 142.467 256.969 137.091 256.969 131.038ZM303.54 131.252C303.54 127.62 302.828 124.522 301.404 121.959C299.98 119.324 298.057 117.33 295.636 115.977C293.215 114.553 290.616 113.841 287.838 113.841C285.061 113.841 282.498 114.517 280.148 115.87C277.798 117.223 275.875 119.217 274.38 121.852C272.955 124.416 272.243 127.478 272.243 131.038C272.243 134.599 272.955 137.732 274.38 140.438C275.875 143.073 277.798 145.102 280.148 146.526C282.569 147.951 285.132 148.663 287.838 148.663C290.616 148.663 293.215 147.986 295.636 146.633C298.057 145.209 299.98 143.215 301.404 140.652C302.828 138.017 303.54 134.883 303.54 131.252Z" fill="#2C2B3A"/>
        <path d="M380.387 86.2822V138.622C380.387 145.886 378.286 151.547 374.085 155.606C369.883 159.594 364.257 161.587 357.208 161.587C350.087 161.587 344.319 159.522 339.903 155.392C335.56 151.262 333.388 145.494 333.388 138.088H348.342C348.413 141.292 349.161 143.785 350.585 145.565C352.08 147.345 354.217 148.235 356.994 148.235C359.7 148.235 361.765 147.381 363.189 145.672C364.614 143.963 365.326 141.613 365.326 138.622V86.2822H380.387Z" fill="#FFBD4A"/>
        <path d="M381.168 131.038C381.168 125.056 382.343 119.751 384.693 115.123C387.114 110.494 390.354 106.933 394.413 104.441C398.543 101.949 403.136 100.702 408.192 100.702C412.607 100.702 416.453 101.593 419.728 103.373C423.075 105.153 425.746 107.396 427.74 110.102V101.664H442.801V160.84H427.74V152.188C425.817 154.965 423.146 157.279 419.728 159.131C416.381 160.911 412.5 161.801 408.085 161.801C403.101 161.801 398.543 160.519 394.413 157.956C390.354 155.392 387.114 151.796 384.693 147.167C382.343 142.467 381.168 137.091 381.168 131.038ZM427.74 131.252C427.74 127.62 427.027 124.522 425.603 121.959C424.179 119.324 422.256 117.33 419.835 115.977C417.414 114.553 414.815 113.841 412.038 113.841C409.26 113.841 406.697 114.517 404.347 115.87C401.997 117.223 400.074 119.217 398.579 121.852C397.155 124.416 396.442 127.478 396.442 131.038C396.442 134.599 397.155 137.732 398.579 140.438C400.074 143.073 401.997 145.102 404.347 146.526C406.768 147.951 409.332 148.663 412.038 148.663C414.815 148.663 417.414 147.986 419.835 146.633C422.256 145.209 424.179 143.215 425.603 140.652C427.027 138.017 427.74 134.883 427.74 131.252ZM425.71 86.2822L401.142 97.4979V86.8163L425.71 74.1052V86.2822Z" fill="#FFBD4A"/>
      </svg>
    );
  }
};

export default Logo;

import React, { useContext, useEffect, useState } from 'react';
import { GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { SportCenterHomeContext } from '../../Home';
import CardCourtData from '../../../../../common/component/CardCourtData/CardCourtData';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import { isDefined } from '../../../../../common/util/validations';

const HorizontalCourts = () => {
  const { sportCenter } = useContext(SportCenterHomeContext);
  const navigate = useNavigate();

  return (
    <>
      <GridItem xs={6}>
        <Typography variant={'small'} bold>Horários por quadra hoje</Typography>
      </GridItem>
      <GridItem xs={6} style={{ height: '106px' }}>
        <Carousel height={'106px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
          {sportCenter && sportCenter?.courts && sportCenter?.courts?.map((court) => {
            let countAvailable = 0;
            let countReserved = 0;
            court.viewSchedules?.forEach((view) => {
              if (view.status === 'reserved') {
                countReserved++;
              } else {
                countAvailable++;
              }
            });
            return <CardCourtData onClick={() => navigate(RoutePath.SPORT_CENTER_COURT + '?id=' + court.id)} key={court.name} court={court.name} available={countAvailable} reserved={countReserved}/>;
          })}
        </Carousel>
      </GridItem>
    </>
  );
};

export default HorizontalCourts;

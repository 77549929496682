import React, { useEffect, useState } from 'react';
import { DialogProps } from './interface';
import { ButtonContainer, DialogContainer, DialogPage } from './styled';
import { Grid, GridItem } from '../Grid/Grid';
import Typography from '../Typography/Typography';
import Close from '../Icons/Close';
import Button from '../Button/Button';
import useScreen from '../../hook/useScreen';

const Dialog = (Props: DialogProps) => {
  const { open, title, children, setOpen, buttonLabelConfirm, buttonLabelCancel, isObligatory, onConfirm, onCancel } = Props;
  const [vibrate, setVibrate] = useState(false);
  const { height } = useScreen();

  const onConfirmClick = () => {
    onConfirm();
    setOpen(false);
  };

  const onCancelClick = () => {
    onCancel();
    setOpen(false);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement>) => {
    if (event.target !== event.currentTarget) {
      return;
    }

    if (!isObligatory) {
      onCancelClick();
      return;
    }

    setVibrate(true);
    setTimeout(() => {
      setVibrate(false);
    }, 450);
  };


  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [open]);

  return (
    <DialogPage style={{ height: height+'px' }} open={open} onClick={(event) => handleClose(event)}>
      <DialogContainer bottom={0} className={vibrate ? 'vibrate' : ''}>
        <Grid noMargin height={'auto'}>
          <GridItem xs={5}><Typography variant={'h3'}>{title}</Typography></GridItem>
          <GridItem xs={1} alignItems={'flex-end'}><ButtonContainer onClick={() => onCancelClick()}><Close/></ButtonContainer></GridItem>
          <GridItem xs={6} marginXs={'16px 0 24px 0'}>{children}</GridItem>
          <GridItem xs={3} alignItems={'flex-end'}><Button color={'error'} onClick={() => onCancelClick()} variant={'transparent'} title={buttonLabelCancel}/></GridItem>
          <GridItem xs={3}><Button color={'primary'} onClick={() => onConfirmClick()} variant={'filled'} title={buttonLabelConfirm}/></GridItem>
        </Grid>
      </DialogContainer>
    </DialogPage>
  );
};

export default Dialog;

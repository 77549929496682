import { isDefined } from './validations';

export const setToClientCopy = (
  text: string
) => {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
};

export async function delay(ms = 100) {
  await new Promise((resolve) => setTimeout(resolve, ms));
}

export function propsFilter(obj: any, key: string | undefined, value: any) {
  let result: any = {};
  for (const chave in obj) {
    if (isDefined(key)) {
      if (Array.isArray(obj[chave])) {
        for (const objTemp of obj[chave]) {
          if (objTemp[key!] === value) {
            result = { ...result, [chave]: obj[chave] };
          }
        }
      } else {
        if (obj[chave][key!] === value) {
          result = { ...result, [chave]: obj[chave] };
        }
      }
    } else {
      if (obj[chave] === value) {
        result = { ...result, [chave]: obj[chave] };
      }
    }
  }
  return result;
}

export function propsFilterKey(obj: any, keys: string[]) {
  const result: any = {};
  for (const key of keys) {
    if (obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
  }
  return result;
}


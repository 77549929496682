import React from 'react';

const Stock = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.3572 3.73814V1.65283" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.3674 9.30971C18.3674 10.3603 19.2111 10.7105 20.3572 10.7105C21.5033 10.7105 22.347 10.7105 22.347 9.30971C22.347 7.2244 18.3674 7.2244 18.3674 5.1391C18.3674 3.73828 19.2111 3.73828 20.3572 3.73828C21.5033 3.73828 22.347 4.26359 22.347 5.1391" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.3572 10.7104V12.7958" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.83675 6.42822H3.24491C2.36577 6.42822 1.65308 7.14092 1.65308 8.02006V15.1833C1.65308 16.0625 2.36577 16.7752 3.24491 16.7752H4.83675C5.71589 16.7752 6.42859 16.0625 6.42859 15.1833V8.02006C6.42859 7.14092 5.71589 6.42822 4.83675 6.42822Z" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.04077 16.7754V19.1631" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.04077 6.42834V1.65283" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.5919 12.7959H12C11.1209 12.7959 10.4082 13.5086 10.4082 14.3877V16.7755C10.4082 17.6547 11.1209 18.3673 12 18.3673H13.5919C14.471 18.3673 15.1837 17.6547 15.1837 16.7755V14.3877C15.1837 13.5086 14.471 12.7959 13.5919 12.7959Z" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.7959 18.3672V22.3468" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.7959 12.7955V7.22412" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Stock;

import React from 'react';

const MoneyGraph = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M1.35718 0.856934V23.1426H23.6429" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M6.5 11.1426L10.7857 15.4283L17.6429 5.14258L23.6429 9.42829" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default MoneyGraph;

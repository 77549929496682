import React, { useEffect, useRef, useState } from 'react';
import {
  Arrow,
  Container,
  InputContainer,
  InputStyle,
  LabelStyle,
  Required,
  Tooltip,
  TooltipContainer,
  WrapperIcon
} from './styled';
import { InputProps } from './interface';
import { ValueWithoutBoolean } from '../hooks/UseForm/interface';
import { isDefined } from '../../util/validations';

const Input = (Props: InputProps) => {
  const { register, name, tooltip, label, disabled, isFullWidth, icon, type, disableIconColor, required = false } = Props;
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  useEffect(() => {
    register?.registerValue(register, name, required);
  }, []);

  return (
    <InputContainer isFullWidth={isFullWidth}>
      <LabelStyle disabled={disabled} htmlFor={name}>{label} {required && <Required disabled={disabled}>*</Required>}</LabelStyle>
      <Container isFullWidth={isFullWidth} hasIcon={!!icon}>
        {isDefined(tooltip) && isFocused && <TooltipContainer>
          <Tooltip>
            {tooltip}
          </Tooltip>
          <Arrow/>
        </TooltipContainer>}
        <InputStyle
          onChange={(event) =>
            register?.setValue(event?.target?.value, name)
          }
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={register?.getValue(name) as ValueWithoutBoolean}
          {...Props}
          name={name}
          id={name}
          type={type}
          error={register?.getError(name)}
        />
        {!!icon && !disabled && <WrapperIcon error={register?.getError(name)} disableIconColor={disableIconColor} htmlFor={name}>{icon}</WrapperIcon>}
      </Container>
    </InputContainer>
  );
};

export default Input;

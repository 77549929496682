import { isDefined, isNotEmpty } from './validations';

export const formatCnpjCpf = (type: 'CNPJ' | 'CPF', text: string): string => {
  if (type === 'CNPJ') {
    // Formata CNPJ (99.999.999/9999-99)
    if (text.length > 14) {
      text = text.slice(0, 14);
    }
    if (text.length <= 2) {
      return text;
    } else if (text.length <= 5) {
      return `${text.slice(0, 2)}.${text.slice(2)}`;
    } else if (text.length <= 8) {
      return `${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5)}`;
    } else if (text.length <= 12) {
      return `${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5, 8)}/${text.slice(8)}`;
    } else {
      return `${text.slice(0, 2)}.${text.slice(2, 5)}.${text.slice(5, 8)}/${text.slice(8, 12)}-${text.slice(12)}`;
    }
  } else if (type === 'CPF') {
    // Formata CPF (999.999.999-99)
    if (text.length > 11) {
      text = text.slice(0, 11);
    }
    if (text.length <= 3) {
      return text;
    } else if (text.length <= 6) {
      return `${text.slice(0, 3)}.${text.slice(3)}`;
    } else if (text.length <= 9) {
      return `${text.slice(0, 3)}.${text.slice(3, 6)}.${text.slice(6)}`;
    } else {
      return `${text.slice(0, 3)}.${text.slice(3, 6)}.${text.slice(6, 9)}-${text.slice(9)}`;
    }
  } else {
    // Tipo inválido, retorna o texto original
    return text;
  }
};

export const formatarToReal = (valor?: number | string): string => {
  // Arredonda o valor para 2 casas decimais
  const valorArredondado = Math.round(Number(valor) * 100) / 100;

  // Separa a parte inteira e a parte decimal
  const partes = valorArredondado.toFixed(2).split('.');
  const parteInteira = partes[0];
  const parteDecimal = partes[1];

  // Formata a parte inteira com o separador de milhares
  const parteInteiraFormatada = parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

  // Retorna a string formatada
  return `${parteInteiraFormatada},${parteDecimal}`;
};

export const formatPhone = (phone: string) => {
  if (!isDefined(phone)) return phone;
  const digitsOnly = phone.replace(/\D/g, '');
  const formattedPhone = digitsOnly.replace(/(\d{2})(\d{1,5})(\d{1,4})/, '($1) $2-$3');
  return formattedPhone.slice(0, 15);
};

export function formatNumberToReal(input: string): string {
  // Remove todos os caracteres não numéricos
  const numericValue = input.toString().replace(/[^\d]/g, '');
  // Formata o valor numérico com a máscara desejada
  const formattedValue = parseFloat(numericValue) / 100;
  const formattedString = formattedValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });

  return formattedString;
}

export function formatCEP(cep: string): string {
  const cepRegex = /^(\d{5})(\d{3})$/;
  const formattedCEP = cep.replace(cepRegex, '$1-$2');
  return formattedCEP.slice(0, 9);
}

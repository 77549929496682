import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Header from '../../../common/component/Header/Header';
import Greeting from './components/Greeting/Greeting';
import CentersTime from './components/CentersTime/CentersTime';
import HorizontalCourts from './components/HorizontalCourts/HorizontalCourts';
import ManagementMyCenter from './components/ManagementMyCenter/ManagementMyCenter';
import Revenue from './components/Revenue/Revenue';
import { Filter, SportCenterHomeContextType } from './interface';
import Dependencies from './components/Dependencies/Dependencies';
import SportCenterService from '../../../common/services/sportCenter';
import { isDefined } from '../../../common/util/validations';
import { SportCenter } from '../../../common/model/SportCenter';
import UserService from '../../../common/services/user';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import MenuSportCenter from '../../../common/component/MenuSportCenter/MenuSportCenter';
import ScheduleService from '../../../common/services/schedule';

export const SportCenterHomeContext = createContext<SportCenterHomeContextType>({
  sportCenter: {} as SportCenter,
  filter: { status: 'available' },
  setFilter: (filter: Filter) => {},
  getSportCenter: () => new Promise(() => {})
});

const Home = () => {
  const [filter, setFilter] = useState<Filter>({ status: 'reserved' });
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const userId = async () => (await UserService.getCurrent())?.id ?? '';
  const getSportCenter = async () => {
    const sportCenter = await SportCenterService.getByUserId(await userId());
    sportCenter.courts = await SportCenterService.getCourts(sportCenter.id!);
    if (isDefined(sportCenter)) {
      const updatedCourts = [];
      for (const court of sportCenter.courts!) {
        const updatedCourt = {
          ...court,
          viewSchedules: await ScheduleService.getViewSchedules(court.id!)
        };
        updatedCourts.push(updatedCourt);
      }
      sportCenter.courts = updatedCourts;
    }
    if (isDefined(sportCenter?.workTime)) {
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);

  const context = useMemo(() => ({
    filter,
    setFilter,
    sportCenter,
    getSportCenter
  }), [filter, sportCenter]);

  return (
    <RestrictArea role={'sportCenter'}>
      <SportCenterHomeContext.Provider value={context}>
        <Grid noMargin color={'neutral'} colorVariant={'a100'} alignContent={'start'} isScrollable height={'calc(100% - 86px)'}>
          <GridItem xs={6} style={{ marginTop: '16px', marginBottom: '16px' }}>
            <Grid rowGapXs={16} alignItems={'center'}>
              <Greeting/>
              <Dependencies/>
              <CentersTime/>
              <HorizontalCourts/>
              <ManagementMyCenter/>
              {/* <Revenue/> */}
            </Grid>
          </GridItem>
        </Grid>
        <MenuSportCenter/>
      </SportCenterHomeContext.Provider>
    </RestrictArea>
  );
};

export default Home;

import React from 'react';
import { TypographyProps } from './interface';
import { TypographyVariant } from './type';
import { getColor, getContrastColor } from '../Colors';
import './styled.css';

const variantClass: Record<TypographyVariant, string> = {
  h1: 'h1-variant',
  h2: 'h2-variant',
  h3: 'h3-variant',
  paragraph: 'p-variant',
  body: 'body-variant',
  paragraphSmall: 'p-small-variant',
  small: 'small-variant',
  xSmall: 'x-small-variant',
  tiny: 'tiny-variant'
};

const variantComponent: Record<TypographyVariant, React.ReactElement> = {
  h1: <h1 />,
  h2: <h2 />,
  h3: <h3 />,
  paragraph: <p />,
  body: <span />,
  paragraphSmall: <p />,
  small: <span />,
  xSmall: <span />,
  tiny: <span />
};
const Typography = (Props: TypographyProps) => {
  const {
    component,
    variant,
    colorVariant,
    bold,
    uppercase,
    tabular,
    children,
    center,
    noUserSelect,
    notIsContrastColor = false,
    color = 'neutral',
    isFullWidth= false,
    underline,
    isClickable = false,
    lineThrough = false,
    ...OtherProps
  } = Props;

  const conditionalClasses = [
    variantClass[variant],
    bold ? 'bold' : '',
    uppercase ? 'uppercase' : '',
    tabular ? 'tabular' : '',
    center ? 'center' : '',
    noUserSelect ? 'no-user-select' : '',
    isFullWidth ? 'fullWidth' : '',
    underline ? 'underline' : '',
    isClickable ? 'isClickable' : '',
    lineThrough ? 'lineThrough' : ''
  ].join(' ');

  const combinedStyle = {
    ...Props.style,
    color: notIsContrastColor
      ? getColor(color, colorVariant)
      : getContrastColor(color, colorVariant)
  };

  return React.cloneElement(
    component || variantComponent[variant],
    {
      ...OtherProps,
      className: conditionalClasses,
      style: combinedStyle
    },
    children
  );
};

export default Typography;

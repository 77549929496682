import React, { useEffect, useState } from 'react';

interface CircleProgressUnitProps {
  percentage: number;
  rotate: number;
  color: string;
  strokeWidth: number;
  size: number;
}

export const CircleProgressUnit: React.FC<CircleProgressUnitProps> = ({ percentage = 90, rotate, color, strokeWidth, size }) => {
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percentage / 100) * circumference;

  const [currentOffset, setCurrentOffset] = useState(circumference);
  const [currentRotate, setCurrentRotate] = useState(0);

  useEffect(() => {
    let animationFrameId: number;

    const animateCircle = () => {
      const step = (offset - currentOffset) / 10;
      const newOffset = currentOffset + step;

      if (Math.abs(newOffset - offset) < 1) {
        setCurrentOffset(offset);
      } else {
        setCurrentOffset(newOffset);
        animationFrameId = requestAnimationFrame(animateCircle);
      }
    };

    animationFrameId = requestAnimationFrame(animateCircle);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [offset, currentOffset]);

  useEffect(() => {
    let animationFrameId: number;

    const animateRotate = () => {
      const step = (rotate - currentRotate) / 10;
      const newRotate = currentRotate + step;

      if (Math.abs(newRotate - rotate) < 1) {
        setCurrentRotate(rotate);
      } else {
        setCurrentRotate(newRotate);
        animationFrameId = requestAnimationFrame(animateRotate);
      }
    };

    animationFrameId = requestAnimationFrame(animateRotate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [rotate, currentRotate]);

  return (
    <div style={{ position: 'absolute', width: `${size}px`, height: `${size}px` }}>
      <svg style={{
        transform: `rotate(${currentRotate}deg)`,
        margin: 'auto'
      }} width={size} height={size}>
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill='transparent'
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={currentOffset}
          transform={`rotate(-90 ${size / 2} ${size / 2})`}
        />
      </svg>
    </div>
  );
};

import React, { useCallback, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Button from '../../../common/designSystem/Button/Button';
import Header from './Components/Header/Header';
import PlayerConfirmed from './Components/PlayerConfirmed/PlayerConfirmed';
import PlayerRefused from './Components/PlayerRefused/PlayerRefused';
import PlayerQuestion from './Components/PlayerQuestion/PlayerQuestion';
import Dialog from '../../../common/designSystem/Dialog/Dialog';
import { Team } from '../../../common/model/Team';
import TeamService from '../../../common/services/team';
import { isDefined } from '../../../common/util/validations';
import { RoutePath } from '../../../routesPath';
import { Schedule } from '../../../common/model/Schedule';
import ScheduleService from '../../../common/services/schedule';
import { enqueueSnackbar } from 'notistack';
import UserService from '../../../common/services/user';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import { formatToScheduleDate } from '../../../common/util/formateDate';
import sportCenter from '../../../common/services/sportCenter';
import SportCenterService from '../../../common/services/sportCenter';
import { SportCenter } from '../../../common/model/SportCenter';

const PlayersGame = () => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const queryParams = new URLSearchParams(location.search);
  const game = queryParams.get('game');
  const scheduleId = queryParams.get('scheduleId');
  const [time, setTime] = useState({} as Team);
  const [schedule, setSchedule] = useState({} as Schedule);
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const [team, setTeam] = useState<{ confirmed: any[], pending: any[], rejected: any[] }>();
  const [openAccordion, setOpenAccordion] = useState<string>('confirmed');
  const [userId, setUserId] = useState('');
  const [me, setMe] = useState<any>();

  const getTime = async () => {
    const timeTemp = await TeamService.get(game!);
    setTime(timeTemp);
    const scheduleTemp = await ScheduleService.get(scheduleId!, true, timeTemp.admin);
    setSchedule(scheduleTemp);
    const sportCenterTemp = await SportCenterService.get(scheduleTemp.sportCenterId);
    setSportCenter(sportCenterTemp);
  };

  useEffect(() => {
    if (isDefined(game) && isDefined(scheduleId)) {
      getTime();
    }
  }, [game, scheduleId]);

  const getUserId = async () => {
    setUserId((await UserService.getCurrent())?.id ?? '');
  };

  useEffect(() => {
    getUserId();
  }, []);

  useEffect(() => {
    if (isDefined(time)) {
      let confirmed: any[] = [];
      let pending: any[] = [];
      let rejected: any[] = [];
      time.players[schedule.date as string].forEach((player) => {
        if (isDefined(userId) && player.id === userId) {
          setMe(player);
        }
        if (player.status === 'confirmed') {
          confirmed = [...confirmed, player];
        } else if (player.status === 'recused') {
          rejected = [...rejected, player];
        } else {
          pending = [...pending, player];
        }
      });
      setTeam({ confirmed, pending, rejected });
    }
  }, [time]);

  const openLink = () => {
    const sport = schedule.subSchedules?.find((schedule) => schedule.playerId == time.admin)?.sport;
    const sportIcon = () => {
      if (sport == 'Beach Tênis') {
        return '🎾';
      }
      if (sport == 'Futebol' || sport == 'Futebol society') {
        return '⚽';
      }
      if (sport == 'Vôlei') {
        return '🏐';
      }
      return '⚽';
    };
    window.open(
      'https://api.whatsapp.com/send?' +
      `text=Olá! 😊%0AVenha jogar ${sport} comigo no ${schedule.centerName}, no dia ${(schedule.date as string)?.replaceAll('-', '/')} às ${schedule.subSchedules?.find((schedule) => schedule.playerId == time.admin)?.scheduledHour}h!%0A%0AVamos nos divertir juntos. ${sportIcon()}%0A%0AConfirme sua participação clicando no link abaixo: 📲%0A${window.location.host + RoutePath.PLAYER_INVITE + '?team=' + game + 'game=' + schedule.id}`,
      '_blank');
  };

  const changeMyChoice = async (status: 'confirmed' | 'recused' | 'pending') => {
    try {
      const timeTemp = await TeamService.get(time.id ?? '');
      const date = typeof schedule.date == 'string' ? schedule.date : formatToScheduleDate(schedule.date);
      await TeamService.save({
        ...timeTemp, players: { ...timeTemp.players, [date]: timeTemp.players[date].map((player) => {
          if (player.id == userId) {
            return { ...player, status: status };
          }
          return player;
        }) }
      });
      await getTime();
      if (status == 'confirmed') {
        enqueueSnackbar('Você confirmou sua presença com sucesso!', { variant: 'success' });
      } else {
        enqueueSnackbar('Você recusou sua presença com sucesso!', { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  return (<RestrictArea role={'player'}>
    <Header centerName={schedule.centerName} courtName={schedule.courtName} lat={sportCenter?.address?.location?.lat?.toString()} lon={sportCenter?.address?.location?.lng?.toString()} />
    <Grid alignContent={'start'} isScrollable height={'calc(100% - 199px)'} style={{ paddingTop: '24px', paddingBottom: '16px', backgroundColor: 'white' }} rowGapXs={16} >
      {/* <GridItem xs={6} sm={5} alignContent='center' alignItems='end' isRow> */}
      {/*   <Input name='' label='' placeholder='Buscar jogador' isFullWidth icon={<Search/>} style={{ outline: 'none', boxShadow: '0px 3px 4px 0px rgba(106, 115, 129, 0.12)' }}/> */}
      {/* </GridItem> */}
      <GridItem xs={6}>
        <Typography variant="small" colorVariant="a500" notIsContrastColor>
          Total de jogadores {isDefined(schedule?.date) ? time?.players[schedule.date as string]?.length : ''}
        </Typography>
      </GridItem>
      <PlayerConfirmed setOpenDialog={(val) => setOpenDialog(val)} players={isDefined(team?.confirmed) ? team!.confirmed! : []} open={openAccordion} setOpen={(val) => setOpenAccordion(val)}/>
      <PlayerQuestion players={isDefined(team?.pending) ? team!.pending! : []} open={openAccordion} setOpen={(val) => setOpenAccordion(val)}/>
      <PlayerRefused players={isDefined(team?.rejected) ? team!.rejected! : []} open={openAccordion} setOpen={(val) => setOpenAccordion(val)}/>
      <GridItem xs={6}>
        <Typography variant={'paragraphSmall'} bold>{isDefined(me) && me.status == 'pending' ? 'Você vai jogar?' : 'Alterar meu status'}</Typography>
      </GridItem>
      {isDefined(me) && me.status == 'pending' && <>
        <GridItem xs={6} padding='0 16px 0 0'>
          <Button color={'info'} onClick={async () => await changeMyChoice('confirmed')} variant={'filled'} title={'Confirmar'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
        <GridItem xs={6} padding='8px 16px 0 0'>
          <Button color={'error'} onClick={async () => await changeMyChoice('recused')} variant={'transparent'} title={'Recusar'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
      </>
      }
      {isDefined(me) && me.status == 'confirmed' && <>
        <GridItem xs={6} padding='8px 16px 0 0'>
          <Button color={'error'} onClick={async () => await changeMyChoice('recused')} variant={'transparent'} title={'Cancelar presença na partida'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
        <GridItem xs={6} padding='8px 16px 0 0'>
          <Button color={'info'} onClick={async () => await changeMyChoice('pending')} variant={'transparent'} title={'Ainda não sei'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
      </>}
      {isDefined(me) && me.status == 'recused' && <>
        <GridItem xs={6} padding='8px 16px 0 0'>
          <Button color={'success'} onClick={async () => await changeMyChoice('confirmed')} variant={'transparent'} title={'Confirmar minha presença'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
        <GridItem xs={6} padding='8px 16px 0 0'>
          <Button color={'info'} onClick={async () => await changeMyChoice('pending')} variant={'transparent'} title={'Ainda não sei'} style={{ height: '37px', display: 'flex', alignItems: 'center' }} isFullWidth/>
        </GridItem>
      </>}
      <GridItem xs={6}>
        <Typography variant={'paragraphSmall'} bold>
          Convide mais jogadores
        </Typography>
      </GridItem>
      <GridItem xs={6}>
        <Button color={'success'} isFullWidth onClick={() => openLink()} variant={'filled'} title='Convidar via Whatsapp' iconRight={
          <svg viewBox="0 0 32 32" width={24} height={24} className="whatsapp-ico"><path fill={'#fff'} d=" M19.11 17.205c-.372 0-1.088 1.39-1.518 1.39a.63.63 0 0 1-.315-.1c-.802-.402-1.504-.817-2.163-1.447-.545-.516-1.146-1.29-1.46-1.963a.426.426 0 0 1-.073-.215c0-.33.99-.945.99-1.49 0-.143-.73-2.09-.832-2.335-.143-.372-.214-.487-.6-.487-.187 0-.36-.043-.53-.043-.302 0-.53.115-.746.315-.688.645-1.032 1.318-1.06 2.264v.114c-.015.99.472 1.977 1.017 2.78 1.23 1.82 2.506 3.41 4.554 4.34.616.287 2.035.888 2.722.888.817 0 2.15-.515 2.478-1.318.13-.33.244-.73.244-1.088 0-.058 0-.144-.03-.215-.1-.172-2.434-1.39-2.678-1.39zm-2.908 7.593c-1.747 0-3.48-.53-4.942-1.49L7.793 24.41l1.132-3.337a8.955 8.955 0 0 1-1.72-5.272c0-4.955 4.04-8.995 8.997-8.995S25.2 10.845 25.2 15.8c0 4.958-4.04 8.998-8.998 8.998zm0-19.798c-5.96 0-10.8 4.842-10.8 10.8 0 1.964.53 3.898 1.546 5.574L5 27.176l5.974-1.92a10.807 10.807 0 0 0 16.03-9.455c0-5.958-4.842-10.8-10.802-10.8z" fillRule="evenodd"></path></svg>
        }></Button>
      </GridItem>
      <GridItem xs={6}>
        <Dialog
          open={openDialog}
          setOpen={setOpenDialog}
          title={'Times incompletos'}
          buttonLabelConfirm={'Sim'}
          buttonLabelCancel={'Não'}
          onCancel={() => console.log('Não')}
          onConfirm={() => console.log('Sim')}
        >
          <Typography variant={'paragraph'}>
            Deseja sortear mesmo assim?
          </Typography>
        </Dialog>
      </GridItem>
    </Grid>
  </RestrictArea>);
};

export default PlayersGame;

import { User } from '../model/User';
import { remove, get, save } from './firestore';
import { getAuth } from 'firebase/auth';
import { appFirebase } from './firebase';
import { collections } from '../constant/collections';
import { isDefined } from '../util/validations';
import { getLocalStorage, setLocalStorage } from '../util/localStorage';
import { clearAllCache, clearCacheByService, getCache, getTimeCacheInvalid, setCache } from '../util/cache';
import SportCenterService from './sportCenter';

class UserServiceProvider {
  auth = getAuth(appFirebase);
  save = async (data: User) => {
    const { createAt = new Date(), ...userData } = data;
    const value = await this.get(data.id);
    if (isDefined(value)) {
      setLocalStorage(userData, 'user');
      return await save(collections.users, userData);
    }
    setLocalStorage({ createAt, ...userData }, 'user');
    return await save(collections.users, { createAt, ...userData });
  };

  init = () => {
    this.auth.onAuthStateChanged(async (user) => {
      if (user) {
        setLocalStorage(await this.getCurrent(user.uid), 'user');
      } else {
        setLocalStorage({}, 'user');
      }
    });
  };

  delete = async (data: User) => {
    return await remove(collections.users, data.id);
  };

  deleteCurrentUser = async (callback: any): Promise<void> => {
    if (!this.hasCurrentUser()) throw new Error('Usuário inexistente');

    const user = await this.getCurrent();
    await this.delete(user!);
    this.auth.currentUser!.delete();
    if (user?.role === 'sportCenter') await SportCenterService.removeUserFromCenter(user.id);

    await this.logout();
    callback();
  };

  get = async (id: string): Promise<User> => {
    const valueInCache = getCache('user', 'get', id);
    const timeCacheInvalid = getTimeCacheInvalid('user', 'get', id);
    if (!isDefined(valueInCache) || timeCacheInvalid) {
      const value = await get(`${collections.users}/${id}`);
      setCache('user', 'get', id, value);
      return value as User;
    } else {
      return { ...valueInCache,
        lastLoginAt: new Date(valueInCache.lastLoginAt.seconds),
        createAt: new Date(valueInCache.createAt.seconds)
      } as User;
    }
  };

  getCurrent = async (id:string | undefined = undefined): Promise<User | undefined> => {
    if (isDefined(id)) {
      return this.get(id!);
    }
    let user = getLocalStorage('user') as User | undefined;
    if (isDefined(user)) {
      const twentyFourHours = 24 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const lastLoginTime = (new Date(user?.lastLoginAt instanceof Date ? 10 : user!.lastLoginAt.seconds! * 1000)).getTime();
      if (currentTime - lastLoginTime < twentyFourHours) {
        return user;
      }
      try {
        user = await this.get(user!.id);
        return user;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  };

  logout = async () => {
    setLocalStorage({}, 'user');
    clearAllCache();
    await this.auth.signOut();
  };

  hasCurrentUser = () => this.auth?.currentUser?.uid !== undefined;
}

const UserService = new UserServiceProvider();

export default UserService;

import styled from 'styled-components';
import { ButtonCePriceStyledProps } from './interface';
import { getColor } from '../../designSystem/Colors';
import { BorderRadius, Shadows } from '../../designSystem/GlobalStyle';

export const ButtonCePriceStyled = styled.button<ButtonCePriceStyledProps>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  outline: none;
  background: ${getColor('neutral', 'white')};
  width: 100%;
  cursor: pointer;
  padding: 16px 16px;
  border: 0.5px solid ${getColor('neutral', 'a100')};
  box-shadow: ${Shadows.med};
  border-radius: ${BorderRadius.medium};
  ${({ margin }) => margin && `margin: ${margin};`}

  :hover {
    filter: brightness(0.95);
  }
`;

import React from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Input from '../../../../../common/designSystem/Input/Input';
import Society from '../../../../../common/designSystem/Icons/Society';
import BeachTennis from '../../../../../common/designSystem/Icons/BeachTennis';
import Volleyball from '../../../../../common/designSystem/Icons/Volleyball';
import Footvolley from '../../../../../common/designSystem/Icons/Footvolley';
import Basketball from '../../../../../common/designSystem/Icons/Basketball';
import Futsal from '../../../../../common/designSystem/Icons/Futsal';
import BeachVolley from '../../../../../common/designSystem/Icons/BeachVolley';
import Soccer from '../../../../../common/designSystem/Icons/Soccer';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import Button from '../../../../../common/designSystem/Button/Button';
import Search from '../../../../../common/designSystem/Icons/Search';
import { getDevice } from '../../../../../common/designSystem/Screen';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';
import { useGlobalContext } from '../../../../../contexts/GlobalContext';

const Header = () => {
  const navigate = useNavigate();
  const { backoffice } = useGlobalContext();

  return (
    <Grid alignItems={'center'} alignContent={'start'} rowGapXs={16} style={{ paddingTop: '24px', paddingBottom: '8px', background: '#fff' }} height={'fit-content'}>
      {/* <GridItem xs={4} sm={5} alignContent='center' alignItems='end' isRow> */}
      {/*   <Input name='' label='' placeholder='Nome do Centro Esportivo' isFullWidth icon={<Search/>} style={{ outline: 'none', boxShadow: '0px 3px 4px 0px rgba(106, 115, 129, 0.12)' }}/> */}
      {/* </GridItem> */}
      {/* <GridItem xs={2} sm={1} alignItems={'start'}> */}
      {/*   <Button color={'transparent'} onClick={() => {}} variant={'select'} style={{ boxShadow: '0px 3px 4px 0px rgba(106, 115, 129, 0.12)', height: '52px' }} icon={<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> */}
      {/*     <g clipPath="url(#clip0_2765_4572)"> */}
      {/*       <path d="M2.85733 5.00022C4.0408 5.00022 5.00019 4.04083 5.00019 2.85737C5.00019 1.6739 4.0408 0.714508 2.85733 0.714508C1.67387 0.714508 0.714478 1.6739 0.714478 2.85737C0.714478 4.04083 1.67387 5.00022 2.85733 5.00022Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M5.00024 2.85736H19.286" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M10.0002 12.1431C11.1836 12.1431 12.143 11.1837 12.143 10.0002C12.143 8.81675 11.1836 7.85736 10.0002 7.85736C8.81669 7.85736 7.8573 8.81675 7.8573 10.0002C7.8573 11.1837 8.81669 12.1431 10.0002 12.1431Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M0.714478 10H7.85733" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M12.1431 10H19.2859" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M17.1429 19.2857C18.3263 19.2857 19.2857 18.3263 19.2857 17.1429C19.2857 15.9594 18.3263 15 17.1429 15C15.9594 15 15 15.9594 15 17.1429C15 18.3263 15.9594 19.2857 17.1429 19.2857Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*       <path d="M15.0002 17.1427H0.714478" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/> */}
      {/*     </g> */}
      {/*     <defs> */}
      {/*       <clipPath id="clip0_2765_4572"> */}
      {/*         <rect width="20" height="20" fill="white"/> */}
      {/*       </clipPath> */}
      {/*     </defs> */}
      {/*   </svg> */}
      {/*   } isFullWidth/> */}
      {/* </GridItem> */}
      <GridItem xs={6}>
        <Typography variant={'paragraph'}>Reserve por esporte:</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Carousel height={'64px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
          {backoffice?.sports.map((obj) => <div key={obj.title} onClick={() => navigate(RoutePath.PLAYER_RESERVATION + '?sport=' + obj.title)} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minWidth: '80px', width: 'fit-content', minHeight: '48px', wordWrap: 'unset' }}>
            {obj.icon}
            <Typography style={{ marginTop: '8px' }} variant={'xSmall'} isFullWidth>{obj.title}</Typography>
          </div>)}
        </Carousel>
      </GridItem>
    </Grid>
  );
};

export default Header;

import React from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Accordion from '../../../../../common/designSystem/Accordion/Accordion';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import UserCheck from '../../../../../common/designSystem/Icons/UserCheck';
import Crown from '../../../../../common/designSystem/Icons/Crown';
import Button from '../../../../../common/designSystem/Button/Button';
import Arrow from '../../../../../common/designSystem/Icons/Arrow';
import { Colors } from '../../../../../common/designSystem/Colors';

const PlayerConfirmed = ({ players, open, setOpen, setOpenDialog }: {players: any[], open: string, setOpen: (val: string) => void, setOpenDialog: (val: boolean) => void}) => {
  return players.length != 0 ? (
    <GridItem xs={6}>
      <Grid style={{ background: Colors.neutral.a200, borderRadius: '8px' }}>
        <GridItem xs={6} alignContent='center'>
          <Accordion
            header={<Typography variant={'body'} bold style={{ display: 'flex', alignItems: 'center' }}><span style={{ marginRight: '8px' }}><UserCheck/></span>{players.length} Confirmados</Typography>}
            onClick={() => setOpen('confirmed')}
            open={open === 'confirmed'}
            xs={6}
            rowGapXs={16}>
            {players.map((player: any) =>
              <GridItem xs={6}>
                <Typography variant='small' style={{ display: 'flex', flexDirection: 'row' }}> {player?.type == 'admin' ? <span style={{ marginRight: '11px', display: 'flex', flexDirection: 'column' }}><Crown/></span> : <span style={{ width: '30px' }}></span> }  {player.name}</Typography>
              </GridItem>
            )}
            {/* <GridItem xs={6}> */}
            {/*   <Button color={'primary'} isFullWidth onClick={() => setOpenDialog(true) } variant={'filled'} title='Sortear time' iconRight={<Arrow></Arrow>}></Button> */}
            {/* </GridItem> */}
          </Accordion>
        </GridItem>
      </Grid>
    </GridItem>
  ) : <></>;
};

export default PlayerConfirmed;

import { CreatePayment, Payment } from '../model/Payment';
import { httpsCallable } from 'firebase/functions';
import { functions } from './firebase';
import { isProduction } from '../util/getEnvironment';

const addPropertyIfDefined = (obj: any, key: string, value: any) => {
  if (value !== undefined && value !== null) {
    obj[key] = value;
  }
};

export const createPayment = async (data: CreatePayment) => {
  const payment = {
    installments: data.installments,
    isProduction: isProduction,
    additional_info: {
      items: [
        {
          id: data.itemId,
          title: data.itemTitle
        }
      ]
    },
    payer: {
      email: data.clientEmail,
      identification: {
        number: data.clientIdentification,
        type: data.clientIdentificationType
      }
    },
    description: data.itemTitle,
    payment_method_id: data.paymentMethodId,
    transaction_amount: data.itemPrice,
    userId: data.userId,
    notification_url: 'https://southamerica-east1-jogaja-78903.cloudfunctions.net/notificationPayment?isProduction='+isProduction
  };
  addPropertyIfDefined(payment.additional_info.items[0], 'item_description', data.itemDescription);
  addPropertyIfDefined(payment.additional_info.items[0], 'item_category_id', data.itemCategoryId);
  addPropertyIfDefined(payment.additional_info.items[0], 'item_picture_url', data.itemPictureUrl);
  addPropertyIfDefined(payment, 'token', data.token);
  addPropertyIfDefined(payment, 'issuer_id', data.issuerId);
  addPropertyIfDefined(payment, 'application_fee', data.applicationFee);

  const createPayment = httpsCallable(functions, 'createPayment');
  const response = (await createPayment(payment)).data as {status: number, data: Payment};
  if (response.status !== 200) {
    return undefined;
  }
  return response?.data;
};

import { get, query, remove, save } from './firestore';
import { Court, SportCenter } from '../model/SportCenter';
import { collections } from '../constant/collections';
import { isDefined } from '../util/validations';
import { CashFlow } from '../model/shared/CashFlow';
import { WorkShift } from '../model/enums/WorkShift';
import { WeekDay } from '../model/enums/WeekDay';
import { Sport } from '../model/Backoffice';
import { translateError } from '../util/error';
import { clearCacheByService, getCache, getTimeCacheInvalid, setCache } from '../util/cache';
import { SportCenterStatus } from '../model/enums/SportCenterStatus';

class SportCenterServiceProvider {
  showLogs = false;
  saveCourt = async (sportCenterId: string, court: Court) => {
    try {
      await save(`${collections.sportCenters}/${sportCenterId}/courts`, court);
      clearCacheByService('sportCenter');
    } catch (error) {
      throw new Error('Erro ao salvar: ' + translateError((error as any)?.code));
    }
  };

  saveCourts = async (sportCenterId: string, courts: Court[]) => {
    try {
      for (let i = 0; i < courts.length; i++) {
        await this.saveCourt(sportCenterId, courts[i]);
      }
    } catch (error) {
      throw new Error('Erro ao salvar: ' + translateError((error as any)?.code));
    }
  };

  saveCacheFlow = async (sportCenterId: string, cash: CashFlow) => {
    await save(`${collections.sportCenters}/${sportCenterId}/cashFlow`, cash);
  };

  saveCashFlows = (sportCenterId: string, cashFlow: CashFlow[]) => {
    cashFlow?.forEach(async (cash) => await this.saveCacheFlow(sportCenterId, cash));
  };

  save = async (data: SportCenter) => {
    try {
      const { courts, cashFlow, ...sportCenter } = data;
      const saved = await save(collections.sportCenters, sportCenter);
      if (isDefined(courts)) {
        await this.saveCourts(saved?.id, courts!);
      }
      if (isDefined(cashFlow)) {
        await this.saveCashFlows(saved?.id, cashFlow!);
      }
      clearCacheByService('sportCenter');
      return saved;
    } catch (error) {
      throw new Error('Erro ao salvar: ' + translateError((error as any)?.code));
    }
  };

  get = async (id: string, allInformation = false): Promise<SportCenter> => {
    const sportCenter = (await get(`${collections.sportCenters}/${id}`)) as SportCenter;
    if (allInformation) {
      sportCenter.courts = await this.getCourts(id);
      sportCenter.cashFlow = await this.getCashFlows(id);
    }
    return sportCenter;
  };

  getAll = async (allInformation = false): Promise<SportCenter[]> => {
    const sportCenters = (await get(collections.sportCenters)) as SportCenter[];
    if (allInformation) {
      sportCenters.forEach(async (center) => {
        center.courts = await this.getCourts(center.id!);
        center.cashFlow = await this.getCashFlows(center.id!);
      });
    }
    return sportCenters;
  };

  findBySportAndTimeAvailability = async (sport: Sport, days: WeekDay[], workShift: WorkShift, initHour: number, endHour: number): Promise<SportCenter[]> => {
    const sportCenters = (await query({
      collectionPath: collections.sportCenters,
      query: [{
        field: 'sportServed',
        operator: 'array-contains',
        value: sport.title
      },
      {
        field: `workTime.${workShift}`,
        operator: '==',
        value: true
      },
      {
        field: 'status',
        operator: '==',
        value: SportCenterStatus.ENABLED
      }],
      page: 0,
      size: 30
    })) as SportCenter[];
    return (await Promise.all(
      sportCenters
        .filter((sportCenter) => {
          const endHourSportCenter = sportCenter.workTime![`${workShift}CloseTime`].value;
          return days.every((day) => sportCenter.workTime?.weekDaysForWork.includes(day)) &&
          sportCenter.workTime![`${workShift}OpenTime`].value <= initHour &&
          (workShift === 'night' && endHourSportCenter === 0 ? 24 : endHourSportCenter) >= endHour;
        })
        .map(async (sportCenter): Promise<SportCenter> => {
          sportCenter.courts = (await this.getCourtsBySportAndCenterId(sportCenter.id!, sport));
          return sportCenter;
        })
    ));
  };

  getCourtsBySportAndCenterId = async (sportCenterId: string, sport: Sport): Promise<Court[]> => {
    return (await query({
      collectionPath: `${collections.sportCenters}/${sportCenterId}/courts`,
      query: [{
        field: 'sportServed',
        operator: 'array-contains',
        value: sport.title
      }],
      page: 0,
      size: 30
    }));
  };

  getCourts = async (sportCenterId: string, noCache = false): Promise<Court[]> => {
    const valueInCache = getCache('sportCenter', 'getCourts', sportCenterId);
    const timeCacheInvalid = getTimeCacheInvalid('sportCenter', 'getCourts', sportCenterId);
    if (!isDefined(valueInCache) || timeCacheInvalid || noCache) {
      const courts = (await get(`${collections.sportCenters}/${sportCenterId}/courts`)) as Court[];
      this.showLogs && console.log(`SportCenter.getCourts(${sportCenterId}) by: firebase`);
      setCache('sportCenter', 'getCourts', sportCenterId, courts);
      return courts;
    } else {
      this.showLogs && console.log(`SportCenter.getCourt(${sportCenterId}) by: cache`);
      return valueInCache as Court[];
    }
  };

  getCourt = async (sportCenterId: string, courtId: string): Promise<Court> => {
    const valueInCache = getCache('sportCenter', 'getCourt', sportCenterId+courtId);
    const timeCacheInvalid = getTimeCacheInvalid('sportCenter', 'getCourt', sportCenterId+courtId);
    if (!isDefined(valueInCache) || timeCacheInvalid) {
      this.showLogs && console.log(`SportCenter.getCourt(${sportCenterId}, ${courtId}) by: firebase`);
      const court = (await get(`${collections.sportCenters}/${sportCenterId}/courts/${courtId}`)) as Court;
      setCache('sportCenter', 'getCourt', sportCenterId+courtId, court);
      return court;
    } else {
      this.showLogs && console.log(`SportCenter.getCourt(${sportCenterId}, ${courtId}) by: cache`);
      return valueInCache as Court;
    }
  };

  getCashFlows = async (sportCenterId: string): Promise<CashFlow[]> => {
    this.showLogs && console.log(`SportCenter.getCashFlows(${sportCenterId}) by: firebase`);
    return (await get(`${collections.sportCenters}/${sportCenterId}/cashFlow`)) as CashFlow[];
  };

  getCashFlow = async (sportCenterId: string, cashFlowId: string): Promise<CashFlow> => {
    this.showLogs && console.log(`SportCenter.getCashFlow(${sportCenterId}, ${cashFlowId}) by: firebase`);
    return (await get(`${collections.sportCenters}/${sportCenterId}/cashFlow/${cashFlowId}`)) as CashFlow;
  };

  getByUserId = async (id: string, noCache = false): Promise<SportCenter> => {
    const value = getCache('sportCenter', 'getByUserId', id);
    const timeCacheInvalid = getTimeCacheInvalid('sportCenter', 'getByUserId', id);
    if (!isDefined(value) || timeCacheInvalid || noCache) {
      const sportCenter = (await query({
        collectionPath: `${collections.sportCenters}`,
        query: [
          {
            field: 'admins',
            operator: 'array-contains',
            value: id
          }
        ],
        page: 0,
        size: 1
      }))[0] as unknown as SportCenter;
      setCache('sportCenter', 'getByUserId', id, sportCenter);
      this.showLogs && console.log(`SportCenter.getByUserId(${id}) by: firebase`);
      return sportCenter;
    } else {
      this.showLogs && console.log(`SportCenter.getByUserId(${id}) by: cache`);
      return value as SportCenter;
    }
  };

  getHighlight = async (): Promise<SportCenter[]> => {
    const valueInCache = getCache('sportCenter', 'getHighlight', undefined);
    const timeCacheInvalid = getTimeCacheInvalid('sportCenter', 'getHighlight', undefined);
    if (!isDefined(valueInCache) || timeCacheInvalid) {
      const sportCenters = (await query({
        collectionPath: `${collections.sportCenters}`,
        query: [
          {
            field: 'paymentProvider',
            operator: 'array-contains',
            value: 'Mercado Pago'
          }
        ],
        page: 0,
        size: 3
      })) as unknown as SportCenter[];
      for (let i = 0; i < sportCenters.length; i++) {
        sportCenters[i].courts = await this.getCourts(sportCenters[i].id!);
      }
      setCache('sportCenter', 'getHighlight', undefined, sportCenters);
      this.showLogs && console.log('SportCenter.getHighlight() by: firebase');
      return sportCenters;
    } else {
      this.showLogs && console.log('SportCenter.getHighlight() by: cache');
      return valueInCache as SportCenter[];
    }
  };

  delete = async (id: string) => {
    clearCacheByService('sportCenter');
    return await remove(collections.sportCenters, id);
  };

  softDelete = async (id: string, sportCenter: SportCenter) => {
    clearCacheByService('sportCenter');
    return await save(collections.sportCenters, { ...sportCenter, status: SportCenterStatus.DISABLED } as SportCenter);
  };

  deleteCourts = async (sportCenterId: string, ids: string[]): Promise<void> => {
    ids?.forEach((id) => this.deleteCourt(sportCenterId, id));
  };

  deleteCourt = async (sportCenterId: string, id: string) => {
    return await remove(`${collections.sportCenters}/${sportCenterId}/courts`, id);
  };

  deleteCacheFlow = async (sportCenterId: string, id: string) => {
    return await remove(`${collections.sportCenters}/${sportCenterId}/cashFlow`, id);
  };

  removeUserFromCenter = async (userId: string): Promise<void> => {
    const sportCenter = await this.getByUserId(userId);
    if (sportCenter.admins.length === 1 && sportCenter.admins[0] === userId) {
      this.softDelete(sportCenter.id!, sportCenter);
    }
  };
}

const SportCenterService = new SportCenterServiceProvider();
export default SportCenterService;

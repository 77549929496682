import React from 'react';

const UserQuestion = () => {
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
      <path d="M8.14324 8.1431C9.91844 8.1431 11.3575 6.70401 11.3575 4.92881C11.3575 3.15361 9.91844 1.71452 8.14324 1.71452C6.36804 1.71452 4.92896 3.15361 4.92896 4.92881C4.92896 6.70401 6.36804 8.1431 8.14324 8.1431Z" stroke="#E07C02" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.28603 18.8578H1.7146V16.7149C1.71572 15.4443 2.09335 14.2025 2.79981 13.1463C3.50627 12.0902 4.50986 11.2671 5.68383 10.781C6.8578 10.2949 8.14949 10.1676 9.39577 10.4152" stroke="#E07C02" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.8335 13.9167C11.8335 12.7661 12.7662 11.8333 13.9168 11.8333C15.0674 11.8333 16.0002 12.7661 16.0002 13.9167C16.0002 15.0673 15.0674 16 13.9168 16" stroke="#E07C02" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.2917 18.5C13.6369 18.5 13.9167 18.7798 13.9167 19.125C13.9167 19.4702 13.6369 19.75 13.2917 19.75C12.9466 19.75 12.6667 19.4702 12.6667 19.125C12.6667 18.7798 12.9466 18.5 13.2917 18.5" stroke="#E07C02" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>);
};

export default UserQuestion;

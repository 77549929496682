import React, { useCallback, useEffect, useRef, useState } from 'react';
import Input from '../Input/Input';
import { SelectProps } from './interface';
import Dialog from '../Dialog/Dialog';
import { Container, Option, WrapperOption } from './styled';
import { isDefined } from '../../util/validations';
import Button from '../Button/Button';
import Next from '../Icons/Next';

const Select = ({ name, label, required = false, options, isFullWidth, placeholder, register, variant = 'select' }: SelectProps) => {
  const [open, setOpen] = useState(false);
  const [selectIndex, setSelectIndex] = useState<number | null>(null);
  const [selectIndexOld, setSelectIndexOld] = useState<number | null>(null);
  const [select, setSelect] = useState<string | undefined>(options.find((option) => option?.value === register?.initialValue)?.title);
  const [canChange, setCanChange] = useState(false);
  const wrapperOptionRef = useRef<HTMLDivElement>(null);

  const handleScroll = (event: Event) => {
    const target = event.target as HTMLElement;
    if (!target) return;
    const scroll = target.scrollTop;
    const height = target.scrollHeight;
    const countOfElements = target.childElementCount;
    const heightOfElement = height / countOfElements;
    const option = Math.round(scroll / heightOfElement);
    setSelectIndex(option);
  };

  const setScroll = (element: HTMLElement | null, option: number) => {
    const height = element?.scrollHeight;
    const countOfElements = element?.childElementCount;
    const heightOfElement = height! / countOfElements!;
    element?.scroll({ left: 0, top: heightOfElement * option, behavior: 'smooth' });
  };

  const click = (option: number) => {
    setSelectIndex(option);
    setSelect(options[option]?.title);
    setScroll(wrapperOptionRef.current, option);
  };

  useEffect(() => {
    click(selectIndex!);
  }, [open]);

  useEffect(() => {
    if (isDefined(register?.getValue(name))) {
      const index = options.findIndex((option) => option?.value === register?.getValue(name));
      setSelectIndexOld(index);
      setSelectIndex(index);
      setSelect(options[index]?.title);
    }
  }, [register?.getValue(name)]);

  useEffect(() => {
    if (wrapperOptionRef !== null) {
      register?.registerValue(register, name, required);
      const current = wrapperOptionRef?.current;
      current?.addEventListener('scroll', (event) => {
        handleScroll(event);
      });
      if (isDefined(register?.initialValue)) {
        const index = options.findIndex((option) => option?.value === register?.initialValue);
        setSelectIndexOld(index);
        setSelectIndex(index);
        setSelect(options[index]?.title);
      }
      return () => {
        current?.removeEventListener('scroll', (event) => {
          handleScroll(event);
        });
      };
    }
  }, [wrapperOptionRef]);

  useEffect(() => {
    if (canChange) {
      click(selectIndex!);
      setSelect(options[selectIndex!].title);
      register?.setValue(options[selectIndex!].value, name);
      setSelectIndexOld(selectIndex);
      setCanChange(false);
    }
  }, [canChange, selectIndex]);

  return (
    <>
      {variant === 'button' && <Button tabIndex={-1} style={{ marginTop: '8px' }} color={'info'} colorVariant={'a400'} onClick={() => setOpen(true)} variant={'transparent'} title={select} iconRight={<Next color={'info'} colorVariant={'a400'}/>}/>}
      {variant === 'select' && <Input isFullWidth={isFullWidth} name={name} inputMode={'none'} label={label || ''} required={required} value={select} placeholder={placeholder || ''} onClick={() => setOpen(true)}/>}
      <Dialog open={open} setOpen={setOpen} title={label} buttonLabelCancel={'Cancelar'} buttonLabelConfirm={'Salvar'} onConfirm={() => setCanChange(true)} onCancel={() => {
        setSelectIndex(selectIndexOld);
        setSelect(options[selectIndexOld!]?.value);
      }}>
        <Container>
          <WrapperOption ref={wrapperOptionRef}>
            <Option selected={false}></Option>
            {options.map((item, index) => (
              <Option onClick={() => click(index)} key={item.value} selected={selectIndex === index}>{item.title}</Option>
            ))}
            <Option selected={false}></Option>
          </WrapperOption>
        </Container>
      </Dialog>
    </>
  );
};

export default Select;

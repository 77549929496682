import React from 'react';
import { IconProps } from './interface';

const LocationPinNavMap = ({ width, height } : IconProps) => {
  return (<svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 15 20" fill="none">
    <path d="M13.6667 7.31077C13.6667 11.8339 8.99262 17.5406 7.69509 18.3486C7.59622 18.4112 7.4816 18.4444 7.36458 18.4444C7.24756 18.4444 7.13295 18.4112 7.03407 18.3486C5.73689 17.5406 1.0625 11.8339 1.0625 7.31077C1.0625 3.83026 3.88408 1.00868 7.36458 1.00868C10.8451 1.00868 13.6667 3.83026 13.6667 7.31077Z" stroke="#2C2B3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.1656 7.31077C10.1656 8.05362 9.87048 8.76605 9.3452 9.29132C8.81993 9.8166 8.1075 10.1117 7.36465 10.1117C6.62179 10.1117 5.90937 9.8166 5.38409 9.29132C4.85882 8.76605 4.56372 8.05362 4.56372 7.31077C4.56372 6.56792 4.85882 5.85549 5.38409 5.33021C5.90937 4.80494 6.62179 4.50984 7.36465 4.50984C8.1075 4.50984 8.81993 4.80494 9.3452 5.33021C9.87048 5.85549 10.1656 6.56792 10.1656 7.31077Z" stroke="#2C2B3A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>);
};

export default LocationPinNavMap;

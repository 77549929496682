import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import DaysInWeek from '../../../common/designSystem/DaysInWeek/DaysInWeek';
import Carousel from '../../../common/designSystem/Carousel/Carousel';
import ShowCourtStatus from '../../../common/component/ShowCourtStatus/ShowCourtStatus';
import Button from '../../../common/designSystem/Button/Button';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import { Court, SportCenter, ViewSchedules } from '../../../common/model/SportCenter';
import { isDefined } from '../../../common/util/validations';
import UserService from '../../../common/services/user';
import SportCenterService from '../../../common/services/sportCenter';
import MenuSportCenter from '../../../common/component/MenuSportCenter/MenuSportCenter';
import Dependencies from '../Home/components/Dependencies/Dependencies';
import ScheduleService from '../../../common/services/schedule';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import { getDevice } from '../../../common/designSystem/Screen';
import { getPaddingSize } from '../../../common/designSystem/Grid/styled';

const CourtPage = () => {
  const [schedules, setSchedules] = useState<ViewSchedules[]>([]);
  const [status, setStatus] = useState('all');
  const [courtName, setCourtName] = useState('');
  const [userId, setUserId] = useState('');
  const navigate = useNavigate();
  const { register, getValue } = useForm();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');
  const getUserId = async () => setUserId((await UserService.getCurrent())?.id ?? '');

  const getViewSchedules = async () => {
    setSchedules((await ScheduleService.getViewSchedules(id!, getValue('date'))));
  };

  async function getCourtName() {
    const sportCenter = await SportCenterService.getByUserId(userId);
    setCourtName((await SportCenterService.getCourt(sportCenter.id!, id!)).name);
  }

  useEffect(() => {
    if (isDefined(id) && isDefined(userId)) {
      getViewSchedules();
    }
  }, [id, getValue('date'), userId]);

  useEffect(() => {
    if (isDefined(id) && isDefined(userId)) {
      getCourtName();
    }
  }, [id, userId]);

  useEffect(() => {
    getUserId();
  }, []);

  const getStatus = (status: string) => {
    switch (status) {
    case 'available':
      return 'Disponível';
    case 'reserved':
      return 'Reservado';
    }
  };

  return <RestrictArea role={'sportCenter'}>
    <Grid alignContent='start' isScrollable height={'calc(100% - 128px)'} style={{ paddingTop: '24px', paddingBottom: '16px' }} rowGapXs={14}>
      <GridItem xs={3} display='flex' flexDirection='row' alignItems='center'>
        <Typography isFullWidth variant={'h2'} bold>{courtName}</Typography>
      </GridItem>
      <GridItem xs={3} display='flex' flexDirection='row' justifyContent={'flex-end'} >
        <Button color={'transparent'} onClick={() => navigate(RoutePath.SPORT_CENTER_MY_COURTS)} variant={'filled'} title={'Ver outra quadra'}></Button>
      </GridItem>
      <GridItem xs={6}>
        <Typography variant={'paragraph'} bold>Meus horários</Typography>
      </GridItem>
      <Dependencies/>
      <GridItem xs={6} style={{ width: '100%' }}>
        <DaysInWeek register={register()} name={'date'}></DaysInWeek>
      </GridItem>
      <GridItem xs={6} alignItems='start'>
        <Carousel height={'46px'} key={'id'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
          <Button color={'primary'} onClick={() => setStatus('all') } selected={status === 'all'} variant={'select-border'} title={'Todos'}/>
          <Button color={'primary'} onClick={() => setStatus('reserved') } selected={status === 'reserved'} variant={'select-border'} title={'Reservados'}/>
          <Button color={'primary'} onClick={() => setStatus('available') } selected={status === 'available'} variant={'select-border'} title={'Disponíveis'}/>
          <Button color={'primary'} onClick={() => setStatus('blocked') } selected={status === 'blocked'} variant={'select-border'} title={'Bloqueados'}/>
        </Carousel>
      </GridItem>
      {schedules.filter((schedule) => schedule.status === status || status === 'all').map((schedule) =>
        <GridItem xs={6}>
          <ShowCourtStatus {...schedule} date={getValue('date')} get={getViewSchedules} onlyName/>
        </GridItem>
      )}
    </Grid>
    <MenuSportCenter/>
  </RestrictArea>;
};

export default CourtPage;

import React, { useState } from 'react';
import { Grid, GridItem } from '../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../common/designSystem/Typography/Typography';
import Button from '../../../../common/designSystem/Button/Button';
import { RoutePath } from '../../../../routesPath';
import { useNavigate } from 'react-router-dom';
import Dialog from '../../../../common/designSystem/Dialog/Dialog';
import MenuPlayer from '../../../../common/component/MenuPlayer/MenuPlayer';
import UserService from '../../../../common/services/user';

const PlayerAccount = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  return (<>
    <Grid alignContent={'flex-start'} isScrollable height={'100%'} style={{ paddingTop: '24px', paddingBottom: '16px' }} rowGapXs={24} alignItems={'center'}>
      <GridItem xs={6}>
        <Typography variant="h1" >Minha conta</Typography>
      </GridItem>
      {/* <GridItem xs={6}> */}
      {/*   <Typography variant="paragraph" >Altera as informações da sua conta</Typography> */}
      {/* </GridItem> */}
      {/* <GridItem xs={6}> */}
      {/*   <Input name={''} label={'E-mail'} placeholder='jogaja@jogaja.com' isFullWidth required></Input> */}
      {/* </GridItem> */}
      {/* <GridItem xs={6}> */}
      {/*   <Input name={''} label={'Telefone'} placeholder='(99) 99999-9999' isFullWidth required></Input> */}
      {/* </GridItem> */}
      {/* <GridItem xs={6}> */}
      {/*   <Checkbox */}
      {/*     register={register({ initialValue: true })} */}
      {/*     label={<>Desejo receber as comunicações do JogaJá via e-mail</>} */}
      {/*     name={'checkboxLabel'} */}
      {/*   /> */}
      {/* </GridItem> */}
      {/* <GridItem xs={6}> */}
      {/*   <Button isFullWidth color={'primary'} onClick={() => navigate(RoutePath.PLAYER_PASSWORD_SETTING)} variant={'filled'} icon={<Typography variant='paragraph' colorVariant='scrim' notIsContrastColor>Alterar minha senha</Typography>}></Button> */}
      {/* </GridItem> */}
      <GridItem xs={6}>
        <Button isFullWidth color={'error'} onClick={() => {
          UserService.logout(); navigate(RoutePath.LOGIN);
        }} variant={'select-border'} icon={<Typography variant='paragraph' color='error' notIsContrastColor>Sair</Typography>}></Button>
      </GridItem>
      <GridItem xs={6}>
        <Button isFullWidth color={'error'} onClick={() => setOpenDialog(true) } variant={'transparent'} icon={<Typography variant='paragraph' color='error' notIsContrastColor>Excluir minha conta</Typography>}></Button>
      </GridItem>
      <GridItem xs={6}>
        <Dialog
          open={openDialog}
          setOpen={setOpenDialog}
          title={'Excluir Conta'}
          buttonLabelConfirm={'Sim, excluir'}
          buttonLabelCancel={'Cancelelar'}
          onCancel={() => {}}
          onConfirm={() => UserService.deleteCurrentUser(() => navigate(RoutePath.LOGIN))}
        >
          <Typography variant={'paragraph'}>
          Tem certeza que deseja excluir sua conta?
          </Typography>
        </Dialog>
      </GridItem>
    </Grid>
    <MenuPlayer/>
  </>);
};

export default PlayerAccount;

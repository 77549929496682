import React, { useEffect, useState } from 'react';
import { useMercadoPago } from '../../hook/useMercadoPago/mercadopago';
import { getPublicKey } from '../../util/getEnvironment';
import { CardInputProps, PaymentData } from './interface';
import { CardInput, CardNumberInputContainer, ImageCardStyled
} from './styled';
import { Grid, GridItem } from '../Grid/Grid';
import { LabelStyle, Required } from '../Input/styled';
import FrontCard from './FrontCard';
import { formatCnpjCpf } from '../../util/formatText';
import useForm from '../hooks/UseForm/UseForm';
import Select from '../Select/Select';
import Input from '../Input/Input';

const STEPS = { NUMBER: 1, NAME: 0, DATE: 1, CODE: 1, IDENT: 0
};

const InputCard = ({ step, setInstallments, setData, getData }: CardInputProps) => {
  const { setPayment, generateToken, installments, paymentMethods, ids, issuers } = useMercadoPago({ publicKey: getPublicKey, locale: 'pt-BR', placeHolders: { cardNumber: '5555 5555 5555 5555', expirationDate: 'MM/YYYY', securityCode: '123' } }); const [imageCard, setImageCard] = useState('https://jogaja.com/card.png'); const [cardData, setCardData] = useState({ lastFourNumbers: '****' }); const [payment, setPaymentTemp] = useState<PaymentData>({ cardHolderName: 'Nome do Títular', identificationType: 'CPF', identificationNumber: '' }); const { register, getValue } = useForm();

  useEffect(() => {
    if (paymentMethods?.secure_thumbnail) {
      setImageCard(paymentMethods?.secure_thumbnail);
    } if (paymentMethods?.toString().includes('Error:')) {
      setImageCard('https://jogaja.com/card.png');
    } if (setInstallments) {
      setInstallments(installments);
    } generateToken().then((data: any) => {
      setCardData({
        lastFourNumbers: data?.last_four_digits
      });
      const value = {
        cardHolderName: payment.cardHolderName,
        identificationType: payment.identificationType,
        identificationNumber: payment.identificationNumber,
        issuerId: issuers?.id,
        issuerName: issuers?.name,
        lastFourDigits: data?.last_four_digits,
        paymentMethodId: paymentMethods?.id,
        cardImage: paymentMethods?.secure_thumbnail,
        token: data?.id,
        expirationMonth: data?.expiration_month,
        expirationYear: data?.expiration_year
      };
      console.log('Pietro', value);
      setData(value);
    });
  }, [paymentMethods, installments, issuers, payment, getData]);

  useEffect(() => {
    setPayment({ cardHolderName: payment.cardHolderName, identificationType: payment.identificationType, identificationNumber: payment.identificationNumber, userId: '' });
  }, [payment]);

  const handleIdentification = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newText = e.target.value.replace(/[^\d]/g, ''); setPaymentTemp((paymentTemp) => ({ ...paymentTemp, identificationNumber: formatCnpjCpf(getValue('ident'), newText) }));
  };

  return ( <>
    <Grid noMargin rowGapXs={16}>
      <GridItem xs={6} alignItems={'center'}>
        {/* {(step === STEPS.DATE || step === STEPS.CODE) && */}
        {/* <BackCard selected={(step === STEPS.DATE && 'date') || (step === STEPS.CODE && 'code') || ''}/>} */}
        <FrontCard name={payment?.cardHolderName} number={cardData?.lastFourNumbers} selected={(step === STEPS.NAME && 'name') || (step === STEPS.NUMBER && 'number') || ''}/>
      </GridItem>
      <GridItem xs={6}>
        <LabelStyle htmlFor={ids.cardNumber}>{'Número do cartão'} <Required>*</Required></LabelStyle>
        <CardNumberInputContainer>
          <CardInput isCardNumber id={ids.cardNumber}/>
          <ImageCardStyled src={imageCard} id={ids.cardNumberImage}/>
        </CardNumberInputContainer>
      </GridItem>
      <GridItem xs={6}>
        <LabelStyle htmlFor={ids.expirationDate}>{'Data de vencimento'} <Required>*</Required></LabelStyle>
        <CardInput id={ids.expirationDate}/>
      </GridItem>
      <GridItem xs={6}>
        <LabelStyle htmlFor={ids.securityCode}>{'Código de segurança'} <Required>*</Required></LabelStyle>
        <CardInput id={ids.securityCode}/>
      </GridItem>
      <GridItem xs={6}>
        <Input isFullWidth required onChange={(val) => {
          generateToken().then((data: any) => {
            setCardData({ lastFourNumbers: data?.last_four_digits });
            const value = {
              cardHolderName: payment.cardHolderName,
              identificationType: payment.identificationType,
              identificationNumber: payment.identificationNumber,
              issuerId: issuers?.id,
              issuerName: issuers?.name,
              lastFourDigits: data?.last_four_digits,
              paymentMethodId: paymentMethods?.id,
              cardImage: paymentMethods?.secure_thumbnail,
              token: data?.id,
              expirationMonth: data?.expiration_month,
              expirationYear: data?.expiration_year
            };
            setData(value);
          });
          setPaymentTemp((paymentTemp) => ({ ...paymentTemp, cardHolderName: (val.target as HTMLInputElement).value }));
        }} name={'name'} placeholder={'Nome do títular do cartão'} tabIndex={0} style={{ textTransform: 'uppercase' }} label={'Nome do títular do cartão'}/>
      </GridItem>
      <GridItem xs={6}>
        <Input isFullWidth required onChange={handleIdentification} value={payment.identificationNumber} tabIndex={0} placeholder={getValue('ident') === 'CPF' ? '999.999.999-99' : '99.999.999/9999-99'} name={'doc'} label={'Documento do títular'}/>
        <Select options={[{ value: 'CPF', title: 'CPF' }, { value: 'CNPJ', title: 'CNPJ' }]} name={'ident'} label={'Selecione a identificação'} variant={'button'} register={register({ initialValue: 'CPF' })}/>
      </GridItem>
    </Grid>
  </> );
};

export default InputCard;

import React, { useEffect, useState } from 'react';
import { Colors } from '../../../common/designSystem/Colors';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Input from '../../../common/designSystem/Input/Input';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import { getAddressByCep } from '../../../common/services/viacep';
import { formatCEP } from '../../../common/util/formatText';
import { isDefined } from '../../../common/util/validations';
import Button from '../../../common/designSystem/Button/Button';
import Select from '../../../common/designSystem/Select/Select';
import { STATES } from '../../../common/util/dataToSelect';
import { getGeocode } from '../../../common/services/googleMaps';
import { GoogleMap, MarkerF, useLoadScript } from '@react-google-maps/api';
import { RoutePath } from '../../../routesPath';
import { Address, SportCenter } from '../../../common/model/SportCenter';
import SportCenterService from '../../../common/services/sportCenter';
import UserService from '../../../common/services/user';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import Footer from '../../../common/component/Footer/Footer';
import { enqueueSnackbar } from 'notistack';
import { SportCenterStatus } from '../../../common/model/enums/SportCenterStatus';

const SettingAddress = () => {
  const { setBackground } = useGlobalContext();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get('name');
  const { register, values, setValue, getValue, getError, validateForm } = useForm();
  const [viaCepData, setViaCepData] = useState<any>();
  const [geocode, setGeoCode] = useState<any>();
  const [locationPath, setLocationPath] = useState<any>();
  const navigate = useNavigate();
  const [notKnowCep, setNotKnowCep] = useState<boolean>(false);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyChUK2BKKuTEm2WEmaRebd100xKFOqE_kc'
  });
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const userId = async () => (await UserService.getCurrent())!.id;
  const getSportCenter = async () => {
    const sportCenter = await SportCenterService.getByUserId(await userId(), true);
    if (isDefined(sportCenter.workTime)) {
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);

  useEffect(() => {
    if (isDefined(sportCenter?.address) && !isDefined(getValue('cep'))) {
      setValue(sportCenter?.address?.state, 'state');
      setValue(sportCenter?.address?.city, 'city');
      setValue(sportCenter?.address?.neighborhood, 'neighborhood');
      setValue(sportCenter?.address?.street, 'street');
      setValue(sportCenter?.address?.complement, 'complement');
      setValue(sportCenter?.address?.number, 'number');
      setGeoCode(sportCenter?.address);
      setValue(sportCenter?.address?.cep, 'cep');
    }
  }, [sportCenter, getValue('cep')]);

  useEffect(() => {
    setBackground(Colors.neutral.white);
  }, []);

  async function getAddress() {
    if (!getError('cep') && isDefined(getValue('cep') && !notKnowCep)) {
      const cep = (getValue('cep') as string)?.replaceAll('-', '');
      setViaCepData(await getAddressByCep(cep));
    }
  }

  useEffect(() => {
    if (!isDefined(getValue('state')) && !notKnowCep && isDefined(name)) {
      setValue(viaCepData?.uf, 'state');
      setValue(viaCepData?.localidade, 'city');
      setValue(viaCepData?.bairro, 'neighborhood');
      setValue(viaCepData?.logradouro, 'street');
      setValue(viaCepData?.complemento, 'complement');
    }
  }, [viaCepData, values]);

  useEffect(() => {
    if (isDefined(name)) {
      getAddress();
    } else {
      if (isDefined(sportCenter?.address)) {
        if (sportCenter?.address.cep !== getValue('cep')) {
          getAddress();
        }
      }
    }
  }, [getValue('cep'), sportCenter]);

  const save = async () => {
    try {
      if (validateForm()) {
        const id = (await UserService.getCurrent())!.id;
        let sportCenterNew: SportCenter;
        if (isDefined(name)) {
          sportCenterNew = {
            name: name!,
            admins: [id],
            address: ({ ...values, location: locationPath || geocode?.location } as Address),
            status: SportCenterStatus.ENABLED
          };
        } else {
          sportCenterNew = {
            ...sportCenter,
            address: ({ ...values, location: locationPath || geocode?.location } as Address)
          };
        }
        await SportCenterService.save(sportCenterNew);
        enqueueSnackbar('Salvo com sucesso', { variant: 'success' });
        isDefined(name) ? navigate(RoutePath.SPORT_CENTER_SETTING_INFRA + '?name=' + name) :
          navigate(RoutePath.SPORT_CENTER_MY_CENTER);
      } else {
        enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  return (
    <RestrictArea role={'sportCenter'}>
      <Grid alignContent={'start'} isScrollable style={{ paddingBottom: '40px' }} height={'calc(100% - 106px)'} rowGapXs={8}>
        <GridItem xs={6} marginXs={'24px 0'}>
          {isDefined(name) ? <Typography variant={'h3'}>Olá, {name}<br/> seja bem vindo!</Typography> :
            <Typography variant={'h3'}>Endereço do meu centro</Typography>}
        </GridItem>
        <GridItem xs={6}>
          {isDefined(name) ? <Typography variant={'small'}>{isDefined(geocode?.location) ? 'Por favor selecione no mapa onde está seu estabelecimento' : 'Para começar vamos configurar o seu endereço'}</Typography> :
            <Typography variant={'small'}>Altere as informações a qualquer momento</Typography>}
        </GridItem>
        {((!notKnowCep && !isDefined(geocode)) || !isDefined(name)) && <GridItem xs={6} marginXs={'0 0 0 0'}>
          <Input register={register({ onMask: (val) => formatCEP(val), onValidation: (val) => (val as string).length >= 8 })} name={'cep'} label={'CEP'} placeholder={'00000-000'} isFullWidth/>
        </GridItem>}
        {(((isDefined(viaCepData) || notKnowCep) && !isDefined(geocode)) || !isDefined(name)) ? <>
          <GridItem xs={6} {...(notKnowCep ? { marginXs: '24px 0 0 0' } : {})}>
            <Select required register={register()} options={STATES} name={'state'} label={'Estado'} placeholder={'São Paulo'} isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Input required register={register()} name={'city'} label={'Cidade'} placeholder={'São Paulo'} isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Input required register={register()} name={'neighborhood'} label={'Bairro'} placeholder={'Centro'} isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Input required register={register()} name={'street'} label={'Rua'} placeholder={'Av. Paulista'} isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Input register={register()} name={'complement'} label={'Complemento'} placeholder={'Apto. 54'} isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Input required register={register()} name={'number'} label={'Número'} placeholder={'1894'} isFullWidth/>
          </GridItem>
        </> :
          (!isDefined(geocode) && <GridItem xs={6}>
            <Button isFullWidth color={'info'} onClick={() => setNotKnowCep(true)} variant={'transparent'} title={'Não sei meu CEP'}/>
          </GridItem>)}
        {(isLoaded && isDefined(geocode)) && <GridItem alignItems={'center'} style={{ width: '100%', height: '275px' }} xs={6} marginXs={'24px 0 24px 0'}>
          <GoogleMap onClick={(e) => setLocationPath({ lat: e.latLng?.lat(), lng: e.latLng?.lng() })}
            mapTypeId={'hybrid'} mapContainerClassName={'map-container'} center={geocode?.location} zoom={18}>
            {(isDefined(locationPath) || isDefined(geocode?.location)) && <MarkerF position={locationPath || geocode?.location} />}
          </GoogleMap>
        </GridItem>}
        {!isDefined(name) && <GridItem xs={6} marginXs={'0px 0 24px 0'}>
          <Button disabled={!validateForm()} isFullWidth color={'primary'} onClick={async () => {
            setGeoCode(await getGeocode(`${getValue('number')} ${getValue('street') + ', '}${getValue('neighborhood') + ', '}${getValue('city') + ', '}${getValue('state')}, BR`));
          }} variant={'filled'} title={'Buscar'}/>
        </GridItem>}
      </Grid>
      <Footer>
        <Grid noMargin style={{ width: '100%' }} alignItems={'center'}>
          {!isDefined(name) && <GridItem xs={3}>
            <Button color={'error'} onClick={async () => navigate(RoutePath.SPORT_CENTER_MY_CENTER)} isFullWidth variant={'outlined'} title={'Voltar'}/>
          </GridItem>}
          {(isDefined(name) && !isDefined(geocode)) && <GridItem xs={6}><Button disabled={!validateForm()} isFullWidth color={'primary'} onClick={async () => {
            setGeoCode(await getGeocode(`${getValue('number')} ${getValue('street') + ', '}${getValue('neighborhood') + ', '}${getValue('city') + ', '}${getValue('state')}, BR`));
          }} variant={'filled'} title={'Buscar'}/> </GridItem>}
          {isDefined(geocode) && <GridItem xs={isDefined(name) ? 6 : 3}>
            <Button disabled={!validateForm()} color={'info'} onClick={async () => await save()} isFullWidth variant={'filled'} title={isDefined(name) ? 'Próximo' : 'Salvar'}/>
          </GridItem>}
        </Grid>
      </Footer>
    </RestrictArea>
  );
};

export default SettingAddress;

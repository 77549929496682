import React from 'react';

const PadLockShield = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12.6174 23.0231C12.2203 23.1764 11.7803 23.1764 11.3831 23.0231C8.28147 21.807 5.61821 19.6844 3.74065 16.9323C1.86309 14.1802 0.858341 10.9261 0.857422 7.59456V2.57171C0.857422 2.11705 1.03803 1.68102 1.35952 1.35952C1.68102 1.03803 2.11705 0.857422 2.57171 0.857422H21.4289C21.8835 0.857422 22.3195 1.03803 22.641 1.35952C22.9625 1.68102 23.1431 2.11705 23.1431 2.57171V7.57742C23.1457 10.9119 22.1425 14.1697 20.2647 16.9252C18.387 19.6807 15.7218 21.8059 12.6174 23.0231Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5717 9.42871H9.42889C8.48211 9.42871 7.7146 10.1962 7.7146 11.143V14.5716C7.7146 15.5183 8.48211 16.2859 9.42889 16.2859H14.5717C15.5185 16.2859 16.286 15.5183 16.286 14.5716V11.143C16.286 10.1962 15.5185 9.42871 14.5717 9.42871Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.5716 9.42927V7.71498C14.5716 7.3773 14.5051 7.04292 14.3758 6.73094C14.2466 6.41896 14.0572 6.13549 13.8184 5.89671C13.5796 5.65793 13.2962 5.46852 12.9842 5.33929C12.6722 5.21007 12.3378 5.14355 12.0001 5.14355C11.6625 5.14355 11.3281 5.21007 11.0161 5.33929C10.7041 5.46852 10.4206 5.65793 10.1819 5.89671C9.94309 6.13549 9.75368 6.41896 9.62445 6.73094C9.49522 7.04292 9.42871 7.3773 9.42871 7.71498V9.42927" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default PadLockShield;

import React from 'react';

const Perfil = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path d="M12.5006 13.715C14.8675 13.715 16.7863 11.7962 16.7863 9.42927C16.7863 7.06233 14.8675 5.14355 12.5006 5.14355C10.1336 5.14355 8.21484 7.06233 8.21484 9.42927C8.21484 11.7962 10.1336 13.715 12.5006 13.715Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.18018 20.4C5.94516 19.1443 7.0203 18.1066 8.30225 17.3864C9.58419 16.6663 11.0298 16.2881 12.5002 16.2881C13.9705 16.2881 15.4162 16.6663 16.6981 17.3864C17.98 18.1066 19.0552 19.1443 19.8202 20.4" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.5003 23.1431C18.6543 23.1431 23.6431 18.1543 23.6431 12.0003C23.6431 5.84625 18.6543 0.857422 12.5003 0.857422C6.34625 0.857422 1.35742 5.84625 1.35742 12.0003C1.35742 18.1543 6.34625 23.1431 12.5003 23.1431Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Perfil;

import styled from 'styled-components';
import { SwitchStyledProps } from './interface';
import { getColor } from '../Colors';
import { GridItem } from '../Grid/Grid';
import Switch from './Switch';
import React from 'react';

export const WrapperSwitch = styled.div<SwitchStyledProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  label {
    margin-left: 8px;
    -webkit-tap-highlight-color: transparent !important;
    -webkit-touch-callout: none !important;
    -webkit-user-select: none !important;
    -khtml-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    
    ${({ disabled }) => disabled && `
      cursor: not-allowed;
    `}
  }
  
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}
`;
export const SwitchStyled = styled.div<SwitchStyledProps>`
  width: ${({ size }) => size === 'small' ? '32px' : '36px'};
  height: ${({ size }) => size === 'small' ? '16px' : '20px'};
  border-radius: ${({ size }) => size === 'small' ? '8px' : '10px'};
  outline: 1px solid ${getColor('neutral', 'a600')};
  background: ${({ checked }) => checked ? getColor('primary') : getColor('neutral', 'a200')};
  transition: background 0.2s linear;

  ${({ disabled }) => disabled && `
    background-color: ${getColor('neutral', 'a200')};
    outline: 1px solid ${getColor('neutral', 'a400')};
  `}
`;

export const SwitchCircle = styled.div<SwitchStyledProps>`
  width: ${({ size }) => size === 'small' ? '16px' : '20px'};
  height: ${({ size }) => size === 'small' ? '16px' : '20px'};
  border-radius: 50%;
  background-color: ${getColor('neutral', 'white')};
  outline: 1px solid ${getColor('neutral', 'a600')};
  transition: transform 0.2s ease-in-out;
  transform: ${({ checked }) => checked ? 'translateX(16px)' : 'translateX(0)'};
  
  ${({ disabled }) => disabled && `
    background-color: ${getColor('neutral', 'a100')};
    outline: 1px solid ${getColor('neutral', 'a400')};
  `}
`;

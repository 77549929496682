import React from 'react';
import { GridItem } from '../../../../../common/designSystem/Grid/Grid';
import GameSoccer from '../../../../../common/designSystem/Icons/GameSoccer';
import GameVolleyball from '../../../../../common/designSystem/Icons/GameVolleyball';
import GameBasketball from '../../../../../common/designSystem/Icons/GameBasketball';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';

const gameLogos = [
  {
    logo: <GameSoccer></GameSoccer>
  },
  {
    logo: <GameVolleyball></GameVolleyball>
  },
  {
    logo: <GameBasketball/>
  }
];

const GameLogo = () => {
  return (<GridItem xs={6}>
    <Carousel height={'108px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
      {gameLogos.map((obj) => <div style={{ minWidth: '64px', minHeight: '64px', width: '100%' }}>{obj.logo}</div>)}
    </Carousel>
  </GridItem>);
};

export default GameLogo;

import React, { useEffect, useState } from 'react';
import UserService from '../../services/user';
import { isDefined } from '../../util/validations';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import Logo from '../../designSystem/Logo/Logo';
import styled from 'styled-components';
import { Role } from '../../model/User';
import { getLocalStorage, setLocalStorage } from '../../util/localStorage';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
`;
const RestrictArea = ({ children, role }: {children: React.ReactNode, role: Role}) => {
  const [logged, setLogged] = useState(false);
  const navigate = useNavigate();

  function sumRedirectTry() {
    const redirectTry = getLocalStorage('redirectTry');
    if (isDefined(redirectTry)) {
      if (redirectTry <= 2) {
        setLocalStorage(redirectTry + 1, 'redirectTry');
      } else {
        setLocalStorage(null, 'redirectTry');
      }
    } else {
      setLocalStorage(1, 'redirectTry');
    }
  }

  function toLogin() {
    sumRedirectTry();
    setLocalStorage(window.location.pathname+window.location.search, 'redirectPath');
    setTimeout(() => {
      navigate(RoutePath.LOGIN);
    }, 100);
  }

  async function checkLogin() {
    const user = await UserService.getCurrent();
    if (!isDefined(user)) {
      return toLogin();
    }
    if (user?.role !== role) {
      return toLogin();
    }
    setLocalStorage(null, 'redirectTry');
    setLocalStorage(null, 'redirectPath');
    setLogged(true);
  }

  useEffect(() => {
    checkLogin();
  }, [role]);

  return logged ? (
    <>
      {children}
    </>
  ) : (
    <Container>
      <Logo size={'xxl'}/>
    </Container>
  );
};

export default RestrictArea;

import React from 'react';
import { IconProps } from './interface';

const Calendar = ({ width = 17, height = 16 } : IconProps) => {
  return (
    <svg width={width} height={height} style={{ width: (width + 'px'), height: height + 'px' }} viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3004_2109)">
        <path d="M1.60732 3.17874C1.32316 3.17874 1.05063 3.29162 0.849703 3.49256C0.648771 3.69349 0.535889 3.96601 0.535889 4.25017V13.893C0.535889 14.1772 0.648771 14.4497 0.849703 14.6506C1.05063 14.8516 1.32316 14.9645 1.60732 14.9645H13.393C13.6772 14.9645 13.9497 14.8516 14.1506 14.6506C14.3516 14.4497 14.4645 14.1772 14.4645 13.893V4.25017C14.4645 3.96601 14.3516 3.69349 14.1506 3.49256C13.9497 3.29162 13.6772 3.17874 13.393 3.17874H11.2502" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M0.535889 7.46472H14.4645" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.75024 1.03589V5.3216" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11.2502 1.03589V5.3216" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M3.75024 3.17874H9.10739" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      </g>
      <defs>
        <clipPath id="clip0_3004_2109">
          <rect width="15" height="15" fill="white" transform="translate(0 0.5)"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default Calendar;


import styled from 'styled-components';
import { MenuButtonStyledProps } from './interface';

export const MenuButtonStyled = styled.div<MenuButtonStyledProps>`
  display: flex;
  flex-direction: column; 
  align-items: center; 
  min-width: 80px;
  width: fit-content; 
  min-height: 48px;
  word-wrap: unset;
  cursor: pointer;
`;

import axios from 'axios';

export async function getGeocode(address: string): Promise<any> {
  const apiKey = 'AIzaSyChUK2BKKuTEm2WEmaRebd100xKFOqE_kc';
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(address)}&key=${apiKey}`;

  try {
    const response = await axios.get(url);
    return response.data?.results[0]?.geometry;
  } catch (error) {
    console.error('Error retrieving geocode:', error);
    throw error;
  }
}

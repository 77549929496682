import React, { useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import BigButton from '../../../common/designSystem/BigButton/BigButton';
import Typography from '../../../common/designSystem/Typography/Typography';
import Games from '../../../common/designSystem/Icons/Games';
import SettingSliderHorizontal from '../../../common/designSystem/Icons/SettingSliderHorizontal';
import LocationPinNavMap from '../../../common/designSystem/Icons/LocationPinNavMap';
import MenuSportCenter from '../../../common/component/MenuSportCenter/MenuSportCenter';
import Button from '../../../common/designSystem/Button/Button';
import UserService from '../../../common/services/user';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import RestrictArea from '../../../common/component/RestrictArea/RestrictArea';
import Dialog from '../../../common/designSystem/Dialog/Dialog';

const MyCenter = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  return (<RestrictArea role={'sportCenter'}>
    <Grid alignContent={'start'} isScrollable height={'calc(100%)'} style={{ paddingTop: '24px', paddingBottom: '16px' }} rowGapXs={16} alignItems={'center'}>
      <GridItem xs={6}>
        <Typography variant={'h3'}>Meu centro</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Typography variant={'paragraphSmall'}>Edite todas suas informações, adicione ou remova quadras.</Typography>
      </GridItem>
      <GridItem xs={6}>
        <BigButton onClick={() => navigate(RoutePath.SPORT_CENTER_SETTING_COURT) } title={'Minhas quadras'} description={'Nome, tipo, preço, esportes e jogadores'} iconTop={<Games></Games>}></BigButton>
      </GridItem>
      <GridItem xs={6}>
        <BigButton onClick={() => navigate(RoutePath.SPORT_CENTER_SETTING_INFRA) } title={'Atendimento e infraestrutura'} description={'Dia, turno e infra estrutura'} iconTop={<SettingSliderHorizontal></SettingSliderHorizontal>}></BigButton>
      </GridItem>
      <GridItem xs={6}>
        <BigButton onClick={() => navigate(RoutePath.SPORT_CENTER_SETTING_ADDRESS) } title={'Endereço'} description={'Edite seu endereço'} iconTop={<LocationPinNavMap width={24} height={24}></LocationPinNavMap>}></BigButton>
      </GridItem>
      <GridItem xs={6}>
        <Button isFullWidth color={'error'} onClick={() => {
          UserService.logout(); navigate(RoutePath.LOGIN);
        }} variant={'select-border'} icon={<Typography variant='paragraph' color='error' notIsContrastColor>Sair</Typography>}></Button>
      </GridItem>
      <GridItem xs={6}>
        <Button isFullWidth color={'error'} onClick={() => setOpenDialog(true) } variant={'transparent'} icon={<Typography variant='paragraph' color='error' notIsContrastColor>Excluir minha conta</Typography>}></Button>
      </GridItem>
      <GridItem xs={6}>
        <Dialog
          open={openDialog}
          setOpen={setOpenDialog}
          title={'Excluir Conta'}
          buttonLabelConfirm={'Sim, excluir'}
          buttonLabelCancel={'Cancelelar'}
          onCancel={() => {}}
          onConfirm={() => UserService.deleteCurrentUser(() => navigate(RoutePath.LOGIN))}
        >
          <Typography variant={'paragraph'}>
          Tem certeza que deseja excluir sua conta?
          </Typography>
        </Dialog>
      </GridItem>
    </Grid>
    <MenuSportCenter></MenuSportCenter>
  </RestrictArea>
  );
};

export default MyCenter;

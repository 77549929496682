import React, { useContext, useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import Button from '../../../../../common/designSystem/Button/Button';
import CircleProgress from '../../../../../common/component/Charts/CircleProgress/CircleProgress';
import { Colors } from '../../../../../common/designSystem/Colors';
import { RoundDiv } from './styled';
import Bars from '../../../../../common/component/Charts/Bars/Bars';
import { ViewSchedules } from '../../../../../common/model/SportCenter';
import { isDefined } from '../../../../../common/util/validations';
import { SportCenterHomeContext } from '../../Home';
import ScheduleService from '../../../../../common/services/schedule';

const ManagementMyCenter = () => {
  const { sportCenter } = useContext(SportCenterHomeContext);
  const [schedules7Days, setSchedules7Days] = useState([] as {countReservedDay: number, countAvailableDay: number, day: string}[]);
  const [countBars, setCountBars] = useState<{ available: number, reserved: number } | undefined>();
  const [countCircle, setCountCircle] = useState<{ available: number, reserved: number } | undefined>();

  async function getSchedules7Days() {
    if (isDefined(sportCenter?.courts)) {
      if (isDefined(sportCenter?.courts)) {
        let updatedCourts = [] as {countReservedDay: number, countAvailableDay: number, day: string}[];
        const hoje = new Date();
        let countAvailable = 0;
        let countReserved = 0;
        for (let i = 1; i <= 7; i++) {
          const date = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() + (i - 7));
          const viewSchedules = await ScheduleService.getViewSchedules(undefined, date);
          let countAvailableDay = 0;
          let countReservedDay = 0;
          viewSchedules.forEach((viewSchedule) => {
            if (viewSchedule.status == 'reserved') {
              countReserved++;
              countReservedDay++;
            } else {
              countAvailable++;
              countAvailableDay++;
            }
          });
          const dayWeek = ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'];
          const dayWeekIndex = date.getDay();
          updatedCourts = [...updatedCourts, { countReservedDay, countAvailableDay, day: dayWeek[dayWeekIndex] }];
        }
        setCountBars({ available: countAvailable, reserved: countReserved });
        setSchedules7Days([
          ...updatedCourts
        ]);
      }
    }
  }

  async function getSchedules30Days() {
    if (isDefined(sportCenter?.courts)) {
      if (isDefined(sportCenter?.courts)) {
        let countAvailable = 0;
        let countReserved = 0;
        const hoje = new Date();
        const primeiroDiaDoMes = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
        let dates = [] as Date[];
        for (let dia = primeiroDiaDoMes.getDate(); dia <= new Date(hoje.getFullYear(), hoje.getMonth() + 1, 0).getDate(); dia++) {
          const dataAtual = new Date(hoje.getFullYear(), hoje.getMonth(), dia);
          dates = [...dates, dataAtual];
        }
        const viewSchedules = await ScheduleService.getViewSchedules(undefined, dates);
        viewSchedules.forEach((viewSchedule) => {
          if (viewSchedule.status == 'reserved') {
            countReserved++;
          } else {
            countAvailable++;
          }
        });
        setCountCircle({ available: countAvailable, reserved: countReserved });
      }
    }
  }

  useEffect(() => {
    if (!isDefined(countBars)) {
      getSchedules7Days();
    }
    if (!isDefined(countCircle)) {
      getSchedules30Days();
    }
  }, [sportCenter, countBars, countCircle]);

  return (countCircle || isDefined(schedules7Days)) ? (
    <>
      <GridItem xs={4}>
        <Typography variant={'small'} bold>Gestão do meu centro</Typography>
      </GridItem>
      <GridItem xs={2} alignItems={'flex-end'}>
        {/* <Button color={'primary'} onClick={() => {}} variant={'transparent'} title={'ver mais'}/> */}
      </GridItem>
      {countCircle && <GridItem xs={6} style={{ borderRadius: '8px', padding: '8px 16px' }} color={'neutral'} colorVariant={'white'}>
        <Grid noMargin rowGapXs={16} alignContent={'flex-start'}>
          <GridItem xs={6} style={{ height: 'fit-content' }}>
            <Typography variant={'small'} bold>Horários</Typography>
          </GridItem>
          <GridItem sm={1} xs={3}>
            <CircleProgress data={[
              { value: countCircle.available, color: Colors.chart6.main },
              { value: countCircle.reserved, color: Colors.chart2.main }
            ]} strokeWidth={16} size={100}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant={'xSmall'}>total</Typography>
                <Typography variant={'small'} bold>{countCircle.available + countCircle.reserved}</Typography>
              </div>
            </CircleProgress>
          </GridItem>
          <GridItem sm={5} xs={3} justifyContent={'space-evenly'}>
            <GridItem xs={6} justifyContent={'space-evenly'}>
              <Typography variant={'small'}>No mês atual de todas as quadras</Typography>
            </GridItem>
            <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
              <RoundDiv color={Colors.chart6.main}/>
              <Typography variant={'small'} bold>{countCircle.available}</Typography>
              <Typography variant={'small'}>Disponível</Typography>
            </GridItem>
            <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
              <RoundDiv color={Colors.chart2.main}/>
              <Typography variant={'small'} bold>{countCircle.reserved}</Typography>
              <Typography variant={'small'}>Reservado</Typography>
            </GridItem>
          </GridItem>
        </Grid>
      </GridItem>}
      {isDefined(schedules7Days) && <GridItem xs={6} style={{ borderRadius: '8px', padding: '8px 16px' }} color={'neutral'} colorVariant={'white'}>
        <Grid noMargin alignContent={'flex-start'} rowGapXs={16}>
          <GridItem xs={6}>
            <Typography variant={'small'} bold>Últimos 7 dias de todas as quadras</Typography>
          </GridItem>
          <GridItem xs={6}>
            <Bars data={schedules7Days.map((day) => (
              [{ value: (day?.countAvailableDay + day?.countReservedDay), color: Colors.chart6.main }, { value: day?.countReservedDay, color: Colors.chart2.main }]
            ))}
            legend={{
              bottom: schedules7Days.map((obj) => obj.day),
              leftCount: 4
            }}
            />
          </GridItem>
          <GridItem sm={5} xs={3} justifyContent={'space-evenly'}>
            <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
              <RoundDiv color={Colors.chart6.main}/>
              <Typography variant={'small'} bold>{countBars?.available}</Typography>
              <Typography variant={'small'}>Disponível</Typography>
            </GridItem>
            <GridItem xs={6} flexDirection={'row'} style={{ gap: '8px' }} alignItems={'center'}>
              <RoundDiv color={Colors.chart2.main}/>
              <Typography variant={'small'} bold>{countBars?.reserved}</Typography>
              <Typography variant={'small'}>Reservado</Typography>
            </GridItem>
          </GridItem>
        </Grid>
      </GridItem>}
    </>
  ) : <></>;
};

export default ManagementMyCenter;

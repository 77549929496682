import React from 'react';

const ShieldCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M12.5629 22.1638C12.1971 22.3049 11.7918 22.3049 11.426 22.1638C8.57155 21.0678 6.11665 19.1319 4.38563 16.6114C2.65459 14.091 1.72886 11.1047 1.73072 8.04712V3.31C1.73072 2.89122 1.89708 2.48957 2.1932 2.19345C2.48934 1.89732 2.89097 1.73096 3.30975 1.73096H20.6792C21.0979 1.73096 21.4996 1.89732 21.7958 2.19345C22.0919 2.48957 22.2583 2.89122 22.2583 3.31V8.04712C22.26 11.1047 21.3344 14.091 19.6034 16.6114C17.8723 19.1319 15.4174 21.0678 12.5629 22.1638Z" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16.7765 7.22363L10.4079 14.3883L7.22363 12.0001" stroke="#2C2B3A" strokeWidth="1.71" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default ShieldCheck;

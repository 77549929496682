import styled from 'styled-components';
import { ModalCourtStyledProps } from './interface';

export const Icon = styled.div`
  display: block;
  width: 16px;
  height: 16px;
`;

export const ModalCourt = styled.div<ModalCourtStyledProps>`
  display: block;
  width: 100%;
  height: 100%;
  position: fixed;
  left: ${({ open }) => open ? '0' : '-100%'};
  z-index: 4;
  transition: all 0.5s;
`;

import { isDefined } from './validations';

export const generateNumberRange = (start: number, end: number, step = 0.5): number[] => {
  const range = [];
  for (let i = start; i <= end; i += step) {
    range.push(i);
  }
  return range;
};

export const getPriceTax = (price: number, percentage: number) => {
  if (isDefined(percentage)) {
    const value = price * percentage;
    return value >= 0.01 ? value : 0.01;
  }
  return undefined;
};

export const getDiscountedPrice = (price: number, discount: number): number => parseFloat((price - (price * discount)).toFixed(2));

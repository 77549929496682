import React from 'react';
import { ButtonCePriceStyled } from './styled';
import Typography from '../../designSystem/Typography/Typography';
import { ButtonCePriceProps } from './interface';
import { isDefined } from '../../util/validations';

const ButtonSPPrice = ({
  name,
  type,
  sportType,
  price,
  discountedPrice,
  margin,
  onClick
}: ButtonCePriceProps) => {
  return (
    <ButtonCePriceStyled margin={margin} onClick={() => onClick()}>
      <Typography variant={'body'} bold style={{ marginBottom: '8px' }}>
        {name}
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%', columnGap: '10px' }}>
        <Typography variant={'tiny'} style={{ marginBottom: '8px', columnGap: '20px', textAlign: 'left', width: '70%' }}>{sportType.join(' - ')}</Typography>
        <Typography variant={'tiny'} style={{ marginBottom: '8px', columnGap: '20px', textAlign: 'right', marginLeft: '16px', width: 'calc(30% - 16px)' }}>
          {isDefined(type) && `Quadra ${type}`}
        </Typography>
      </div>
      <Typography variant={'tiny'} bold style={{ marginBottom: '8px' }}>
        Valor
      </Typography>
      <Typography
        variant={'xSmall'}
        color={discountedPrice ? 'neutral' : 'info'}
        colorVariant={'a500'}
        notIsContrastColor
        lineThrough={!!discountedPrice}
      >
        {price}
      </Typography>
      {!!discountedPrice && <Typography
        variant={'xSmall'}
        color={'info'}
        colorVariant={'a500'}
        notIsContrastColor
      >
        {discountedPrice}
      </Typography>
      }
    </ButtonCePriceStyled>
  );
};

export default ButtonSPPrice;

import styled from 'styled-components';
import { getColor } from '../Colors';
import { BorderRadius, Shadows } from '../GlobalStyle';
import { BigButtonStyledProps } from './interface';

export const BigButtonStyled = styled.button<BigButtonStyledProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  outline: none;
  background: ${getColor('neutral', 'white')};
  width: 100%;
  height: 100px;
  cursor: pointer;
  padding: 24px 33px;
  border: 0.5px solid ${getColor('neutral', 'a100')};
  box-shadow: ${Shadows.med};
  border-radius: ${BorderRadius.medium};
  ${({ margin }) => margin && `margin: ${margin};`}
  
  :hover {
    filter: brightness(0.95);
  }
`;

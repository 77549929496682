import styled from 'styled-components';
import { getColor } from '../Colors';
import { ColumnProps } from './interface';
import { BorderRadius } from '../GlobalStyle';

export const CalendarStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 364px;
  box-sizing: border-box;
  background: ${getColor('neutral', 'white')};
  border-radius: ${BorderRadius.medium};
  padding: 8px;
`;

export const CalendarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
`;

export const CalendarBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`;

export const WeekDays = styled.div`
  display: grid;
  grid-template-columns: repeat(7, calc(100% / 7));
  height: 40px;
  margin-bottom: 8px;
`;

export const WeekDaysColumn = styled.div<ColumnProps>`
  height: 40px;
  width: 40px;
  border: 1px solid ${getColor('transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
`;
export const Column = styled.div<ColumnProps>`
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  border: 1px solid ${getColor('transparent')};
  ${({ selectDay }) => selectDay && `
    border: 1px solid ${getColor('primary')};
    background: ${getColor('primary', 'a100')};
  `}
  cursor: pointer;
  :hover {
    background: ${getColor('primary', 'a200')};
    border: 1px solid ${getColor('primary')};
  }
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    background: ${getColor('transparent')};
    color: ${getColor('neutral', 'a400')};
    border: 1px solid ${getColor('transparent')};
    :hover {
      background: ${getColor('transparent')};
      border: 1px solid ${getColor('transparent')};
    }
  `}
`;

export const EmptyColumn = styled.div`
  height: 40px;
  width: 40px;
  border: 1px solid ${getColor('transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Days = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  flex: 1;
`;

export const Title = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
`;

import styled from 'styled-components';
import { GridItem } from '../Grid/Grid';

export const Day = styled(GridItem)`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 4px 0;
  border-radius: 4px;
  width: 33px;
  height: 42px;
  cursor: pointer;
  z-index: 1;
  transition: background-color 0.3s;
  
  :hover {
    filter: brightness(0.9);
  }
`;

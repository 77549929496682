export const active = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" fill="#FFF8EC" stroke="#F2CF8F"/>
    <circle cx="8" cy="8" r="4" fill="#F2CF8F"/>
  </svg>
);

export const inactive = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" stroke="#6A7381"/>
  </svg>
);

export const disabledActive = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" fill="#F8F9FB" stroke="#A8B1BD"/>
    <circle cx="8" cy="8" r="4" fill="#A8B1BD"/>
  </svg>
);

export const disabledInactive = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="7.5" stroke="#CDD3DD"/>
  </svg>
);

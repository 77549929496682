import React from 'react';

const InterfaceUserCheck = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M23.1434 13.7148L16.0805 23.1434L11.3662 19.612" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.57174 8.57171C10.702 8.57171 12.4289 6.84481 12.4289 4.71456C12.4289 2.58432 10.702 0.857422 8.57174 0.857422C6.4415 0.857422 4.7146 2.58432 4.7146 4.71456C4.7146 6.84481 6.4415 8.57171 8.57174 8.57171Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.14314 21.4293H0.857422V18.8579C0.858763 17.3331 1.31192 15.843 2.15967 14.5756C3.00742 13.3083 4.21173 12.3205 5.62049 11.7372C7.02926 11.1539 8.57929 11.0012 10.0748 11.2983C11.5704 11.5953 12.9443 12.329 14.0231 13.4065" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default InterfaceUserCheck;

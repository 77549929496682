import React from 'react';

const Society = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3071_2815)">
        <path d="M0.5 12C0.5 11.1774 0.586284 10.3755 0.750139 9.60265L4.94319 8.53061L7.5 9.80902V13.7324L4.92221 15.4509L0.693521 14.1125C0.566475 13.4281 0.5 12.722 0.5 12ZM8 8.94098L5.44173 7.66185L4.54765 3.24112C5.65774 2.29566 6.94938 1.55778 8.36257 1.08698L11.5 4.20788V7.19098L8 8.94098ZM1.04362 8.49545C1.58158 6.81216 2.49703 5.29693 3.69091 4.04956L4.41571 7.6333L1.04362 8.49545ZM12 3.29475L9.47083 0.778901C10.2843 0.596382 11.1306 0.5 12 0.5C12.8694 0.5 13.7157 0.596382 14.5292 0.778901L12 3.29475ZM12.5 4.20788L15.6374 1.08698C17.0506 1.55778 18.3423 2.29566 19.4523 3.24112L18.5619 7.64376L15.5319 8.94235L12.5 7.20984V4.20788ZM19.5791 7.65885L20.3091 4.04956C21.5635 5.36016 22.5105 6.96645 23.0353 8.75275L19.5791 7.65885ZM19.0262 8.53275L23.3065 9.88748C23.4335 10.5719 23.5 11.278 23.5 12C23.5 12.7102 23.4357 13.405 23.3126 14.079L19.0516 14.9781L16 13.6703V9.8297L19.0262 8.53275ZM19.5663 15.8915L23.0623 15.1538C22.5298 17.0253 21.5349 18.7027 20.2083 20.0544L19.5663 15.8915ZM18.5481 15.8503L19.322 20.8683C18.3648 21.6595 17.2777 22.2985 16.0977 22.7486L12.5 19.7651V16.2902L15.5319 14.5577L18.5481 15.8503ZM12 20.6496L14.9703 23.1129C14.0232 23.3653 13.0277 23.5 12 23.5C10.9723 23.5 9.97677 23.3653 9.02966 23.1128L12 20.6496ZM11.5 19.7651L7.90229 22.7486C6.6684 22.278 5.53605 21.6007 4.54765 20.7589L5.44915 16.3015L8.03592 14.577L11.5 16.309V19.7651ZM4.42088 16.3412L3.69091 19.9504C2.43651 18.6398 1.48949 17.0335 0.964723 15.2472L4.42088 16.3412ZM11.9843 15.4331L8.5 13.691V9.80902L11.9843 8.06688L15 9.79016V13.7098L11.9843 15.4331Z" stroke="#2C2B3A"/>
      </g>
      <defs>
        <clipPath id="clip0_3071_2815">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Society;

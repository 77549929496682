import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import Typography from '../../../../../common/designSystem/Typography/Typography';
import FavoriteStar from '../../../../../common/designSystem/Icons/FavoriteStar';
import { SportCenter } from '../../../../../common/model/SportCenter';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../../../routesPath';

const CenterHighlights = ({ center } : {center: SportCenter}) => {
  const [courts, setCourts] = useState({ uncovered: 0, covered: 0 });
  const navigate = useNavigate();

  useEffect(() => {
    let uncovered = 0;
    let covered = 0;
    center.courts?.forEach((court) => {
      if (court.type === 'covered') {
        covered++;
      } else {
        uncovered++;
      }
    });
    setCourts({ uncovered, covered });
  }, [center]);
  return (
    <Grid
      noMargin
      style={{ height: '160px', borderRadius: '10px', cursor: 'pointer', background: `linear-gradient(#1D1C20b8, #1D1C20b8), url(${require('../../../../../static/img/center-highlight.png')})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}
      onClick={() => navigate(RoutePath.PLAYER_RESERVATION + '?sportCenterId=' + center.id!)}
    >
      <GridItem xs={6} padding='45px 0 0 16px' justifyContent='end'>
        <Typography variant={'h3'} color='primary' colorVariant='a400' notIsContrastColor>{center.name}</Typography>
        <Typography variant={'paragraphSmall'} color='primary' colorVariant='a400' notIsContrastColor>{courts.covered} Quadras cobertas</Typography>
        <Typography variant={'paragraphSmall'} color='primary' colorVariant='a400' notIsContrastColor>{courts.uncovered} Quadra descoberta</Typography>
      </GridItem>
      <GridItem xs={6} isRow padding='0 0 0 16px' style={{ columnGap: '3px' }} >
        {/* <Typography variant={'paragraphSmall'} color='primary' colorVariant='a400' notIsContrastColor>5</Typography> */}
        {/* <FavoriteStar/> */}
      </GridItem>
    </Grid>
  );
};

export default CenterHighlights;

export const isNotNull = (value : any) => value !== null;

export const isNotUndefined = (value : any) => value !== undefined;

export const isNotBlank = (value : any) => String(value).trim().length > 0;

export const isNotEmpty = (value : any | any[]) => Array.isArray(value) ? Array(value).length > 0 && Object.values(value).length > 0 : true;

export const isNotEmptyObject = (value : any | any[]) => typeof value === 'object' && !(value instanceof Date) ? Object.keys(value).length > 0 : true;

export const isDefined = (value: any | any[]) => isNotNull(value) && isNotUndefined(value) && isNotBlank(value) && isNotEmpty(value) && isNotEmptyObject(value);

export function validatePassword(password: string): string[] {
  const errors: string[] = [];

  if (!isDefined(password)) return [];

  const lowercaseRegex = /[a-z]/;
  const uppercaseRegex = /[A-Z]/;
  const numericRegex = /[0-9]/;
  const nonAlphanumericRegex = /[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`]/;
  const minLength = 6;
  const maxLength = 4096;

  if (!lowercaseRegex.test(password)) {
    errors.push('A senha deve conter pelo menos uma letra minúscula.');
  }

  if (!uppercaseRegex.test(password)) {
    errors.push('A senha deve conter pelo menos uma letra maiúscula.');
  }

  if (!numericRegex.test(password)) {
    errors.push('A senha deve conter pelo menos um caractere numérico.');
  }

  if (!nonAlphanumericRegex.test(password)) {
    errors.push('A senha deve conter pelo menos um caractere não alfanumérico.');
  }

  if (password.length < minLength || password.length > maxLength) {
    errors.push(`A senha deve ter entre ${minLength} e ${maxLength} caracteres.`);
  }

  return errors;
}

export const validateEmail = (email: string) => {
  if (!isDefined(email)) return true;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string) => {
  if (!isDefined(phone)) return true;
  const phoneRegex = /^\(\d{2}\) \d{5}-\d{4}$/;
  return phoneRegex.test(phone);
};

import React, { createContext, useContext, ReactNode, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { BackofficeProps } from '../common/model/Backoffice';
import useScreen from '../common/hook/useScreen';
import UserService from '../common/services/user';
import BackofficeService from '../common/services/backoffice';
import Society from '../common/designSystem/Icons/Society';
import BeachTennis from '../common/designSystem/Icons/BeachTennis';
import Volleyball from '../common/designSystem/Icons/Volleyball';
import Footvolley from '../common/designSystem/Icons/Footvolley';
import Basketball from '../common/designSystem/Icons/Basketball';
import Futsal from '../common/designSystem/Icons/Futsal';
import BeachVolley from '../common/designSystem/Icons/BeachVolley';
import Soccer from '../common/designSystem/Icons/Soccer';
import { save } from '../common/services/firestore';

interface GlobalContextProps {
  children: ReactNode;
}

interface GlobalContextValue {
  backoffice?: BackofficeProps,
  setBackground: Dispatch<SetStateAction<string>>
}

const GlobalContext = createContext<GlobalContextValue>({} as GlobalContextValue);

const GlobalContextProvider: React.FC<GlobalContextProps> = ({ children }) => {
  const [background, setBackground] = useState('#F8F9FB');
  const [backoffice, setBackoffice] = useState<BackofficeProps>();
  const root = document.getElementById('root');
  useScreen(false);


  const initialState: GlobalContextValue = {
    setBackground
  };

  useEffect(() => {
    const buttonIcon = {
      '4': <Society />,
      '1': <BeachTennis />,
      '3': <Volleyball />,
      'se': <Footvolley />,
      'sem': <Basketball />,
      'id': <Futsal />,
      'aqui': <BeachVolley />,
      '2': <Soccer />,
      '5': <Soccer />
    } as {[id: string]: JSX.Element};
    BackofficeService.get().then((backoffice: BackofficeProps) => {
      backoffice.sports.map((sport) => {
        sport.icon = buttonIcon[sport.id.toString()];
        return sport;
      });
      setBackoffice(backoffice);
    });
  }, []);

  useEffect(() => {
    document.body.style.background = '#fff';
    root!.style.background = background;
    UserService.init();
  }, [background]);

  const state = {
    ...initialState,
    backoffice
  } as GlobalContextValue;

  return <GlobalContext.Provider value={state}>{children}</GlobalContext.Provider>;
};

export const useGlobalContext = () => useContext(GlobalContext);

export default GlobalContextProvider;

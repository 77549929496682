import { get, query, remove, save } from './firestore';
import { collections } from '../constant/collections';
import { Team } from '../model/Team';
import UserService from './user';
import { isDefined } from '../util/validations';

class TeamServiceProvider {
  save = async (data: Team) => {
    return await save(collections.team, data);
  };

  get = async (id: string): Promise<Team> => {
    return (await get(`${collections.team}/${id}`)) as Team;
  };

  getAllByPlayer = async (id: string): Promise<Team[]> => {
    return (await query({
      collectionPath: collections.team,
      query: [
        {
          field: 'playersIds',
          operator: 'array-contains',
          value: id
        }
      ],
      page: 0, size: 999
    })) as Team[];
  };

  getBySchedule = async (id: string): Promise<Team> => {
    return (await query({
      collectionPath: collections.team,
      query: [
        {
          field: 'scheduleId',
          operator: '==',
          value: id
        }
      ],
      page: 0, size: 999
    }))[0] as Team;
  };

  getAllMy = async () => {
    const user = await UserService.getCurrent();
    if (isDefined(user?.id)) {
      return await this.getAllByPlayer(user!.id!);
    }
    return [];
  };

  delete = async (id: string) => {
    return await remove(collections.team, id);
  };
}

const TeamService = new TeamServiceProvider();
export default TeamService;

import React from 'react';

const UserDelete = () => {
  return (<>
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
      <path d="M8.14324 8.1431C9.91844 8.1431 11.3575 6.70401 11.3575 4.92881C11.3575 3.15361 9.91844 1.71452 8.14324 1.71452C6.36804 1.71452 4.92896 3.15361 4.92896 4.92881C4.92896 6.70401 6.36804 8.1431 8.14324 8.1431Z" stroke="#C61A0B" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.14317 18.8574H1.7146V16.7145C1.71425 15.5887 2.00956 14.4825 2.571 13.5067C3.13244 12.5308 3.9403 11.7195 4.91376 11.154C5.88722 10.5884 6.99214 10.2884 8.11796 10.284C9.24378 10.2796 10.351 10.5709 11.3289 11.1288" stroke="#C61A0B" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.2859 14.2292L14.2288 20.2863" stroke="#C61A0B" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14.2288 14.2292L20.2859 20.2863" stroke="#C61A0B" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  </>);
};

export default UserDelete;

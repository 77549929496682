import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyChUK2BKKuTEm2WEmaRebd100xKFOqE_kc',
  authDomain: 'jogaja-78903.firebaseapp.com',
  projectId: 'jogaja-78903',
  storageBucket: 'jogaja-78903.appspot.com',
  messagingSenderId: '16788317579',
  appId: '1:16788317579:web:9b04096ef2d125c032bb8e',
  measurementId: 'G-DLC7QX7SSY'
};

export const appFirebase = initializeApp(firebaseConfig);
export const functions = getFunctions(appFirebase, 'southamerica-east1');
export const database = getFirestore();

import React from 'react';

const BeachVolley = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3071_2817)">
        <path d="M13.3741 17.78C19.3362 14.8761 21.5183 12.0924 20.3195 4.06049M15.9785 8.00329C10.9592 4.16194 7.47425 4.44484 1.01525 8.58548M6.42848 10.046C5.65003 15.8533 6.85219 18.9992 14.7828 23.1611M12 11.5C11.5657 14.4726 11.8206 16.4696 12.9158 18M18.9224 21.184C16.4093 20.2256 14.7363 19.3247 13.7001 18.1772C16.6734 16.719 18.8031 15.2357 20.0011 13.0279C21.1175 10.9706 21.3824 8.36479 20.9186 4.73928C22.5327 6.71942 23.5 9.24648 23.5 12C23.5 15.7519 21.7038 19.0842 18.9224 21.184ZM19.7214 3.47752C20.4381 7.79378 20.218 10.5315 19.1222 12.551C18.057 14.514 16.1136 15.8951 13.0942 17.3601C12.3259 16.0701 12.1164 14.375 12.4572 11.8399C14.3995 10.9359 15.8893 10.0104 16.7969 8.58047C17.7815 7.02935 18.0222 4.98346 17.6338 1.9721C18.3861 2.39567 19.086 2.90152 19.7214 3.47752ZM12.5351 18.3269C13.6185 19.8178 15.4134 20.8609 17.9153 21.8641C17.1576 22.3195 16.3433 22.69 15.4856 22.9624C11.2381 20.7842 8.92965 18.9457 7.76531 16.9579C6.65073 15.055 6.53883 12.9355 6.93428 10.0368C8.30264 10.0763 9.70783 10.6367 11.4606 11.7476C11.0794 14.6222 11.349 16.6948 12.5351 18.3269ZM6.90244 17.4633C8.11089 19.5264 10.3435 21.3379 14.0222 23.3229C13.3659 23.4392 12.6902 23.5 12 23.5C5.96659 23.5 1.01808 18.8535 0.538166 12.944C2.73002 11.2376 4.3902 10.3455 5.91654 10.1033C5.54171 12.9842 5.64718 15.3203 6.90244 17.4633ZM6.47633 9.04174C4.61491 9.12995 2.76106 10.0313 0.503507 11.7131C0.523713 10.8873 0.630904 10.0835 0.816115 9.3104C4.24594 7.06378 6.7549 5.92101 9.01934 5.80262C11.181 5.6896 13.2069 6.50824 15.6924 8.41398C14.9268 9.39816 13.7408 10.1386 12.0401 10.931C10.0225 9.64607 8.29078 8.95576 6.47633 9.04174ZM12 0.5C13.6164 0.5 15.1538 0.833199 16.5481 1.43425C16.9975 4.38301 16.8568 6.23513 16.2214 7.55968C13.6732 5.62155 11.4366 4.67487 8.96713 4.80398C6.67536 4.92381 4.25882 5.96873 1.28082 7.82584C2.95173 3.53798 7.12161 0.5 12 0.5Z" stroke="#2C2B3A"/>
      </g>
      <defs>
        <clipPath id="clip0_3071_2817">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default BeachVolley;

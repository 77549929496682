import { getColor } from '../../designSystem/Colors';
import styled from 'styled-components';

export const FooterStyled = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => hidden ? 'none' : 'flex' };
  position: absolute;
  justify-content: space-between;
  align-items: center;
  padding: 10px 24px;
  height: 66px;
  width: calc(100% - 48px);
  background: ${getColor('neutral', 'white')};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.25);
  bottom: 0;
  z-index: 2;
`;

export const UseSpace = styled.div<{ hidden: boolean }>`
  display: ${({ hidden }) => hidden ? 'none' : 'flex' };
  height: 86px;
  width: 100%;
`;

export enum RoutePath {
  SPORT_CENTER_MP_RECEIVE = '/mp-receive',
  SPORT_CENTER_CREATE_ACCOUNT = '/sport-center/create-account',
  SPORT_CENTER_ONBOARDING = '/sport-center/onboarding',
  SPORT_CENTER_SETTING_ADDRESS = '/sport-center/setting-address',
  SPORT_CENTER_SETTING_INFRA = '/sport-center/setting-infra',
  SPORT_CENTER_SETTING_COURT = '/sport-center/setting-court',
  SPORT_CENTER_COURT = '/sport-center/court',
  SPORT_CENTER_MY_COURTS = '/sport-center/my-courts',
  SPORT_CENTER_MY_CENTER = '/sport-center/my-center',
  SPORT_CENTER_HOME = '/sport-center/home',
  PLAYER_RESERVATION = '/player/reservation',
  PLAYER_GAME = '/player/players-game',
  MY_GAMES = '/player/my-games',
  PLAYER_INVITE = '/player/invite',
  PLAYER_HOME = '/player/home',
  PLAYER_CREATE_ACCOUNT = '/player/create-account',
  PLAYER_ACCOUNT = '/player/player-account',
  PLAYER_PASSWORD_SETTING = '/player/player-setting-password',
  SPLASH_SCREEN = '/',
  LOGIN = '/login',
  CREATE_ACCOUNT = '/create-account',
  COMPONENTS = '/components',
  NOT_FOUND = '/404',
  WILDCARD = '*'
}

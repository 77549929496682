import styled from 'styled-components';
import { ContainerProps, InputStyledProps, InputIconProps } from './interface';
import { BorderRadius } from '../GlobalStyle';
import { Colors, getColor, getContrastColor } from '../Colors';

export const LabelStyle = styled.label<InputStyledProps>`
  color: ${getColor('neutral', 'a900')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  
  ${({ disabled }) => disabled && `
    color: ${getColor('neutral', 'a500')};
  `}
`;

export const Required = styled.span<InputStyledProps>`
  color: ${getColor('error', 'a300')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  
  ${({ disabled }) => disabled && `
    color: ${getColor('neutral', 'a300')};
  `}
`;

export const InputContainer = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: ${({ isFullWidth }) => (isFullWidth ? 'calc(100% - 3px)' : 'fit-content')};
`;

export const Container = styled.div<ContainerProps>`
  display: block;
  margin: auto;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'fit-content')};
  height: 54px;
`;

export const WrapperIcon = styled.label<InputIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: ${BorderRadius.medium};
  background: ${getColor('neutral', 'white')};
  bottom: 47px;
  left: calc(100% - 50px);
  width: 48px;
  height: 46px;
  cursor: text;
  
  svg {
    width: 22px;
    height: 22px;
    fill: ${({ disableIconColor, error }) => disableIconColor ? 'auto' : error ? Colors.error.a300 : getColor('primary', 'a300')};
    line {
      stroke: ${({ disableIconColor, error }) => disableIconColor ? 'auto' : error ? Colors.error.a300 : getColor('primary', 'a300')};
    }
    path {
      fill: ${({ disableIconColor, error }) => disableIconColor ? 'auto' : error ? Colors.error.a300 : getColor('primary', 'a300')};
    }
  }
`;

export const InputStyle = styled.input<InputStyledProps>`
  padding: ${({ icon }) => icon ? '14px 49px 14px 17px' : '14px 17px'};
  border-radius: ${BorderRadius.medium};
  outline: 1px solid ${({ error }) => error ? Colors.error.a300 : getColor('neutral', 'a600')};
  border: none;
  color: ${getContrastColor('neutral')};
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: ${({ isFullWidth, icon }) => isFullWidth ? icon ? 'calc(100% - 66px)' : 'calc(100% - 38px)' : icon ? '268px' : '300px'};
  margin: 4px 2px 1px 2px;
  
  ::placeholder {
    color: ${getColor('neutral', 'a300')};
  }

  :hover {
    outline: 2px solid ${({ error }) => error ? Colors.error.a300 : getColor('neutral', 'a600')};
  }
  
  :focus {
    outline: 2px solid ${({ error }) => error ? Colors.error.a300 : getColor('primary', 'a300')};
  }
  
  :disabled {
    color: ${getColor('neutral', 'a700')};
    outline: 2px solid ${({ error }) => error ? Colors.error.a300 : getColor('neutral', 'a300')};
    background: ${getColor('transparent')};
    cursor: not-allowed;

    ::placeholder {
      color: ${getColor('neutral', 'a400')};
    }
  }
`;

export const TooltipContainer = styled.div`
  display: block;
  float: right;
  width: 0;
  height: 0;
`;

export const Tooltip = styled.div`
  position: relative;
  display: block;
  width: 200px;
  height: 86px;
  padding: 8px;
  margin-left: calc(-200px - 16px);
  margin-top: calc(-86px - 24px);
  border-radius: 8px;
  background: ${Colors.neutral.a900};
`;

export const Arrow = styled.div`
  width: 0;
  height: 0;
  margin-left: -36px;
  border-left: 8px solid transparent; /* Lado esquerdo transparente */
  border-right: 8px solid transparent; /* Lado direito transparente */
  border-top: 8px solid ${Colors.neutral.a900};
`;

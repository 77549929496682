import React from 'react';

export const inactive = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <mask id="path-1-inside-1_358_1514" fill="white">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z"/>
  </mask>
  <path d="M2 2H14V0H2V2ZM2 2V0C0.895431 0 0 0.895431 0 2H2ZM2 14V2H0V14H2ZM2 14H0C0 15.1046 0.89543 16 2 16V14ZM14 14H2V16H14V14ZM14 14V16C15.1046 16 16 15.1046 16 14H14ZM14 2V14H16V2H14ZM14 2H16C16 0.89543 15.1046 0 14 0V2ZM1 2C1 1.44772 1.44772 1 2 1V-1C0.343146 -1 -1 0.343146 -1 2H1ZM1 14V2H-1V14H1ZM2 15C1.44772 15 1 14.5523 1 14H-1C-1 15.6569 0.343146 17 2 17V15ZM14 15H2V17H14V15ZM15 14C15 14.5523 14.5523 15 14 15V17C15.6569 17 17 15.6569 17 14H15ZM15 2V14H17V2H15ZM14 1C14.5523 1 15 1.44772 15 2H17C17 0.343146 15.6569 -1 14 -1V1ZM2 1H14V-1H2V1Z" fill="#49505A" mask="url(#path-1-inside-1_358_1514)"/>
</svg>;

export const active = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="14" height="14" fill="#F7C46B"/>
  <mask id="path-2-inside-1_358_1522" fill="white">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z"/>
  </mask>
  <path d="M2 2H14V0H2V2ZM2 2V0C0.895431 0 0 0.895431 0 2H2ZM2 14V2H0V14H2ZM2 14H0C0 15.1046 0.89543 16 2 16V14ZM14 14H2V16H14V14ZM14 14V16C15.1046 16 16 15.1046 16 14H14ZM14 2V14H16V2H14ZM14 2H16C16 0.89543 15.1046 0 14 0V2ZM1 2C1 1.44772 1.44772 1 2 1V-1C0.343146 -1 -1 0.343146 -1 2H1ZM1 14V2H-1V14H1ZM2 15C1.44772 15 1 14.5523 1 14H-1C-1 15.6569 0.343146 17 2 17V15ZM14 15H2V17H14V15ZM15 14C15 14.5523 14.5523 15 14 15V17C15.6569 17 17 15.6569 17 14H15ZM15 2V14H17V2H15ZM14 1C14.5523 1 15 1.44772 15 2H17C17 0.343146 15.6569 -1 14 -1V1ZM2 1H14V-1H2V1Z" fill="#F7C46B" mask="url(#path-2-inside-1_358_1522)"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M11.6515 5.1415C11.8495 4.94901 12.166 4.95347 12.3585 5.15146C12.551 5.34945 12.5465 5.666 12.3485 5.8585L7.20568 10.8585C7.01162 11.0472 6.70266 11.0472 6.5086 10.8585L3.65146 8.08072C3.45347 7.88823 3.44901 7.57168 3.6415 7.37368C3.834 7.17569 4.15055 7.17123 4.34854 7.36373L6.85714 9.80265L11.6515 5.1415Z" fill="#FFF8EC"/>
</svg>
;

export const disabledActive = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="14" height="14" fill="#F8F9FB"/>
  <mask id="path-2-inside-1_358_1526" fill="white">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 1H2C1.44772 1 1 1.44772 1 2V14C1 14.5523 1.44772 15 2 15H14C14.5523 15 15 14.5523 15 14V2C15 1.44772 14.5523 1 14 1ZM2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2Z"/>
  </mask>
  <path d="M2 2H14V0H2V2ZM2 2V0C0.895431 0 0 0.895431 0 2H2ZM2 14V2H0V14H2ZM2 14H0C0 15.1046 0.89543 16 2 16V14ZM14 14H2V16H14V14ZM14 14V16C15.1046 16 16 15.1046 16 14H14ZM14 2V14H16V2H14ZM14 2H16C16 0.89543 15.1046 0 14 0V2ZM1 2C1 1.44772 1.44772 1 2 1V-1C0.343146 -1 -1 0.343146 -1 2H1ZM1 14V2H-1V14H1ZM2 15C1.44772 15 1 14.5523 1 14H-1C-1 15.6569 0.343146 17 2 17V15ZM14 15H2V17H14V15ZM15 14C15 14.5523 14.5523 15 14 15V17C15.6569 17 17 15.6569 17 14H15ZM15 2V14H17V2H15ZM14 1C14.5523 1 15 1.44772 15 2H17C17 0.343146 15.6569 -1 14 -1V1ZM2 1H14V-1H2V1Z" fill="#CDD3DD" mask="url(#path-2-inside-1_358_1526)"/>
  <path fillRule="evenodd" clipRule="evenodd" d="M11.6515 5.1415C11.8495 4.94901 12.166 4.95347 12.3585 5.15146C12.551 5.34945 12.5465 5.666 12.3485 5.8585L7.20568 10.8585C7.01162 11.0472 6.70266 11.0472 6.5086 10.8585L3.65146 8.08072C3.45347 7.88823 3.44901 7.57168 3.6415 7.37368C3.834 7.17569 4.15055 7.17123 4.34854 7.36373L6.85714 9.80265L11.6515 5.1415Z" fill="#A8B1BD"/>
</svg>
;

export const disabledInactive = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#CDD3DD"/>
</svg>;

export const error = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="1" y="1" width="14" height="14" rx="1" stroke="#DF320C" strokeWidth="2"/>
</svg>;

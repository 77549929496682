import React from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Typography from '../../../common/designSystem/Typography/Typography';
import Button from '../../../common/designSystem/Button/Button';
import CourtMenagerButtonGroup from './components/ButtonGroup/CourtMenagerButtonGroup';
import GameLogo from './components/GameLogo/GameLogo';

const LandingPageHourControl = () => {
  return (
    <>
      <Grid alignContent={'start'} alignItems='center' isScrollable height={'calc(100% - 66px)'} rowGapXs={8}>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h1'} >Controle de Horários na Palma da Mão.</Typography>
          <Typography variant={'paragraph'} >Tenha total controle dos horários vendidos e vagos de uma maneira muito simples</Typography>
        </GridItem>
        <GameLogo></GameLogo>
        <GridItem xs={6}>
          <Button color={'primary'} onClick={() => {}} variant={'filled'} title={'Comece agora'} isFullWidth/>
        </GridItem>
        <GridItem xs={6} marginXs={'24px 0'}>
          <Typography variant={'h2'} bold={false}>Gestão de todas as suas quadras em um único lugar!</Typography>
          <Typography variant={'paragraph'} >Mantenha um controle de todas as suas quadras, datas e reservas em um único lugar de fácil acesso.</Typography>
        </GridItem>
        <GridItem xs={6}>
          <CourtMenagerButtonGroup></CourtMenagerButtonGroup>
        </GridItem>
        <GridItem xs={6}>
          <Button color={'primary'} onClick={() => {}} variant={'filled'} title={'Comece agora'} isFullWidth/>
        </GridItem>
      </Grid>
    </>
  );
};

export default LandingPageHourControl;

import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../designSystem/Grid/Grid';
import Typography from '../../designSystem/Typography/Typography';
import { Colors } from '../../designSystem/Colors';
import { ViewSchedules } from '../../model/SportCenter';
import Modal from '../../designSystem/Modal/Modal';
import ScheduleService from '../../services/schedule';
import { Schedule, SubSchedule } from '../../model/Schedule';
import { isDefined } from '../../util/validations';
import { formatarToReal, formatNumberToReal } from '../../util/formatText';
import Button from '../../designSystem/Button/Button';
import InputTime from '../../designSystem/InputTime/InputTime';
import useForm from '../../designSystem/hooks/UseForm/UseForm';
import Input from '../../designSystem/Input/Input';
import { formatToScheduleDate } from '../../util/formateDate';
import SportCenterService from '../../services/sportCenter';
import UserService from '../../services/user';
import { Required } from '../../designSystem/Input/styled';
import { enqueueSnackbar } from 'notistack';
import { generateNumberRange } from '../../util/number';
import useScreen from '../../hook/useScreen';
import { start } from 'repl';

const ShowCourtStatus: React.FC<ViewSchedules> = ({ courtName, get, courtId, date, id, playerName, status, type, startTime, endTime, sport, onlyName }) => {
  const [open, setOpen] = useState(false);
  const [doReservation, setDoReservation] = useState<string>();
  const [schedule, setSchedule] = useState<Schedule>();
  const [availables, setAvailables] = useState<number[]>([]);
  const [reserveds, setReserveds] = useState<number[]>([]);
  const [subSchedule, setSubSchedule] = useState<SubSchedule>();
  const { register, validateForm, getValue, setValue, registerValue, clearForm } = useForm();
  const { height } = useScreen();

  const getStatusColor = (status: string) => {
    switch (status) {
    case 'available':
      return Colors.chart6.main;
    case 'reserved':
      return Colors.chart2.main;
    case 'blocked':
      return Colors.neutral.a500;
    }
  };

  const getStatus = (status: string) => {
    switch (status) {
    case 'available':
      return 'Disponível';
    case 'reserved':
      return 'Reservado';
    case 'blocked':
      return 'Bloqueado';
    }
  };

  const getData = async () => {
    const scheduleTemp = await ScheduleService.get(id!.split('/')[0]!);
    const subScheduleTemp = await ScheduleService.getSubSchedule(id!.split('/')[0]!, id!.split('/')[1]!);
    setSchedule(scheduleTemp);
    setSubSchedule(subScheduleTemp);
  };

  useEffect(() => {
    if (isDefined(id)) {
      getData();
    }
  }, [id, open]);

  useEffect(() => {
    if (isDefined(date) && status === 'available') {
      getAvailable();
    }
  }, [status, date]);

  const getAvailable = async () => {
    const schedules = await ScheduleService.getViewSchedules(courtId, date);
    registerValue(register(), 'sportServed', true);
    registerValue(register(), 'reservationType', true);
    setAvailables(schedules.filter((schedule) => schedule.status === 'available').map((schedule) => Number(schedule.startTime.split(':')[0])));
    setReserveds(schedules.filter((schedule) => schedule.status === 'reserved').map((schedule) => Number(schedule.startTime.split(':')[0])));
  };

  function colmum(label: string, value?: string) {
    return (
      <>
        <GridItem xs={3}>{label}</GridItem>
        <GridItem xs={3} style={{ textAlign: 'right' }}>
          {value}
        </GridItem>
      </>
    );
  }

  const reservedModal = () => {
    return isDefined(schedule) && <Grid isScrollable style={{ maxHeight: `calc(${height}px - 112px)`, justifyContent: 'start' }} noMargin rowGapXs={8}>
      {colmum('Data:', (schedule!.date as string).replaceAll('-', '/'))}
      {colmum('Início:', startTime)}
      {colmum('Termino:', endTime)}
      {colmum('Tipo da reserva:', subSchedule?.reservationType)}
      {colmum('Esporte:', subSchedule?.sport)}
      {colmum('Valor:', `R$ ${formatarToReal(subSchedule?.price)}`)}
      {colmum('Status:', 'Pago')}
    </Grid>;
  };

  const availableModal = () => {
    const hours = () => {
      const start = Number(startTime.split(':')[0]);
      const hours = availables.filter((hour) => hour > start && reserveds.filter((hourTemp) => hourTemp >= start).every((hourTemp) => hour <= hourTemp));
      return hours.includes(23) ? [...hours, 0] : [...hours, hours[hours.length-1]+1];
    };

    const save = async () => {
      try {
        const duration = getValue('endTime')?.value - getValue('startTime')?.value;
        if (validateForm()) {
          const sportCenter = await SportCenterService.getByUserId((await UserService.getCurrent())!.id);
          await ScheduleService.save({
            sportCenterId: sportCenter?.id ?? '',
            courtId: courtId ?? '',
            courtName: courtName ?? '',
            centerName: sportCenter?.name ?? '',
            date: formatToScheduleDate(date),
            reservedHours: generateNumberRange(getValue('startTime')?.value ?? 0, (getValue('startTime')?.value ?? 0) + (isDefined(duration) ? duration - 0.5 : 0)),
            subSchedules: [{
              playerId: '',
              playerName: getValue('name') ?? '',
              paymentId: '',
              sport: isDefined(getValue('name')) ? getValue('sportServed') : '',
              price: isDefined(getValue('name')) ? parseFloat((getValue('price'))?.replace(/[^\d]/g, '')) / 100 : 0,
              scheduledHour: getValue('startTime')?.value,
              duration: duration,
              reservationType: isDefined(getValue('name')) ? getValue('reservationType') : 'Bloqueado'
            }]
          });
          clearForm();
          await get();
          setOpen(false);
          enqueueSnackbar('Salvo com sucesso', { variant: 'success' });
        } else {
          enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
        }
      } catch (e) {
        enqueueSnackbar((e as any)?.message, { variant: 'warning' });
      }
    };

    return (<Grid isScrollable style={{ maxHeight: `calc(${height}px - 112px)`, justifyContent: 'start' }} noMargin rowGapXs={8}>
      {!isDefined(doReservation) ? <>
        {colmum('Quadra:', courtName)}
        {colmum('Status:', getStatus(status))}
        {colmum('Início:', startTime)}
        {colmum('Término:', endTime)}
        <GridItem xs={3}>
          <Button color={'info'} isFullWidth onClick={() => setDoReservation('blocked')} variant={'outlined'} icon={<Typography variant={'paragraphSmall'} color={'info'} notIsContrastColor>Bloquear</Typography>}/>
        </GridItem>
        <GridItem xs={3}>
          <Button color={'primary'} isFullWidth onClick={() => setDoReservation('reservation')} variant={'filled'} title={'Reservar'}/>
        </GridItem>
      </> : <>
        {colmum('Quadra:', courtName)}
        {doReservation == 'reservation' && <>
          <GridItem xs={6}>
            <Typography variant={'small'}>Tipo da reserva: <Required>*</Required></Typography>
          </GridItem>
          <GridItem xs={6} flexDirection={'row'} style={{ flexWrap: 'wrap', gap: '16px' }}>
            <Button key={'diario'} color={'primary'} selected={getValue('reservationType') == 'Diário'} onClick={() => setValue( 'Diário', 'reservationType')} variant={'select'} title={'Diário'}/>
            <Button key={'mensal'} color={'primary'} selected={getValue('reservationType') == 'Mensal'} onClick={() => setValue( 'Mensal', 'reservationType')} variant={'select'} title={'Mensal'}/>
          </GridItem>
          {getValue('reservationType') == 'Mensal' && <GridItem xs={6} flexDirection={'row'} alignItems={'center'} style={{ flexWrap: 'wrap', gap: '8px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M11.9998 23.1426C18.1538 23.1426 23.1426 18.1538 23.1426 11.9998C23.1426 5.84576 18.1538 0.856934 11.9998 0.856934C5.84576 0.856934 0.856934 5.84576 0.856934 11.9998C0.856934 18.1538 5.84576 23.1426 11.9998 23.1426Z" stroke={Colors.warning.a300} stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 6V11.1429" stroke={Colors.warning.a300} stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M11.9997 17.1425C12.4731 17.1425 12.8569 16.7588 12.8569 16.2854C12.8569 15.812 12.4731 15.4282 11.9997 15.4282C11.5263 15.4282 11.1426 15.812 11.1426 16.2854C11.1426 16.7588 11.5263 17.1425 11.9997 17.1425Z" stroke={Colors.warning.a300} stroke-width="1.71429" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <Typography style={{ width: 'calc(100% - 32px)' }} variant={'small'} color={'warning'} colorVariant={'a300'} notIsContrastColor>Atenção, reserva para um mês (4 reservas), não renova automaticamente</Typography>
          </GridItem>}
        </>}
        <GridItem xs={6}>
          <InputTime register={register({ initialValue: { hour: Number(startTime.split(':')[0]), minute: 0, value: Number(startTime.split(':')[0]) } })} disabled label={'Inicio'} name={'startTime'} hourOptions={hours()} isFullWidth/>
        </GridItem>
        <GridItem xs={6}>
          <InputTime register={register({ initialValue: { hour: Number(endTime.split(':')[0]), minute: 0, value: Number(endTime.split(':')[0]) } })} required minuteOptions={['00']} disabled={hours().length == 0} hourOptions={hours()} label={'Término'} name={'endTime'} isFullWidth/>
        </GridItem>
        {hours().length == 0 && <GridItem xs={6}>
          <Typography variant={'paragraphSmall'} color={'info'} notIsContrastColor>Próximo horário já reservado/bloqueado, por isso não pode alterar o término.</Typography>
        </GridItem>}
        {doReservation == 'reservation' && <>
          <GridItem xs={6}>
            <Input register={register()} name={'name'} label={'Nome do cliente:'} required isFullWidth/>
          </GridItem>
          <GridItem xs={6}>
            <Typography variant={'small'}>Selecione o esporte: <Required>*</Required></Typography>
          </GridItem>
          <GridItem xs={6} flexDirection={'row'} style={{ flexWrap: 'wrap', gap: '16px' }}>
            {[...sport.split(' | ')].map((sportType) => <>
              <Button key={sportType} color={'primary'} selected={getValue('sportServed') == sportType} onClick={() => setValue( sportType, 'sportServed')} variant={'select'} title={sportType}/>
            </>)}
          </GridItem>
          <GridItem xs={6}>
            <Input register={register({ initialValue: 0, onMask: (value) => formatNumberToReal(value), onValidation: (value) => Number(value?.replace(/[^\d]/g, '')) > 0 })} required isFullWidth name={'price'} label={'Valor pago'}/>
          </GridItem>
        </>}
        <GridItem xs={6}>
          <Button disabled={!validateForm(true)} color={'primary'} isFullWidth onClick={() => save()} variant={'filled'} title={doReservation == 'reservation' ? 'Reservar' : 'Bloquear'}/>
        </GridItem>
      </>}
    </Grid>);
  };

  return (<>
    <GridItem onClick={() => status != 'blocked' && setOpen(true)} style={{ borderRadius: '8px', padding: '8px', gap: '4px' }} color={'neutral'} colorVariant={'white'} display={'flex'}>
      <Grid noMargin>
        <GridItem xs={6} display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <GridItem display={'flex'} flexDirection={'row'} style={{ gap: '8px' }}>
            <div style={{ height: '100%', width: '6px', background: getStatusColor(status) }}/>
            <GridItem display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'} style={{ width: '100%', height: 'fit-content' }}>
              <Typography variant={'body'}>{status == 'reserved' ? playerName : status == 'blocked' ? getStatus(status) : (isDefined(onlyName) && onlyName ? '' : courtName)}</Typography>
              <Typography variant={'xSmall'}>{status == 'reserved' ? courtName : getStatus(status)}</Typography>
            </GridItem>
          </GridItem>
          <GridItem display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
            <Typography variant={'body'} bold>{startTime}</Typography>
            <Typography variant={'xSmall'}>{endTime}</Typography>
          </GridItem>
        </GridItem>
        <GridItem xs={4} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} style={{ gap: '4px' }}>
          <Typography variant={'xSmall'}>{sport}</Typography>
        </GridItem>
        <GridItem xs={2} display={'flex'} flexDirection={'row'} justifyContent={'flex-start'} style={{ gap: '4px' }}>
          <Typography variant={'xSmall'} style={{ textAlign: 'end' }} isFullWidth>{status == 'reserved' && `${type}`}</Typography>
        </GridItem>
      </Grid>
    </GridItem>
    <Modal open={open} setOpen={setOpen} onCancel={() => {
      setDoReservation(undefined);
      setSchedule({} as Schedule);
      setSubSchedule({} as SubSchedule);
    }} title={(status == 'reserved' ? playerName : (isDefined(doReservation) ? (doReservation == 'reservation' ? 'Reservar Horário' : 'Bloquear Horário') : courtName)) ?? 'Carregando...'}>{status == 'reserved' ? reservedModal() : availableModal()}</Modal>
  </>
  );
};

export default ShowCourtStatus;

export const ReservationFlowSequence = {
  SPORT_CHOOSE: 'SportChoose',
  TIME_TYPE_CHOOSE: 'TimeTypeChoose',
  DATE_CHOOSE: 'DateChoose',
  WEEK_DAYS_CHOOSE: 'WeekDaysChoose',
  SPORT_CENTER_CHOOSE: 'SportCenterChoose',
  COURT_CHOOSE: 'CourtChoose',
  LOGIN: 'Login',
  REGISTER: 'Register',
  RESET_PASSWORD: 'ResetPassword',
  PAYMENT_METHOD_CHOOSE: 'PaymentMethodChoose',
  CONFIRM_PAYMENT: 'ConfirmPayment',
  NEW_CARD: 'NewCard',
  INVITATIONS: 'Invitations'
};

export type ReservationFlowSequence = 'SportChoose' | 'TimeTypeChoose' | 'DateChoose' | 'WeekDaysChoose' |
  'SportCenterChoose' | 'CourtChoose' | 'Login' | 'Register' | 'ResetPassword' | 'PaymentMethodChoose' |
  'ConfirmPayment' | 'NewCard' | 'Invitations';

import React, { useEffect, useState } from 'react';
import { SwitchCircle, SwitchStyled, WrapperSwitch } from './styled';
import { SwitchProps } from './interface';

const Switch = (Props: SwitchProps) => {
  const { name, labelActive, labelInactive, register, required = false, size = 'small', disabled } = Props;

  const onClickAction = () => {
    if (disabled) return;
    register?.setValue(!register.getValue(name), name);
  };

  useEffect(() => {
    register?.registerValue(register, name, required);
  }, []);

  return (
    <WrapperSwitch disabled={disabled} onClick={() => onClickAction()}>
      <SwitchStyled size={size} checked={(register?.getValue(name) as boolean) || false} disabled={disabled}>
        <SwitchCircle size={size} checked={(register?.getValue(name) as boolean) || false} disabled={disabled}/>
      </SwitchStyled>
      <label>{(register?.getValue(name) as boolean) || false ? labelActive : labelInactive}</label>
    </WrapperSwitch>
  );
};

export default Switch;

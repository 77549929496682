import styled from 'styled-components';
import { GridItemStyleProps, GridStyleProps, PaddingSize } from './interface';
import { device } from '../Screen';
import { getCssColorProps } from '../Colors';
import { useGlobalProps } from '../GlobalStyle';

const calcGrid6To12 = (columns: number | undefined) => {
  if (columns !== undefined) {
    return Math.round((columns / 6) * 12);
  }
  return columns;
};

const calcGrid12To6 = (columns: number | undefined) => {
  if (columns !== undefined) {
    return Math.round((columns / 12) * 6);
  }
  return columns;
};

export const getPaddingSize = (size: PaddingSize): number => {
  if (size === 'XS') {
    return 24;
  }
  if (size === 'SM') {
    return 32;
  }
  if (size === 'MD') {
    return 56;
  }
  if (size === 'LG') {
    return 64;
  }
  if (size === 'XL') {
    return 80;
  }
  if (size === 'XXL') {
    return 128;
  }

  return 0;
};

export const GridItemStyle = styled.div<GridItemStyleProps>`
  display: flex;
  flex-direction: ${({ isRow }) => isRow ? 'row' : 'column'};
  ${({ color, colorVariant }) => getCssColorProps(color, colorVariant)};
  
  @media ${device.xxl} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => xxl || xl || lg || calcGrid6To12(md) || calcGrid6To12(sm) || calcGrid6To12(xs) || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginXxl || marginXl || marginLg || marginMd || marginSm || marginXs || 0};
  }
  @media ${device.xl} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => xl || lg || calcGrid6To12(md) || calcGrid6To12(sm) || calcGrid6To12(xs) || xxl || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginXl || marginLg || marginMd || marginSm || marginXs || marginXxl || 0};
  }
  @media ${device.lg} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => lg || calcGrid6To12(md) || calcGrid6To12(sm) || calcGrid6To12(xs) || xl || xxl || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginLg || marginMd || marginSm || marginXs || marginXl || marginXxl || 0};
  }
  @media ${device.md} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => md || sm || xs || calcGrid12To6(lg) || calcGrid12To6(xl) || calcGrid12To6(xxl) || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginMd || marginSm || marginXs || marginLg || marginXl || marginXxl || 0};
  }
  @media ${device.sm} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => sm || xs || md || calcGrid12To6(lg) || calcGrid12To6(xl) || calcGrid12To6(xxl) || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginSm || marginXs || marginMd || marginLg || marginXl || marginXxl || 0};
  }
  @media ${device.xs} {
    grid-column: span ${({ xs, sm, md, lg, xl, xxl }) => xs || sm || md || calcGrid12To6(lg) || calcGrid12To6(xl) || calcGrid12To6(xxl) || 1};
    margin: ${({ marginXs, marginSm, marginMd, marginLg, marginXl, marginXxl }) =>
    marginXs || marginSm || marginMd || marginLg || marginXl || marginXxl || 0};
  }
  
  ${(Props) => useGlobalProps(Props)}
`;

export const GridStyle = styled.div<GridStyleProps>`
  display: grid;
  width: auto;
  ${({ height }) => height ? 'height: ' + height : 'height: 100%'};
  ${({ color, colorVariant }) => getCssColorProps(color, colorVariant)};
  overflow: hidden;
  ${({ isScrollable }) => isScrollable && 'overflow-y: auto;'};
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  margin-bottom: ${({ marginBottom }) => marginBottom || 0}px;
  justify-content: flex-start;

  @media ${device.xxl} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('XXL')}px`};
    column-gap: 24px;
    grid-template-columns: repeat(12, calc(100% / 12 - 22px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapXxl || rowGapXl || rowGapLg || rowGapMd || rowGapSm || rowGapXs || 0}px;
  }

  @media ${device.xl} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('XL')}px`};
    column-gap: 24px;
    grid-template-columns: repeat(12, calc(100% / 12 - 22px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapXl || rowGapLg || rowGapMd || rowGapSm || rowGapXs || rowGapXxl || 0}px;
  }

  @media ${device.lg} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('LG')}px`};
    column-gap: 24px;
    grid-template-columns: repeat(12, calc(100% / 12 - 22px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapLg || rowGapMd || rowGapSm || rowGapXs || rowGapXl || rowGapXxl || 0}px;
  }

  @media ${device.md} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('MD')}px`};
    column-gap: 24px;
    grid-template-columns: repeat(6, calc(100% / 6 - 20px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapMd || rowGapSm || rowGapXs || rowGapLg || rowGapXl || rowGapXxl || 0}px;
  }

  @media ${device.sm} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('SM')}px`};
    column-gap: 16px;
    grid-template-columns: repeat(6, calc(100% / 6 - 13.33333333px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapSm || rowGapXs || rowGapMd || rowGapLg || rowGapXl || rowGapXxl || 0}px;
  }
  
  @media ${device.xs} {
    padding: ${({ noMargin }) => noMargin ? 0 : `0 ${getPaddingSize('XS')}px`};
    column-gap: 16px;
    grid-template-columns: repeat(6, calc(100% / 6 - 13.33333333px));
    row-gap: ${({ rowGapXs, rowGapSm, rowGapMd, rowGapLg, rowGapXl, rowGapXxl }) => rowGapXs || rowGapSm || rowGapMd || rowGapLg || rowGapXl || rowGapXxl || 0}px;
  }
  
  ${(Props) => useGlobalProps(Props)}
`;

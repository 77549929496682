import Components from './pages/Common/Components/Components';
import SplashScreen from './pages/Common/SplashScreen/SplashScreen';
import Reservation from './pages/Player/Reservation/Reservation';
import MpRecive from './pages/SportCenter/MPRecive/MPRecive';
import React from 'react';
import SCHome from './pages/SportCenter/Home/Home';
import CreateAccountSportCenter from './pages/SportCenter/CreateAccount/CreateAccountSportCenter';
import Onboarding from './pages/SportCenter/Onboarding/Onboarding';
import SettingAddress from './pages/SportCenter/SettingAddress/SettingAddress';
import SettingInfra from './pages/SportCenter/SettingInfra/SettingInfra';
import SettingCourt from './pages/SportCenter/SettingCourt/SettingCourt';
import LandingPageHourControl from './pages/SportCenter/LandingPage/LandingPageHourControl';
import LandingPageFinanceMenager from './pages/SportCenter/LandingPage/LandingPageFinanceMenage';
import Login from './pages/Common/Login/Login';
import CreateAccount from './pages/Common/CreateAccount/CreateAccount';
import CreateAccountPlayer from './pages/Player/CreateAccount/CreateAccountPlayer';
import Home from './pages/Player/Home/Home';
import MyCourts from './pages/SportCenter/MyCourts/MyCourts';
import CourtPage from './pages/SportCenter/Court/CourtPage';
import PlayersGame from './pages/Player/PlayersGame/PlayersGame';
import MyCenter from './pages/SportCenter/MyCenter/MyCenter';
import PlayerAccount from './pages/Player/PlayerAccount/PlayerSettings/PlayerAccount';
import PlayerSettingPassword from './pages/Player/PlayerAccount/PlayerSettingPassword/PlayerSettingPassword';
import Invite from './pages/Player/Invite/invite';
import MyGames from './pages/Player/MyGames/MyGames';

export interface RouteConfig {
  path: string;
  element: React.ReactNode;
}

const SPORT_CENTER = '/sport-center';

export const SPORT_CENTER_ROUTER: RouteConfig[] = [
  {
    path: '/mp-receive',
    element: <MpRecive />
  },
  {
    path: SPORT_CENTER + '/create-account',
    element: <CreateAccountSportCenter />
  },
  {
    path: SPORT_CENTER + '/onboarding',
    element: <Onboarding />
  },
  {
    path: SPORT_CENTER + '/setting-address',
    element: <SettingAddress />
  },
  {
    path: SPORT_CENTER + '/setting-infra',
    element: <SettingInfra />
  },
  {
    path: SPORT_CENTER + '/setting-court',
    element: <SettingCourt />
  },
  {
    path: SPORT_CENTER + '/landing-page-hour-control',
    element: <LandingPageHourControl />
  },
  {
    path: SPORT_CENTER + '/landing-page-finance-menager',
    element: <LandingPageFinanceMenager />
  },
  {
    path: SPORT_CENTER + '/court',
    element: <CourtPage />
  },
  {
    path: SPORT_CENTER + '/my-courts',
    element: <MyCourts />
  },
  {
    path: SPORT_CENTER + '/my-center',
    element: <MyCenter />
  },
  {
    path: SPORT_CENTER + '/home',
    element: <SCHome />
  }
];

const PLAYER = '/player';

export const PLAYER_ROUTER: RouteConfig[] = [
  {
    path: PLAYER + '/reservation',
    element: <Reservation />
  },
  {
    path: PLAYER + '/my-games',
    element: <MyGames />
  },
  {
    path: PLAYER + '/create-account',
    element: <CreateAccountPlayer />
  },
  {
    path: PLAYER + '/invite',
    element: <Invite />
  },
  {
    path: PLAYER + '/players-game',
    element: <PlayersGame />
  },
  {
    path: PLAYER + '/player-account',
    element: <PlayerAccount />
  },
  {
    path: PLAYER + '/player-setting-password',
    element: <PlayerSettingPassword />
  },
  {
    path: PLAYER + '/home',
    element: <Home />
  }
];

export const ROUTER: RouteConfig[] = [
  ...SPORT_CENTER_ROUTER,
  ...PLAYER_ROUTER,
  {
    path: '/',
    element: <Reservation />
  },
  {
    path: '/*',
    element: <Reservation />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/create-account',
    element: <CreateAccount />
  },
  {
    path: '/components',
    element: <Components />
  },
  {
    path: '/404',
    element: <SplashScreen />
  },
  {
    path: '*',
    element: <SplashScreen />
  }
];

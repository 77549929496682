import { useEffect, useState } from 'react';

const useScreen = (blockAction = true) => {
  const [height, setHeight] = useState<number>(window.visualViewport?.height || 0);
  const [width, setWidth] = useState<number>(window.visualViewport?.width || 0);
  const [clicked, setClicked] = useState(false);

  const rootElement = document.getElementById('root')!;
  const htmlElement = document.getElementById('html')!;
  const bodyElement = document.getElementById('body')!;

  useEffect(() => {
    const interval = setInterval(() => {
      const tempHeight = window.visualViewport?.height || 0;
      if (tempHeight !== height) {
        setHeight(tempHeight);
      }
      const tempWidth = window.visualViewport?.width || 0;
      if (tempWidth !== width) {
        setWidth(tempWidth);
      }
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [height, width]);

  useEffect(() => {
    const handleDown = () => {
      setClicked(true);
    };

    const handleUp = () => {
      rootElement.scrollIntoView();
      setClicked(false);
    };

    const handleTouchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        handleDown();
      }
    };

    const handleTouchEnd = () => {
      handleUp();
    };

    document.addEventListener('mousedown', handleDown);
    document.addEventListener('mouseup', handleUp);
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('mousedown', handleDown);
      document.removeEventListener('mouseup', handleUp);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [rootElement]);

  useEffect(() => {
    const screenHeight = height + 'px';
    rootElement && (rootElement.style.height = screenHeight);
    htmlElement && (htmlElement.style.height = screenHeight);
    bodyElement && (bodyElement.style.height = screenHeight);
  }, [height, rootElement, htmlElement, bodyElement]);

  useEffect(() => {
    rootElement.style.transition = 'all 0.5s';
    if (window.innerHeight !== height && !blockAction) {
      rootElement.style.marginTop = ((window.innerHeight - height) / 2) + 'px';
      const interval = setInterval(() => {
        rootElement.scrollIntoView();
      });
      return () => {
        clearInterval(interval);
      };
    } else {
      rootElement.style.marginTop = '0';
      rootElement.scrollIntoView();
    }
  }, [height, clicked, blockAction, rootElement]);

  return {
    height,
    width
  };
};

export default useScreen;

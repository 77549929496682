import React from 'react';
import { CardContainer, CardText } from './styled';

const FrontCard = ({ name, number = '****', selected = '' }: {name?: string, number?: string, selected: string}) => {
  return (
    <CardContainer>
      <CardText right={16} top={10} style={{ textTransform: 'uppercase' }} selected={selected === 'name'} textAlign={'right'}>{name ? name : 'Nome do Títular'}</CardText>
      <CardText left={16} top={117} selected={selected === 'number'} textAlign={'left'}>**** **** **** {number}</CardText>
      <svg style={{ marginTop: '-40px' }} width="272" height="167" viewBox="0 0 272 167" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_1454_1529)">
          <rect width="272" height="167" rx="12" fill="url(#paint0_linear_1454_1529)"/>
          <path fillRule="evenodd" clipRule="evenodd" d="M12.9379 -43.6653C71.8839 -37.5686 78.3631 53.8424 128.176 85.9433C183.427 121.548 290.094 85.3901 310.272 147.945C330.469 210.559 216.275 241.519 198.822 304.952C179.771 374.19 260.092 464.068 209.318 514.849C161.521 562.654 80.4903 493.424 12.9379 490.867C-50.6098 488.461 -116.195 529.108 -172.93 500.38C-232.083 470.428 -267.797 405.28 -283.37 340.831C-298.27 279.166 -287.613 212.136 -255.849 157.221C-227.428 108.086 -166.494 94.1499 -121.027 60.1686C-74.6364 25.4973 -44.6698 -49.6236 12.9379 -43.6653Z" fill="#F7F9FC" fillOpacity="0.55"/>
          <rect x="24" y="28" width="51" height="32" rx="7" fill="#F4CA56"/>
          <path d="M44.0909 28L44.0909 37.9535M44.0909 53.3968L44.0909 51.3651M44.0909 37.9535L24 37.9535M44.0909 37.9535L44.0909 51.3651M44.0909 51.3651L24 51.3651M44.0909 57.9683L44.0909 60M54.9091 60L54.9091 51.3651M54.9091 36.127L54.9091 37.9535M54.9091 31.5556L54.9091 28M54.9091 37.9535L75 37.9535M54.9091 37.9535L54.9091 51.3651M54.9091 51.3651L75 51.3651" stroke="#B38E3E"/>
        </g>
        <defs>
          <linearGradient id="paint0_linear_1454_1529" x1="136" y1="0" x2="136" y2="167" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FFBD4A"/>
            <stop offset="0.501103" stopColor="#F6C46B"/>
            <stop offset="1" stopColor="#FFF8EC"/>
          </linearGradient>
          <clipPath id="clip0_1454_1529">
            <rect width="272" height="167" rx="12" fill="white"/>
          </clipPath>
        </defs>
      </svg>
    </CardContainer>
  );
};

export default FrontCard;

import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../../common/designSystem/Button/Button';
import Footer from '../../../../../common/component/Footer/Footer';
import { useContextReservation } from '../../Reservation';
import Back from '../../../../../common/designSystem/Icons/Back';
import Next from '../../../../../common/designSystem/Icons/Next';
import { isDefined } from '../../../../../common/util/validations';
import { ReservationFlowSequence } from '../../../../../common/constant/reservationFlow';

const NavigationButtons = () => {
  const navigate = useNavigate();
  const { goPreviousStep, currentStep, footerControl } = useContextReservation();

  const handleBackButtonClick = useCallback(() => {
    goPreviousStep();
  }, [currentStep, goPreviousStep, navigate]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        footerControl.onClickNext ? footerControl.onClickNext() : () => {};
      }
    };

    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [footerControl.onClickNext]);

  return (
    <>{((window.location.pathname == '/player/reservation' && currentStep == ReservationFlowSequence.SPORT_CHOOSE) || currentStep != ReservationFlowSequence.SPORT_CHOOSE) && <Footer hidden={footerControl.hiddenFooter} >
      {footerControl?.footerAlternative || (<>
        <Button onClick={footerControl?.onClickBackAlternative || handleBackButtonClick} color={'neutral'}
          {...(isDefined(footerControl?.tabIndexStart) ? { tabIndex: footerControl.tabIndexStart! + 1 } : {})}
          colorVariant={'a800'}
          iconLeft={<Back color={'neutral'} colorVariant={'a800'} />}
          variant={'transparent'}
          title={'Voltar'} />
        {footerControl?.showNextButton &&
          <Button onClick={() => footerControl.onClickNext ? footerControl.onClickNext() : () => {}}
            color={'primary'}
            {...(isDefined(footerControl?.tabIndexStart) ? { tabIndex: footerControl?.tabIndexStart } : {})}
            title={footerControl.nextText || 'Avançar'}
            disabled={!footerControl.canContinue}
            iconRight={<Next color={!footerControl.canContinue ? 'neutral' : 'primary'}
              colorVariant={!footerControl.canContinue ? 'a500' : 'main'} isContrastColor={footerControl.canContinue} />}
            variant={'filled'} />}
      </>)}
    </Footer>}</>
  );
};

export default NavigationButtons;

import styled from 'styled-components';
import { CheckboxStyledProps } from './interface';
export const WrapperCheckboxStyled = styled.div<CheckboxStyledProps>`
  display: flex;
  align-items: center;
  width: fit-content;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent !important;
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
  
  svg {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;
  }
  
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
  `}
`;

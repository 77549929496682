import React from 'react';
import { Grid, GridItem } from '../../../../../common/designSystem/Grid/Grid';
import BigButton from '../../../../../common/designSystem/BigButton/BigButton';
import GraphBarIncrease from '../../../../../common/designSystem/Icons/GraphBarIncrease';
import GraphDot from '../../../../../common/designSystem/Icons/GraphDot';
import GraphArowIncrease from '../../../../../common/designSystem/Icons/GraphArowIncrease';
import InterfaceUserCheck from '../../../../../common/designSystem/Icons/InterfaceUserCheck';
import Carousel from '../../../../../common/designSystem/Carousel/Carousel';
import { getPaddingSize } from '../../../../../common/designSystem/Grid/styled';
import { getDevice } from '../../../../../common/designSystem/Screen';

const buttonGroup = [
  {
    description: 'Aumente sua receita',
    iconTop: <GraphBarIncrease></GraphBarIncrease>
  },
  {
    description: 'Eficiência na gestão das suas quadras',
    iconTop: <GraphDot></GraphDot>
  },
  {
    description: 'Relatórios detalhados',
    iconTop: <GraphArowIncrease></GraphArowIncrease>
  },
  {
    description: 'Melhore a experiência do seu cliente',
    iconTop: <InterfaceUserCheck></InterfaceUserCheck>
  }
];

const CourtMenagerButtonGroup = () => {
  return (<Grid noMargin>
    <GridItem xs={6}>
      <Carousel height={'108px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
        {buttonGroup.slice(0, 2).map((obj) => <BigButton onClick={ () => {} } title={''} description={obj.description} iconTop={obj.iconTop} style={{ marginBottom: '8px', minWidth: '124px' }}/>)}
      </Carousel>
    </GridItem>
    <GridItem xs={6}>
      <Carousel height={'108px'} marginLeft={getPaddingSize(getDevice(window.innerWidth))}>
        {buttonGroup.slice(2, 4).map((obj) => <BigButton onClick={ () => {} } title={''} description={obj.description} iconTop={obj.iconTop} style={{ marginBottom: '8px', minWidth: '124px' }}/>)}
      </Carousel>
    </GridItem>
  </Grid>);
};

export default CourtMenagerButtonGroup;

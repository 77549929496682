import React from 'react';

import { MenuButtonProps } from './interface';
import Typography from '../Typography/Typography';
import { MenuButtonStyled } from './styled';

const MenuButton = ({ icon, title, onClick, ...props } : MenuButtonProps) => {
  return (
    <MenuButtonStyled onClick={onClick}>
      {icon}
      <Typography style={{ marginTop: '8px' }} variant={'xSmall'} isFullWidth center>{title}</Typography>
    </MenuButtonStyled>);
};

export default MenuButton;

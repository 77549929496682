import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  createContext,
  useContext
} from 'react';
import Header from '../../../common/component/Header/Header';
import { ReservationFlowSequence } from '../../../common/constant/reservationFlow';
import { useNavigate } from 'react-router-dom';
import { FooterControl, Loader, Reservation, ReservationContextType } from './interface';
import NavigationButtons from './Components/NavigationButtons/NavigationButtons';
import StepContainer from './Components/StepContainer/StepContainer';
import LoadingPage from './Components/LoadingPage/LoadingPage';
import { RoutePath } from '../../../routesPath';
import { isDefined } from '../../../common/util/validations';
import { useGlobalContext } from '../../../contexts/GlobalContext';
import SportCenterService from '../../../common/services/sportCenter';
import Button from '../../../common/designSystem/Button/Button';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import { Colors } from '../../../common/designSystem/Colors';
import Typography from '../../../common/designSystem/Typography/Typography';

const Context = createContext<ReservationContextType>({
  setNextStep: () => {},
  setData: (data: Reservation) => data,
  goPreviousStep: () => {},
  currentStep: '',
  data: {} as Reservation,
  footerControl: {} as FooterControl,
  setFooterControl: () => {},
  setLoader: () => {}
});

export const useContextReservation = () =>
  useContext<ReservationContextType>(Context);
const ReservationContext = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(
    ReservationFlowSequence.SPORT_CHOOSE
  );
  const { backoffice } = useGlobalContext();
  const queryParams = new URLSearchParams(location.search);
  const sport = queryParams.get('sport');
  const sportCenterId = queryParams.get('sportCenterId');
  const [previousStep, setPreviousStep] = useState<string[]>([]);
  const [data, setData] = useState<Reservation>({} as Reservation);
  const [footerControl, setFooterControl] = useState({} as FooterControl);
  const [loader, setLoader] = useState<Loader>({ isLoading: false, text: '' });

  const setNextStep = (step: string) => {
    setPreviousStep((old) => [...old, currentStep]);
    setCurrentStep(step);
  };

  useEffect(() => {
    if (isDefined(sport) && !isDefined(data.sport)) {
      const find = backoffice?.sports.find((sportTemp) => sportTemp.title == sport);
      setData({ ...data, sport: find });
      setNextStep(ReservationFlowSequence.TIME_TYPE_CHOOSE);
    }
  }, [sport]);

  useEffect(() => {
    if (isDefined(sportCenterId) && !isDefined(data.sportCenter)) {
      setLoader({ ...loader, isLoading: true });
      SportCenterService.get(sportCenterId!, true)
        .then((sportCenter) => setData({ ...data, sportCenter, alternativeFlux: true }))
        .then(() => {
          setLoader({ ...loader, isLoading: false });
          setNextStep(ReservationFlowSequence.COURT_CHOOSE);
        });
    }
  }, [sportCenterId]);

  const goPreviousStep = () => {
    if (previousStep.length === 0
          || (isDefined(sport) && previousStep.length === 1)
          || (isDefined(sportCenterId) && previousStep.length === 2)) {
      navigate(RoutePath.PLAYER_HOME);
    }
    if (currentStep === ReservationFlowSequence.PAYMENT_METHOD_CHOOSE && data.alternativeFlux) {
      data?.reservationType?.title === 'Diário' ?
        setCurrentStep(ReservationFlowSequence.DATE_CHOOSE)
        : setCurrentStep(ReservationFlowSequence.WEEK_DAYS_CHOOSE);
      setPreviousStep((old) => old.slice(0, 5));
    } else if (currentStep === ReservationFlowSequence.PAYMENT_METHOD_CHOOSE) {
      setCurrentStep(ReservationFlowSequence.COURT_CHOOSE);
      setPreviousStep((old) => old.slice(0, 4));
    } else if (currentStep === ReservationFlowSequence.CONFIRM_PAYMENT) {
      setCurrentStep(ReservationFlowSequence.PAYMENT_METHOD_CHOOSE);
      setPreviousStep((old) => old.slice(0, 5));
    } else {
      setCurrentStep(previousStep[previousStep.length - 1]);
      setPreviousStep( [...previousStep.slice(0, -1)]);
    }
  };

  const context = useMemo(
    () => ({
      data,
      setData,
      setNextStep,
      goPreviousStep,
      currentStep,
      footerControl,
      setFooterControl,
      setLoader
    } as ReservationContextType),
    [data, currentStep, footerControl]
  );

  const generateDocumentTitle = () => {
    const sport = data?.sport ? ` - ${data.sport.title}` : '';
    const timeType = data.reservationType ? ` - ${data.reservationType.title}` : '';
    document.title = `Reservar${sport}${timeType}`;
  };

  useEffect(() => {
    console.log(data);
    generateDocumentTitle();
  }, [data]);

  return (
    <Context.Provider value={context}>
      {window.location.pathname != '/player/reservation' &&
        <Header>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', alignItems: 'center', color: Colors.primary.main, fontWeight: 100 }}>
            <Button color={'primary'} onClick={() => navigate(RoutePath.CREATE_ACCOUNT)} variant={'filled'} icon={<Typography variant={'tiny'} color={'neutral'} colorVariant={'a800'} notIsContrastColor>Criar conta</Typography>}/>
            <Button color={'primary'} colorVariant={'a100'} onClick={() => navigate(RoutePath.LOGIN)} variant={'filled'} icon={<Typography variant={'tiny'} color={'primary'} notIsContrastColor>Entrar</Typography>}/>
          </div>
        </Header>}
      {loader.isLoading && <LoadingPage text={loader.text!} />}
      <StepContainer />
      <NavigationButtons />
    </Context.Provider>
  );
};

export default React.memo(ReservationContext);

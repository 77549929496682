import { getLocalStorage, setLocalStorage } from './localStorage';
import { isDefined } from './validations';

export const getCache = (serviceName: string, functionName: string, params: string | undefined) => {
  const service = getLocalStorage('cache')[serviceName];
  const funct = isDefined(service) ? service[functionName] : undefined;
  if (!isDefined(params)) {
    return funct;
  } else {
    const param = isDefined(funct) ? funct[params!] : undefined;
    return param;
  }
};
export const getTimeCacheInvalid = (serviceName: string, functionName: string, params: string | undefined, timeInMinutes = 60) => {
  const date = new Date();
  date.setMinutes(new Date().getMinutes() - timeInMinutes);
  const service = getLocalStorage('cache')[serviceName];
  if (!isDefined(params)) {
    const funct = isDefined(service) ? service['RT' + functionName] : undefined;
    const cacheTime = isDefined(funct) ? new Date(funct) : undefined;
    return (!isDefined(cacheTime) ? true : cacheTime!.getTime() < date.getTime());
  } else {
    const funct = isDefined(service) ? service[functionName] : undefined;
    const param = isDefined(funct) ? funct['RT' + params] : undefined;
    const cacheTime = isDefined(param) ? new Date(param) : undefined;
    return (!isDefined(cacheTime) ? true : cacheTime!.getTime() < date.getTime());
  }
};

export const setCache = (serviceName: string, functionName: string, params: string | undefined, value: any) => {
  if (isDefined(serviceName) && isDefined(functionName)) {
    const oldCache = getLocalStorage('cache');
    const service = isDefined(getLocalStorage('cache')[serviceName]) ? getLocalStorage('cache')[serviceName] : {};
    const funct = isDefined(service) ? service[functionName] : {};
    let cache: any;
    if (!isDefined(params)) {
      cache = {
        ...oldCache,
        [serviceName]: {
          ...service,
          [functionName]: Array.isArray(value) ? [...value] : { ...value },
          ['RT' + functionName]: new Date()
        }
      };
    } else {
      cache = {
        ...oldCache,
        [serviceName]: {
          ...service,
          [functionName]: {
            ...funct,
            [params!]: Array.isArray(value) ? [...value] : { ...value },
            ['RT' + params]: new Date()
          }
        }
      };
    }
    setLocalStorage(cache, 'cache');
  }
};

export const clearCacheByParam = (serviceName: string, functionName: string, params: string) => {
  const oldCache = getLocalStorage('cache');
  if (oldCache && oldCache[serviceName] && oldCache[serviceName][functionName] && oldCache[serviceName][functionName][params]) {
    delete oldCache[serviceName][functionName][params];
    setLocalStorage(oldCache, 'cache');
  }
};

export const clearCacheByService = (serviceName: string) => {
  const oldCache = getLocalStorage('cache');
  if (oldCache && oldCache[serviceName]) {
    delete oldCache[serviceName];
    setLocalStorage(oldCache, 'cache');
  }
};

export const clearCacheByFunction = (serviceName: string, functionName: string) => {
  const oldCache = getLocalStorage('cache');
  if (oldCache && oldCache[serviceName] && oldCache[serviceName][functionName]) {
    delete oldCache[serviceName][functionName];
    setLocalStorage(oldCache, 'cache');
  }
};

export const clearAllCache = () => {
  setLocalStorage({}, 'cache');
};

import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  deleteUser,
  sendPasswordResetEmail
} from 'firebase/auth';
import { appFirebase } from './firebase';
import { Role, User } from '../model/User';
import UserService from './user';
import { setError, translateError } from '../util/error';
import { isDefined } from '../util/validations';

const auth = getAuth(appFirebase);

class AuthenticationServiceProvider {
  createAccountWithEmailAndPassword = async (email: string, password: string, name: string, role: Role): Promise<User | undefined> => {
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      try {
        const newUser: User = {
          id: userCredential.user.uid,
          createAt: new Date(),
          lastLoginAt: new Date(),
          role,
          name,
          providers: ['email']
        };
        await UserService.save(newUser);
        return newUser;
      } catch (error) {
        await deleteUser(userCredential.user);
        throw new Error('Erro ao salvar usuário: ' + translateError((error as any)?.code));
      }
    } catch (error) {
      throw new Error('Erro ao registrar usuário: ' + translateError((error as any)?.code));
    }
  };

  loginWithEmailAndPassword = async (email: string, password: string): Promise<User | undefined> => {
    let userCredential: any = {};
    try {
      userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const currentUser = await UserService.getCurrent(user.uid);
      const newUser: User = {
        name: user.displayName || '',
        email: user.email || '',
        ...currentUser!,
        lastLoginAt: new Date(),
        ...(userCredential.providerId && { providers: [userCredential.providerId] })
      };
      await UserService.save(newUser);
      return newUser;
    } catch (error) {
      throw new Error('Erro ao autenticar usuário: ' + translateError((error as any)?.code));
    }
  };

  loginWithGoogle = async (role?: Role): Promise<User | undefined> => {
    try {
      const provider = new GoogleAuthProvider();
      const userCredential = await signInWithPopup(auth, provider);
      const user = userCredential.user;
      const userData = await UserService.getCurrent(user.uid);
      if (user) {
        let newUser: User;
        if (isDefined(userData)) {
          newUser = {
            ...userData!,
            lastLoginAt: new Date(),
            ...(userCredential.providerId && { providers: [userCredential.providerId] })
          };
        } else {
          if (isDefined(role)) {
            newUser = {
              role: role,
              id: user.uid,
              name: user.displayName || '',
              email: user.email || '',
              createAt: new Date(),
              lastLoginAt: new Date(),
              ...(userCredential.providerId && { providers: [userCredential.providerId] })
            };
          } else {
            newUser = {
              id: user.uid,
              name: user.displayName || '',
              email: user.email || '',
              createAt: new Date(),
              lastLoginAt: new Date(),
              ...(userCredential.providerId && { providers: [userCredential.providerId] })
            };
          }
        }
        await UserService.save(newUser);
        return newUser;
      }
    } catch (error) {
      throw new Error('Erro ao autenticar usuário: ' + translateError((error as any)?.code));
    }
  };

  linkWithPopup = async (provider: any): Promise<void> => {
    try {
      const user = auth.currentUser;
      await signInWithPopup(auth, provider);
      await UserService.save({ id: user?.uid, providers: [provider.providerId] } as User);
    } catch (error) {
      setError('Erro ao vincular conta', 'authenticaion.ts', 'linkWithPopup', error);
    }
  };

  sendEmailResetPassword = async (email: string): Promise<void> => {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error) {
      setError('Erro ao enviar email de redefinição de senha', 'authentication.ts', 'sendEmailResetPassword', error);
    }
  };
}

const AuthenticationService = new AuthenticationServiceProvider();
export default AuthenticationService;

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ROUTER } from './routes';
import GlobalContextProvider from './contexts/GlobalContext';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import EnvironmentMode from './common/component/EnvironmentMode/EnvironmentMode';
import Button from './common/designSystem/Button/Button';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SnackbarProvider
      action={(key) => (
        <Button
          onClick={() => closeSnackbar(key)}
          title={'Fechar'}
          color={'primary'} variant={'transparent'}/>
      )}
      preventDuplicate={true}
      maxSnack={5}
      dense={true}
      autoHideDuration={4000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}>
      <GlobalContextProvider>
        <EnvironmentMode/>
        <BrowserRouter>
          <Routes>
            {ROUTER.map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </BrowserRouter>
      </GlobalContextProvider>
    </SnackbarProvider>
  </React.StrictMode>
);
reportWebVitals();

import React from 'react';
import { IconProps } from './interface';
import { getColor, getContrastColor } from '../Colors';

const Menu = ({ height = 32, width = 32, colorVariant, color, isContrastColor }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="8.5" width="24" height="3" rx="1" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
      <rect x="4" y="14.5" width="24" height="3" rx="1" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
      <rect x="4" y="20.5" width="24" height="3" rx="1" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
    </svg>
  );
};

export default Menu;

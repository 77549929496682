import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../common/services/firebase';
import { isProduction } from '../../../common/util/getEnvironment';
import Logo from '../../../common/designSystem/Logo/Logo';
import styled from 'styled-components';
import { RoutePath } from '../../../routesPath';
import { SportCenter } from '../../../common/model/SportCenter';
import UserService from '../../../common/services/user';
import SportCenterService from '../../../common/services/sportCenter';
import { isDefined } from '../../../common/util/validations';
import Typography from '../../../common/designSystem/Typography/Typography';
import { enqueueSnackbar } from 'notistack';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 24px);
  padding: 12px;
  width: calc(100vw - 24px);
`;

const MpRecive = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');
  const state = queryParams.get('state');
  const navigate = useNavigate();
  const [status, setStatus] = useState('AAAA');
  const [sportCenter, setSportCenter] = useState({} as SportCenter);
  const userId = async () => (await UserService.getCurrent())!.id;
  const getSportCenter = async () => {
    setStatus('Obtendo centro esportivo');
    let sportCenter = await SportCenterService.getByUserId(await userId());
    sportCenter = { ...sportCenter, courts: await SportCenterService.getCourts(sportCenter.id!) };
    if (isDefined(sportCenter.workTime)) {
      setSportCenter(sportCenter);
    }
  };

  useEffect(() => {
    getSportCenter();
  }, []);

  const fetchToken = async () => {
    setStatus('Aguardando Mercado Pago');
    const mptoken = httpsCallable(functions, 'mptoken');
    const response = (
      await mptoken({ isProduction: isProduction, code, userId: state })).data as {status: number, data: any};
    if (response?.status === 200) {
      setStatus('Salvando... ');
      await SportCenterService.save({ ...sportCenter, paymentProvider: ['Mercado Pago'] });
      setStatus('Salvo!');
      enqueueSnackbar('Conta vinculada com sucesso!', { variant: 'success' });
      navigate(RoutePath.SPORT_CENTER_HOME);
    }
  };
  useEffect(() => {
    if (code !== undefined && isDefined(sportCenter)) {
      fetchToken();
    }
  }, [code, sportCenter]);

  return (
    <Container>
      <Typography variant={'h3'} color={'primary'} isFullWidth center>Registrando no Mercado Pago</Typography>
      <Typography variant={'body'} color={'primary'} isFullWidth center>Pode demorar alguns segundos, por favor aguarde!</Typography>
      <Typography variant={'body'} color={'primary'} isFullWidth center>{status}</Typography>
      <Logo size={'xxl'}/>
    </Container>
  );
};

export default MpRecive;

import React from 'react';
import { IconProps } from './interface';

const ShowAndHide = ({ height = 16, width = 16, onClick }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
      <g clipPath="url(#clip0_1429_740)">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 2C10.1293 2 12.0732 3.09656 13.7992 4.90476C14.3937 5.52758 14.9201 6.19316 15.3741 6.85896C15.5332 7.09237 15.6722 7.30956 15.7903 7.5052C15.8623 7.62444 15.9121 7.71134 15.9389 7.76057C16.0204 7.90981 16.0204 8.09019 15.9389 8.23943C15.9121 8.28866 15.8623 8.37556 15.7903 8.4948C15.6722 8.69044 15.5332 8.90763 15.3741 9.14104C14.9201 9.80684 14.3937 10.4724 13.7992 11.0952C12.0732 12.9034 10.1293 14 8 14C5.87074 14 3.92683 12.9034 2.20082 11.0952C1.60632 10.4724 1.0799 9.80684 0.625949 9.14104C0.466801 8.90763 0.327831 8.69044 0.209686 8.4948C0.137678 8.37556 0.0879066 8.28866 0.0610522 8.23943C-0.0203507 8.09019 -0.0203507 7.90981 0.0610522 7.76057C0.0879066 7.71134 0.137678 7.62444 0.209686 7.5052C0.327831 7.30956 0.466801 7.09237 0.625949 6.85896C1.0799 6.19316 1.60632 5.52758 2.20082 4.90476C3.92683 3.09656 5.87074 2 8 2ZM8 3C6.19176 3 4.47942 3.96594 2.92418 5.59524C2.37025 6.17555 1.87713 6.79903 1.45218 7.42229C1.29679 7.65019 1.17161 7.84608 1.07811 8C1.17161 8.15392 1.29679 8.34981 1.45218 8.57771C1.87713 9.20097 2.37025 9.82445 2.92418 10.4048C4.47942 12.0341 6.19176 13 8 13C9.80824 13 11.5206 12.0341 13.0758 10.4048C13.6298 9.82445 14.1229 9.20097 14.5478 8.57771C14.7032 8.34981 14.8284 8.15392 14.9219 8C14.8284 7.84608 14.7032 7.65019 14.5478 7.42229C14.1229 6.79903 13.6298 6.17555 13.0758 5.59524C11.5206 3.96594 9.80824 3 8 3ZM8 5C9.65685 5 11 6.34315 11 8C11 9.65685 9.65685 11 8 11C6.34315 11 5 9.65685 5 8C5 6.34315 6.34315 5 8 5ZM8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6Z" fill="#2C64EF"/>
      </g>
      <defs>
        <clipPath id="clip0_1429_740">
          <rect width="16" height="16" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default ShowAndHide;

import React from 'react';
import { ArrowStyled, ButtonPriceChooseStyled, ImageStyled } from './styled';
import Typography from '../../designSystem/Typography/Typography';
import { ButtonPriceChooseProps } from './interface';
import { Grid, GridItem } from '../../designSystem/Grid/Grid';
import Next from '../../designSystem/Icons/Next';

const ButtonPriceChoose = ({
  title,
  description,
  descriptionTwo,
  icon,
  margin,
  onClick
}: ButtonPriceChooseProps) => {
  return (
    <ButtonPriceChooseStyled margin={margin} onClick={() => onClick()}>
      <Grid noMargin style={{ width: '100%' }}>
        <GridItem xs={6} isRow alignItems={'center'} marginXs={'24px 8px'}>
          <ImageStyled>{icon}</ImageStyled>
          <GridItem alignItems={'flex-start'} marginXs={'0 0 0 8px'}>
            <Typography
              variant={'body'}
              color={'neutral'}
              colorVariant={'a900'}
              notIsContrastColor
              style={{ marginBottom: '4px' }}
            >
              {title}
            </Typography>
            <Typography
              variant={'tiny'}
              color={'neutral'}
              colorVariant={'a500'}
              notIsContrastColor
              style={{ marginBottom: '4px' }}
            >
              {description}
            </Typography>
            {descriptionTwo && (
              <Typography
                variant={'tiny'}
                color={'neutral'}
                colorVariant={'a500'}
                notIsContrastColor
              >
                {descriptionTwo}
              </Typography>
            )}
          </GridItem>
          <ArrowStyled>
            <Next />
          </ArrowStyled>
        </GridItem>
      </Grid>
    </ButtonPriceChooseStyled>
  );
};

export default ButtonPriceChoose;

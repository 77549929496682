import React from 'react';
import { IconProps } from './interface';

const TimeClockCircle = ({ width, height } : IconProps) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.50017 14.9645C12.3464 14.9645 15.4645 11.8464 15.4645 8.00017C15.4645 4.15391 12.3464 1.03589 8.50017 1.03589C4.65391 1.03589 1.53589 4.15391 1.53589 8.00017C1.53589 11.8464 4.65391 14.9645 8.50017 14.9645Z" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.5 5.32178V8.00035L11.2214 11.1718" stroke="#2C2B3A" strokeWidth="1.71429" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default TimeClockCircle;

import React, { useEffect, useState } from 'react';
import Back from '../Icons/Back';
import Next from '../Icons/Next';
import { GridItem } from '../Grid/Grid';
import Typography from '../Typography/Typography';
import Button from '../Button/Button';
import { Register } from '../hooks/UseForm/interface';
import { Day } from './styled';

const addDaysToDate = (date: Date, days: number): Date => {
  const newDate = new Date(date.getTime());
  newDate.setDate(date.getDate() + days);
  return newDate;
};

const getDaysOfWeek = (sumDay: number, selectedDay: Date) => {
  const daysOfWeek = [
    { number: 21, name: 'S', selected: true, date: new Date() },
    { number: 22, name: 'T', selected: false, date: new Date() },
    { number: 23, name: 'Q', selected: false, date: new Date() },
    { number: 24, name: 'Q', selected: false, date: new Date() },
    { number: 25, name: 'S', selected: false, date: new Date() },
    { number: 26, name: 'S', selected: false, date: new Date() },
    { number: 27, name: 'D', selected: false, date: new Date() }
  ];

  const today = new Date();

  return daysOfWeek.map((day, index) => {
    const date = addDaysToDate(today, index + sumDay);
    const dayNumber = date.getDate();
    const dayOfWeek = (date.toLocaleDateString('pt-BR', { weekday: 'short' })).slice(0, 1);

    return {
      ...day,
      selected: date.getDate() === selectedDay.getDate() && date.getMonth() === selectedDay.getMonth() && date.getFullYear() === selectedDay.getFullYear(),
      number: dayNumber,
      name: dayOfWeek,
      date: date
    };
  });
};

const DaysInWeek = ({ register, name, required= false }:{register?: Register, name: string, required?: boolean}) => {
  const [sumDay, setSumDay] = useState<number>(0);
  const [selectedDay, setSelectedDay] = useState(new Date());
  const [daysOfWeek, setDaysOfWeek] = useState(getDaysOfWeek(0, selectedDay));

  useEffect(() => {
    setDaysOfWeek(getDaysOfWeek(sumDay, selectedDay));
  }, [sumDay, selectedDay]);

  useEffect(() => {
    if (register) {
      register.initialValue = selectedDay;
      register?.registerValue(register, name, required);
    }
  }, []);

  useEffect(() => {
    register?.setValue(selectedDay, name);
  }, [selectedDay]);

  return (
    <GridItem display={'flex'} justifyContent={'center'} alignItems={'center'} flexDirection={'row'} style={{ gap: '4px' }}>
      <Button style={{ width: '24px' }} color={'neutral'} onClick={() => setSumDay((val) => val - 1)} variant={'transparent'} icon={<Back color={'neutral'} colorVariant={'a900'}/>}/>
      {daysOfWeek.map((day) =>
        <Day color={day.selected ? 'primary' : 'neutral'} colorVariant={day.selected ? 'main' : 'white'} onClick={() => setSelectedDay(day.date)} key={day.number}>
          <Typography style={{ textTransform: 'uppercase' }} variant={'small'} bold>{day.name}</Typography>
          <Typography variant={'small'}>{day.number}</Typography>
        </Day>
      )}
      <Button style={{ width: '24px' }} color={'neutral'} onClick={() => setSumDay((val) => val + 1)} variant={'transparent'} icon={<Next color={'neutral'} colorVariant={'a900'}/>}/>
    </GridItem>
  );
};

export default DaysInWeek;

import React from 'react';
import { IconProps } from './interface';
import { getColor, getContrastColor } from '../Colors';
import styled from 'styled-components';

export const Svg = styled.svg`
  @keyframes ckw {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  animation: ckw 1s linear infinite;
`;
const Loading = ({ color, colorVariant, width = 24, height = 24, isContrastColor = false }: IconProps) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.84581 21.1474C6.43903 21.8694 5.51701 22.132 4.85143 21.6384C3.39144 20.5555 2.191 19.1509 1.34796 17.5258C0.273704 15.4549 -0.173633 13.1158 0.0606815 10.7947C0.294996 8.47363 1.2008 6.27115 2.66726 4.45679C4.13373 2.64243 6.09726 1.29486 8.3176 0.57897C10.5379 -0.13692 12.9188 -0.190086 15.1689 0.425976C17.419 1.04204 19.4407 2.30061 20.9867 4.0477C22.5327 5.7948 23.5359 7.95464 23.8736 10.263C24.1386 12.0745 23.9847 13.9158 23.4322 15.6475C23.1804 16.4369 22.2786 16.7623 21.5267 16.414V16.414C20.7748 16.0656 20.4612 15.1749 20.6799 14.3756C21.0063 13.1833 21.0849 11.9311 20.9044 10.6974C20.6511 8.96627 19.8988 7.34653 18.7394 6.03633C17.58 4.72614 16.0639 3.78229 14.3765 3.32029C12.689 2.85829 10.9036 2.89816 9.23846 3.43503C7.57335 3.97189 6.10085 4.98247 5.0011 6.34312C3.90136 7.70376 3.22207 9.35547 3.04635 11.0961C2.87063 12.8368 3.2061 14.5909 4.01171 16.1439C4.58588 17.2508 5.38111 18.2212 6.34245 18.9984C6.98687 19.5193 7.2526 20.4255 6.84581 21.1474V21.1474Z" fill={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
      <defs>
        <radialGradient id="paint0_angular_358_1448" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(12 12) rotate(21.8014) scale(12.1166)">
          <stop offset="0.0242934" stopColor={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)}/>
          <stop offset="0.756173" stopColor={!isContrastColor ? getColor(color, colorVariant) : getContrastColor(color, colorVariant)} stopOpacity="0"/>
        </radialGradient>
      </defs>
    </Svg>
  );
};

export default Loading;

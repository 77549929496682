import React from 'react';

const Footvolley = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3071_2821)">
        <path d="M12 11.5C11.1534 17.2945 12.9259 19.3821 18.4355 21.5322M13.3441 17.7391C12.3739 16.404 12.082 14.6311 12.4572 11.8399C14.3995 10.9359 15.8893 10.0104 16.7969 8.58047C17.7815 7.02935 18.0222 4.98346 17.6338 1.9721C18.4352 2.4233 19.1771 2.96786 19.8449 3.59113C22.0946 5.69087 23.5 8.68091 23.5 12C23.5 15.7519 21.7038 19.0842 18.9224 21.184C16.0982 20.1069 14.3349 19.1025 13.3441 17.7391ZM16.5481 1.43425C17.0552 4.76127 16.811 6.69226 15.9526 8.04457C15.2069 9.2193 13.9471 10.0425 12.0401 10.931C10.0225 9.64607 8.29078 8.95576 6.47633 9.04174C4.61491 9.12995 2.76106 10.0313 0.503507 11.7131C0.525336 10.8209 0.648693 9.95444 0.862271 9.12463C2.13892 4.16449 6.64215 0.5 12 0.5C13.6164 0.5 15.1538 0.833199 16.5481 1.43425ZM12.5351 18.3269C13.6185 19.8178 15.4134 20.8609 17.9153 21.8641C17.1019 22.353 16.2234 22.7439 15.2958 23.0209C14.2523 23.3325 13.1462 23.5 12 23.5C5.96659 23.5 1.01808 18.8535 0.538166 12.944C3.02363 11.009 4.82544 10.1211 6.52367 10.0406C8.02447 9.9695 9.53216 10.5254 11.4606 11.7476C11.0794 14.6222 11.349 16.6948 12.5351 18.3269Z" stroke="#2C2B3A"/>
      </g>
      <defs>
        <clipPath id="clip0_3071_2821">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Footvolley;

import React, { useEffect, useState } from 'react';
import { Grid, GridItem } from '../../../common/designSystem/Grid/Grid';
import Logo from '../../../common/designSystem/Logo/Logo';
import Input from '../../../common/designSystem/Input/Input';
import Button from '../../../common/designSystem/Button/Button';
import { Colors } from '../../../common/designSystem/Colors';
import Typography from '../../../common/designSystem/Typography/Typography';
import Google from '../../../common/designSystem/Icons/Google';
import AuthenticationService from '../../../common/services/authentication';
import ShowAndHideActive from '../../../common/designSystem/Icons/ShowAndHideActive';
import ShowAndHide from '../../../common/designSystem/Icons/ShowAndHide';
import UserService from '../../../common/services/user';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../routesPath';
import useForm from '../../../common/designSystem/hooks/UseForm/UseForm';
import { isDefined, validateEmail, validatePassword } from '../../../common/util/validations';
import { getLocalStorage, setLocalStorage } from '../../../common/util/localStorage';
import { enqueueSnackbar } from 'notistack';
import { flutterMessage } from '../../../common/util/flutter';

const Login = () => {
  const [showPassword, setShowPassword] = useState(true);
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const mobile = queryParams.get('mobile');
  const [userId, setUserId] = useState('');
  const { register, validateForm, getValue } = useForm();

  async function goToHome() {
    const user = await UserService.getCurrent();
    const redirectPath = getLocalStorage('redirectPath');
    if (isDefined(redirectPath)) {
      setLocalStorage(null, 'redirectPath');
      return navigate(redirectPath);
    }
    if (user?.role == 'sportCenter') {
      return navigate(RoutePath.SPORT_CENTER_HOME);
    }
    if (user?.role == 'player') {
      return navigate(RoutePath.PLAYER_HOME);
    }
    if (!isDefined(user?.role)) {
      return navigate(RoutePath.CREATE_ACCOUNT);
    }
  }

  const loginWithGoogle = async () => {
    if (mobile === 'true') {
      flutterMessage('action', 'loginGoogle');
      return;
    }
    try {
      await AuthenticationService.loginWithGoogle();
      await goToHome();
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  const loginWithEmail = async () => {
    try {
      if (validateForm()) {
        await AuthenticationService.loginWithEmailAndPassword(getValue('email'), getValue('password'));
        await goToHome();
      } else {
        enqueueSnackbar('Preencher todos os campos corretamente', { variant: 'warning' });
      }
    } catch (e) {
      enqueueSnackbar((e as any)?.message, { variant: 'warning' });
    }
  };

  useEffect(() => {
    if (isDefined(userId)) {
      goToHome();
    }
  }, [userId]);

  const getUserId = async () => {
    setUserId((await UserService.getCurrent())!.id);
  };

  useEffect(() => {
    getUserId();
  }, []);

  return (
    <Grid alignContent={'start'} isScrollable height={'calc(100% - 48px)'} style={{ paddingTop: '24px', paddingBottom: '24px' }} rowGapXs={16} colorVariant={'a100'}>
      <GridItem alignItems={'center'} marginXs={'0 0 32px 0'} xs={6}>
        <Logo size={'lg'}/>
      </GridItem>
      <GridItem xs={6}>
        <Input register={register({ onValidation: (value) => validateEmail(value) })} name={'email'} label={'E-mail'} required type={'email'} isFullWidth/>
      </GridItem>
      <GridItem xs={6}>
        <Input register={register({ onValidation: (value) => validatePassword(value).length == 0 })} name={'password'} label={'Senha'} required isFullWidth
          type={!showPassword ? 'text' : 'password'} icon={!showPassword ? <ShowAndHideActive onClick={() => setShowPassword(!showPassword)} /> : <ShowAndHide onClick={() => setShowPassword(!showPassword)} />}
        />
      </GridItem>
      <GridItem xs={6}>
        <Button color={'info'} onClick={() => {}} variant={'transparent'} title={'Esqueci minha senha'} isFullWidth/>
      </GridItem>
      <GridItem xs={6}>
        <Button color={'primary'} onClick={() => loginWithEmail()} variant={'filled'} title={'Entrar'} isFullWidth/>
      </GridItem>
      {/* <GridItem xs={2} justifyContent={'center'}> */}
      {/*   <div style={{ width: '100%', height: '1px', background: Colors.neutral.a500 }}/> */}
      {/* </GridItem> */}
      {/* <GridItem xs={2}> */}
      {/*   <Typography variant={'small'} color={'neutral'} center colorVariant={'a500'} notIsContrastColor>ou</Typography> */}
      {/* </GridItem> */}
      {/* <GridItem xs={2} justifyContent={'center'}> */}
      {/*   <div style={{ width: '100%', height: '1px', background: Colors.neutral.a500 }}/> */}
      {/* </GridItem> */}
      {/* <GridItem xs={6}> */}
      {/*   <Button iconLeft={<Google/>} color={'neutral'} colorVariant={'white'} onClick={async () => loginWithGoogle()} variant={'filled'} title={'Continuar com Google'} isFullWidth/> */}
      {/* </GridItem> */}
      <GridItem xs={6} marginXs={'24px 0 0 0'}>
        <Typography variant={'small'} color={'neutral'} center colorVariant={'a500'}>Não tem conta? Cadastre-se, é grátis!</Typography>
      </GridItem>
      <GridItem xs={6}>
        <Button color={'info'} onClick={() => navigate(RoutePath.CREATE_ACCOUNT)} variant={'transparent'} title={'Quero me cadastrar'} isFullWidth/>
      </GridItem>
    </Grid>
  );
};

export default Login;

import { Duration } from '../model/Backoffice';
import { HourMinute } from '../model/SportCenter';
import { WeekDay } from '../model/enums/WeekDay';
import { WorkShift } from '../model/enums/WorkShift';

export const dateToString = (data: Date, separator: string): string => {
  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Note que os meses em JavaScript começam em 0
  const ano = String(data.getFullYear());

  return `${dia}${separator}${mes}${separator}${ano}`;
};

export function compareDates(data1: Date, data2: Date) {
  // Extrai o dia, mês e ano de cada data
  const dia1 = data1.getDate();
  const mes1 = data1.getMonth();
  const ano1 = data1.getFullYear();

  const dia2 = data2.getDate();
  const mes2 = data2.getMonth();
  const ano2 = data2.getFullYear();

  if (ano1 === ano2 && mes1 === mes2 && dia1 === dia2) {
    return true; // As datas são iguais
  } else {
    return false; // As datas são diferentes
  }
}

export const addDuration = (time: HourMinute, amountToSum: Duration): HourMinute => {
  const date = new Date();
  date.setHours(time.hour + amountToSum.hours);
  date.setMinutes((time?.minute || 0) + (amountToSum?.minutes || 0));
  return { hour: date.getHours(), minute: date.getMinutes(), value: time.value + amountToSum.value };
};

export const formatToScheduleDate = (date: Date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}-${month}-${year}`;
};

export const extractWeekday = (date: Date): WeekDay => {
  const weekdayString = date.toLocaleDateString('en-US', { weekday: 'long' }).trim().toUpperCase();
  return WeekDay[weekdayString as keyof typeof WeekDay];
};

export function nextDatesWithIndices(indices: number[], start= new Date()) {
  const today = start;
  const currentDay = today.getDay(); // Get the current day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
  const nextDates = [];

  for (let i = 0; i < indices.length; i++) {
    const daysToAdd = (indices[i] - currentDay + 7) % 7; // Calculate how many days until the next day with the specified index
    const date = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000); // Add the days to get the next date
    nextDates.push(date);
  }

  return nextDates;
}


export const getWorkShift = (hour: number): WorkShift => {
  if (hour >= 6 && hour < 12) {
    return WorkShift.MORNING;
  } else if (hour >= 12 && hour < 18) {
    return WorkShift.AFTERNOON;
  } else {
    return WorkShift.NIGHT;
  }
};
